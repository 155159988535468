import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from './Utils.js';
import { fetchComments, saveLike, saveLikeComment, addComment } from './ApiService';

function Post({ post, currentUser, updatePost }) {
    const [visibleComments, setVisibleComments] = useState(false);
    const [newComment, setNewComment] = useState('');
    const [comments, setComments] = useState(post.comments || []);
    const [likedByUser, setLikedByUser] = useState(post.likedByUser);
    const [likesCount, setLikesCount] = useState(post.likesCount);
    const [commentsCount, setCommentsCount] = useState(post.commentsCount);
    const [loadingComments, setLoadingComments] = useState(false);
    const textareaRef = useRef(null);

    // Function to adjust the height dynamically based on content and limit to 5 lines
    const autoGrow = (e) => {
        const textarea = e.target;
        textarea.style.height = 'auto'; // Reset the height
        textarea.style.height = `${textarea.scrollHeight}px`; // Adjust height based on content

        // Calculate the maximum height (5 lines)
        const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight, 10);
        const maxHeight = lineHeight * 5; // 5 lines

        // Apply the maximum height
        if (textarea.scrollHeight > maxHeight) {
            textarea.style.height = `${maxHeight}px`;
            textarea.style.overflowY = 'auto'; // Allow scrolling after reaching the limit
        } else {
            textarea.style.overflowY = 'hidden'; // Hide scrolling if under the limit
        }
    };

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [newComment]);

    // Handle liking a post
    const handleLike = async () => {
        if (!currentUser) {
            console.error("User not authenticated");
            return;
        }

        const userId = currentUser.uid;
        const newIsLike = !likedByUser;

        // Optimistically update the like state
        setLikedByUser(newIsLike);
        setLikesCount(prevLikes => newIsLike ? prevLikes + 1 : prevLikes - 1);

        try {
            await saveLike(post.id, userId, newIsLike);
        } catch (error) {
            console.error("Failed to update like:", error);
            setLikedByUser(!newIsLike); // Revert on error
            setLikesCount(prevLikes => !newIsLike ? prevLikes + 1 : prevLikes - 1);
        }
    };

    // Handle liking a comment
    const handleLikeComment = async (commentId) => {
        if (!currentUser) {
            console.error("User not authenticated");
            return;
        }

        const userId = currentUser.uid;

        const commentToLike = comments.find(comment => comment.id === commentId);
        if (!commentToLike) return;

        const newIsLike = !commentToLike.likedByUser;

        // Optimistically update the like state for the comment
        setComments(prevComments =>
            prevComments.map(comment =>
                comment.id === commentId
                    ? { ...comment, likesCount: newIsLike ? comment.likesCount + 1 : comment.likesCount - 1, likedByUser: newIsLike }
                    : comment
            )
        );

        try {
            await saveLikeComment(post.id, commentId, userId, newIsLike);
        } catch (error) {
            console.error("Failed to update comment like:", error);
            setComments(prevComments =>
                prevComments.map(comment =>
                    comment.id === commentId
                        ? { ...comment, likesCount: !newIsLike ? comment.likesCount + 1 : comment.likesCount - 1, likedByUser: !newIsLike }
                        : comment
                )
            );
        }
    };

    // // Helper function to await state update
    // const setLoadingCommentsAsync = (value) => {
    //     return new Promise(resolve => {
    //         setLoadingComments(value);
    //         resolve();
    //     });
    // };


    // Toggle comments visibility and fetch comments if not already fetched
    const toggleComments = async () => {
        if (!visibleComments) {
            // await setLoadingCommentsAsync(true);
            setLoadingComments(true);

            try {
                const loadedComments = await fetchComments(post.id);
                setComments(loadedComments);
            } catch (error) {
                console.error("Failed to load comments:", error);
            } finally {
                setLoadingComments(false);
            }
        }
        setVisibleComments(!visibleComments);
    };

    // Handle adding a new comment
    const handleAddComment = async () => {
        if (!currentUser || !newComment.trim()) {
            console.error("User not authenticated or comment is empty");
            return;
        }

        const commentData = {
            author: { name: currentUser.Name, photo: currentUser.Photo },
            content: newComment.trim(),
            createdAt: new Date().toISOString(),
        };

        try {
            // Add the comment to the database
            await addComment(post.id, commentData);

            // Optimistically update the local comments list
            setComments(prevComments => [...prevComments, commentData]);
            setCommentsCount(prevCount => prevCount + 1); // Increment comments count

            // Clear the comment input
            setNewComment('');

            // Reset the visibility state to force comments re-fetch when toggled again
            setVisibleComments(true);  // Keep the comments visible
        } catch (error) {
            console.error("Failed to add comment:", error);
        }
    };

    return (
        <div className="card card-flush mb-10" key={post.id}>
            <div className="card-header pt-9">
                <div className="d-flex align-items-center">
                    <div className="symbol symbol-50px me-5">
                        <img src={post.author.photo} alt="" />
                    </div>
                    <div className="flex-grow-1">
                        <a href="#" className="text-gray-800 text-hover-primary fs-5 fw-bold">{post.author.name}</a>
                        <span className="text-muted fs-7 fw-semibold d-block">{post.category}</span>
                        <span className="text-gray-500 fw-semibold d-block">{formatDate(post.createdAt)}</span>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <Link to={`/community/post/${post.id}`}>
                    <h4 className="fs-3 fw-bold text-gray-800 mb-3">{post.subject}</h4>
                </Link>
                {/* <h4 className="fs-3 fw-bold text-gray-800 mb-3">{post.subject}</h4> */}
                <div className="fs-6 fw-normal text-gray-700 mb-5" style={{ whiteSpace: 'pre-wrap' }}>
                    {post.content}
                </div>
                <div className="d-flex flex-wrap">
                    {post.hashtags.map((hashtag, index) => (
                        <span
                            key={index}
                            className={`badge badge-light-primary me-2 mb-2 mt-2 fs-7`}
                        // className={`badge ${selectedHashtag === hashtag ? "badge-light-danger" : "badge-light-primary"} me-2 mb-2 mt-2 fs-7`}
                        // onClick={() => handleHashtagClick(hashtag)}
                        // style={{ cursor: 'pointer' }}
                        >
                            #{hashtag}
                        </span>
                    ))}
                </div>
            </div>
            <div className="card-footer pt-0">
                <div className="separator separator-solid"></div>
                <ul className="nav py-3">
                    <li className="nav-item">
                        <span className="nav-link btn btn-sm btn-color-gray-600 btn-active-color-primary fw-bold px-4 me-1" onClick={toggleComments}>
                            <i className="ki-outline ki-message-text-2 fs-2 me-1"></i>{commentsCount === 0 ? "Comment" : commentsCount}
                        </span>
                    </li>
                    <li className="nav-item">
                        <span className="nav-link btn btn-sm btn-color-gray-600 btn-active-color-primary fw-bold px-4 me-1" onClick={handleLike}>
                            <i className={`ki-outline ki-heart fs-2 me-1 ${likedByUser ? 'text-danger' : ''}`}></i>
                            {likesCount === 0 ? "Like" : likesCount}
                        </span>
                    </li>
                </ul>

                {loadingComments ??
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading comments...</span>
                    </div>
                }

                {visibleComments && (
                    <div>
                        {loadingComments ? (
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading comments...</span>
                            </div>
                        ) : (
                            comments.map((comment, index) => (
                                <div className="d-flex pt-6" key={index}>
                                    <div className="symbol symbol-45px me-5">
                                        <img src={comment.author.photo} alt="" />
                                    </div>
                                    <div className="d-flex flex-column flex-row-fluid">
                                        <div className="d-flex align-items-center flex-wrap mb-0">
                                            <a href="#" className="text-gray-800 text-hover-primary fw-bold me-3">{comment.author.name}</a>
                                            <span className="text-gray-500 fw-semibold fs-7 me-5">{formatDate(comment.createdAt)}</span>
                                        </div>
                                        <span className="text-gray-800 fs-7 fw-normal pt-1">{comment.content}</span>
                                        {comment.id && (
                                            <div className="d-flex align-items-center mt-2">
                                                <span
                                                    className="nav-link btn btn-sm btn-color-gray-600 btn-active-color-primary fw-bold px-2 me-2"
                                                    onClick={() => handleLikeComment(comment.id)}
                                                >
                                                    <i className={`ki-outline ki-heart fs-2 me-1 ${comment.likedByUser ? 'text-danger' : ''}`}></i>
                                                    {/* {comment.likesCount ?? 0} Like{comment.likesCount === 1 ? "" : "s"} */}
                                                    {comment.likesCount == null || comment.likesCount == 0 ? "Like" : comment.likesCount}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                )}

                <div className="d-flex align-items-start mt-3 position-relative w-100">
                    <div className="symbol symbol-40px me-3">
                        <img src={currentUser?.Photo} alt="" />
                    </div>
                    <div className="position-relative w-100">
                        <textarea
                            ref={textareaRef}
                            className="form-control form-control-solid border ps-5"
                            rows="1"
                            placeholder="Write a comment..."
                            value={newComment}
                            onChange={(e) => {
                                setNewComment(e.target.value);
                                autoGrow(e);
                            }}
                            style={{
                                paddingRight: '70px', // Leave space for the button
                                resize: 'none',
                                overflow: 'hidden',
                                lineHeight: '1.5em',
                                maxHeight: '7.5em', // Limit to 5 lines (1.5em * 5)
                                marginTop: '0'
                            }}
                        ></textarea>
                        <button
                            onClick={handleAddComment}
                            className="btn btn-primary btn-sm"
                            style={{
                                position: 'absolute',
                                bottom: '10px', // Position the button at the bottom
                                right: '10px',
                                padding: '5px 10px',
                            }}
                        >
                            Send
                        </button>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Post;
