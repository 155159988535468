import React, { useState, useEffect, useCallback } from 'react';
import Swal from 'sweetalert2'
import Joyride from 'react-joyride';
import { useDropzone } from 'react-dropzone';
import Modal from "react-bootstrap/Modal";
import { useParams, Link } from 'react-router-dom';
import { useExhibitContext } from './Contexts/ExhibitContext.js';
import { formatDate, formatBytes, useQuery } from './Utils.js'
import { useAuth } from './Contexts/AuthContext';
import { isPetitionOwner } from './Utils.js'
import { fetchPetition, fetchExhibit, fetchFiles, updateDocument, deleteFile, addFileDocs, addFile, updateExhibitFilesInfo, updateExhibitFileVisibility } from './ApiService.js';
import Header from './Header.js';
import Footer from './Footer.js';
import Loading from './Loading';

function ExhibitDetail({ type }) {

    const { petitionId, exhibitId } = useParams(); // Get the file ID from URL parameters
    const { oauthAccessToken, currentUser, logout } = useAuth();
    const [exhibit, setExhibit] = useState(null);
    const [petition, setPetition] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(6);
    const [files, setFiles] = useState([]);
    const [updateFiles, setUpdateFiles] = useState(false); // New state to trigger re-fetching
    const [newFileModalIsOpen, setNewFileModalIsOpen] = React.useState(false);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const query = useQuery();
    const isShowTour = query.get('showTour') === '1';
    const [runTour, setRunTour] = useState(isShowTour);

    // Inside the component function
    const { triggerUpdate } = useExhibitContext();

    // const [selectedOption, setSelectedOption] = useState("");
    const [checkedItems, setCheckedItems] = useState({
        advancedDegree: {
            mastersDegree: false,
            bachelorsDegree: false,
            fiveYearsExperience: false
        },

        exceptionalAbility: {
            academicRecord: false,
            professionalExperience: false,
            professionalLicense: false,
            commandSalary: false,
            professionalMemberships: false,
            recognitionAchievements: false
        },

        descriptionEndeavor: [],
        documentaryEvidence: [],

        qualifications: [],
        futureActivityPlans: [],
        progressTowardsEndeavor: [],
        interestOfStakeholders: [],

        laborCertificationImpracticality: [],
        benefitToUSDespiteWorkers: [],
        urgencyAndJobCreation: []
    });

    const initialFormState = {
        fileName: "",
        isDocs: "true",
    };

    // const onDrop = useCallback(async (acceptedFiles) => {
    //     if (acceptedFiles.length > 0) {
    //         setLoading(true);
    //         for (const file of acceptedFiles) {  // Process each file
    //             try {
    //                 await addFile(file, petitionId, exhibitId, exhibit.folderId, oauthAccessToken, type);
    //             } catch (error) {
    //                 console.error(error);
    //                 if (error.message === 'Unauthorized') {
    //                     logout();
    //                 } else {
    //                     Swal.fire("Error", "An error occurred while creating the file. Please try again.", "error");
    //                 }
    //             }
    //         }
    //         setLoading(false);
    //         closeNewFileModal();
    //         setFormState(initialFormState);
    //     } else {
    //         Swal.fire("Info", "Please upload at least one file.", "info");
    //     }
    // }, [addFile, petitionId, exhibitId, oauthAccessToken, type, logout, initialFormState, closeNewFileModal]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: async (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                setLoading(true);

                for (const file of acceptedFiles) {  // Process each file
                    try {
                        await addFile(file, petitionId, exhibitId, exhibit.folderId, oauthAccessToken, type);
                    } catch (error) {
                        console.error(error);
                        if (error.message === 'Unauthorized') {
                            logout();
                        } else {
                            Swal.fire("Error", "An error occurred while creating the file. Please try again.", "error");
                        }
                    }
                }

                setUpdateFiles(prev => !prev);
                setFilesToUpload([]);  // Clear the files array        
                setLoading(false);
            } else {
                Swal.fire("Info", "Please upload at least one file.", "info");
            }
        }
    });

    const filesElements = filesToUpload.map(fileToUpload => (
        <li key={fileToUpload.name}>
            {fileToUpload.name} - {fileToUpload.size} bytes
        </li>
    ));

    useEffect(() => {
        async function fetchData() {
            await fetchPetitionCall(petitionId, setPetition);
            await fetchExhibitCall(petitionId, exhibitId, setExhibit);
            await fetchFilesCall(petitionId, exhibitId, setFiles);

            setLoading(false);
        }

        fetchData();

    }, [petitionId, exhibitId, updateFiles]);

    async function fetchPetitionCall(petitionId, setPetition) {
        fetchPetition(petitionId)
            .then((result) => {
                // console.log(result);
                setPetition(result);
                return result.id;
            })
            .catch((error) => {
                console.error('Error fetching petition:', error);
                return null;
            });
    }

    async function fetchExhibitCall(petitionId, exhibitId, setExhibit) {
        fetchExhibit(petitionId, exhibitId, type)
            .then((result) => {
                setExhibit(result);

                if (result.checkedItems) {
                    setCheckedItems(result.checkedItems);
                }

                return result.id;
            })
            .catch((error) => {
                console.error('Error fetching petition:', error);
                return null;
            });
    }

    const fetchFilesCall = useCallback(async (petitionId, exhibitId, setFiles) => {
        await updateExhibitFilesInfo(petitionId, exhibitId, oauthAccessToken, type);
        const files = await fetchFiles(petitionId, exhibitId, type);

        setFiles(files);
    }, [oauthAccessToken]);

    function openNewFileModal() {
        setNewFileModalIsOpen(true);
    }

    function closeNewFileModal() {
        setNewFileModalIsOpen(false);
    }

    const handleCheckboxChange = async (event, section, value) => {
        event.stopPropagation();

        setCheckedItems(prev => {
            const sectionData = prev[section];

            if (sectionData === undefined) {
                console.error(`Section "${section}" not found in state.`);
                return prev;
            }

            let newSectionData;

            if (typeof sectionData === 'object' && !Array.isArray(sectionData)) {
                // Handle object type (for advancedDegree and exceptionalAbility sections)
                const isChecked = sectionData[value];
                newSectionData = {
                    ...sectionData,
                    [value]: !isChecked
                };
            } else if (Array.isArray(sectionData)) {
                // Handle array type (for other sections)
                const isChecked = sectionData.includes(value);
                newSectionData = isChecked
                    ? sectionData.filter(item => item !== value)
                    : [...sectionData, value];
            } else {
                console.error(`Invalid data type for section "${section}": expected array or object.`);
                return prev;
            }

            // Check if there's a change
            if (JSON.stringify(sectionData) !== JSON.stringify(newSectionData)) {
                // There's a change
                const newState = { ...prev, [section]: newSectionData };
                saveCheckedItems(newState);
                return newState;
            }

            // No change
            return prev;
        });
    };

    const handleNewFileSubmit = async (event) => {
        event.preventDefault();  // Prevent the default form submission behavior
        setLoading(true);

        if (formState.isDocs === "true") {
            try {
                await addFileDocs(formState.fileName, petitionId, exhibitId, exhibit.folderId, oauthAccessToken, type);

                setUpdateFiles(prev => !prev);
                setLoading(false);
                closeNewFileModal();
                setFormState(initialFormState);

            } catch (error) {
                setLoading(false);

                console.error(error);
                if (error.message === 'Unauthorized') {
                    logout();
                } else {
                    Swal.fire("Error", "An error occurred while creating the file. Please try again.", "error");
                }
            }
        }
        else {
            if (filesToUpload.length > 0) {
                for (const file of filesToUpload) {  // Process each file

                    try {
                        await addFile(file, petitionId, exhibitId, exhibit.folderId, oauthAccessToken, type);

                        setUpdateFiles(prev => !prev);
                        setLoading(false);
                        closeNewFileModal();
                        setFormState(initialFormState);
                        setFilesToUpload([]);  // Clear the files array        

                    } catch (error) {
                        setLoading(false);

                        console.error(error);
                        if (error.message === 'Unauthorized') {
                            logout();
                        } else {
                            Swal.fire("Error", "An error occurred while creating the file. Please try again.", "error");
                        }
                    }
                }
            } else {
                setLoading(false);
                Swal.fire("Info", "Please upload at least one file.", "info");
            }
        }
    }

    const [formState, setFormState] = useState(initialFormState);

    const handleInputChange = (event) => {
        const target = event.target;
        let value = target.value;

        // If the input type is 'radio' and the target name is 'isDocs', convert value to string for correct comparison.
        if (target.type === 'radio' && target.name === 'isDocs') {
            value = target.value;
        }

        setFormState(prev => ({
            ...prev,
            [target.name]: value
        }));
    };

    const toggleFileVisibility = async (fileId) => {
        const fileToToggle = files.find(file => file.id === fileId);

        if (!fileToToggle) {
            console.error('File not found');
            return;
        }

        const isHidden = !fileToToggle.isHidden;

        setFiles(files.map(file =>
            file.id === fileId ? { ...file, isHidden } : file
        ));

        try {
            await updateExhibitFileVisibility(petitionId, exhibitId, type, fileId, isHidden);
        } catch (error) {
            console.error('Error updating file visibility:', error);
        }
    };

    const deleteFileDataConfirmation = async (fileId) => {
        Swal.fire({
            customClass: {
                confirmButton: "btn btn-danger",
                cancelButton: "btn btn-light-info"
            },
            buttonsStyling: false,
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteFileData(fileId);
            }
        });
    }

    const deleteFileData = async (fileId) => {

        setLoading(true);

        try {
            await deleteFile(petitionId, exhibitId, fileId, type);
            setUpdateFiles(prev => !prev); // Toggle the state to trigger re-fetching of files
        } catch (error) {
            console.error("Error deleting file:", error);
        } finally {
            setLoading(false);
        }
    };

    const saveCheckedItems = async (checkedItems) => {
        try {
            checkedItems = {
                checkedItems: checkedItems
            }

            let docPath = `Petitions/${petitionId}/Exhibits/${exhibitId}`;
            if (type && type == "RFEResponse") {
                docPath = `Petitions/${petitionId}/RFE/1/Exhibits/${exhibitId}`;
            }

            await updateDocument(docPath, checkedItems)

            triggerUpdate();
        } catch (error) {
            Swal.fire("Error", "Error saving data: " + error.message, "error");
        }
    };

    const getCheckedItemCount = (section) => {
        const sectionData = checkedItems[section];
        if (typeof sectionData === 'object' && !Array.isArray(sectionData)) {
            return Object.values(sectionData).filter(Boolean).length;
        } else if (Array.isArray(sectionData)) {
            return sectionData.length;
        }
        return 0;
    };

    const steps = [
        {
            target: '#navMenuFiles',
            content: 'This section allows you to upload or create files for each piece of evidence. Feel free to add as many files as necessary to fully support your evidence.',
            placement: 'top'
        },
        {
            target: '#navMenuAdvancedDegree',
            content: 'If you are applying under the Advanced Degree category, use this section to check off applicable items from the checklist that your evidence supports.',
            placement: 'top'
        },
        {
            target: '#navMenuExceptionalAbility',
            content: 'If you are applying under Exceptional Ability, select relevant items from the checklist that your evidence fulfills, similar to the Advanced Degree section.',
            placement: 'top'
        },
        {
            target: '#navMenuProng1',
            content: 'Review the checklist for Prong 1 here and mark any criteria that your evidence meets. Remember to do the same for Prongs 2 and 3 to ensure comprehensive coverage.',
            placement: 'top'
        },
    ];

    const handleFileUpload = async () => {
        if (filesToUpload.length > 0) {
            setLoading(true);
            for (const file of filesToUpload) {  // Process each file
                try {
                    await addFile(file, petitionId, exhibitId, exhibit.folderId, oauthAccessToken, type);
                    setUpdateFiles(prev => !prev);
                    setLoading(false);
                    closeNewFileModal();
                    setFormState(initialFormState);
                    setFilesToUpload([]);  // Clear the files array        
                } catch (error) {
                    setLoading(false);
                    console.error(error);
                    if (error.message === 'Unauthorized') {
                        logout();
                    } else {
                        Swal.fire("Error", "An error occurred while creating the file. Please try again.", "error");
                    }
                }
            }
        } else {
            setLoading(false);
            Swal.fire("Info", "Please upload at least one file.", "info");
        }
    };


    // if (loading) return <Loading />;
    // if (error) return <div>Error: {error}</div>;
    return (
        <>
            <Joyride
                continuous
                run={runTour}
                steps={steps}
                scrollToFirstStep
                showProgress
                showSkipButton
                styles={{
                    options: {
                        zIndex: 1000,
                        primaryColor: '#DC3545'
                    },
                }}
                callback={(data) => {
                    if (data.status === 'finished' || data.status === 'skipped') {
                        setRunTour(false);
                    }
                }}
            />
            <Loading isLoading={loading} />
            <div id="kt_app_body" data-kt-app-header-fixed-mobile="true" data-kt-app-toolbar-enabled="true" className="app-default">
                <Modal show={newFileModalIsOpen} onHide={closeNewFileModal} className="modal-lg">
                    <form id="uploadForm" onSubmit={handleNewFileSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>New File</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/*  {/* <!--begin::Modal body--> */}
                            <div className="scroll-y">
                                {/*  {/* <!--begin::Stepper--> */}
                                <div className="d-flex flex-column">
                                    {/*  {/* <!--begin::Form--> */}
                                    {/* <form className="mx-auto mw-600px w-100 py-10" noValidate="novalidate" id="kt_create_account_form"> */}
                                    {/*  {/* <!--begin::Step 2--> */}
                                    <div data-kt-stepper-element="content">
                                        {/*  {/* <!--begin::Wrapper--> */}
                                        <div className="w-100">
                                            {/*  {/* <!--begin::Heading--> */}
                                            <div className="pb-10 xpb-lg-15">
                                                {/*  {/* <!--begin::Title--> */}
                                                <h2 className="fw-bold text-gray-900">File Details</h2>
                                                {/*  {/* <!--end::Title--> */}
                                                {/*  {/* <!--begin::Notice--> */}
                                                <div className="text-muted fw-semibold fs-6">If you need more info, please contact us. </div>
                                                {/*  {/* <!--end::Notice--> */}
                                            </div>
                                            {/*  {/* <!--end::Heading--> */}
                                            {/* <!--begin::Input group--> */}
                                            <div className="fv-row">
                                                {/* <!--begin::Row--> */}
                                                <div className="row">
                                                    {/* <!--begin::Col--> */}
                                                    <div className="col-lg-12">
                                                        <input
                                                            type="radio"
                                                            className="btn-check"
                                                            name="isDocs"
                                                            value="true"  // Ensure value matches the type expected in state
                                                            checked={formState.isDocs === "true"}  // Convert state back to string for comparison
                                                            onChange={handleInputChange}
                                                            id="create_new_file"  // Match htmlFor with input id
                                                        />
                                                        <label className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10" htmlFor="create_new_file">
                                                            <img className=" fs-3x me-5" src="/assets/images/GoogleDriveLogo.png" alt="Google Docs File" style={{ width: "39px" }} />
                                                            <span className="d-block fw-semibold text-start">
                                                                <span className="text-gray-900 fw-bold d-block fs-4 mb-2">Create new Google Docs file</span>
                                                                <span className="text-muted fw-semibold fs-6">Recommended for cover letter, regular letters, business plan, etc.</span>
                                                            </span>
                                                        </label>
                                                    </div>
                                                    {/* <!--end::Col--> */}
                                                    {/* <!--begin::Col--> */}
                                                    {/* <div className="col-lg-6">
                                                        <input
                                                            type="radio"
                                                            className="btn-check"
                                                            name="isDocs"
                                                            value="false"  // Ensure value matches the type expected in state
                                                            checked={formState.isDocs === "false"}  // Convert state back to string for comparison
                                                            onChange={handleInputChange}
                                                            id="upload_existing_file"  // Match htmlFor with input id
                                                        />
                                                        <label className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center" htmlFor="upload_existing_file">
                                                            <i className="ki-outline ki-file-up fs-3x me-5"></i>
                                                            <span className="d-block fw-semibold text-start">
                                                                <span className="text-gray-900 fw-bold d-block fs-4 mb-2">Upload existing files</span>
                                                                <span className="text-muted fw-semibold fs-6">Use for upload PDFs, images, Word or Excel files, etc.</span>
                                                            </span>
                                                        </label>
                                                    </div> */}
                                                    {/* <!--end::Col--> */}
                                                </div>
                                                {/* <!--end::Row--> */}
                                            </div>
                                            {/* <!--end::Input group--> */}

                                            {formState.isDocs === "true" ? (
                                                <div className="fv-row">
                                                    {/*  {/* <!--begin::Label--> */}
                                                    <label className="form-label mb-3 required">File name</label>
                                                    {/*  {/* <!--end::Label--> */}
                                                    {/*  {/* <!--begin::Input--> */}
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-lg form-control-solid"
                                                        name="fileName"
                                                        value={formState.fileName}
                                                        onChange={handleInputChange}
                                                        required
                                                    />

                                                    {/* <input type="text" className="form-control form-control-lg form-control-solid" name="account_name" placeholder="" value="" /> */}
                                                    {/*  {/* <!--end::Input--> */}
                                                </div>
                                            ) : (
                                                < div className="mb-10 fv-row">
                                                    {/*  {/* <!--begin::Label--> */}
                                                    <label className="form-label mb-3 required">File(s)</label>
                                                    {/*  {/* <!--end::Label--> */}
                                                    {/*  {/* <!--begin::Input--> */}
                                                    {/* <input type="file" id="inputUploadFile" required /> */}
                                                    <div {...getRootProps()} className="dropzone">
                                                        <input {...getInputProps()} name="files" />
                                                        {isDragActive ?
                                                            <p>Drop the files here ...</p> :
                                                            <p>Drag 'n' drop some files here, or click to select files</p>
                                                        }
                                                    </div>
                                                    <ul>{filesElements}</ul>
                                                    {/* <input type="text" className="form-control form-control-lg form-control-solid" name="account_name" placeholder="" value="" /> */}
                                                    {/*  {/* <!--end::Input--> */}
                                                </div>
                                            )}
                                        </div>
                                        {/*  {/* <!--end::Wrapper--> */}
                                    </div>
                                    {/*  {/* <!--end::Step 2--> */}
                                    {/* </form> */}
                                    {/*  {/* <!--end::Form--> */}
                                </div>
                                {/*  {/* <!--end::Stepper--> */}
                            </div>
                            {/*  {/* <!--end::Modal body--> */}
                        </Modal.Body>
                        <Modal.Footer>
                            <span className="btn btn-light-secondary" onClick={closeNewFileModal}>
                                Close
                            </span>
                            <button className="btn btn-primary" type="submit">
                                Submit
                            </button>
                        </Modal.Footer>
                    </form>
                </Modal>

                {/* <!--begin::App--> */}
                <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
                    {/* <!--begin::Page--> */}
                    <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
                        {/* <!--begin::Header--> */}
                        <Header />
                        {/* <!--end::Header--> */}
                        {/* <!--begin::Wrapper--> */}
                        <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                            {/* <!--begin::Toolbar--> */}
                            <div id="kt_app_toolbar" className="app-toolbar pt-4 pt-lg-7 mb-n2 mb-lg-n3">
                                {/* <!--begin::Toolbar container--> */}
                                <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack flex-row-fluid">
                                    {/* <!--begin::Toolbar container--> */}
                                    <div className="d-flex flex-stack flex-row-fluid">
                                        {/* <!--begin::Toolbar container--> */}
                                        <div className="d-flex flex-column flex-row-fluid">
                                            {/* <!--begin::Toolbar wrapper--> */}
                                            {/* <!--begin::Breadcrumb--> */}
                                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold mb-1 mb-lg-3 me-2 fs-7">
                                                {/* <!--begin::Item--> */}
                                                <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                                                    <Link to={`/`}>
                                                        <span className="text-white text-hover-primary">
                                                            <i className="ki-outline ki-home text-gray-700 fs-6"></i>
                                                        </span>
                                                    </Link>
                                                </li>
                                                {/* <!--end::Item--> */}
                                                {/* <!--begin::Item--> */}
                                                <li className="breadcrumb-item">
                                                    <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1"></i>
                                                </li>
                                                {/* <!--end::Item--> */}
                                                {/* <!--begin::Item--> */}
                                                <li className="breadcrumb-item text-gray-700 fw-bold lh-1"><Link to={`/petition/${petitionId}`}>{petition?.petitionerName}</Link></li>
                                                {/* <!--end::Item--> */}
                                                {/* <!--begin::Item--> */}
                                                <li className="breadcrumb-item">
                                                    <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1"></i>
                                                </li>
                                                {/* <!--end::Item--> */}
                                                {/* <!--begin::Item--> */}
                                                <li className="breadcrumb-item text-gray-900 fs-4 fw-bold lh-1">{exhibit?.exhibitName}</li>
                                                {/* <!--end::Item--> */}
                                            </ul>
                                            {/* <!--end::Breadcrumb--> */}
                                        </div>
                                        {/* <!--end::Toolbar container--> */}
                                        {/* <!--begin::Actions--> */}
                                        {/* <!--end::Actions--> */}
                                    </div>
                                    {/* <!--end::Toolbar container--> */}
                                </div>
                                {/* <!--end::Toolbar container--> */}
                            </div>
                            {/* <!--end::Toolbar--> */}

                            {/* <!--begin::Wrapper container--> */}
                            <div className="app-container container-xxl d-flex">
                                {/* <!--begin::Main--> */}
                                {/* <!--begin::Main--> */}
                                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                                    {/* <!--begin::Content wrapper--> */}
                                    <div className="d-flex flex-column flex-column-fluid">
                                        {/* <!--begin::Content--> */}
                                        <div id="kt_app_content" className="app-content">
                                            {/* <!--begin::Navbar--> */}
                                            <div className="card mb-5 mb-xl-10">
                                                <div className="card-body pt-9 pb-0">
                                                    {/* <!--begin::Details--> */}
                                                    <div className="d-flex flex-wrap flex-sm-nowrap">
                                                        {/* <!--begin: Pic--> */}
                                                        {/* <div className="me-7 mb-4">
                                                        <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                                            <img src="assets/media/avatars/300-1.jpg" alt="image" />
                                                            <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                                                        </div>
                                                    </div> */}
                                                        {/* <!--end::Pic--> */}
                                                        {/* <!--begin::Info--> */}
                                                        <div className="flex-grow-1">
                                                            {/* <!--begin::Title--> */}
                                                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                                                {/* <!--begin::User--> */}
                                                                <div className="d-flex flex-column">
                                                                    {/* <!--begin::Info--> */}
                                                                    <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                                                        <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                                                                            <i className="ki-outline ki-lots-shopping fs-4 me-1"></i>{exhibit?.exhibitType}</span>
                                                                        <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                                                                            <i className="ki-outline ki-file-up fs-4 me-1"></i>{formatDate(exhibit?.createdAt)}</span>
                                                                    </div>
                                                                    <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                                                        <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                                                                            <i className="ki-outline ki-information-5 fs-4 me-1"></i> Go through the checklists below and select the items that best fit this evidence. </span>
                                                                    </div>
                                                                    {/* <!--end::Info--> */}
                                                                </div>
                                                                {/* <!--end::User--> */}
                                                                {/* <!--begin::Actions--> */}
                                                                {/* <div className="d-flex my-4">
                                                                <span className="btn btn-sm btn-primary me-3" onClick={() => {
                                                                    const url = exhibit.isDocs
                                                                        ? `https://docs.google.com/document/d/${exhibit.googleDocId}/edit`
                                                                        : `https://drive.google.com/file/d/${exhibit.googleDocId}/view`;
                                                                    window.open(url, '_blank');
                                                                }}>Open</span>
                                                            </div> */}
                                                                {/* <!--end::Actions--> */}
                                                            </div>
                                                            {/* <!--end::Title--> */}
                                                        </div>
                                                        {/* <!--end::Info--> */}
                                                    </div>
                                                    {/* <!--end::Details--> */}
                                                    {/* <!--begin::Navs--> */}
                                                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                                                        {/* <!--begin::Nav item--> */}
                                                        <li className="nav-item mt-2" style={{ cursor: 'pointer' }} id="navMenuFiles">
                                                            <span onClick={() => setActiveTab(6)} className={`nav-link text-active-primary ms-0 me-10 py-5 ${activeTab === 6 ? "active" : ""}`}>Files
                                                                {files.length > 0 ? (
                                                                    <span className="badge badge-light-primary ms-2">{files.length}</span>
                                                                ) : (
                                                                    <span className="badge badge-light-danger ms-2">0</span>
                                                                )}

                                                            </span>
                                                        </li>
                                                        {/* <!--end::Nav item--> */}
                                                        {/* <!--begin::Nav item--> */}
                                                        {petition?.subCategory == "AD" && (
                                                            <li className="nav-item mt-2" style={{ cursor: 'pointer' }} id="navMenuAdvancedDegree">
                                                                <span onClick={() => setActiveTab(1)} className={`nav-link text-active-primary ms-0 me-10 py-5 ${activeTab === 1 ? "active" : ""}`}>Advanced Degree
                                                                    {getCheckedItemCount("advancedDegree") > 0 && (
                                                                        <span className="badge badge-light-primary ms-2">{getCheckedItemCount("advancedDegree")}</span>
                                                                    )}
                                                                </span>
                                                            </li>
                                                        )}
                                                        {/* <!--end::Nav item--> */}
                                                        {/* <!--begin::Nav item--> */}
                                                        {petition?.subCategory == "EA" && (
                                                            <li className="nav-item mt-2" style={{ cursor: 'pointer' }} id="navMenuExceptionalAbility">
                                                                <span onClick={() => setActiveTab(2)} className={`nav-link text-active-primary ms-0 me-10 py-5 ${activeTab === 2 ? "active" : ""}`}>Exceptional Ability
                                                                    {getCheckedItemCount("exceptionalAbility") > 0 && (
                                                                        <span className="badge badge-light-primary ms-2">{getCheckedItemCount("exceptionalAbility")}</span>
                                                                    )}
                                                                </span>
                                                            </li>
                                                        )}
                                                        {/* <!--end::Nav item--> */}
                                                        {/* <!--begin::Nav item--> */}
                                                        <li className="nav-item mt-2" style={{ cursor: 'pointer' }} id="navMenuProng1">
                                                            <span onClick={() => setActiveTab(3)} className={`nav-link text-active-primary ms-0 me-10 py-5 ${activeTab === 3 ? "active" : ""}`}>Prong 1
                                                                {getCheckedItemCount("descriptionEndeavor") + getCheckedItemCount("documentaryEvidence") > 0 && (
                                                                    <span className="badge badge-light-primary ms-2">{getCheckedItemCount("descriptionEndeavor") + getCheckedItemCount("documentaryEvidence")}</span>
                                                                )}
                                                            </span>
                                                        </li>
                                                        {/* <!--end::Nav item--> */}
                                                        {/* <!--begin::Nav item--> */}
                                                        <li className="nav-item mt-2" style={{ cursor: 'pointer' }} id="navMenuProng2">
                                                            <span onClick={() => setActiveTab(4)} className={`nav-link text-active-primary ms-0 me-10 py-5 ${activeTab === 4 ? "active" : ""}`}>Prong 2
                                                                {getCheckedItemCount("qualifications") + getCheckedItemCount("futureActivityPlans") + getCheckedItemCount("progressTowardsEndeavor") + getCheckedItemCount("interestOfStakeholders") > 0 && (
                                                                    <span className="badge badge-light-primary ms-2">{getCheckedItemCount("qualifications") + getCheckedItemCount("futureActivityPlans") + getCheckedItemCount("progressTowardsEndeavor") + getCheckedItemCount("interestOfStakeholders")}</span>
                                                                )}
                                                            </span>
                                                        </li>
                                                        {/* <!--end::Nav item--> */}
                                                        {/* <!--begin::Nav item--> */}
                                                        <li className="nav-item mt-2" style={{ cursor: 'pointer' }} id="navMenuProng3">
                                                            <span onClick={() => setActiveTab(5)} className={`nav-link text-active-primary ms-0 me-10 py-5 ${activeTab === 5 ? "active" : ""}`}>Prong 3
                                                                {getCheckedItemCount("laborCertificationImpracticality") + getCheckedItemCount("benefitToUSDespiteWorkers") + getCheckedItemCount("urgencyAndJobCreation") > 0 && (
                                                                    <span className="badge badge-light-primary ms-2">{getCheckedItemCount("laborCertificationImpracticality") + getCheckedItemCount("benefitToUSDespiteWorkers") + getCheckedItemCount("urgencyAndJobCreation")}</span>
                                                                )}
                                                            </span>
                                                        </li>
                                                        {/* <!--end::Nav item--> */}
                                                    </ul>
                                                    {/* <!--begin::Navs--> */}
                                                </div>
                                            </div>
                                            {/* <!--end::Navbar--> */}
                                            {/* <!--begin::Card--> */}
                                            <div className="card mb-5 mb-lg-10" style={{ display: activeTab === 6 ? "block" : "none" }}>
                                                {/* <!--begin::Card header--> */}
                                                <div className="card-header">
                                                    {/* <!--begin::Card title--> */}
                                                    <div className="card-title">
                                                        <h3 className='card-title align-items-start flex-column'>
                                                            <span className="card-label fs-2 fw-bold text-primary">Files</span>
                                                            <span className="text-gray-500 mt-1 fw-semibold fs-6">Create or upload all files relevant to this specific exhibit.</span>
                                                        </h3>
                                                    </div>
                                                    {/* <!--end::Card title--> */}
                                                    {/* <!--begin::Card toolbar--> */}
                                                    <div className="card-toolbar">
                                                        {/* <!--begin::Button--> */}

                                                        {/* <label className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10" htmlFor="create_new_file">
                                                            <img className=" fs-3x me-5" src="/assets/images/GoogleDriveLogo.png" alt="Google Docs File" style={{ width: "39px" }} />
                                                            <span className="d-block fw-semibold text-start">
                                                                <span className="text-gray-900 fw-bold d-block fs-4 mb-2">Create new file</span>
                                                                <span className="text-muted fw-semibold fs-6">Recommended for cover letter, regular letters, business plan, etc.</span>
                                                            </span>
                                                        </label> */}

                                                        {isPetitionOwner(petition, currentUser) ? (
                                                            <span className="btn btn-sm btn-primary" onClick={openNewFileModal}><img className=" fs-3x me-5" src="/assets/images/GoogleDriveLogo.png" alt="Google Docs File" style={{ width: "30px" }} />Google Docs</span>
                                                        ) : (<></>)}

                                                        {/* <span onClick={saveCheckedItems} className="btn btn-sm btn-primary my-1">New File</span> */}
                                                        {/* <!--end::Button--> */}
                                                    </div>
                                                    {/* <!--end::Card toolbar--> */}
                                                </div>

                                                <div className="card-body">
                                                    {/* <GoogleDriveFrame documentId={exhibit.googleDocId} title={exhibit.exhibitName} isDocs={exhibit.isDocs} mimeType={exhibit.mimeType} /> */}
                                                    {/*  {/* <!--begin::Table container--> */}
                                                    {(1 == 1 || files.length > 0 ?
                                                        <div className="table-responsive">
                                                            {/*  {/* <!--begin::Table--> */}
                                                            {/* <table className="table table-row-dashed align-middle gs-0 gy-6 my-0">
                                                                <thead>
                                                                    <tr className="fs-7 fw-bold text-gray-500">
                                                                        <th className="p-0 pb-3 min-w-150px text-start">NAME</th>
                                                                        <th className="p-0 pb-3 w-200px text-start">TYPE</th>
                                                                        <th className="p-0 pb-3 w-150px text-start">SIZE</th>
                                                                        <th className="p-0 pb-3 min-w-100px text-start">CREATED AT</th>
                                                                        <th className="p-0 pb-3 min-w-100px text-start">MODIFIED AT</th>
                                                                        <th className="p-0 pb-3 min-w-100px text-center">ACTION</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="fw-semibold text-gray-600" style={{ whiteSpace: 'nowrap' }}>
                                                                    {files.map(file => (
                                                                        <tr key={file.id}>
                                                                            <td className="ps-0 text-start">
                                                                                <div className="d-flex align-items-center">
                                                                                    <a href={file.isDocs
                                                                                        ? `https://docs.google.com/document/d/${file.googleDocId}/edit`
                                                                                        : `https://drive.google.com/file/d/${file.googleDocId}/view`}
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer">
                                                                                        <span className="text-primary fs-6 d-block ellipsis" data-bs-toggle="tooltip" title={file.fileName}>{file.fileName}</span>
                                                                                    </a>
                                                                                </div>
                                                                            </td>
                                                                            <td className="ps-0">
                                                                                {file.isDocs === 0 ? file.mimeType : (<img src="/assets/images/GoogleDriveLogo.png" alt="Google Docs File" style={{ width: "25px" }} />)}
                                                                            </td>
                                                                            <td className="ps-0 pe-20">
                                                                                <span>{formatBytes(file.size)}</span>
                                                                            </td>
                                                                            <td className="ps-0">
                                                                                <span>{formatDate(file.createdAt)}</span>
                                                                            </td>
                                                                            <td className="ps-0">
                                                                                <span>{formatDate(file.modifiedAt)}</span>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <span className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px me-lg-1">
                                                                                    <i className="ki-outline ki-message-edit fs-2 text-primary" style={{ padding: "5px" }}
                                                                                        onClick={() => {
                                                                                            const url = file.isDocs
                                                                                                ? `https://docs.google.com/document/d/${file.googleDocId}/edit`
                                                                                                : `https://drive.google.com/file/d/${file.googleDocId}/view`;
                                                                                            window.open(url, '_blank');
                                                                                        }}></i>
                                                                                </span>
                                                                                <span className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px">
                                                                                    <i className="ki-outline ki-delete-folder fs-2 text-danger" onClick={() => deleteFileData(file.id)}>
                                                                                    </i>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table> */}
                                                            {/* <!--begin::Row--> */}
                                                            <div className="row g-5 gx-xl-10 mb-5 mb-xl-10">
                                                                {isPetitionOwner(petition, currentUser) ? (
                                                                    <div className="col-sm-6 col-xxl-3">
                                                                        {/* <!--begin::Card widget 14--> */}
                                                                        <div {...getRootProps()} className="min-h-350px dropzone h-xl-100 btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center active">
                                                                            {/* <!--begin::Body--> */}
                                                                            <input {...getInputProps()} name="files" disabled={loading} />
                                                                            {isDragActive ?
                                                                                <p>Drop the files here ...</p> :
                                                                                <p><i className="ki-outline ki-file-up fs-3x me-5"></i> <br />Drag 'n' drop some files here, or click to select files</p>
                                                                            }
                                                                        </div>
                                                                        {/* <!--end::Card widget 14--> */}
                                                                    </div>
                                                                ) : (<></>)}

                                                                {files.map(file => (
                                                                    <div key={file.id} className="col-sm-6 col-xxl-3">
                                                                        {/* <!--begin::Card widget 14--> */}
                                                                        <div className="card card-flush h-xl-100">
                                                                            {/* <!--begin::Body--> */}
                                                                            <div className="card-body text-center pb-5">
                                                                                {/* <!--begin::Overlay--> */}
                                                                                <a className={`d-block overlay ${file.isHidden ? 'hidden-mask' : ''}`} data-fslightbox="lightbox-hot-sales" href={file.isDocs
                                                                                    ? `https://docs.google.com/document/d/${file.googleDocId}/edit`
                                                                                    : `https://drive.google.com/file/d/${file.googleDocId}/view`}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    data-bs-toggle="tooltip" title={`${file.isHidden ? 'Hidden files will not be included in the print version.' : ''}`}>
                                                                                    {/* <!--begin::Image--> */}
                                                                                    <div className="overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover mb-7 border border-gray-600" style={{ height: "266px", backgroundImage: `url("${file.isDocs ? "/assets/images/GoogleDriveLogo_old.png" : file.thumbnailLink}")` }}></div>
                                                                                    {/* <!--end::Image--> */}
                                                                                    {/* <!--begin::Action--> */}
                                                                                    <div className="overlay-layer bg-dark bg-opacity-25">
                                                                                        <i className="ki-outline ki-eye fs-3x text-white"></i>
                                                                                    </div>
                                                                                    {/* <!--end::Action--> */}
                                                                                </a>
                                                                                {/* <!--end::Overlay--> */}
                                                                                {/* <!--begin::Info--> */}
                                                                                <span className={`text-start fw-bold ${file.isHidden ? "text-danger" : "text-gray-800 text-hover-primary"} cursor-pointer fs-5 d-block`}>{file.fileName}</span>
                                                                                <div className="d-flex align-items-end flex-stack mb-1 mt-1">
                                                                                    {/* <!--begin::Title--> */}
                                                                                    <div className="text-start">
                                                                                        <span className="text-gray-700 cursor-pointer text-hover-primary fs-6 d-block">{formatDate(file.createdAt)}</span>
                                                                                        {/* <span className="text-gray-500 mt-1 fw-bold fs-6">Last Modified: [Last Modified Date]</span> */}
                                                                                    </div>
                                                                                    {/* <!--end::Title--> */}
                                                                                    {/* <!--begin::Total--> */}
                                                                                    <span className="text-gray-600 text-end fw-bold fs-6">{formatBytes(file.size)}</span>
                                                                                    {/* <!--end::Total--> */}
                                                                                </div>
                                                                                {/* <!--end::Info--> */}
                                                                            </div>
                                                                            {/* <!--end::Body--> */}
                                                                            {isPetitionOwner(petition, currentUser) ? (
                                                                                <>
                                                                                    {/* <!--begin::Footer--> */}
                                                                                    <div className="card-footer d-flex flex-stack pt-0">
                                                                                        {/* <!--begin::Link--> */}
                                                                                        <span className="btn btn-sm btn-light-primary icon-wrapper" data-bs-toggle="tooltip" title="Hidden files will not be included in the print version.">
                                                                                            <i className={`ki-outline ${file.isHidden ? "ki-eye-slash" : "ki-eye"} fs-2`} onClick={() => toggleFileVisibility(file.id)}></i>
                                                                                        </span>
                                                                                        {/* <span className='btn btn-sm btn-light-primary' onClick={() => toggleFileVisibility(file.id, file.isHidden)}><i className={`ki-outline ${file.isHidden ? "ki-eye-slash" : "ki-eye"} fs-2`}></i></span> */}
                                                                                        {/* <a className="btn btn-sm btn-primary flex-shrink-0 me-2" data-bs-target="#kt_modal_bidding" data-bs-toggle="modal">Place a Bid</a> */}
                                                                                        {/* <!--end::Link--> */}
                                                                                        {/* <!--begin::Link--> */}
                                                                                        <span className="btn btn-sm btn-light-danger"
                                                                                            onClick={() => deleteFileDataConfirmation(file.id)}>Delete</span>
                                                                                        {/* <!--end::Link--> */}
                                                                                    </div>
                                                                                    {/* <!--end::Footer--> */}
                                                                                </>
                                                                            ) : (<></>)}
                                                                        </div>
                                                                        {/* <!--end::Card widget 14--> */}
                                                                    </div>

                                                                ))}
                                                            </div>
                                                            {/* <!--end::row--> */}
                                                        </div>
                                                        :
                                                        <div style={{ display: 'grid', placeItems: 'center', height: '60' }}>
                                                            <img height="300px" src="/assets/media/illustrations/21.png" alt="No files found"></img>
                                                            <p style={{ textAlign: 'center', marginTop: '20', fontSize: '16', color: '#555' }}>
                                                                Get started on your EB-2 NIW petition by creating or uploading your supporting documents!
                                                            </p>
                                                        </div>
                                                    )}
                                                    {/*  {/* <!--end::Table--> */}

                                                </div>
                                                {/* <!--end::Card body--> */}
                                            </div>
                                            {/* <!--end::Card--> */}
                                            {/* <!--begin::Login sessions--> */}
                                            <div className="card mb-5 mb-lg-10" style={{ display: activeTab === 1 ? "block" : "none" }}>
                                                {/* <!--begin::Card header--> */}
                                                <div className="card-header">
                                                    {/* <!--begin::Card title--> */}
                                                    <div className="card-title">
                                                        <h3 className='card-title align-items-start flex-column'>
                                                            <span className="card-label fs-2 fw-bold text-primary">Advanced Degree</span>
                                                            <span className="text-gray-500 mt-1 fw-semibold fs-6">The person's proposed endeavor has both substantial merit and national importance.</span>
                                                        </h3>
                                                    </div>
                                                    {/* <!--end::Card title--> */}
                                                    {/* <!--begin::Card toolbar--> */}
                                                    <div className="card-toolbar">
                                                        {/* <!--begin::Button--> */}
                                                        {/* <span onClick={saveCheckedItems} className="btn btn-sm btn-primary my-1">Save</span> */}
                                                        {/* <!--end::Button--> */}
                                                    </div>
                                                    {/* <!--end::Card toolbar--> */}
                                                </div>
                                                {/* <!--end::Card header--> */}
                                                {/* <!--begin::Card body--> */}
                                                <div className="card-body py-0">
                                                    <form className="form">
                                                        {/* Master's Degree */}
                                                        <div className='card-header'>
                                                            <h3 className='card-title align-items-start flex-column py-3'>
                                                                <span className="card-label fs-4 fw-bold text-gray-800">Master's Degree or Higher</span>
                                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">Evidence of a U.S. master's degree or higher, or foreign equivalent.</span>
                                                            </h3>
                                                        </div>
                                                        <div className="card-body px-12 py-0 mt-3 mb-3">
                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.advancedDegree.mastersDegree} onChange={(e) => handleCheckboxChange(e, "advancedDegree", "mastersDegree")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">U.S. master's degree or higher, or foreign equivalent.</span>
                                                                </span>
                                                            </label>
                                                        </div>

                                                        {/* Bachelor's Degree */}
                                                        <div className='card-header'>
                                                            <h3 className='card-title align-items-start flex-column py-3'>
                                                                <span className="card-label fs-4 fw-bold text-gray-800">Bachelor's Degree Plus 5 Years of Experience</span>
                                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">Evidence of a U.S. bachelor's degree, or foreign equivalent, plus 5 years of progressive post-degree work experience.</span>
                                                            </h3>
                                                        </div>
                                                        <div className="card-body px-12 py-0 mt-3 mb-3">
                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.advancedDegree.bachelorsDegree} onChange={(e) => handleCheckboxChange(e, "advancedDegree", "bachelorsDegree")} />
                                                                <span className="form-check-label d-flex flex-column alignments-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">U.S. bachelor's degree or foreign equivalent.</span>
                                                                </span>
                                                            </label>
                                                            <label className="form-check form-check-custom form-check-solid alignments-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.advancedDegree.fiveYearsExperience} onChange={(e) => handleCheckboxChange(e, "advancedDegree", "fiveYearsExperience")} />
                                                                <span className="form-check-label d-flex flex-column alignments-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">5 years of progressive post-degree work experience.</span>
                                                                </span>
                                                            </label>
                                                        </div>

                                                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                                                            {/* <button className="btn btn-light btn-active-light-primary me-2">Discard</button> */}
                                                            {/* <span onClick={saveCheckedItems} className="btn btn-primary px-6">Save Changes</span> */}
                                                        </div>
                                                    </form>
                                                </div>
                                                {/* <!--end::Card body--> */}
                                            </div>
                                            {/* <!--end::Login sessions--> */}
                                            {/* <!--begin::Card--> */}
                                            <div className="card mb-5 mb-lg-10" style={{ display: activeTab === 2 ? "block" : "none" }}>
                                                {/* <!--begin::Card header--> */}
                                                <div className="card-header">
                                                    {/* <!--begin::Card title--> */}
                                                    <div className="card-title">
                                                        <h3 className='card-title align-items-start flex-column'>
                                                            <span className="card-label fs-2 fw-bold text-primary">Exceptional Ability</span>
                                                            <span className="text-gray-500 mt-1 fw-semibold fs-6">The person's proposed endeavor has both substantial merit and national importance.</span>
                                                        </h3>
                                                    </div>
                                                    {/* <!--end::Card title--> */}
                                                    {/* <!--begin::Card toolbar--> */}
                                                    <div className="card-toolbar">
                                                        {/* <!--begin::Button--> */}
                                                        {/* <span onClick={saveCheckedItems} className="btn btn-sm btn-primary my-1">Save</span> */}
                                                        {/* <!--end::Button--> */}
                                                    </div>
                                                    {/* <!--end::Card toolbar--> */}
                                                </div>
                                                {/* <!--end::Card header--> */}
                                                {/* <!--begin::Card body--> */}
                                                <div className="card-body py-0">
                                                    {/* <!--begin::Form--> */}
                                                    <form className="form">
                                                        {/* Academic Record */}
                                                        <div className='card-header'>
                                                            <h3 className='card-title align-items-start flex-column py-3'>
                                                                <span className="card-label fs-4 fw-bold text-gray-800">Academic Record</span>
                                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">Documentary evidence of educational qualifications.</span>
                                                            </h3>
                                                        </div>
                                                        <div className="card-body px-12 py-0 mt-3 mb-3">
                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.exceptionalAbility.academicRecord} onChange={(e) => handleCheckboxChange(e, "exceptionalAbility", "academicRecord")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Degree, diploma, certificate, or award relevant to area of exceptional ability.</span>
                                                                </span>
                                                            </label>
                                                        </div>

                                                        {/* Professional Experience */}
                                                        <div className='card-header'>
                                                            <h3 className='card-title align-items-start flex-column py-3'>
                                                                <span className="card-label fs-4 fw-bold text-gray-800">Professional Experience</span>
                                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">Evidence of extensive experience in the field.</span>
                                                            </h3>
                                                        </div>
                                                        <div className="card-body px-12 py-0 mt-3 mb-3">
                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.exceptionalAbility.professionalExperience} onChange={(e) => handleCheckboxChange(e, "exceptionalAbility", "professionalExperience")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Letters from employers showing at least 10 years of relevant full-time experience.</span>
                                                                </span>
                                                            </label>
                                                        </div>

                                                        {/* Professional License */}
                                                        <div className='card-header'>
                                                            <h3 className='card-title align-items-start flex-column py-3'>
                                                                <span className="card-label fs-4 fw-bold text-gray-800">Professional License</span>
                                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">Proof of license or certification in the professional field.</span>
                                                            </h3>
                                                        </div>
                                                        <div className="card-body px-12 py-0 mt-3 mb-3">
                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.exceptionalAbility.professionalLicense} onChange={(e) => handleCheckboxChange(e, "exceptionalAbility", "professionalLicense")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">License to practice or certification for the profession or occupation.</span>
                                                                </span>
                                                            </label>
                                                        </div>

                                                        {/* Command Salary */}
                                                        <div className='card-header'>
                                                            <h3 className='card-title align-items-start flex-column py-3'>
                                                                <span className="card-label fs-4 fw-bold text-gray-800">Command Salary</span>
                                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">Evidence of remuneration indicative of exceptional ability.</span>
                                                            </h3>
                                                        </div>
                                                        <div className="card-body px-12 py-0 mt-3 mb-3">
                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.exceptionalAbility.commandSalary} onChange={(e) => handleCheckboxChange(e, "exceptionalAbility", "commandSalary")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Evidence of high salary or other remuneration for services demonstrating exceptional ability.</span>
                                                                </span>
                                                            </label>
                                                        </div>

                                                        {/* Professional Memberships */}
                                                        <div className='card-header'>
                                                            <h3 className='card-title align-items-start flex-column py-3'>
                                                                <span className="card-label fs-4 fw-bold text-gray-800">Memberships</span>
                                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">Evidence of membership in professional associations.</span>
                                                            </h3>
                                                        </div>
                                                        <div className="card-body px-12 py-0 mt-3 mb-3">
                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.exceptionalAbility.professionalMemberships} onChange={(e) => handleCheckboxChange(e, "exceptionalAbility", "professionalMemberships")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Evidence of membership in professional associations.</span>
                                                                </span>
                                                            </label>
                                                        </div>

                                                        {/* Recognition */}
                                                        <div className='card-header'>
                                                            <h3 className='card-title align-items-start flex-column py-3'>
                                                                <span className="card-label fs-4 fw-bold text-gray-800">Recognition</span>
                                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">Evidence of recognition for achievements and significant contributions to the industry or field by peers, governmental entities, or professional or business organizations.</span>
                                                            </h3>
                                                        </div>
                                                        <div className="card-body px-12 py-0 mt-3 mb-3">
                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.exceptionalAbility.recognitionAchievements} onChange={(e) => handleCheckboxChange(e, "exceptionalAbility", "recognitionAchievements")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Evidence of recognition for achievements and significant contributions to the industry or field.</span>
                                                                </span>
                                                            </label>
                                                        </div>

                                                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                                                            {/* <button className="btn btn-light btn-active-light-primary me-2">Discard</button> */}
                                                            {/* <span onClick={saveCheckedItems} className="btn btn-primary px-6">Save Changes</span> */}
                                                        </div>
                                                    </form>
                                                </div>
                                                {/* <!--end::Card body--> */}
                                            </div>
                                            {/* <!--end::Card--> */}
                                            {/* <!--begin::Card--> */}
                                            <div className="card mb-5 mb-lg-10" style={{ display: activeTab === 3 ? "block" : "none" }}>
                                                {/* <!--begin::Card header--> */}
                                                <div className="card-header">
                                                    {/* <!--begin::Card title--> */}
                                                    <div className="card-title">
                                                        <h3 className='card-title align-items-start flex-column'>
                                                            <span className="card-label fs-2 fw-bold text-primary">Prong 1</span>
                                                            <span className="text-gray-500 mt-1 fw-semibold fs-6">The person's proposed endeavor has both substantial merit and national importance.</span>
                                                        </h3>
                                                    </div>
                                                    {/* <!--end::Card title--> */}
                                                    {/* <!--begin::Card toolbar--> */}
                                                    <div className="card-toolbar">
                                                        {/* <!--begin::Button--> */}
                                                        {/* <span onClick={saveCheckedItems} className="btn btn-sm btn-primary my-1">Save</span> */}
                                                        {/* <!--end::Button--> */}
                                                    </div>
                                                    {/* <!--end::Card toolbar--> */}
                                                </div>
                                                {/* <!--end::Card header--> */}
                                                {/* <!--begin::Card body--> */}
                                                <div className="card-body py-0">
                                                    {/* <!--begin::Form--> */}
                                                    <form className="form">
                                                        {/* Description of Endeavor Section */}
                                                        <div className='card-header'>
                                                            <h3 className='card-title align-items-start flex-column py-3'>
                                                                <span className="card-label fs-4 fw-bold text-gray-800">Description of Endeavor</span>
                                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">Provide a detailed description of the proposed endeavor and explain why it is of national importance.</span>
                                                            </h3>
                                                        </div>
                                                        <div className="card-body px-12 py-0 mt-3 mb-3">
                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.descriptionEndeavor.includes("detailedDescription")} onChange={(e) => handleCheckboxChange(e, "descriptionEndeavor", "detailedDescription")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Provide a detailed description of the proposed endeavor.</span>
                                                                    <span className="text-muted fs-6">Description of what the proposed endeavor involves and its potential impact.</span>
                                                                </span>
                                                            </label>

                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.descriptionEndeavor.includes("nationalImportance")} onChange={(e) => handleCheckboxChange(e, "descriptionEndeavor", "nationalImportance")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Explain why it is of national importance.</span>
                                                                    <span className="text-muted fs-6">Provide reasons why the endeavor matters to the U.S. on a national level.</span>
                                                                </span>
                                                            </label>
                                                        </div>

                                                        {/* Documentary Evidence Section */}
                                                        <div className='card-header'>
                                                            <h3 className='card-title align-items-start flex-column py-3'>
                                                                <span className="card-label fs-4 fw-bold text-gray-800">Documentary Evidence</span>
                                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">Provide documentary evidence to support the proposed endeavor.</span>
                                                            </h3>
                                                        </div>
                                                        <div className="card-body px-12 py-0 mt-3 mb-3">
                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.documentaryEvidence.includes("nationalOrGlobalImplications")} onChange={(e) => handleCheckboxChange(e, "documentaryEvidence", "nationalOrGlobalImplications")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Provide evidence of national or global implications within the field.</span>
                                                                    <span className="text-muted fs-6">Documentation of how the endeavor has implications beyond a local level.</span>
                                                                </span>
                                                            </label>

                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.documentaryEvidence.includes("employUSWorkers")} onChange={(e) => handleCheckboxChange(e, "documentaryEvidence", "employUSWorkers")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Show significant potential to employ U.S. workers or other substantial economic effects.</span>
                                                                    <span className="text-muted fs-6">Evidence showing the endeavor could benefit U.S. workers or have positive economic effects.</span>
                                                                </span>
                                                            </label>

                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.documentaryEvidence.includes("broadEnhancement")} onChange={(e) => handleCheckboxChange(e, "documentaryEvidence", "broadEnhancement")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Demonstrate broad enhancement to societal welfare or cultural/artistic enrichment.</span>
                                                                    <span className="text-muted fs-6">Evidence showing the endeavor enhances societal welfare, culture, or arts.</span>
                                                                </span>
                                                            </label>

                                                            <label className="form-check form-check-custom form-check-solid align-items-start">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.documentaryEvidence.includes("nationalInitiatives")} onChange={(e) => handleCheckboxChange(e, "documentaryEvidence", "nationalInitiatives")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Include evidence of relevance to national government initiatives.</span>
                                                                    <span className="text-muted fs-6">Provide documentation showing the endeavor aligns with national initiatives or priorities.</span>
                                                                </span>
                                                            </label>
                                                        </div>

                                                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                                                            {/* <button className="btn btn-light btn-active-light-primary me-2">Discard</button> */}
                                                            {/* <span onClick={saveCheckedItems} className="btn btn-primary px-6">Save Changes</span> */}
                                                        </div>
                                                    </form>
                                                    {/* <!--end::Form--> */}
                                                </div>
                                                {/* <!--end::Card body--> */}
                                            </div>
                                            {/* <!--end::Card--> */}
                                            {/* <!--begin::Card--> */}
                                            <div className="card mb-5 mb-lg-10" style={{ display: activeTab === 4 ? "block" : "none" }}>
                                                {/* <!--begin::Card header--> */}
                                                <div className="card-header">
                                                    {/* <!--begin::Card title--> */}
                                                    <div className="card-title">
                                                        <h3 className='card-title align-items-start flex-column'>
                                                            <span className="card-label fs-2 fw-bold text-primary">Prong 2</span>
                                                            <span className="text-gray-500 mt-1 fw-semibold fs-6">The person is well positioned to advance the proposed endeavor.</span>
                                                        </h3>
                                                    </div>
                                                    {/* <!--end::Card title--> */}
                                                    {/* <!--begin::Card toolbar--> */}
                                                    <div className="card-toolbar">
                                                        {/* <!--begin::Button--> */}
                                                        {/* <span onClick={saveCheckedItems} className="btn btn-sm btn-primary my-1">Save</span> */}
                                                        {/* <!--end::Button--> */}
                                                    </div>
                                                    {/* <!--end::Card toolbar--> */}
                                                </div>
                                                {/* <!--end::Card header--> */}
                                                {/* <!--begin::Card body--> */}
                                                <div className="card-body py-0">
                                                    <form className="form">
                                                        {/* Education, Skills, and Success Record */}
                                                        <div className='card-header'>
                                                            <h3 className='card-title align-items-start flex-column py-3'>
                                                                <span className="card-label fs-4 fw-bold text-gray-800">Education, Skills, and Success Record</span>
                                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">Provide evidence of the beneficiary's qualifications.</span>
                                                            </h3>
                                                        </div>
                                                        <div className="card-body px-12 py-0 mt-3 mb-3">
                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.qualifications.includes("patents")} onChange={(e) => handleCheckboxChange(e, "qualifications", "patents")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Include patents, copyrights, or trademarks owned.</span>
                                                                    <span className="text-muted fs-6">Evidence of intellectual property ownership.</span>
                                                                </span>
                                                            </label>

                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.qualifications.includes("lettersFromExperts")} onChange={(e) => handleCheckboxChange(e, "qualifications", "lettersFromExperts")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Gather letters from experts detailing past achievements and potential.</span>
                                                                    <span className="text-muted fs-6">Letters from field experts highlighting achievements.</span>
                                                                </span>
                                                            </label>

                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.qualifications.includes("publishedArticles")} onChange={(e) => handleCheckboxChange(e, "qualifications", "publishedArticles")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Attach published articles or media reports about achievements.</span>
                                                                    <span className="text-muted fs-6">Media coverage of the beneficiary's work.</span>
                                                                </span>
                                                            </label>

                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.qualifications.includes("strongCitationHistory")} onChange={(e) => handleCheckboxChange(e, "qualifications", "strongCitationHistory")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Show evidence of a strong citation history.</span>
                                                                    <span className="text-muted fs-6">Proof of the beneficiary's influence in the field.</span>
                                                                </span>
                                                            </label>

                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.qualifications.includes("influenceOnField")} onChange={(e) => handleCheckboxChange(e, "qualifications", "influenceOnField")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Demonstrate influence on the field and critical roles in endeavors.</span>
                                                                    <span className="text-muted fs-6">Evidence of the beneficiary's impact on the field.</span>
                                                                </span>
                                                            </label>

                                                            <label className="form-check form-check-custom form-check-solid align-items-start">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.qualifications.includes("pastInventions")} onChange={(e) => handleCheckboxChange(e, "qualifications", "pastInventions")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Provide examples of past inventions or innovations used or licensed by others.</span>
                                                                    <span className="text-muted fs-6">Proof of the beneficiary's contributions to the field.</span>
                                                                </span>
                                                            </label>
                                                        </div>

                                                        {/* Future Activity Plans */}
                                                        <div className='card-header'>
                                                            <h3 className='card-title align-items-start flex-column py-3'>
                                                                <span className="card-label fs-4 fw-bold text-gray-800">Future Activity Plans</span>
                                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">Provide plans for future activities in the U.S.</span>
                                                            </h3>
                                                        </div>
                                                        <div className="card-body px-12 py-0 mt-3 mb-3">
                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.futureActivityPlans.includes("workPlan")} onChange={(e) => handleCheckboxChange(e, "futureActivityPlans", "workPlan")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Submit a detailed plan for continuing work in the U.S.</span>
                                                                    <span className="text-muted fs-6">Outline for future activities.</span>
                                                                </span>
                                                            </label>

                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.futureActivityPlans.includes("businessModel")} onChange={(e) => handleCheckboxChange(e, "futureActivityPlans", "businessModel")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Provide a detailed business model, if applicable.</span>
                                                                    <span className="text-muted fs-6">Business plans, if relevant.</span>
                                                                </span>
                                                            </label>

                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.futureActivityPlans.includes("correspondence")} onChange={(e) => handleCheckboxChange(e, "futureActivityPlans", "correspondence")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Include correspondence from potential employers, clients, or customers.</span>
                                                                    <span className="text-muted fs-6">Letters of interest from potential collaborators.</span>
                                                                </span>
                                                            </label>

                                                            <label className="form-check form-check-custom form-check-solid align-items-start">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.futureActivityPlans.includes("financialSupport")} onChange={(e) => handleCheckboxChange(e, "futureActivityPlans", "financialSupport")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Show documentation of plans for financial support.</span>
                                                                    <span className="text-muted fs-6">Proof of financial backing or plans for funding.</span>
                                                                </span>
                                                            </label>
                                                        </div>

                                                        {/* Progress Towards Endeavor */}
                                                        <div className='card-header'>
                                                            <h3 className='card-title align-items-start flex-column py-3'>
                                                                <span className="card-label fs-4 fw-bold text-gray-800">Progress Towards Endeavor</span>
                                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">Provide evidence of progress towards achieving the proposed endeavor.</span>
                                                            </h3>
                                                        </div>
                                                        <div className="card-body px-12 py-0 mt-3 mb-3">
                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.progressTowardsEndeavor.includes("grants")} onChange={(e) => handleCheckboxChange(e, "progressTowardsEndeavor", "grants")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Evidence of relevant grants, contracts, or agreements.</span>
                                                                    <span className="text-muted fs-6">Proof of financial or contractual support.</span>
                                                                </span>
                                                            </label>

                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.progressTowardsEndeavor.includes("achievementsToDevelop")} onChange={(e) => handleCheckboxChange(e, "progressTowardsEndeavor", "achievementsToDevelop")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Documentation of achievements to be further developed.</span>
                                                                    <span className="text-muted fs-6">Evidence of achievements with potential for further development.</span>
                                                                </span>
                                                            </label>
                                                        </div>

                                                        {/* Interest of Stakeholders */}
                                                        <div className='card-header'>
                                                            <h3 className='card-title align-items-start flex-column py-3'>
                                                                <span className="card-label fs-4 fw-bold text-gray-800">Interest of Stakeholders</span>
                                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">Provide evidence of interest from relevant stakeholders.</span>
                                                            </h3>
                                                        </div>
                                                        <div className="card-body px-12 py-0 mt-3 mb-3">
                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.interestOfStakeholders.includes("lettersFromGovEntities")} onChange={(e) => handleCheckboxChange(e, "interestOfStakeholders", "lettersFromGovEntities")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Letters from government entities showing interest.</span>
                                                                    <span className="text-muted fs-6">Proof of governmental interest in the endeavor.</span>
                                                                </span>
                                                            </label>

                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.interestOfStakeholders.includes("usInvestors")} onChange={(e) => handleCheckboxChange(e, "interestOfStakeholders", "usInvestors")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Evidence of investment from U.S. investors.</span>
                                                                    <span className="text-muted fs-6">Proof of financial backing from U.S. investors.</span>
                                                                </span>
                                                            </label>

                                                            <label className="form-check form-check-custom form-check-solid align-items-start">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.interestOfStakeholders.includes("howWorkIsUtilized")} onChange={(e) => handleCheckboxChange(e, "interestOfStakeholders", "howWorkIsUtilized")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Documentation of how work is utilized by others, including contracts and patents.</span>
                                                                    <span className="text-muted fs-6">Evidence of how the beneficiary's work is being used by others.</span>
                                                                </span>
                                                            </label>
                                                        </div>

                                                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                                                            {/* <button className="btn btn-light btn-active-light-primary me-2">Discard</button> */}
                                                            {/* <span onClick={saveCheckedItems} className="btn btn-primary px-6">Save Changes</span> */}
                                                        </div>
                                                    </form>
                                                </div>
                                                {/* <!--end::Card body--> */}
                                            </div>
                                            {/* <!--end::Card--> */}
                                            {/* <!--begin::Card--> */}
                                            <div className="card mb-5 mb-lg-10" style={{ display: activeTab === 5 ? "block" : "none" }}>
                                                {/* <!--begin::Card header--> */}
                                                <div className="card-header">
                                                    {/* <!--begin::Card title--> */}
                                                    <div className="card-title">
                                                        <h3 className='card-title align-items-start flex-column'>
                                                            <span className="card-label fs-2 fw-bold text-primary">Prong 3</span>
                                                            <span className="text-gray-500 mt-1 fw-semibold fs-6">On balance, it would be beneficial to the United States to waive the job offer and thus the permanent labor certification requirements.</span>
                                                        </h3>
                                                    </div>
                                                    {/* <!--end::Card title--> */}
                                                    {/* <!--begin::Card toolbar--> */}
                                                    <div className="card-toolbar">
                                                        {/* <!--begin::Button--> */}
                                                        {/* <span onClick={saveCheckedItems} className="btn btn-sm btn-primary my-1">Save</span> */}
                                                        {/* <!--end::Button--> */}
                                                    </div>
                                                    {/* <!--end::Card toolbar--> */}
                                                </div>
                                                {/* <!--end::Card header--> */}
                                                {/* <!--begin::Card body--> */}
                                                <div className="card-body py-0">
                                                    <form className="form">
                                                        {/* Labor Certification Impracticality */}
                                                        <div className='card-header'>
                                                            <h3 className='card-title align-items-start flex-column py-3'>
                                                                <span className="card-label fs-4 fw-bold text-gray-800">Labor Certification Impracticality</span>
                                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">Explain why securing a job offer or labor certification is impractical.</span>
                                                            </h3>
                                                        </div>
                                                        <div className="card-body px-12 py-0 mt-3 mb-3">
                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.laborCertificationImpracticality.includes("jobOfferOrLaborCertImpractical")} onChange={(e) => handleCheckboxChange(e, "laborCertificationImpracticality", "jobOfferOrLaborCertImpractical")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Explain why securing a job offer or labor certification is impractical.</span>
                                                                    <span className="text-muted fs-6">Evidence of why a job offer or labor certification is impractical.</span>
                                                                </span>
                                                            </label>
                                                        </div>

                                                        {/* Benefit to the U.S. Despite Availability of Workers */}
                                                        <div className='card-header'>
                                                            <h3 className='card-title align-items-start flex-column py-3'>
                                                                <span className="card-label fs-4 fw-bold text-gray-800">Benefit to the U.S. Despite Availability of Workers</span>
                                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">Justify the national benefit from the beneficiary's contributions, despite available U.S. workers.</span>
                                                            </h3>
                                                        </div>
                                                        <div className="card-body px-12 py-0 mt-3 mb-3">
                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.benefitToUSDespiteWorkers.includes("justifyBenefitToUS")} onChange={(e) => handleCheckboxChange(e, "benefitToUSDespiteWorkers", "justifyBenefitToUS")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Provide justification of the national benefit from the beneficiary's contributions, despite available U.S. workers.</span>
                                                                    <span className="text-muted fs-6">Evidence of why the beneficiary's contributions are valuable even with available U.S. workers.</span>
                                                                </span>
                                                            </label>
                                                        </div>

                                                        {/* Urgency and Job Creation */}
                                                        <div className='card-header'>
                                                            <h3 className='card-title align-items-start flex-column py-3'>
                                                                <span className="card-label fs-4 fw-bold text-gray-800">Urgency and Job Creation</span>
                                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">Demonstrate urgency and potential for job creation.</span>
                                                            </h3>
                                                        </div>
                                                        <div className="card-body px-12 py-0 mt-3 mb-3">
                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.urgencyAndJobCreation.includes("demonstrateUrgency")} onChange={(e) => handleCheckboxChange(e, "urgencyAndJobCreation", "demonstrateUrgency")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Demonstrate the urgency of the national interest in the beneficiary’s contributions.</span>
                                                                    <span className="text-muted fs-6">Evidence of the urgency for the beneficiary's contributions.</span>
                                                                </span>
                                                            </label>

                                                            <label className="form-check form-check-custom form-check-solid align-items-start mb-2">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.urgencyAndJobCreation.includes("showJobCreationPotential")} onChange={(e) => handleCheckboxChange(e, "urgencyAndJobCreation", "showJobCreationPotential")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Show potential for job creation.</span>
                                                                    <span className="text-muted fs-6">Evidence of the potential for creating jobs in the U.S.</span>
                                                                </span>
                                                            </label>

                                                            <label className="form-check form-check-custom form-check-solid align-items-start">
                                                                <input className="form-check-input me-3" disabled={!isPetitionOwner(petition, currentUser) ? "disabled" : ""} type="checkbox" checked={checkedItems.urgencyAndJobCreation.includes("confirmNoAdverseEffect")} onChange={(e) => handleCheckboxChange(e, "urgencyAndJobCreation", "confirmNoAdverseEffect")} />
                                                                <span className="form-check-label d-flex flex-column align-items-start">
                                                                    <span className="fw-bold fs-5 mb-0 text-gray-700">Confirm that the beneficiary’s self-employment does not adversely affect U.S. workers.</span>
                                                                    <span className="text-muted fs-6">Evidence that self-employment does not harm U.S. workers.</span>
                                                                </span>
                                                            </label>
                                                        </div>

                                                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                                                            {/* <button className="btn btn-light btn-active-light-primary me-2">Discard</button> */}
                                                            {/* <span onClick={saveCheckedItems} className="btn btn-primary px-6">Save Changes</span> */}
                                                        </div>
                                                    </form>
                                                </div>
                                                {/* <!--end::Card body--> */}
                                            </div>
                                            {/* <!--end::Card--> */}
                                        </div>
                                        {/* <!--end::Content--> */}
                                    </div>
                                    {/* <!--end::Content wrapper--> */}
                                    {/* <!--begin::Footer--> */}
                                    <Footer />
                                    {/* <!--end::Footer--> */}
                                </div>
                                {/* <!--end:::Main--> */}
                            </div>
                            {/* <!--end::Wrapper container--> */}

                        </div>
                        {/* <!--end::Wrapper--> */}
                    </div>
                    {/* <!--end::Page--> */}
                </div >
            </div >
        </>
    );
}

export default ExhibitDetail;