import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './Contexts/AuthContext';
import { signInWithGoogle } from './authFunctions';
import Modal from "react-bootstrap/Modal";

const Login = () => {
    const navigate = useNavigate();
    const { login, startLogin, endLogin, logout } = useAuth(); // Use the login, startLogin, endLogin, and logout methods from context

    const [modalPermissionIsOpen, setModalPermissionIsOpen] = React.useState(false);

    useEffect(() => {
        localStorage.clear();
        sessionStorage.clear();
    }, []);

    function openModalPermission() {
        setModalPermissionIsOpen(true);
    }

    function closeModalPermission() {
        setModalPermissionIsOpen(false);
    }

    const handleGoogleSignIn = async () => {
        startLogin(); // Indicate login process started
        try {
            const { success, user, oauthAccessToken } = await signInWithGoogle(logout);
            if (success) {
                login(user, oauthAccessToken); // Use the login method from context
                navigate('/home');
            } else {
                throw new Error('Login failed');
            }
        } catch (error) {
            openModalPermission();
            console.error("Failed to sign in with Google", error);
        } finally {
            endLogin(); // Indicate login process ended
        }
    };

    return (
        <>
            <Modal show={modalPermissionIsOpen} onHide={closeModalPermission}>
                <Modal.Header closeButton>
                    <Modal.Title>Why We Need Access to Your Google Drive</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="scroll-y">
                        <div className="d-flex flex-column">
                            <div className="w-100">
                                <div className="mb-10 mb-lg-15">
                                    <div className="fs-6">
                                        <p>To provide you with our full range of features, EB Genius requires permission to create file in your Google Drive. Here's what we use this access for and how it benefits you:</p>
                                        <ul>
                                            <li><strong>File Management:</strong> We create folders in your Google Drive to organize exhibits and files related to your petitions. This helps keep your data structured and easy to navigate.</li>
                                            <li><strong>Document Creation and Storage:</strong> We generate documents and store them securely in your Drive. These could include cover letter, recommendation letters and any other document you ask EB Genius to create.</li>
                                            <li><strong>Direct Uploads:</strong> To save you time, EB Genius allows you to upload files directly to these folders. This feature is designed to streamline how you manage and interact with your petition's exhibits through our platform.</li>
                                        </ul>
                                        <p>We request these permissions only to enhance the functionality and efficiency of the services we provide you. Your data remains under your control, and we ensure it is handled with the highest standards of security and confidentiality.</p>
                                        <p>You don't need to worry, as the permission required won't allow EB Genius to access any files other than those uploaded or created directly by our app.</p>
                                        <p><strong>Please grant us permission to ensure you get the most out of our app. Thank you for your trust and cooperation!</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <span className="btn btn-light-secondary" onClick={closeModalPermission}>
                        Close
                    </span>
                </Modal.Footer>
            </Modal>

            <div className="d-flex flex-column flex-root">
                <style>{`body { background-image: url('/assets/media/auth/bg10.jpeg'); }`}</style>
                <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                    <div className="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
                        <img src="/assets/media/auth/agency.png" alt="" className="mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20" />
                        <h1 className="text-primary fs-2qx fw-light text-center mb-7">Easily create powerful EB-2 NIW petitions!</h1>
                        <div className="text-gray-600 fs-base text-center fw-semibold">
                            {/* Some descriptive text here */}
                        </div>
                    </div>
                    <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12">
                        <div className="bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10">
                            <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px">
                                <div className="form w-100">
                                    <div className="text-center mb-11 text-primary">
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <span style={{
                                                fontWeight: 500,
                                                fontSize: '2.5em',
                                                letterSpacing: '0.05em'
                                            }}>EB</span><span style={{
                                                fontWeight: 100,
                                                fontSize: '2.5em',
                                                letterSpacing: '0.05em'
                                            }}>Genius</span>
                                        </div>
                                        <br />
                                        <h1 className="text-gray-700 fw-bolder mb-3">Sign In</h1>
                                        <div className="text-gray-500 fw-semibold fs-6">Boost Your Petition Success</div>
                                    </div>
                                    <div className="row g-3 mb-9">
                                        <div className="col-md-12">
                                            <button onClick={handleGoogleSignIn} className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
                                                <img alt="Google Logo" src="assets/media/svg/brand-logos/google-icon.svg" className="h-15px me-3" />Sign in with Google</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
