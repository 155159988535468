// src/App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './Contexts/AuthContext';
import { ExhibitProvider } from './Contexts/ExhibitContext';
import AppRoutes from './AppRoutes';

function App() {
  return (
    <AuthProvider>
      <ExhibitProvider>
        <Router>
          <AppRoutes />
        </Router>
      </ExhibitProvider>
    </AuthProvider>
  );
}

export default App;
