import React, { useEffect, useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './Contexts/AuthContext.js';
import GoogleDriveFrame from './GoogleDriveFrame.js';
import Modal from "react-bootstrap/Modal";
import { formatDate, formatBytes } from './Utils.js'
import { addFileRFE, fetchRFE, fetchRFESentences } from './ApiService.js';
import Loading from './Loading.js';
import Swal from 'sweetalert2'
import html2pdf from 'html2pdf.js';

function PetitionRFE({ petitionId, petitionFolderId, petitionerName }) {
    const { oauthAccessToken, logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [rfe, setRFE] = useState(null);
    const [activeTab, setActiveTab] = useState(1);
    const [sentences, setSentences] = useState([]);
    const [modalPermissionIsOpen, setModalPermissionIsOpen] = React.useState(false);
    const navigate = useNavigate();

    function openModalPermission() {
        setModalPermissionIsOpen(true);
    }

    function closeModalPermission() {
        setModalPermissionIsOpen(false);
    }

    useEffect(() => {
        async function fetchData() {
            await fetchRFECall(petitionId, setRFE);
        }

        fetchData();
    }, [petitionId]);

    async function fetchRFESentencesCall(docId, setSentences) {
        try {
            const result = await fetchRFESentences(docId);
            console.log('Fetched sentences:', result.processedSentences);
            setSentences(result.processedSentences);
        } catch (error) {
            console.error('Error fetching RFE sentences:', error);
        }
    }

    async function fetchRFECall(petitionId, setRFE) {
        try {
            const result = await fetchRFE(petitionId);
            // console.log('Fetched RFE:', result);
            setRFE(result);
            if (result.googleDocId) {
                await fetchRFESentencesCall(result.googleDocId, setSentences);
            }
        } catch (error) {
            console.error('Error fetching RFE:', error);
        }
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            acceptedFiles.forEach(file => {
                if (file.type !== 'application/pdf') {
                    Swal.fire("Error", "Only PDF files are accepted.", "error");
                    return;
                }
                const preview = URL.createObjectURL(file);
                uploadFile(Object.assign(file, { preview }));
            });
        }
    });

    const uploadFile = async (file) => {
        setLoading(true);
        try {
            const result = await addFileRFE(file, petitionId, petitionFolderId, oauthAccessToken);

            if (result.status === 401) {
                navigate('/login');
            }

            const fileId = result.googleDriveFileId;
            const data = { oauthAccessToken, fileId };

            const response = await fetch(`${process.env.REACT_APP_AI_API_URL}/analyzeRFE`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (response.status === 401) {
                navigate('/login');
            }

            // console.log('Fetching RFE after upload...');
            await fetchRFECall(petitionId, setRFE);

            const responseData = await response.json();

            if (responseData.error && responseData.error.includes('The PDF content is empty')) {
                Swal.fire("Error", responseData.error, "error");
            } else {

                // console.log('Fetching sentences after upload...');
                await fetchRFESentencesCall(fileId, setSentences);
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
            // console.log(error);
            if (error.message === 'Unauthorized') {
                logout();
            }
            if (error.message === 'Too Large') {
                Swal.fire("Info", "Your file exceeds the maximum upload size limit. Please ensure that your file is smaller than 50MB and try again.", "info");
            } else {
                Swal.fire("Error", "An error occurred while creating the file. Please try again.", "error");
            }
        }
    };

    const commonStyle = {
        fontFamily: 'TimesNewRoman',
        fontSize: 17,
        color: '#04141d',
        lineHeight: '1.6',
    };

    const notSoCommonStyle = {
        backgroundColor: '#ffe571',
        paddingLeft: '.25rem',
        paddingRight: '.25rem',
        borderRadius: '.25rem',
        fontFamily: 'TimesNewRoman',
        fontSize: 17,
        lineHeight: '1.6',
        border: '1px solid #ffd700',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    };

    const uncommonStyle = {
        backgroundColor: 'red',
        color: 'white',
        paddingLeft: '.25rem',
        paddingRight: '.25rem',
        borderRadius: '.25rem',
        fontFamily: 'TimesNewRoman',
        fontSize: 17,
        lineHeight: '1.6',
        border: '1px solid darkred',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    };

    const getTextMarkStyle = (similarityScore) => {
        if (similarityScore < 0.4) {
            return uncommonStyle;
        } else if (similarityScore < 0.8) {
            return notSoCommonStyle;
        } else {
            return commonStyle;
        }
    };

    const formatFileName = (name) => {
        return name.replace(/[^a-zA-Z0-9]/g, '_');
    };

    const printRFE = async () => {
        setLoading(true);
        try {
            const formattedName = formatFileName(petitionerName);
            const content = document.getElementById('rfe-content');
            const opt = {
                margin: 0.5,
                filename: `EBGenius_RFE_${formattedName}.pdf`,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            };
            await html2pdf().from(content).set(opt).save();
        } catch (error) {
            console.error('Error generating PDF:', error);
            Swal.fire("Error", "An error occurred while generating the PDF. Please try again.", "error");
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <style>
                {`
                    #rfe-content {
                        page-break-inside: avoid;
                    }

                    .rfe-paragraph {
                        page-break-inside: avoid;
                    }

                    .rfe-span {
                        display: inline-block;
                        page-break-inside: avoid;
                    }
                `}
            </style>
            <Loading isLoading={loading} />
            <Modal show={modalPermissionIsOpen} onHide={closeModalPermission} className="modal-lg">
                <Modal.Header closeButton>
                    <Modal.Title>Terms of Use and Data Manipulation Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="scroll-y">
                        <div className="d-flex flex-column">
                            <div className="w-100">
                                <div className="mb-10 mb-lg-15">
                                    <div className="fs-6">
                                        <p>At EB Genius, your privacy and trust are paramount. We are committed to safeguarding your personal information and ensuring that your data is used responsibly. This policy outlines how we handle your data and the measures we take to protect your privacy.</p>
                                        <h5>Data Usage</h5>
                                        <p>By using EB Genius, you agree to allow us to use the data you provide to enhance your experience with our services. Specifically:</p>
                                        <ul>
                                            <li><strong>Improving User Experience:</strong> We use the data you provide to tailor and improve the features and functionality of our platform. This helps us understand how you use our services and make necessary enhancements for a better user experience.</li>
                                            <li><strong>Personalization:</strong> Your data enables us to personalize your interactions with EB Genius, making our platform more intuitive and user-friendly.</li>
                                        </ul>
                                        <h5>Data Protection</h5>
                                        <p>We understand the importance of protecting your data and ensuring your privacy. Therefore, we adhere to the following principles:</p>
                                        <ul>
                                            <li><strong>Confidentiality:</strong> Your data will never be shared with any third-party companies. We do not sell, trade, or otherwise transfer your information to external parties.</li>
                                            <li><strong>Employee Access:</strong> No EB Genius employee will have access to your personal data. Our systems are designed to ensure that your information is kept private and secure.</li>
                                            <li><strong>Security Measures:</strong> We implement robust security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. This includes using encryption and secure servers to store your information.</li>
                                        </ul>
                                        <h5>Your Rights</h5>
                                        <p>You have the right to access, modify, and delete your data at any time. Should you have any questions or concerns about how your data is being used, please contact our support team, and we will be happy to assist you.</p>
                                        <h5>Conclusion</h5>
                                        <p>We value your trust and are dedicated to maintaining the highest standards of privacy and data protection. By agreeing to this policy, you can be confident that your data is used solely to enhance your experience with EB Genius, and your privacy is always our priority.</p>
                                        <p>Thank you for choosing EB Genius. We are committed to providing you with a secure and personalized experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <span className="btn btn-light-secondary" onClick={closeModalPermission}>
                        Close
                    </span>
                </Modal.Footer>
            </Modal>
            {rfe && Object.keys(rfe).length > 0 && rfe.googleDocId ? (
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12'>
                        <div className="card mb-5 mb-xl-10">
                            <div className="card-body pt-9 pb-0">
                                <div className="d-flex flex-wrap flex-sm-nowrap">
                                    <div className="flex-grow-1">
                                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                            <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                                <span className="d-flex align-items-center text-hover-primary me-5 mb-2">
                                                    {rfe.fileName}</span>
                                                <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                                                    <i className="ki-outline ki-file-up fs-4 me-2"></i>{formatDate(rfe.createdAt)}</span>
                                                <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                                                    {formatBytes(rfe.size)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                                    <li className="nav-item mt-2" style={{ cursor: 'pointer' }}>
                                        <span onClick={() => setActiveTab(1)} className={`nav-link text-active-primary ms-0 me-10 py-5 ${activeTab === 1 ? "active" : ""}`}>Content
                                        </span>
                                    </li>
                                    <li className="nav-item mt-2" style={{ cursor: 'pointer' }}>
                                        <span onClick={() => setActiveTab(2)} className={`nav-link text-active-primary ms-0 me-10 py-5 ${activeTab === 2 ? "active" : ""}`}>Genius' Analysis  <span className="badge badge-success ms-2">BETA</span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div style={{ display: activeTab === 1 ? "block" : "none" }}>
                            <GoogleDriveFrame documentId={rfe?.googleDocId} title={rfe.fileName} isDocs={0} mimeType={rfe.mimeType} divTitle="RFE" divSubtitle="Read your RFE" />
                        </div>

                        <div style={{ display: activeTab === 2 ? "block" : "none" }}>
                            <div className="row">
                                <div className='col-12 col-md-12 col-lg-12'>
                                    {sentences.length > 0 ? (
                                        <div className="card mb-5 mb-xl-10">
                                            <div className="card-header">
                                                <h3 className="card-title align-items-start flex-column">
                                                    <span className="card-label fs-3 fw-bold text-gray-800">Review Your RFE: Highlighted Analysis</span>
                                                    <span className="text-gray-500 mt-1 fw-semibold fs-6">Below is the full text of your RFE with highlighted sections. <br />The most unusual parts are marked, helping you identify the critical areas that need your attention the most.</span>
                                                </h3>
                                                <div className="card-toolbar">
                                                    <span onClick={printRFE} className="btn btn-sm btn-primary">Export PDF</span>
                                                </div>
                                            </div>
                                            <div className='card-body' id="rfe-content">
                                                <div style={{ padding: '20px' }}>
                                                    {sentences.reduce((acc, sentence, index) => {
                                                        if (!acc[sentence.paragraphIndex]) {
                                                            acc[sentence.paragraphIndex] = [];
                                                        }
                                                        acc[sentence.paragraphIndex].push(
                                                            <span
                                                                key={index}
                                                                className="rfe-span"
                                                                style={getTextMarkStyle(sentence.similarityScore)}
                                                            >
                                                                {sentence.originalSentence.split('\n').map((text, i) => (
                                                                    <React.Fragment key={i}>
                                                                        {text}
                                                                        {i < sentence.originalSentence.split('\n').length - 1 && <br />}
                                                                    </React.Fragment>
                                                                ))}
                                                                {' '}
                                                            </span>
                                                        );
                                                        return acc;
                                                    }, []).map((paragraph, paragraphIndex) => (
                                                        <p key={paragraphIndex} className="rfe-paragraph" style={{ marginBottom: '15px' }}>
                                                            {paragraph}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="card-px text-center pt-15 pb-15">
                                                    <div className="text-center mt-5">
                                                        <img src='/assets/media/illustrations/5.png' alt="Magnifying Glass" style={{ width: '150px', marginBottom: '20px' }} />
                                                        <p className="text-gray-500 fs-4 fw-semibold">
                                                            The PDF you uploaded could not be read. This may happen if the PDF is a scanned document or an image.
                                                            <br />Please ensure you upload a text-based PDF or ask USCIS to provide a digital copy of the RFE.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )}
                                </div>

                            </div>
                        </div>
                    </div >
                </div >
            ) : (
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12'>
                        <div className="card">
                            <div className="card-body">
                                <div className="card-px text-center pt-15 pb-15">
                                    <h2 className="fs-2x fw-bold mb-0 text-primary">Upload your RFE Document</h2>
                                    <p className="text-gray-500 fs-4 fw-semibold py-7 mb-0">Received an RFE for your EB-2 NIW petition? <br />No problem!
                                    </p>
                                    <p className="text-gray-500 fs-4 fw-semibold">This can take up to a minute or 2. Please, be patient.</p>
                                    <div {...getRootProps()} className="dropzone mb-3">
                                        <input {...getInputProps()} name="files" />
                                        {isDragActive ?
                                            <p>Drop the file here ...</p> :
                                            <p>Use this area to upload your RFE document. <br />Simply drag and drop your PDF file, or click to select the file from your device.</p>
                                        }
                                    </div>
                                    <div className="">
                                        <label className="form-check-label" htmlFor="agreeCheck">
                                            Uploading your RFE allows us to analyze its content and provide personalized guidance to help you respond effectively. <br /> Click  <a href="#" onClick={openModalPermission}>here</a> for details.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }

        </>
    );
}

export default PetitionRFE;
