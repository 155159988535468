import React, { useEffect, useState } from 'react';
import { useAuth } from './Contexts/AuthContext';
import { useParams, Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import { isPetitionOwner } from './Utils.js'
import Header from './Header';
import Footer from './Footer';
import { fetchPetition, updatePetition, deleteCoverLetter, deletePlan, fetchSharedWithUsers, addSharedWithUser, removeSharedWithUser } from './ApiService.js';

function Settings() {
    const { oauthAccessToken, currentUser } = useAuth();
    const { petitionId } = useParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedOption, setSelectedOption] = useState('AD');
    const [petitionDetails, setPetitionDetails] = useState({
        hasCoverLetter: false,
        hasPlan: false,
        hasCoverLetterRFE: false,
        hasPlanRFE: false
    });

    const [formState, setFormState] = useState({
        petitionType: 'OriginalSubmission', // default selected petition type
        petitionerName: '',
        subCategory: 'AD',
        placeOfBirth: '',
        industry: '',
        stateOfEndeavor: '',
        endeavorSummary: ''
    });

    const [sharedUsers, setSharedUsers] = useState([]);
    const [newSharedUserEmail, setNewSharedUserEmail] = useState('');
    const [newSharedUserName, setNewSharedUserName] = useState('');
    const [loadingSharedUsers, setLoadingSharedUsers] = useState(false);
    const [sharedError, setSharedError] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                // Fetch petition details
                const fetchedPetition = await fetchPetitionCall(petitionId);
                if (fetchedPetition) {
                    setFormState({
                        petitionType: fetchedPetition.petitionType,
                        petitionerName: fetchedPetition.petitionerName,
                        subCategory: fetchedPetition.subCategory,
                        placeOfBirth: fetchedPetition.placeOfBirth,
                        industry: fetchedPetition.industry,
                        stateOfEndeavor: fetchedPetition.stateOfEndeavor,
                        endeavorSummary: fetchedPetition.endeavorSummary
                    });

                    setPetitionDetails({
                        hasCoverLetter: fetchedPetition.hasCoverLetter == 1 ? true : false,
                        hasPlan: fetchedPetition.hasPlan == 1 ? true : false,
                        hasCoverLetterRFE: fetchedPetition.hasCoverLetterRFE == 1 ? true : false,
                        hasPlanRFE: fetchedPetition.hasPlanRFE == 1 ? true : false,
                        userId: fetchedPetition.userId
                    })
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, [petitionId]);

    async function fetchPetitionCall(petitionId) {
        try {
            const result = await fetchPetition(petitionId);
            return result;  // Return the fetched data
        } catch (error) {
            console.error('Error fetching petition:', error);
            return null;  // Return null in case of an error
        }
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleOptionChange = (event) => {
        const value = event.target.value;
        // console.log(value);
        setSelectedOption(value);
        // Update formState as well when the option changes
        setFormState(prevState => ({
            ...prevState,
            subCategory: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            await updatePetition(petitionId, formState);
            Swal.fire("Success", 'Petition updated successfully!', "success");
        } catch (error) {
            setError(error.message);
            Swal.fire("Error", 'An error occurred while updating the petition. Please try again.', "error");
        } finally {
            setLoading(false);
        }
    };

    const deleteCoverLetterConfirmation = async (type) => {
        Swal.fire({
            customClass: {
                confirmButton: "btn btn-danger",
                cancelButton: "btn btn-light-info"
            },
            buttonsStyling: false,
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                deletePetitionCall(type);
            }
        });
    }

    const deletePetitionCall = async (type) => {
        setLoading(true);

        try {
            await deleteCoverLetter(petitionId, type);

            if (type == "OriginalSubmission") {
                setPetitionDetails(prevState => ({
                    ...prevState,
                    hasCoverLetter: false
                }));
            }
            else if (type == "RFEResponse") {
                setPetitionDetails(prevState => ({
                    ...prevState,
                    hasCoverLetterRFE: false
                }));
            }

        } catch (error) {
            console.error("Error deleting cover letter:", error);
            Swal.fire("Error", 'Failed to delete Cover Letter. Please try again.', "error");
        } finally {
            setLoading(false);
        }
    };

    const deletePlanConfirmation = async (type) => {
        Swal.fire({
            customClass: {
                confirmButton: "btn btn-danger",
                cancelButton: "btn btn-light-info"
            },
            buttonsStyling: false,
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                deletePlanCall(type);
            }
        });
    }

    const deletePlanCall = async (type) => {
        setLoading(true);

        try {
            await deletePlan(petitionId, type);

            if (type == "OriginalSubmission") {
                setPetitionDetails(prevState => ({
                    ...prevState,
                    hasPlan: false
                }));
            }
            else if (type == "RFEResponse") {
                setPetitionDetails(prevState => ({
                    ...prevState,
                    hasPlanRFE: false
                }));
            }

        } catch (error) {
            console.error("Error deleting plan:", error);
            Swal.fire("Error", 'Failed to delete Plan. Please try again.', "error");
        } finally {
            setLoading(false);
        }
    };

    // Fetch shared users
    const fetchSharedUsers = async () => {
        setLoadingSharedUsers(true);
        try {
            const fetchedSharedUsers = await fetchSharedWithUsers(petitionId);
            setSharedUsers(fetchedSharedUsers);
        } catch (error) {
            setSharedError("Failed to load shared users.");
        } finally {
            setLoadingSharedUsers(false);
        }
    };

    // const handleAddSharedUser = async () => {
    //     if (!newSharedUserEmail) {
    //         Swal.fire("Error", "Email is required.", "error");
    //         return;
    //     }

    //     try {
    //         await addSharedWithUser(petitionId, newSharedUserEmail);
    //         setNewSharedUserEmail('');
    //         fetchSharedUsers(); // refresh list
    //         Swal.fire("Success", "User shared successfully.", "success");
    //     } catch (error) {
    //         Swal.fire("Error", error.message || "Failed to share with user.", "error");
    //     }
    // };

    const sharePetition = async () => {
        console.log('sharePetition');
        const { value: email } = await Swal.fire({
            title: "Enter email to share petition",
            text: "Sharing this petition cannot be undone. The shared user will have read-only access.",
            input: "email",
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return "You need to provide an email!";
                }
            }
        });

        if (email) {
            try {
                // Call the function to share the petition with the email
                setLoadingSharedUsers(true);
                await addSharedWithUser(petitionId, email, oauthAccessToken);
                fetchSharedUsers();

                Swal.fire("Success", "Petition shared successfully.", "success");
            } catch (error) {
                Swal.fire("Error", error, "error");
            } finally {
                setLoadingSharedUsers(false);
            }
        }
    };

    // Remove a shared user
    const handleRemoveSharedUser = async (sharedUserId) => {
        try {
            const result = await Swal.fire({
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-light-info"
                },
                buttonsStyling: false,
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!"
            });

            if (result.isConfirmed) {
                setLoadingSharedUsers(true);

                await removeSharedWithUser(petitionId, sharedUserId, oauthAccessToken);
                await fetchSharedUsers(); // refresh list

                Swal.fire("Success", "User removed from shared list.", "success");
            }

        } catch (error) {
            Swal.fire("Error", "Failed to remove shared user.", "error");
        } finally {
            setLoadingSharedUsers(false);
        }
    };

    useEffect(() => {
        fetchSharedUsers(); // Fetch shared users on component mount
    }, [petitionId]);

    if (loading) return <div>Loading data...</div>;
    if (error) return <div>Error loading data: {error}</div>;

    return (
        <div id="kt_app_body" data-kt-app-header-fixed-mobile="true" data-kt-app-toolbar-enabled="true" className="app-default">
            <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
                <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
                    <Header />
                    <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                        <div id="kt_app_toolbar" className="app-toolbar pt-4 pt-lg-7 mb-n2 mb-lg-n3">
                            <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack flex-row-fluid">
                                <div className="d-flex flex-stack flex-row-fluid">
                                    <div className="d-flex flex-column flex-row-fluid">
                                        {/*   {/*  {/* <!--begin::Breadcrumb--> */}
                                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold mb-1 mb-lg-3 me-2 fs-7">
                                            {/*   {/*  {/* <!--begin::Item--> */}
                                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                                                <Link to={`/`}>
                                                    <span className="text-white text-hover-primary">
                                                        <i className="ki-outline ki-home text-gray-700 fs-6"></i>
                                                    </span>
                                                </Link>
                                            </li>
                                            {/*   {/*  {/* <!--end::Item--> */}
                                            {/* <!--begin::Item--> */}
                                            <li className="breadcrumb-item">
                                                <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1"></i>
                                            </li>
                                            {/* <!--end::Item--> */}
                                            {/* <!--begin::Item--> */}
                                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1"><Link to={`/petition/${petitionId}`}>{formState?.petitionerName}</Link></li>
                                            {/* <!--end::Item--> */}
                                            {/* <!--begin::Item--> */}
                                            <li className="breadcrumb-item">
                                                <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1"></i>
                                            </li>
                                            {/* <!--end::Item--> */}
                                            {/* <!--begin::Item--> */}
                                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1">Settings</li>
                                            {/* <!--end::Item--> */}
                                        </ul>
                                        {/*   {/*  {/* <!--end::Breadcrumb--> */}
                                        {/*   {/*  {/* <!--begin::Page title--> */}
                                        <div className="page-title d-flex align-items-center me-3">
                                            {/*   {/*  {/* <!--begin::Title--> */}
                                            {/* <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">Settings</h1> */}
                                            {/*   {/*  {/* <!--end::Title--> */}
                                        </div>
                                        {/*   {/*  {/* <!--end::Page title--> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="app-container container-xxl d-flex">
                            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                                <div className="d-flex flex-column flex-column-fluid">
                                    <div id="kt_app_content" className="app-content">
                                        <div className="row g-5 g-xxl-10">
                                            <div className="col-xxl-12 mb-xxl-10">
                                                {/* <!--begin::Card--> */}
                                                <div className="card mb-5">
                                                    {/* <!--begin::Card header--> */}
                                                    <div className="card-header">
                                                        {/* <!--begin::Card title--> */}
                                                        <div className="card-title fs-3 fw-bold">Petition Settings</div>
                                                        {/* <!--end::Card title--> */}
                                                    </div>
                                                    {/* <!--end::Card header--> */}
                                                    {/* <!--begin::Form--> */}
                                                    <form className="form" onSubmit={handleSubmit}>
                                                        {/* <!--begin::Card body--> */}
                                                        <div className="card-body p-9">
                                                            {/* <!--begin::Row--> */}
                                                            <div className="row mb-8">
                                                                {/* <!--begin::Col--> */}
                                                                <div className="col-xl-3">
                                                                    <div className="fs-6 fw-semibold mt-2 mb-3">Petition Category</div>
                                                                </div>
                                                                {/* <!--end::Col--> */}
                                                                {/* <!--begin::Col--> */}
                                                                <div className="col-xl-9">
                                                                    <div className="d-flex fw-semibold h-100">
                                                                        <div className="form-check form-check-custom form-check-solid me-9">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                value="AD"
                                                                                name="subCategory"
                                                                                checked={formState.subCategory === 'AD'}
                                                                                onChange={handleOptionChange}
                                                                            />
                                                                            <label className="form-check-label ms-3">Advanced Degree</label>
                                                                        </div>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                value="EA"
                                                                                name="subCategory"
                                                                                checked={formState.subCategory === 'EA'}
                                                                                onChange={handleOptionChange}
                                                                            />
                                                                            <label className="form-check-label ms-3" >Exceptional Ability</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <!--end::Col--> */}
                                                            </div>
                                                            {/* <!--end::Row--> */}
                                                            {/* <!--begin::Row--> */}
                                                            <div className="row mb-8">
                                                                {/* <!--begin::Col--> */}
                                                                <div className="col-xl-3">
                                                                    <label className="form-label mb-3 required">Petitioner's name</label>
                                                                </div>
                                                                {/* <!--end::Col--> */}
                                                                {/* <!--begin::Col--> */}
                                                                <div className="col-xl-9 fv-row">
                                                                    <input type="text" className="form-control form-control-solid" name="petitionerName" value={formState.petitionerName} onChange={handleInputChange} required />
                                                                </div>
                                                            </div>
                                                            {/* <!--end::Row--> */}
                                                            {/* <!--begin::Row--> */}
                                                            <div className="row mb-8">
                                                                {/* <!--begin::Col--> */}
                                                                <div className="col-xl-3">
                                                                    <label className="d-flex align-items-center form-label mb-5 required">Place of Birth</label>
                                                                </div>
                                                                {/* <!--end::Col--> */}
                                                                {/* <!--begin::Col--> */}
                                                                <div className="col-xl-9 fv-row">
                                                                    <select
                                                                        name="placeOfBirth"
                                                                        className="form-select form-select-lg form-select-solid"
                                                                        value={formState.placeOfBirth}
                                                                        onChange={handleInputChange}
                                                                        data-control="select2"
                                                                        data-placeholder="Select..."
                                                                        data-allow-clear="true"
                                                                        data-hide-search="true"
                                                                        required
                                                                    >
                                                                        <option value="">Select country...</option>
                                                                        <option value="Afghanistan">Afghanistan</option>
                                                                        <option value="Albania">Albania</option>
                                                                        <option value="Algeria">Algeria</option>
                                                                        <option value="Argentina">Argentina</option>
                                                                        <option value="Australia">Australia</option>
                                                                        <option value="Austria">Austria</option>
                                                                        <option value="Bangladesh">Bangladesh</option>
                                                                        <option value="Belarus">Belarus</option>
                                                                        <option value="Belgium">Belgium</option>
                                                                        <option value="Brazil">Brazil</option>
                                                                        <option value="Bulgaria">Bulgaria</option>
                                                                        <option value="Canada">Canada</option>
                                                                        <option value="Chile">Chile</option>
                                                                        <option value="China">China</option>
                                                                        <option value="Colombia">Colombia</option>
                                                                        <option value="Croatia">Croatia</option>
                                                                        <option value="Cuba">Cuba</option>
                                                                        <option value="CzechRepublic">Czech Republic</option>
                                                                        <option value="Denmark">Denmark</option>
                                                                        <option value="Egypt">Egypt</option>
                                                                        <option value="Estonia">Estonia</option>
                                                                        <option value="Ethiopia">Ethiopia</option>
                                                                        <option value="Finland">Finland</option>
                                                                        <option value="France">France</option>
                                                                        <option value="Germany">Germany</option>
                                                                        <option value="Ghana">Ghana</option>
                                                                        <option value="Greece">Greece</option>
                                                                        <option value="Hungary">Hungary</option>
                                                                        <option value="Iceland">Iceland</option>
                                                                        <option value="India">India</option>
                                                                        <option value="Indonesia">Indonesia</option>
                                                                        <option value="Iran">Iran</option>
                                                                        <option value="Iraq">Iraq</option>
                                                                        <option value="Ireland">Ireland</option>
                                                                        <option value="Israel">Israel</option>
                                                                        <option value="Italy">Italy</option>
                                                                        <option value="Jamaica">Jamaica</option>
                                                                        <option value="Japan">Japan</option>
                                                                        <option value="Jordan">Jordan</option>
                                                                        <option value="Kenya">Kenya</option>
                                                                        <option value="Latvia">Latvia</option>
                                                                        <option value="Lebanon">Lebanon</option>
                                                                        <option value="Lithuania">Lithuania</option>
                                                                        <option value="Malaysia">Malaysia</option>
                                                                        <option value="Mexico">Mexico</option>
                                                                        <option value="Morocco">Morocco</option>
                                                                        <option value="Nepal">Nepal</option>
                                                                        <option value="Netherlands">Netherlands</option>
                                                                        <option value="NewZealand">New Zealand</option>
                                                                        <option value="Nigeria">Nigeria</option>
                                                                        <option value="Norway">Norway</option>
                                                                        <option value="Pakistan">Pakistan</option>
                                                                        <option value="Peru">Peru</option>
                                                                        <option value="Philippines">Philippines</option>
                                                                        <option value="Poland">Poland</option>
                                                                        <option value="Portugal">Portugal</option>
                                                                        <option value="Romania">Romania</option>
                                                                        <option value="Russia">Russia</option>
                                                                        <option value="SaudiArabia">Saudi Arabia</option>
                                                                        <option value="Serbia">Serbia</option>
                                                                        <option value="Singapore">Singapore</option>
                                                                        <option value="Slovakia">Slovakia</option>
                                                                        <option value="Slovenia">Slovenia</option>
                                                                        <option value="SouthAfrica">South Africa</option>
                                                                        <option value="SouthKorea">South Korea</option>
                                                                        <option value="Spain">Spain</option>
                                                                        <option value="SriLanka">Sri Lanka</option>
                                                                        <option value="Sweden">Sweden</option>
                                                                        <option value="Switzerland">Switzerland</option>
                                                                        <option value="Syria">Syria</option>
                                                                        <option value="Taiwan">Taiwan</option>
                                                                        <option value="Thailand">Thailand</option>
                                                                        <option value="Tunisia">Tunisia</option>
                                                                        <option value="Turkey">Turkey</option>
                                                                        <option value="Ukraine">Ukraine</option>
                                                                        <option value="UnitedArabEmirates">United Arab Emirates</option>
                                                                        <option value="UnitedKingdom">United Kingdom</option>
                                                                        <option value="UnitedStates">United States</option>
                                                                        <option value="Venezuela">Venezuela</option>
                                                                        <option value="Vietnam">Vietnam</option>
                                                                        <option value="Yemen">Yemen</option>
                                                                        <option value="Zimbabwe">Zimbabwe</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            {/* <!--end::Row--> */}
                                                            {/* <!--begin::Row--> */}
                                                            <div className="row mb-8">
                                                                {/* <!--begin::Col--> */}
                                                                <div className="col-xl-3">
                                                                    <label className="d-flex align-items-center form-label mb-5 required">Industry
                                                                    </label>
                                                                </div>
                                                                {/* <!--end::Col--> */}
                                                                {/* <!--begin::Col--> */}
                                                                <div className="col-xl-9 fv-row">
                                                                    <select
                                                                        name="industry"
                                                                        className="form-select form-select-lg form-select-solid"
                                                                        value={formState.industry}
                                                                        onChange={handleInputChange}
                                                                        data-control="select2"
                                                                        data-placeholder="Select..."
                                                                        data-allow-clear="true"
                                                                        data-hide-search="true"
                                                                        required
                                                                    >
                                                                        <option value="">Select industry...</option>
                                                                        <option value="Agriculture">Agriculture</option>
                                                                        <option value="Automotive">Automotive</option>
                                                                        <option value="Aviation">Aviation</option>
                                                                        <option value="Biotechnology">Biotechnology</option>
                                                                        <option value="Chemical">Chemical</option>
                                                                        <option value="Communications">Communications</option>
                                                                        <option value="Construction">Construction</option>
                                                                        <option value="Consulting">Consulting</option>
                                                                        <option value="ConsumerGoods">Consumer Goods</option>
                                                                        <option value="Education">Education</option>
                                                                        <option value="Electronics">Electronics</option>
                                                                        <option value="Energy">Energy</option>
                                                                        <option value="Engineering">Engineering</option>
                                                                        <option value="Entertainment">Entertainment</option>
                                                                        <option value="Environmental">Environmental</option>
                                                                        <option value="Finance">Finance</option>
                                                                        <option value="FoodAndBeverage">Food & Beverage</option>
                                                                        <option value="Government">Government</option>
                                                                        <option value="Healthcare">Healthcare</option>
                                                                        <option value="Hospitality">Hospitality</option>
                                                                        <option value="Insurance">Insurance</option>
                                                                        <option value="Legal">Legal</option>
                                                                        <option value="Manufacturing">Manufacturing</option>
                                                                        <option value="Marketing">Marketing</option>
                                                                        <option value="Media">Media</option>
                                                                        <option value="Mining">Mining</option>
                                                                        <option value="NonProfit">Non-Profit</option>
                                                                        <option value="Pharmaceutical">Pharmaceutical</option>
                                                                        <option value="ProfessionalServices">Professional Services</option>
                                                                        <option value="RealEstate">Real Estate</option>
                                                                        <option value="Retail">Retail</option>
                                                                        <option value="Technology">Technology</option>
                                                                        <option value="Telecommunications">Telecommunications</option>
                                                                        <option value="Textile">Textile</option>
                                                                        <option value="Transportation">Transportation</option>
                                                                        <option value="Utilities">Utilities</option>
                                                                        <option value="Veterinary">Veterinary</option>
                                                                        <option value="Wholesale">Wholesale</option>
                                                                        <option value="Other">Other</option>
                                                                    </select>
                                                                </div>
                                                                {/* <!--begin::Col--> */}
                                                            </div>
                                                            {/* <!--end::Row--> */}
                                                            {/* <!--begin::Row--> */}
                                                            <div className="row mb-8">
                                                                {/* <!--begin::Col--> */}
                                                                <div className="col-xl-3">
                                                                    <label className="form-label mb-3 required">State of Proposed Endeavor
                                                                        <span className="ms-1" data-bs-toggle="tooltip" title="Please select the U.S. state where you intend to execute your proposed endeavor. You may update this information at any time should your plans change.">
                                                                            <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                                                <span className="path1"></span>
                                                                                <span className="path2"></span>
                                                                                <span className="path3"></span>
                                                                            </i>
                                                                        </span></label>
                                                                </div>
                                                                {/* <!--end::Col--> */}
                                                                {/* <!--begin::Col--> */}
                                                                <div className="col-xl-9 fv-row">
                                                                    <select
                                                                        name="stateOfEndeavor"
                                                                        className="form-select form-select-lg form-select-solid"
                                                                        value={formState.stateOfEndeavor}
                                                                        onChange={handleInputChange}
                                                                        data-control="select2"
                                                                        data-placeholder="Select..."
                                                                        data-allow-clear="true"
                                                                        data-hide-search="true"
                                                                        required
                                                                    >
                                                                        <option value="">Select state...</option>
                                                                        <option value="Alabama">Alabama</option>
                                                                        <option value="Alaska">Alaska</option>
                                                                        <option value="Arizona">Arizona</option>
                                                                        <option value="Arkansas">Arkansas</option>
                                                                        <option value="California">California</option>
                                                                        <option value="Colorado">Colorado</option>
                                                                        <option value="Connecticut">Connecticut</option>
                                                                        <option value="Delaware">Delaware</option>
                                                                        <option value="DistrictOfColumbia">District of Columbia</option>
                                                                        <option value="Florida">Florida</option>
                                                                        <option value="Georgia">Georgia</option>
                                                                        <option value="Hawaii">Hawaii</option>
                                                                        <option value="Idaho">Idaho</option>
                                                                        <option value="Illinois">Illinois</option>
                                                                        <option value="Indiana">Indiana</option>
                                                                        <option value="Iowa">Iowa</option>
                                                                        <option value="Kansas">Kansas</option>
                                                                        <option value="Kentucky">Kentucky</option>
                                                                        <option value="Louisiana">Louisiana</option>
                                                                        <option value="Maine">Maine</option>
                                                                        <option value="Maryland">Maryland</option>
                                                                        <option value="Massachusetts">Massachusetts</option>
                                                                        <option value="Michigan">Michigan</option>
                                                                        <option value="Minnesota">Minnesota</option>
                                                                        <option value="Mississippi">Mississippi</option>
                                                                        <option value="Missouri">Missouri</option>
                                                                        <option value="Montana">Montana</option>
                                                                        <option value="Nebraska">Nebraska</option>
                                                                        <option value="Nevada">Nevada</option>
                                                                        <option value="NewHampshire">New Hampshire</option>
                                                                        <option value="NewJersey">New Jersey</option>
                                                                        <option value="NewMexico">New Mexico</option>
                                                                        <option value="NewYork">New York</option>
                                                                        <option value="NorthCarolina">North Carolina</option>
                                                                        <option value="NorthDakota">North Dakota</option>
                                                                        <option value="Ohio">Ohio</option>
                                                                        <option value="Oklahoma">Oklahoma</option>
                                                                        <option value="Oregon">Oregon</option>
                                                                        <option value="Pennsylvania">Pennsylvania</option>
                                                                        <option value="PuertoRico">Puerto Rico</option>
                                                                        <option value="RhodeIsland">Rhode Island</option>
                                                                        <option value="SouthCarolina">South Carolina</option>
                                                                        <option value="SouthDakota">South Dakota</option>
                                                                        <option value="Tennessee">Tennessee</option>
                                                                        <option value="Texas">Texas</option>
                                                                        <option value="Utah">Utah</option>
                                                                        <option value="Vermont">Vermont</option>
                                                                        <option value="Virginia">Virginia</option>
                                                                        <option value="Washington">Washington</option>
                                                                        <option value="WestVirginia">West Virginia</option>
                                                                        <option value="Wisconsin">Wisconsin</option>
                                                                        <option value="Wyoming">Wyoming</option>
                                                                    </select>
                                                                </div>
                                                                {/* <!--begin::Col--> */}
                                                            </div>
                                                            {/* <!--end::Row--> */}
                                                            {/* <!--begin::Row--> */}
                                                            <div className="row mb-8">
                                                                {/* <!--begin::Col--> */}
                                                                <div className="col-xl-3">
                                                                    <label className="form-label mb-3 required">Brief overview of your Proposed Endeavor
                                                                        <span className="ms-1" data-bs-toggle="tooltip" title="Provide a concise summary of your proposed endeavor. This should capture the essence of your project or activity.">
                                                                            <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                                                <span className="path1"></span>
                                                                                <span className="path2"></span>
                                                                                <span className="path3"></span>
                                                                            </i>
                                                                        </span></label>
                                                                </div>
                                                                {/* <!--end::Col--> */}
                                                                {/* <!--begin::Col--> */}
                                                                <div className="col-xl-9 fv-row">
                                                                    <textarea className="form-control form-control-solid h-100px" name="endeavorSummary" value={formState.endeavorSummary} onChange={handleInputChange} required></textarea>
                                                                </div>
                                                                {/* <!--begin::Col--> */}
                                                            </div>
                                                            {/* <!--end::Row--> */}
                                                        </div>
                                                        {/* <!--end::Card body--> */}
                                                        {/* <!--begin::Card footer--> */}
                                                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                                                            {isPetitionOwner(petitionDetails, currentUser) ? (
                                                                <button type="submit" className="btn btn-primary" id="kt_project_settings_submit">Save Changes</button>
                                                            ) : (<></>)}
                                                        </div>
                                                        {/* <!--end::Card footer--> */}
                                                    </form>
                                                    {/* <!--end:Form--> */}
                                                </div>
                                                {/* <!--end::Card--> */}

                                                <div className="row">
                                                    <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                                                        {/* <!--begin::Card--> */}
                                                        <div className="card mb-5">
                                                            {/* <!--begin::Card header--> */}
                                                            <div className="card-header">
                                                                {/* <!--begin::Card title--> */}
                                                                <div className="card-title fs-3 fw-bold">Advanced Settings</div>
                                                                {/* <!--end::Card title--> */}
                                                            </div>
                                                            {/* <!--end::Card header--> */}
                                                            {/* <!--begin::Form--> */}
                                                            <form className="form" onSubmit={handleSubmit}>
                                                                {/* <!--begin::Card body--> */}
                                                                <div className="card-body p-9">
                                                                    {/* <!--begin::Row--> */}
                                                                    <div className="row mb-8">
                                                                        {/* <!--begin::Col--> */}
                                                                        <div className="col-xl-3">
                                                                            <div className="fs-6 fw-semibold mt-2 mb-3">Cover Letter</div>
                                                                        </div>
                                                                        {/* <!--end::Col--> */}
                                                                        {/* <!--begin::Col--> */}
                                                                        <div className="col-xl-9">
                                                                            <div className="d-flex fw-semibold h-100">
                                                                                {petitionDetails.hasCoverLetter ?
                                                                                    (
                                                                                        <div className="form-control2 border-0"> "Cover Letter"
                                                                                            {isPetitionOwner(petitionDetails, currentUser) ? (
                                                                                                <span className="btn btn-sm btn-light-danger ms-3"
                                                                                                    onClick={() => deleteCoverLetterConfirmation("OriginalSubmission")}>Delete</span>
                                                                                            ) : (<></>)}

                                                                                        </div>

                                                                                    ) :
                                                                                    (
                                                                                        <div className="form-control form-control-solid fst-italic">This document has not been created yet.</div>
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                        {/* <!--end::Col--> */}
                                                                    </div>
                                                                    {/* <!--end::Row--> */}
                                                                    {/* <!--begin::Row--> */}
                                                                    <div className="row mb-8">
                                                                        {/* <!--begin::Col--> */}
                                                                        <div className="col-xl-3">
                                                                            <div className="fs-6 fw-semibold mt-2 mb-3">Plan</div>
                                                                        </div>
                                                                        {/* <!--end::Col--> */}
                                                                        {/* <!--begin::Col--> */}
                                                                        <div className="col-xl-9">
                                                                            <div className="d-flex fw-semibold h-100">
                                                                                {petitionDetails.hasPlan ?
                                                                                    (
                                                                                        <div className="form-control2 border-0">"Plan"
                                                                                            {isPetitionOwner(petitionDetails, currentUser) ? (
                                                                                                <span className="btn btn-sm btn-light-danger ms-3"
                                                                                                    onClick={() => deletePlanConfirmation("OriginalSubmission")}>Delete</span>
                                                                                            ) : (<></>)}

                                                                                        </div>

                                                                                    ) :
                                                                                    (
                                                                                        <div className="form-control form-control-solid fst-italic">This document has not been created yet.</div>
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                        {/* <!--end::Col--> */}
                                                                    </div>
                                                                    {/* <!--end::Row--> */}
                                                                    {/* <!--begin::Row--> */}
                                                                    <div className="row mb-8">
                                                                        {/* <!--begin::Col--> */}
                                                                        <div className="col-xl-3">
                                                                            <div className="fs-6 fw-semibold mt-2 mb-3">RFE's Cover Letter</div>
                                                                        </div>
                                                                        {/* <!--end::Col--> */}
                                                                        {/* <!--begin::Col--> */}
                                                                        <div className="col-xl-9">
                                                                            <div className="d-flex fw-semibold h-100">
                                                                                {petitionDetails.hasCoverLetterRFE ?
                                                                                    (
                                                                                        <div className="form-control2 border-0">"RFE's Cover Letter"
                                                                                            {isPetitionOwner(petitionDetails, currentUser) ? (
                                                                                                <span className="btn btn-sm btn-light-danger ms-3"
                                                                                                    onClick={() => deleteCoverLetterConfirmation("RFEResponse")}>Delete</span>
                                                                                            ) : (<></>)}

                                                                                        </div>

                                                                                    ) :
                                                                                    (
                                                                                        <div className="form-control form-control-solid fst-italic">This document has not been created yet.</div>
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                        {/* <!--end::Col--> */}
                                                                    </div>
                                                                    {/* <!--end::Row--> */}
                                                                    {/* <!--begin::Row--> */}
                                                                    <div className="row mb-8">
                                                                        {/* <!--begin::Col--> */}
                                                                        <div className="col-xl-3">
                                                                            <div className="fs-6 fw-semibold mt-2 mb-3">RFE's Plan</div>
                                                                        </div>
                                                                        {/* <!--end::Col--> */}
                                                                        {/* <!--begin::Col--> */}
                                                                        <div className="col-xl-9">
                                                                            <div className="d-flex fw-semibold h-100">
                                                                                {petitionDetails.hasPlanRFE ?
                                                                                    (
                                                                                        <div className="form-control2 border-0">"RFE's Plan"
                                                                                            {isPetitionOwner(petitionDetails, currentUser) ? (
                                                                                                <span className="btn btn-sm btn-light-danger ms-3"
                                                                                                    onClick={() => deletePlanConfirmation("RFEResponse")}>Delete</span>
                                                                                            ) : (<></>)}

                                                                                        </div>

                                                                                    ) :
                                                                                    (
                                                                                        <div className="form-control form-control-solid fst-italic">This document has not been created yet.</div>
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                        {/* <!--end::Col--> */}
                                                                    </div>
                                                                    {/* <!--end::Row--> */}
                                                                </div>
                                                                {/* <!--end::Card body--> */}
                                                                {/* <!--begin::Card footer--> */}
                                                                {/* <div className="card-footer d-flex justify-content-end py-6 px-9">
                                                            <button type="submit" className="btn btn-primary" id="kt_project_settings_submit">Save Changes</button>
                                                        </div> */}
                                                                {/* <!--end::Card footer--> */}
                                                            </form>
                                                            {/* <!--end:Form--> */}
                                                        </div>
                                                        {/* <!--end::Card--> */}
                                                    </div>
                                                    <div className='col-12 col-sm-12 col-md-6 col-lg-6'>
                                                        {/* <!--begin::Shared With--> */}
                                                        <div className="card mb-5">
                                                            {/* <!--begin::Card header--> */}
                                                            <div className="card-header">
                                                                {/* <!--begin::Card title--> */}
                                                                <div className="card-title fs-3 fw-bold">Shared With</div>
                                                                {/* <div className="card-title flex-column">
                                                                    <h2 className="mb-1">Shared With Users</h2>
                                                                    <div className="fs-6 fw-semibold text-muted">Total 25 tasks in backlog</div>
                                                                </div> */}
                                                                {/* <!--end::Card title--> */}
                                                                {/* <!--begin::Card toolbar--> */}
                                                                {(currentUser.uid == petitionDetails.userId) && (
                                                                    <div className="card-toolbar">
                                                                        <button type="button" className="btn btn-light-primary btn-sm" onClick={() => sharePetition()}>
                                                                            <i className="ki-outline ki-add-folder fs-3"></i>Share Petition</button>
                                                                    </div>
                                                                )}
                                                                {/* <!--end::Card toolbar--> */}
                                                            </div>
                                                            {/* <!--end::Card header--> */}
                                                            {/* <!--begin::Card body--> */}
                                                            <div className="card-body d-flex flex-column">

                                                                {sharedUsers
                                                                    .sort((a, b) => b.owner - a.owner) // Sort by owner, `true` (1) appears first
                                                                    .map(user => (
                                                                        <div className="d-flex align-items-center mb-3" key={user.id}>
                                                                            <div className="symbol symbol-50px me-3">
                                                                                <img src={user.photo} className="" alt="" />
                                                                            </div>
                                                                            <div className="d-flex justify-content-start flex-column">
                                                                                <span className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{user.name}
                                                                                    {user.owner ? (
                                                                                        <span className="badge badge-success fs-9 ms-2">Petition's owner</span>
                                                                                    ) : (
                                                                                        <span className="badge badge-info fs-9 ms-2">Read-only</span>
                                                                                    )}
                                                                                </span>
                                                                                <span className="text-gray-500 fw-semibold d-block fs-7">{user.email}</span>
                                                                            </div>

                                                                            {/* Show delete button for non-owner users */}
                                                                            {/* {(!user.owner && currentUser.uid == petitionDetails.userId) && (
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-sm btn-light-danger ms-auto"
                                                                                    onClick={() => handleRemoveSharedUser(user.id)}
                                                                                >
                                                                                    Remove Access
                                                                                </button>
                                                                            )} */}
                                                                        </div>
                                                                    ))}
                                                                {loadingSharedUsers ? (
                                                                    <div className="spinner-border text-primary" role="status">
                                                                        <span className="sr-only">Loading data...</span>
                                                                    </div>
                                                                ) : (<></>)}

                                                            </div>
                                                            {/* <!--end::Card body--> */}
                                                        </div>
                                                        {/* <!--end::Shared With--> */}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <Footer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default Settings;