import React from 'react';

function Footer() {
    return (
        <div id="kt_app_footer" className="app-footer d-flex flex-column flex-md-row flex-center flex-md-stack pb-3">
            {/* <!--begin::Copyright--> */}
            <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-1 me-lg-13">
                <div className="text-primary order-2 order-md-1">
                    <a href="/" className="text-hover-primary2">
                        <div style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <span className="text-muted fw-semibold me-1">2024&copy;</span>
                            <span style={{
                                fontWeight: 500,
                                fontSize: '1.5em',
                                letterSpacing: '0.05em'
                            }}>EB</span><span style={{
                                fontWeight: 100,
                                fontSize: '1.5em',
                                letterSpacing: '0.05em'
                            }}>Genius</span>
                        </div>
                    </a></div>
            </div >
            {/* <!--end::Copyright--> */}
        </div >
    );
}

export default Footer;
