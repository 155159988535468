import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

const aiapi = axios.create({
    baseURL: process.env.REACT_APP_AI_API_URL
});

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
    if (user) {
        user.getIdToken().then((token) => {
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            aiapi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        });
    } else {
        api.defaults.headers.common['Authorization'] = null;
        aiapi.defaults.headers.common['Authorization'] = null;
        // User is signed out. Handle it based on your requirements.
    }
});

// Add a response interceptor
api.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            window.location.href = '/login'; // Redirect to login page
        }
        return Promise.reject(error);
    }
);

aiapi.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            window.location.href = '/login'; // Redirect to login page
        }
        return Promise.reject(error);
    }
);

export { api, aiapi };
