// import React, { useEffect } from 'react';

// const Loading = ({ loading }) => {
//     useEffect(() => {
//         // When the loading prop changes, add or remove the "page-loading" class
//         if (loading) {
//             document.body.classList.add('page-loading');
//         } else {
//             document.body.classList.remove('page-loading');
//         }

//         // Cleanup function to remove the class when the component unmounts
//         return () => {
//             document.body.classList.remove('page-loading');
//         };
//     }, [loading]); // Dependency array, the effect runs only when `loading` changes

//     if (!loading) return null;

//     return (
//         <div style={{
//             position: 'fixed',
//             top: 0,
//             left: 0,
//             width: '100vw',
//             height: '100vh',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             backgroundColor: 'rgba(0, 0, 0, 0.5)',
//             zIndex: 1000
//         }}>
//             <div className="page-loader flex-column">
//                 <span className="spinner-border text-primary" role="status"></span>
//                 <span className="text-muted fs-6 fw-semibold mt-5">Loading...</span>
//             </div>
//         </div>
//     );
// };

// export default Loading;

// import React, { useEffect } from 'react';

// const Loading = ({ loading }) => {

// console.log("loading", loading);
// useEffect(() => {
//     // When the loading prop changes, add or remove the "page-loading" class
//     if (loading) {
//         document.body.classList.add('page-loading');
//     } else {
//         document.body.classList.remove('page-loading');
//     }

//     // Cleanup function to remove the class when the component unmounts
//     return () => {
//         document.body.classList.remove('page-loading');
//     };
// }, [loading]); // Dependency array, the effect runs only when `loading` changes

// if (!loading) return null;

//     return (
//         <div style={{
//             position: 'fixed', // Use 'fixed' to position relative to the viewport
//             top: 0,
//             left: 0,
//             width: '100vw', // Viewport width
//             height: '100vh', // Viewport height
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background
//             zIndex: 1000 // High z-index to ensure it's on top of other content
//         }}>
//             {/* <div style={{ textAlign: 'center' }}>
//                 <p>Loading...</p>
//             </div> */}

//             <div className="page-loader flex-column">
//                 <span className="spinner-border text-primary" role="status"></span>
//                 <span className="text-muted fs-6 fw-semibold mt-5">Loading...</span>
//             </div>
//         </div>
//     );
// };

// export default Loading;
import React, { useEffect } from 'react';

const Loading = ({ isLoading }) => {
    useEffect(() => {
        // Add the 'page-loading' class to the body when the component mounts
        document.body.classList.add('page-loading');

        // Cleanup function to remove the 'page-loading' class when the component unmounts
        return () => {
            document.body.classList.remove('page-loading');
        };
    }, []); // Empty dependency array ensures this effect runs only once on mount and unmount

    if (!isLoading) {
        return null; // Do not render the component if it is not loading
    }

    return (
        <div>
            <div className="page-loader flex-column">
                {/* <span className="text-gray-700 fs-6 fw-semibold mt-5">Loading..</span> */}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Loading;
