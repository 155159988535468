import React, { createContext, useContext, useState } from 'react';

const ExhibitContext = createContext();

export const ExhibitProvider = ({ children }) => {
    const [updateTrigger, setUpdateTrigger] = useState(0);

    const triggerUpdate = () => setUpdateTrigger(updateTrigger + 1);

    return (
        <ExhibitContext.Provider value={{ updateTrigger, triggerUpdate }}>
            {children}
        </ExhibitContext.Provider>
    );
};

export const useExhibitContext = () => useContext(ExhibitContext);
