import React, { useState, useRef, useEffect } from 'react';
import Swal from 'sweetalert2'
import { askGenius } from './../AIApiService.js';
import { marked } from 'marked';
import './ChatWindow.css';

const ChatWindow = ({ isChatOpen, toggleChat, petitionId, petitionerName }) => {
    const [messages, setMessages] = useState([
        { text: `Hi ${petitionerName}! How can I help you with your EB-2 NIW petition?`, received: true }
    ]);
    const [newMessage, setNewMessage] = useState('');
    const [isThinking, setIsThinking] = useState(false); // New state to track "thinking" status

    const inputRef = useRef(null); // Reference for the input field
    const chatBodyRef = useRef(null); // Reference for the chat body

    useEffect(() => {
        if (isChatOpen) {
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
                scrollToBottom();
            }, 0);
        }
    }, [isChatOpen]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
    };

    // Function to parse message text and render it as HTML using Markdown
    const parseMarkdown = (text) => {
        return { __html: marked(text) };
    };

    const handleSendMessage = async () => {
        if (newMessage.trim() !== '') {
            // Add the user's message to the chat
            const userMessage = { text: newMessage.replace(/\n/g, '<br />').replace(/\*/g, '&#42;'), received: false };
            const updatedMessages = [...messages, userMessage];
            setMessages(updatedMessages);
            setNewMessage(''); // Clear the input field

            setIsThinking(true); // Set "thinking" status to true

            // Simulate a response from the Genius after a short delay
            const response = await askGenius(petitionId, newMessage);

            setIsThinking(false); // Set "thinking" status to false after receiving the response

            console.log(response)
            console.log(response.error)
            if (response.error) {
                Swal.fire("Info", response.error, "info");
            }
            else {
                console.log(response.output);
                simulateTypingEffect(response.output, true);
            }

            // const geniusResponse = { text: response.output, received: true };

            // setMessages(prevMessages => [...prevMessages, geniusResponse]);
        }
    };

    // const handleAskGenius = async (petitionId, inputMessage) => {
    //     askGenius(petitionId, inputMessage)
    //         .then((result) => {
    //             return result;
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching petition:', error);
    //             return null;
    //         });
    // };

    const simulateTypingEffect = (text, received = false) => {
        console.log(text);
        const words = text.split(' ');
        let displayedText = '';
        let index = 0;

        // Add a blank message to simulate typing
        setMessages(prevMessages => [
            ...prevMessages,
            { text: '', received: received }
        ]);

        const typingInterval = setInterval(() => {
            if (index < words.length) {
                displayedText += `${words[index]} `;
                setMessages(prevMessages => {
                    // Find the last message that is empty and being "typed"
                    const newMessages = [...prevMessages];
                    newMessages[newMessages.length - 1] = {
                        text: displayedText,
                        received: received
                    };
                    return newMessages;
                });
                index++;
            } else {
                clearInterval(typingInterval);
            }
        }, 100); // Adjust this interval for faster/slower typing
    };

    return (
        isChatOpen && (
            <div className="chat-window">
                <div className="chat-header">
                    <img src="/assets/images/ebgeniusface.png" alt="Genius" className="genius-avatar" />
                    <span className='fs-4'>Ask Genius <span className="badge badge-success ms-2">BETA</span></span>
                    <button className="close-chat" onClick={toggleChat}>
                        <svg viewBox="0 0 12 13" width="20" height="20" fill="currentColor">
                            <g fillRule="evenodd" transform="translate(-450 -1073)">
                                <g fillRule="nonzero">
                                    <path d="m98.095 917.155 7.75 7.75a.75.75 0 0 0 1.06-1.06l-7.75-7.75a.75.75 0 0 0-1.06 1.06z" transform="translate(353.5 159)"></path>
                                    <path d="m105.845 916.095-7.75 7.75a.75.75 0 1 0 1.06 1.06l7.75-7.75a.75.75 0 0 0-1.06-1.06z" transform="translate(353.5 159)"></path>
                                </g>
                            </g>
                        </svg>
                    </button>
                </div>
                <div className="chat-body" ref={chatBodyRef}>
                    {messages.map((msg, index) => (
                        <div key={index} className={`message ${msg.received ? 'received' : 'sent'}`}>
                            <div dangerouslySetInnerHTML={parseMarkdown(msg.text)} />
                            {/* <div className="timestamp">{formatTimestamp(msg.timestamp)}</div> */}
                        </div>))}
                    {isThinking && (
                        <div className="message received" style={{ backgroundColor: "white" }}>
                            <div className="spinner"></div> {/* Add spinner here */}
                        </div>
                    )}
                </div>
                <div className="chat-footer">
                    <input
                        type="text"
                        placeholder="Type your question..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
                        ref={inputRef} // Bind the input field to the reference
                    />
                    <button className="send-button" onClick={handleSendMessage}>Send</button>
                </div>
            </div>
        )
    );
};

export default ChatWindow;
