import React, { useState, useEffect } from 'react';
import { useAuth } from './Contexts/AuthContext';
import Header from './Header';
import Footer from './Footer';
import CommunityCreatePost from './CommunityCreatePost';
import Post from './CommunityPost';
import { fetchRecentPosts, addPost, getTopHashtags } from './ApiService.js';

function Community() {
    const { currentUser } = useAuth();
    const [showModal, setShowModal] = useState(false);
    const [visibleComments, setVisibleComments] = useState({});
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedHashtag, setSelectedHashtag] = useState(''); // Add state for selected hashtag
    const [searchTerm, setSearchTerm] = useState('');
    const [newComment, setNewComment] = useState({});
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true); // Track if there are more posts to load
    // const [likes, setLikes] = useState({});
    const [loading, setLoading] = useState(false);
    // const [loadingShowMore, setLoadingShowMore] = useState(false);
    const [topHashtags, setTopHashtags] = useState([]); // Add state for top hashtags

    // Fetch top hashtags on component mount
    useEffect(() => {
        async function loadTopHashtags() {
            try {
                const hashtags = await getTopHashtags();
                // console.log(hashtags)
                setTopHashtags(hashtags);
            } catch (error) {
                console.error("Failed to load top hashtags:", error);
            }
        }

        loadTopHashtags();
    }, []);

    // const handleCommentChange = (postId, content) => {
    //     setNewComment(prevState => ({
    //         ...prevState,
    //         [postId]: content,
    //     }));
    // };

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        setSelectedHashtag(''); // Clear the selected hashtag when changing category
    };


    const handleHashtagClick = (hashtag) => {
        if (selectedHashtag == hashtag) {
            setSelectedHashtag('');
        }
        else {
            setSelectedHashtag(hashtag);
        }
        setSelectedCategory(''); // Clear the selected category when clicking a hashtag
        setPage(1); // Reset page when a new hashtag is selected
    };

    const handleViewAll = () => {
        setSelectedCategory('');
    };

    const handleModalOpen = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);

    // const handleSavePost = (category, content, hashtags) => {
    //     const newPost = {
    //         id: `post${posts.length + 1}`,
    //         author: {
    //             name: currentUser.Name,
    //             photo: currentUser.Photo
    //         },
    //         createdAt: new Date().toISOString(),
    //         content: content,
    //         category: category,
    //         hashtags: hashtags,
    //         comments: []
    //     };

    //     setPosts([newPost, ...posts]); // Add the new post to the beginning of the list
    //     handleModalClose(); // Close the modal after saving
    // };

    const handleSavePost = async (category, subject, content, hashtags) => {
        if (!currentUser) {
            // Handle unauthenticated user, e.g., show a login prompt
            console.error("User not authenticated");
            return;
        }

        // Filtrar qualquer hashtag vazia ou contendo apenas espaços em branco
        const filteredHashtags = hashtags
            .map(tag => tag.trim().toLowerCase()) // Remove espaços em branco e converte para minúsculas
            .filter(tag => tag.length > 0); // Mantém apenas hashtags não vazias

        const postData = {
            authorName: currentUser.Name,
            authorPhoto: currentUser.Photo,
            subject: subject,
            content: content,
            category: category,
            hashtags: filteredHashtags
        };

        try {
            // Call the addPost API
            const newPost = await addPost(postData);

            // Update the posts state with the new post
            setPosts(prevPosts => [newPost, ...prevPosts]);

            // Close the modal
            handleModalClose();
        } catch (error) {
            // Handle error, e.g., show an error message to the user
            console.error("Failed to add post:", error);
            // Optionally, you can set an error state to display a message in the UI
        }
    };

    // const handleAddComment = async (postId) => {
    //     if (!currentUser || !newComment[postId]?.trim()) {
    //         console.error("User not authenticated or comment is empty");
    //         return;
    //     }

    //     const commentData = {
    //         author: {
    //             name: currentUser.Name,
    //             photo: currentUser.Photo,
    //         },
    //         content: newComment[postId].trim(),
    //         createdAt: new Date().toISOString(), // Temporarily add the createdAt field for immediate rendering
    //     };

    //     try {
    //         // Call the addComment API to add the comment to Firestore
    //         const newCommentDoc = await addComment(postId, commentData);

    //         // Fetch the updated comments using the fetchComments function
    //         const updatedComments = await fetchComments(postId);

    //         // Update the posts state with the updated comments
    //         setPosts(prevPosts =>
    //             prevPosts.map(post =>
    //                 post.id === postId
    //                     ? { ...post, comments: updatedComments, commentsCount: post.commentsCount + 1 }
    //                     : post
    //             )
    //         );

    //         // Clear the comment input
    //         setNewComment(prevState => ({
    //             ...prevState,
    //             [postId]: '',
    //         }));

    //         // Optionally toggle comments visibility if it's not already open
    //         console.log(visibleComments[postId])
    //         console.log(visibleComments)

    //         if (!visibleComments[postId]) {
    //             toggleComments(postId);
    //         }
    //     } catch (error) {
    //         console.error("Failed to add comment:", error);
    //     }
    // };

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        async function loadPosts() {
            setLoading(true);
            try {
                // Fetch the posts from the backend
                const response = await fetchRecentPosts(selectedCategory, searchTerm, selectedHashtag, page);
                // const newPosts = await checkIfUserLikedPosts(response.posts, currentUser.uid);

                if (page > 1) {
                    // Append new posts to the existing list
                    setPosts(prevPosts => [...prevPosts, ...response.posts]);
                } else {
                    // Replace the posts with the new list (for the first page)
                    setPosts(response.posts);
                }

                // Update whether there are more posts to load
                setHasMore(response.hasMore);
            } catch (error) {
                console.error("Failed to load posts:", error);
            } finally {
                setLoading(false);
            }
        }

        if (currentUser?.uid) {
            loadPosts();
        }
    }, [currentUser?.uid, selectedCategory, searchTerm, selectedHashtag, page]);

    const handleShowMore = async () => {
        if (hasMore && !loading) {
            setLoading(true);
            try {
                setPage(prevPage => prevPage + 1);
            } catch (error) {
                console.error("Failed to load more posts:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    // const handleSearch = () => {
    //     //     async function searchPosts() {
    //     //         const searchedPosts = await fetchRecentPosts({ searchTerm });
    //     //         setFilteredPosts(searchedPosts);
    //     //     }

    //     //     searchPosts();
    // };

    const filteredPosts = selectedCategory
        ? posts.filter(post => post.category === selectedCategory)
        : posts;

    // // Toggle comments visibility
    // const toggleComments = async (postId) => {
    //     console.log('toggleComments')
    //     if (visibleComments[postId]) {
    //         // If comments are already visible, just toggle visibility
    //         setVisibleComments(prevState => ({
    //             ...prevState,
    //             [postId]: false
    //         }));
    //     } else {
    //         // If comments are not visible, load them using the fetchComments function
    //         try {
    //             const loadedComments = await fetchComments(postId);
    //             console.log(loadedComments)
    //             console.log('toggleComments 1')

    //             // Update the post's comments in the state and toggle visibility
    //             setPosts(prevPosts => prevPosts.map(post => {
    //                 if (post.id === postId) {
    //                     return {
    //                         ...post,
    //                         comments: loadedComments
    //                     };
    //                 }
    //                 return post;
    //             }));

    //             setVisibleComments(prevState => ({
    //                 ...prevState,
    //                 [postId]: true
    //             }));
    //         } catch (error) {
    //             console.error("Failed to toggle comments visibility:", error);
    //         }
    //     }
    // };

    // const handleLike = async (postId) => {
    //     if (!currentUser) {
    //         console.error("User not authenticated");
    //         return;
    //     }

    //     const userId = currentUser.uid; // Assuming you have the user's ID in the auth object

    //     // Find the post to be liked/unliked
    //     const postToLike = posts.find(post => post.id === postId);

    //     if (!postToLike) {
    //         console.error("Post not found");
    //         return;
    //     }

    //     // Determine the new like state
    //     const newIsLike = !postToLike.likedByUser;

    //     // Optimistically update the post's like state
    //     setPosts(prevPosts =>
    //         prevPosts.map(post =>
    //             post.id === postId
    //                 ? {
    //                     ...post,
    //                     likesCount: newIsLike ? post.likesCount + 1 : post.likesCount - 1,
    //                     likedByUser: newIsLike,
    //                 }
    //                 : post
    //         )
    //     );

    //     try {
    //         // Call the backend function to handle the like action
    //         await saveLike(postId, userId, newIsLike);

    //     } catch (error) {
    //         console.error("Failed to update like:", error);

    //         // Revert the like state if the API call fails
    //         setPosts(prevPosts =>
    //             prevPosts.map(post =>
    //                 post.id === postId
    //                     ? {
    //                         ...post,
    //                         likesCount: !newIsLike ? post.likesCount + 1 : post.likesCount - 1,
    //                         likedByUser: !newIsLike,
    //                     }
    //                     : post
    //             )
    //         );
    //     }
    // };

    // const handleLikeComment = async (postId, commentId) => {
    //     if (!currentUser) {
    //         console.error("User not authenticated");
    //         return;
    //     }

    //     const userId = currentUser.uid; // Assuming you have the user's ID in the auth object

    //     // Find the post and comment to be liked/unliked
    //     const postToUpdate = posts.find(post => post.id === postId);

    //     if (!postToUpdate) {
    //         console.error("Post not found");
    //         return;
    //     }

    //     const commentToLike = postToUpdate.comments.find(comment => comment.id === commentId);

    //     if (!commentToLike) {
    //         console.error("Comment not found");
    //         return;
    //     }

    //     // Determine the new like state
    //     const newIsLike = !commentToLike.likedByUser;

    //     // Optimistically update the comment's like state
    //     setPosts(prevPosts =>
    //         prevPosts.map(post =>
    //             post.id === postId
    //                 ? {
    //                     ...post,
    //                     comments: post.comments.map(comment =>
    //                         comment.id === commentId
    //                             ? {
    //                                 ...comment,
    //                                 likesCount: newIsLike ? comment.likesCount + 1 : comment.likesCount - 1,
    //                                 likedByUser: newIsLike,
    //                             }
    //                             : comment
    //                     ),
    //                 }
    //                 : post
    //         )
    //     );

    //     try {
    //         // Call the backend function to handle the like action
    //         await saveLikeComment(postId, commentId, userId, newIsLike);

    //     } catch (error) {
    //         console.error("Failed to update comment like:", error);

    //         // Revert the like state if the API call fails
    //         setPosts(prevPosts =>
    //             prevPosts.map(post =>
    //                 post.id === postId
    //                     ? {
    //                         ...post,
    //                         comments: post.comments.map(comment =>
    //                             comment.id === commentId
    //                                 ? {
    //                                     ...comment,
    //                                     likesCount: !newIsLike ? comment.likesCount + 1 : comment.likesCount - 1,
    //                                     likedByUser: !newIsLike,
    //                                 }
    //                                 : comment
    //                         ),
    //                     }
    //                     : post
    //             )
    //         );
    //     }
    // };

    const categories = [
        {
            name: "Eligibility & Requirements",
            image: "https://via.placeholder.com/150?text=Eligibility",
            icon: "ki-document"
        },
        {
            name: "Documentation & Evidence",
            image: "https://via.placeholder.com/150?text=Documentation",
            icon: "ki-document"
        },
        {
            name: "Writing the Petition",
            image: "https://via.placeholder.com/150?text=Petition",
            icon: "ki-document"
        },
        {
            name: "RFE Responses",
            image: "https://via.placeholder.com/150?text=RFE",
            icon: "ki-document"
        },
        {
            name: "Case Processing & Timelines",
            image: "https://via.placeholder.com/150?text=Processing",
            icon: "ki-document"
        },
        {
            name: "Success Stories & Experiences",
            image: "https://via.placeholder.com/150?text=Success",
            icon: "ki-document"
        }
    ];

    return (
        <>
            <CommunityCreatePost
                show={showModal}
                handleClose={handleModalClose}
                handleSave={handleSavePost}
            />
            <div id="kt_app_body" data-kt-app-header-fixed-mobile="true" data-kt-app-toolbar-enabled="true" className="app-default">
                <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
                    <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
                        <Header />
                        <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                            <div id="kt_app_toolbar" className="app-toolbar pt-4 pt-lg-7 mb-n2 mb-lg-n3">
                                <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack flex-row-fluid">
                                    <div className="d-flex flex-stack flex-row-fluid">
                                        <div className="d-flex flex-column flex-row-fluid">
                                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold mb-1 mb-lg-3 me-2 fs-7">
                                                <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                                                    <span href="#" className="text-white text-hover-primary">
                                                        <i className="ki-outline ki-people text-gray-700 fs-6"></i>
                                                    </span>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1"></i>
                                                </li>
                                                <li className="breadcrumb-item text-gray-900 fs-4 fw-bold lh-1">Community</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="app-container container-xxl d-flex">
                                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                                    <div className="d-flex flex-column flex-column-fluid">
                                        <div id="kt_app_content" className="app-content">
                                            <div className='row'>
                                                <div className='col-12 col-md-12 col-lg-9'>
                                                    {/* Always show "What's on your mind" section */}
                                                    <div className="card card-flush mb-10 cursor-pointer" onClick={handleModalOpen}>
                                                        <div className="card-header justify-content-start align-items-center pt-4 mt-4 mb-4">
                                                            <div className="symbol symbol-45px me-5">
                                                                <img src={currentUser?.Photo} alt="" />
                                                            </div>
                                                            <span className="text-gray-700 fw-semibold fs-5">What's on your mind, {currentUser?.Name?.split(' ')[0]}?</span>
                                                        </div>
                                                        <div className="card-body pt-2 pb-0 mb-2">
                                                            {/* Previously a textarea, now opens the modal */}
                                                        </div>
                                                    </div>
                                                    {/* End "What's on your mind" section */}

                                                    {/* Begin::Posts */}
                                                    <div className="mb-10" id="kt_social_feeds_posts">
                                                        {filteredPosts.length > 0 ? (
                                                            filteredPosts.map(post => (
                                                                <Post key={post.id} post={post} currentUser={currentUser} />
                                                            ))
                                                        ) : (
                                                            !loading && <p>No posts available.</p>
                                                        )}

                                                        {loading && (
                                                            <div className="d-flex justify-content-center mt-4">
                                                                <div className="spinner-border text-primary" role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/* End::Posts */}

                                                    {/* Begin::Show more feeds */}
                                                    {(hasMore && !loading) && (
                                                        <div className="d-flex flex-center mt-4">
                                                            <span className="btn btn-primary fw-bold px-6" onClick={handleShowMore}>
                                                                <span className="indicator-label">Show more</span>
                                                                {loading && (
                                                                    <span className="indicator-progress ms-2">
                                                                        <span className="spinner-border spinner-border-sm align-middle"></span>
                                                                    </span>
                                                                )}
                                                            </span>
                                                        </div>
                                                    )}
                                                    {/* End::Show more feeds */}

                                                </div>

                                                <div className='col-12 col-md-12 col-lg-3'>

                                                    {/* Add Search Field Here */}
                                                    {/* <div className="d-flex align-items-center">
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-solid border ps-5"
                                                            placeholder="Search posts..."
                                                            value={searchTerm}
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                        />
                                                        <button onClick={handleSearch} className="btn btn-primary btn-sm ms-2">Search</button>
                                                    </div> */}

                                                    {/* Begin::Trending Topics */}
                                                    <div className="d-lg-flex flex-column flex-lg-row-auto w-lg-325px" data-kt-drawer="true" data-kt-drawer-name="end-sidebar">
                                                        <div className="card card-flush mb-5 mb-xl-8">
                                                            {/* Begin::Header */}
                                                            <div className="card-header pt-5">
                                                                <h3 className="card-title align-items-start flex-column">
                                                                    <span className="card-label fw-bold text-gray-900">Topics</span>
                                                                </h3>
                                                                {/* Begin::Toolbar */}
                                                                <div className="card-toolbar">
                                                                    <button
                                                                        onClick={() => handleCategorySelect(null)}
                                                                        className={`btn btn-sm ${!selectedCategory ? 'btn-primary' : 'btn-light'}`}
                                                                    >
                                                                        View All
                                                                    </button>
                                                                </div>
                                                                {/* End::Toolbar */}
                                                            </div>
                                                            {/* End::Header */}

                                                            {/* Begin::Body */}
                                                            <div className="card-body">
                                                                {/* Begin::Menu */}
                                                                <div className="menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary">
                                                                    {categories.map(({ name, icon }) => (
                                                                        <div key={name} className="menu-item mb-3">
                                                                            <span
                                                                                className={`menu-link ${selectedCategory === name ? 'active' : ''}`}
                                                                                onClick={() => handleCategorySelect(name)}
                                                                            >
                                                                                <span className="menu-icon">
                                                                                    <i className={`ki-outline ${icon} fs-2 me-3`}></i>
                                                                                </span>
                                                                                <span className="menu-title fw-bold">{name}</span>
                                                                            </span>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                {/* End::Menu */}
                                                            </div>
                                                            {/* End::Body */}
                                                        </div>
                                                    </div>
                                                    {/* End::Trending Topics */}

                                                    {/* Begin::Top 10 Hashtags */}
                                                    <div className="d-lg-flex flex-column flex-lg-row-auto w-lg-325px" data-kt-drawer="true" data-kt-drawer-name="end-sidebar">
                                                        <div className="card card-flush mb-5 mb-xl-8">
                                                            {/* Begin::Header */}
                                                            <div className="card-header pt-5">
                                                                <h3 className="card-title align-items-start flex-column">
                                                                    <span className="card-label fw-bold text-gray-900">Top Hashtags</span>
                                                                </h3>
                                                            </div>
                                                            {/* End::Header */}

                                                            {/* Begin::Body */}
                                                            <div className="card-body">
                                                                {/* <ul className="list-group"> */}
                                                                {topHashtags.length > 0 ? (
                                                                    topHashtags.map(({ id, count }, index) => (
                                                                        <span key={index}>
                                                                            <span
                                                                                key={index}
                                                                                className={`badge ${selectedHashtag == id ? "badge-light-danger" : "badge-light-primary"} me-3 mb-2 mt-2 fs-5`}
                                                                                onClick={() => handleHashtagClick(id)}
                                                                                style={{ cursor: 'pointer' }}
                                                                            >
                                                                                #{id}
                                                                            </span>
                                                                            {/* <span className="badge badge-primary badge-pill">{count} posts</span> */}
                                                                        </span>
                                                                    ))
                                                                ) : (
                                                                    <span className="list-group-item">No hashtags available.</span>
                                                                )}
                                                                {/* </ul>  */}
                                                                {/* <ul className="list-group">
                                                                    {topHashtags.length > 0 ? (
                                                                        topHashtags.map(({ id, count }, index) => (
                                                                            <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                                                                <span
                                                                                    key={index}
                                                                                    className={`badge ${selectedHashtag == id ? "badge-light-danger" : "badge-light-primary"} me-2 mb-2 mt-2 fs-6`}
                                                                                    onClick={() => handleHashtagClick(id)}
                                                                                    style={{ cursor: 'pointer' }}
                                                                                >
                                                                                    #{id}
                                                                                </span>
                                                                                <span className="badge badge-primary badge-pill">{count} posts</span>
                                                                            </li>
                                                                        ))
                                                                    ) : (
                                                                        <li className="list-group-item">No hashtags available.</li>
                                                                    )}
                                                                </ul> */}
                                                            </div>
                                                            {/* End::Body */}
                                                        </div>
                                                    </div>
                                                    {/* End::Top 10 Hashtags */}                                                </div>
                                            </div>
                                            <Footer />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Community;
