import React, { useState } from 'react';

function EBGLab() {
    const [sensitivity, setSensitivity] = useState(50);
    // const sentences = [{
    //     'sentence': 'U.S. Department of Homeland Security\nJanuary 10, 2024 U.S.',
    //     'similarity_score': 0.8526130570612764
    // },
    // {
    //     'sentence': 'Citizenship and Immigration Services\nTexas Service Center\n6046 N Belt Line Rd STE 172\nIrving, TX 75038-0015\nRE:\nI-140, Immigrant Petition for Alien Worker\nREQUEST FOR EVIDENCE\nIMPORTANT: THIS NOTICE CONTAINS YOUR UNIQUE NUMBER.',
    //     'similarity_score': 0.7715742831523
    // },
    // {
    //     'sentence': 'THE ORIGINAL\nNOTICE MUST BE SUBMITTED WITH THE REQUESTED EVIDENCE.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'You are receiving this notice because U.S.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'Citizenship and Immigration Services (USCIS) requires\nadditional evidence to process your form.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'Please provide the evidence requested below.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'Include\nduplicate copies if you are requesting consular notification.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'Your response must be received in this office by April 8, 2024.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'Please note that you have been allotted the maximum period allowed for responding to a Request for\nEvidence (RFE).',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'The time period for responding cannot be extended.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': '8 Code of Federal Regulations\n(8 CFR 103.2(b)(8)(iv).',
    //     'similarity_score': 0.9999999999999998
    // },
    // {
    //     'sentence': 'Because many immigration benefits are time sensitive, you are encouraged to\nrespond to this request as early as possible, but no later than the deadline provided above.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'If you do\nnot respond to this notice within the allotted time, your case may be denied.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'The regulations do not\nprovide for an extension of time to submit the requested evidence.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'You must submit all requested evidence at the same time.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'If you submit only some of the requested\nevidence, USCIS will consider your response a request for a decision on the record.',
    //     'similarity_score': 1.0000000000000004
    // },
    // { 'sentence': '8 CFR\n103.2(b)(11).', 'similarity_score': 1.0 },
    // {
    //     'sentence': 'If you submit a document in any language other than English, the document must be accompanied by a\nfull and complete English translation.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'The translator must certify that the translation is accurate and he\nor she is competent to translate from that language to English.',
    //     'similarity_score': 1.0000000000000004
    // },
    // {
    //     'sentence': 'If you submit a foreign language\ntranslation in response to this request for evidence, you must also include a copy of the foreign\nlanguage document.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'Processing of your I-140 will resume upon receipt of your response.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'If you have not heard from\nUSCIS within 60 days of responding, you may contact the USCIS Contact Center (NCSC) at\n1-800-375-5283.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'If you are hearing impaired, please call the NCSC TDD at 1-800-767-1833.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'TSCI140TSCI14000027750178 1 of 11 www.uscis.gov\nUSCIS encourages you to sign up for a USCIS online account.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.Reference is made to this Form I-140, Immigrant Petition for Alien Worker, seeking classification as a\nmember of the professions holding an advanced degree and an exemption from the requirement of a\njob offer, and thus of a labor certification, in the national interest of the United States fo\n(petitioner and beneficiary), filed on his/her own behalf.',
    //     'similarity_score': 0.9110694948602807
    // },
    // {
    //     'sentence': 'The priority date for this petition is August 25, 2023.',
    //     'similarity_score': 0.7302112987237741
    // },
    // {
    //     'sentence': 'The beneficiary intends to work as a Dentist.',
    //     'similarity_score': 0.4472119495970516
    // },
    // {
    //     'sentence': 'In order to establish eligibility, the petitioner must establish that:\nThe beneficiary qualifies for the requested classification; and\nAn exemption from the requirement of a job offer, and thus of a labor certification, is in the\nnational interest of the United States.',
    //     'similarity_score': 1.0
    // },
    // { 'sentence': 'I.', 'similarity_score': 0.0 },
    // {
    //     'sentence': 'Eligibility for the Requested Classification\nMember of the Professions Holding an Advanced Degree: Eligibility of the Beneficiary\nThe first issue in this case is whether the beneficiary qualifies for the requested classification of a\nprofessional with an advanced degree.',
    //     'similarity_score': 0.6784243380577362
    // },
    // {
    //     'sentence': 'Advanced Degree\nThe regulation at 8 C.F.R.',
    //     'similarity_score': 0.7862519644705134
    // },
    // {
    //     'sentence': 'Section 204.5(k)(2) contains the following relevant definition:\nAdvanced degree means any United States academic or professional degree or a foreign\nequivalent degree above that of baccalaureate.',
    //     'similarity_score': 0.7377275341449511
    // },
    // {
    //     'sentence': "A United States baccalaureate degree or a\nforeign equivalent degree followed by at least five years of progressive experience in the\nspecialty shall be considered the equivalent of a master's degree.",
    //     'similarity_score': 0.517286088372837
    // },
    // {
    //     'sentence': 'If a doctoral degree is\ncustomarily required by the specialty, the alien must have a United States doctorate or a\nforeign equivalent degree (emphasis added).',
    //     'similarity_score': 0.33067116936867263
    // },
    // {
    //     'sentence': 'The petitioner must establish that he holds an advanced degree as of the priority date.',
    //     'similarity_score': 0.9511661427421093
    // },
    // {
    //     'sentence': 'The petitioner\nsubmitted the following evidence to establish that he meets this requirement:\nA transcript and diploma from Gama Filho University as a title of Dental Surgeon.',
    //     'similarity_score': 0.423637885934531
    // },
    // {
    //     'sentence': 'The petitioner stated the beneficiary qualifies as an advanced degree holder due to his Bachelor of\nDentistry at Gama Filho University in Rio de Janeiro and other certificates.',
    //     'similarity_score': 0.27541376190448674
    // },
    // {
    //     'sentence': 'USCIS consulted the\nAmerican Association of Collegiate Registrars and Admissions Officers’ (AACRAO’s) Electronic\nDatabase for Global Education (EDGE).',
    //     'similarity_score': 0.6430745098754485
    // },
    // {
    //     'sentence': 'AACRAO describes itself as “a non-profit, voluntary, professional association of more than 11,000\nhigher education professionals who represent approximately 2,600 institutions in more than 40\ncountries.',
    //     'similarity_score': 0.8314854285356742
    // },
    // {
    //     'sentence': 'Its mission is to provide professional development, guidelines, and voluntary standards to be used by\nhigher education officials regarding the best practices in records management, admissions, enrollment\nmanagement, administrative information technology, and student services."',
    //     'similarity_score': 0.19584398612898668
    // },
    // {
    //     'sentence': 'See\nhttp://www4.aacrao.org/centennial/about.htm accessed on January 5, 2023.',
    //     'similarity_score': 0.27042278915797663
    // },
    // {
    //     'sentence': 'EDGE is a web-based resource for the evaluation of foreign educational credentials.',
    //     'similarity_score': 0.2378685785659363
    // },
    // {
    //     'sentence': 'Each EDGE\neducational system profile includes: an overview describing the educational history of the country; an\neducational ladder or ladders to reflect changes in the educational structure; grading system(s); sample\ncredentials; placement recommendations; list of post secondary institutions; resources used to develop\nTSCI140TSCI14000027750178 2 of 11 www.uscis.gov\nUSCIS encourages you to sign up for a USCIS online account.',
    //     'similarity_score': 0.3421826861995689
    // },
    // {
    //     'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.the profile; author biography and notes; and glossary, when applicable.',
    //     'similarity_score': 0.5859683188118207
    // },
    // {
    //     'sentence': 'EDGE is a dynamic resource\nfor evaluating foreign credentials.',
    //     'similarity_score': 0.21743129511950016
    // },
    // {
    //     'sentence': 'See http://edge.aacrao.org/info.php accessed on May 16, 2023.',
    //     'similarity_score': 0.28295097755026066
    // },
    // {
    //     'sentence': 'EDGE provides the following information for a Título de Cirurgião Dentista from Brazil:\nCREDENTIAL DESCRIPTION\n[Title of Dental Surgeon].',
    //     'similarity_score': 0.30085594259152393
    // },
    // {
    //     'sentence': 'Awarded after 4 to 5 years of post secondary study.',
    //     'similarity_score': 0.3487397608146148
    // },
    // {
    //     'sentence': 'CREDENTIAL ADVICE\nThe Título de Cirurgião Dentista represents the attainment of a level of education comparable\nto a first professional degree in dentistry in the United States.',
    //     'similarity_score': 0.45702447723299433
    // },
    // {
    //     'sentence': 'However, it does not appear that the Gama Filho University in Rio de Janeiro, Brazil was accredited\nby an accrediting agency at the time the beneficiary received the credential.',
    //     'similarity_score': 0.21914692861540946
    // },
    // {
    //     'sentence': "An academic evaluation of the beneficiary's foreign education was not submitted to indicate the\nbeneficiary received a U.S. equivalency degree.",
    //     'similarity_score': 0.3630600173388134
    // },
    // {
    //     'sentence': 'USCIS consulted the World Higher Education\nDatabase www.whed.net., but the website does not list the Gama Filho University as a higher\neducation institution in Brazil nor does it indicate that the institution was accredited by any accrediting\nagency.',
    //     'similarity_score': 0.22588867853583766
    // },
    // {
    //     'sentence': 'As such, the beneficiary has not established that he has the foreign equivalent of a degree as a\nmedical doctor from an accredited institution of higher education in the United States.',
    //     'similarity_score': 0.34372323468507704
    // },
    // {
    //     'sentence': 'Therefore, the above- listed evidence fails to demonstrate the petitioner possesses an advanced degree.',
    //     'similarity_score': 0.3630791397032697
    // },
    // {
    //     'sentence': 'Please submit evidence to establish that the beneficiary is a professional holding an advanced degree.',
    //     'similarity_score': 0.7686231218579522
    // },
    // {
    //     'sentence': 'This evidence may consist of the following documentation:\nA copy of the beneficiary’s official academic record, showing that the beneficiary has a U.S.\nadvanced degree (or a foreign equivalent degree), the dates of attendance, area of concentration\nof study, and the date the beneficiary received the degree.',
    //     'similarity_score': 0.9153636575517261
    // },
    // {
    //     'sentence': 'Please also submit a complete copy\nof the beneficiary’s transcripts to show the major area of study; or\nA copy of the beneficiary’s official academic record showing that the beneficiary has a U.S.\nbaccalaureate degree (or a foreign equivalent degree), and evidence in the form of letters from\ncurrent or former employer(s) showing that the beneficiary has at least five years of progressive\npost-baccalaureate experience in the specialty.',
    //     'similarity_score': 0.9055582216194666
    // },
    // {
    //     'sentence': 'The employer must issue the letter of experience\non official letterhead and must list the employer’s name and address, the date, the signer’s\nname and title, and a description of the beneficiary’s experience, including dates of\nemployment and specific duties.',
    //     'similarity_score': 0.9823069664548874
    // },
    // {
    //     'sentence': 'If the beneficiary completed his education outside the United States, in addition to the beneficiary’s\nofficial academic record, please submit a detailed advisory evaluation of the beneficiary’s credentials.',
    //     'similarity_score': 0.4910353325558705
    // },
    // {
    //     'sentence': 'This evaluation is necessary to determine the level and major field of the beneficiary’s education in\nterms of equivalent education in the United States.',
    //     'similarity_score': 0.9539595788538602
    // },
    // {
    //     'sentence': 'An acceptable evaluation should:\nConsider formal education only, and not practical training or experience;\nState whether the beneficiary completed the United States equivalent of high school before\nentering college;\nProvide a detailed explanation of the evaluated material, rather than a simple conclusive\nstatement; and\nBriefly state the evaluator’s qualifications and experience.',
    //     'similarity_score': 0.9907008292979046
    // },
    // {
    //     'sentence': 'TSCI140TSCI14000027750178 3 of 11 www.uscis.gov\nUSCIS encourages you to sign up for a USCIS online account.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.The remaining issue is whether the beneficiary established that a waiver of the job offer requirement,\nand a labor certification, is in the national interest.',
    //     'similarity_score': 0.8148895899186342
    // },
    // { 'sentence': 'II.', 'similarity_score': 1.0 },
    // {
    //     'sentence': 'National Interest Waiver\nUSCIS has designated Matter of Dhanasar, 26 I&N Dec. 884 (AAO 2016) (“Dhanasar”) as a\nprecedent decision.',
    //     'similarity_score': 0.9180693268189095
    // },
    // {
    //     'sentence': 'That decision rescinded the earlier precedent decision, Matter of New York State\nDep’t of Transp.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': '("NYSDOT"), 22 I&N Dec. 215 (Acting Assoc.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'Comm’r 1998), regarding national\ninterest waivers under Section 203(b)(2)(B)(i) of the Immigration and Nationality Act, and introduced\na new three-prong test for determining eligibility.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'Under Dhanasar, USCIS may grant a national\ninterest waiver as a matter of discretion if the petitioner demonstrates by a preponderance of the\nevidence that:\nThe beneficiary’s proposed endeavor has both substantial merit and national importance;\nThe beneficiary is well positioned to advance the proposed endeavor; and\nOn balance, it would be beneficial to the United States to waive the requirements of a job offer\nand thus of a labor certification.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'If these three elements are satisfied, USCIS may approve the national interest waiver as a matter of\ndiscretion.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': '1st Prong - Substantial Merit and National Importance\nSubstantial Merit\nThe first prong, substantial merit and national importance, focuses on the specific endeavor that the\nforeign national proposes to undertake.',
    //     'similarity_score': 0.9342467559124135
    // },
    // {
    //     'sentence': 'Matter of Dhanasar, 26 I&N Dec. 884, 889 (AAO 2016).',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': "The\nendeavor's merit may be demonstrated in a range of areas such as business, entrepreneurialism,\nscience, technology, culture, health, or education.",
    //     'similarity_score': 1.0000000000000002
    // },
    // { 'sentence': 'Id.', 'similarity_score': 1.0 },
    // {
    //     'sentence': 'In this case, the self-petitioner submitted a statement and stated he wants to present his knowledge in\nareas of Dentistry, Endodontics and Implantology to the U.S. to generate value in healthcare.',
    //     'similarity_score': 0.2315235067863956
    // },
    // {
    //     'sentence': 'Continuing employment in one’s position, field, or industry is not an endeavor sufficient to evaluate\nunder this analytical framework.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'In Dhanasar, North Carolina Agricultural and Technical State\nUniversity (“North Carolina A&T”) employed the beneficiary as a Postdoctoral Research Associate\nand the petitioner worked in the air and space propulsion systems field.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'Matter of Dhanasar, 26 I&N\nDec. 884, 891 (AAO 2016).',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'His proposed endeavor was to “continue research into the design and\ndevelopment of propulsion systems for potential use in military and civilian technologies such as\nnano-satellites, rocket-propelled ballistic missiles, and single-stage-to-orbit vehicles.” Id.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'The petitioner in Dhanasar, provided specific insight as to what he intended to do as a Postdoctoral\nResearch Associate in the field of air and space propulsion systems.',
    //     'similarity_score': 1.0
    // },
    // { 'sentence': 'Id.', 'similarity_score': 1.0 },
    // {
    //     'sentence': 'Therefore, the AAO found the\npetitioner’s endeavor to “advance scientific knowledge and further national security interests and U.S.\ncompetitiveness in the civil space sector” as having substantial merit.',
    //     'similarity_score': 1.0000000000000002
    // },
    // { 'sentence': 'Id.', 'similarity_score': 1.0 },
    // { 'sentence': 'at 892.', 'similarity_score': 1.0 },
    // {
    //     'sentence': "From this limited amount of information, USCIS cannot determine the beneficiary's specific proposed\nendeavor.",
    //     'similarity_score': 0.34857527610633726
    // },
    // {
    //     'sentence': 'Importantly, the term “endeavor” is more specific than the general occupation.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'A petitioner\nshould offer details not only as to what the occupation normally involves, but what types of work the\nbeneficiary proposes to undertake specifically within that occupation.',
    //     'similarity_score': 0.9475838405264707
    // },
    // {
    //     'sentence': 'In Dhanasar, although Dr.\nDhanasar was an engineer by occupation, the decision discusses his specific proposed endeavors to\nengage in research and development relating to air and space propulsion systems, as well as to teach\naerospace engineering.',
    //     'similarity_score': 0.4008735840632819
    // },
    // {
    //     'sentence': 'Without the petitioner’s proposed endeavor, the petitioner impedes USCIS in determining that the\nTSCI140TSCI14000027750178 4 of 11 www.uscis.gov\nUSCIS encourages you to sign up for a USCIS online account.',
    //     'similarity_score': 0.7873831774624662
    // },
    // {
    //     'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.Without the petitioner’s proposed endeavor, the petitioner impedes USCIS in determining that the\nproposed endeavor has substantial merit and is of national importance and that the petitioner is well\npositioned to advance his proposed endeavor.',
    //     'similarity_score': 0.6135393394634974
    // },
    // {
    //     'sentence': 'As a result, the petitioner also impedes USCIS in\ndetermining that, on balance, it would be beneficial to the United States to waive the requirements of a\njob offer and thus of a labor certification.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'Evidence to establish that the beneficiary’s proposed endeavor has substantial merit consists of, but is\nnot limited to, the following:\nA detailed description of the proposed endeavor and why it is of substantial merit; and\nDocumentary evidence that supports the petitioner’s statements and establishes the endeavor’s\nmerit.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'National Importance\nIn determining national importance, the relevant question is not the importance of the field, industry,\nor profession in which the individual will work; instead USCIS must focus on the “the specific\nendeavor that the foreign national proposes to undertake.” See Dhanasar, 26 I&N Dec. at 889.',
    //     'similarity_score': 0.9890015631427657
    // },
    // {
    //     'sentence': 'In\nDhanasar, the AAO noted that they “look for broader implications” of the proposed endeavor and that\n“[a]n undertaking may have national importance for example, because it has national or even global\nimplications within a particular field.” Id.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'AAO also stated that "[a]n endeavor that has significant\npotential to employ U.S. workers or has other substantial positive economic effects, particularly in an\neconomically depressed area, for instance, may well be understood to have national importance."',
    //     'similarity_score': 1.0
    // },
    // { 'sentence': 'Id.', 'similarity_score': 1.0 },
    // { 'sentence': 'at 890.', 'similarity_score': 1.0 },
    // {
    //     'sentence': "To evaluate whether the petitioner's proposed endeavor satisfies the national importance requirement\nUSCIS must look to evidence documenting the “potential prospective impact'” of his proposed\nendeavor work.",
    //     'similarity_score': 0.969285511221572
    // },
    // { 'sentence': 'Id.', 'similarity_score': 1.0 },
    // { 'sentence': 'at 889.', 'similarity_score': 1.0 },
    // {
    //     'sentence': 'The self-petitioned intends to benefit both health and economic impact the health and wellness\nsegments of Americans.',
    //     'similarity_score': 0.22809108265984948
    // },
    // {
    //     'sentence': 'He believes implant dentistry is one of the main specialties today, has a high\nmarket value and is extremely important for the population since almost half of the population is\nmissing at least one tooth.',
    //     'similarity_score': 0.15388874038684688
    // },
    // {
    //     'sentence': 'He can immediately impact the dental market in an area with annual solid\nmarket growth and community health by working on rehabilitating patients with missing teeth.',
    //     'similarity_score': 0.1702612796684176
    // },
    // {
    //     'sentence': 'USCIS can consider information about the beneficiary’s current and prospective positions to illustrate\nthe capacity in which he intends to work in order to determine whether his proposed endeavor meets\nthe requirements of the first prong of the Dhanasar analytical framework.',
    //     'similarity_score': 0.8398215808481047
    // },
    // {
    //     'sentence': 'It is not apparent how the\nabove-listed "potential impact" has national or even global implications.',
    //     'similarity_score': 0.34478826898301734
    // },
    // {
    //     'sentence': 'In determining national\nimportance, the analysis should focus on the impact of what the petitioner intends to do rather than the\nspecific occupational classification.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'In the business plan for VL Dental Clinic was submitted, it stated, the practice will provide dental care\nservices such as dental consultations and diagnostics services, preventive services, restorative dental\nservices, orthodontics, surgical oral and maxillofacial services, non surgical services and more.',
    //     'similarity_score': 0.2643635618714448
    // },
    // {
    //     'sentence': 'To\nachieve this goal, the business will work on capital needs to scale and grow the brand to become the\nmost recognized and reputable clinic in the region.',
    //     'similarity_score': 0.24248116526908292
    // },
    // {
    //     'sentence': 'However, the self-petitioner failed to demonstrate\nthat his proposed endeavor has national or even global implications within a particular field or\nindustry.',
    //     'similarity_score': 0.48289205304373994
    // },
    // {
    //     'sentence': 'Going on record without supporting documentary evidence is not sufficient for purposes of\nmeeting the burden of proof in these proceedings.',
    //     'similarity_score': 0.8979101603391788
    // },
    // {
    //     'sentence': 'The business plan stated demand for industry services will come from older adults who tend to need\nmore serious and more frequent care as they age.',
    //     'similarity_score': 0.172020576245589
    // },
    // {
    //     'sentence': 'Growth in number of elderly Americans increases\ndemand for dental implants and other cosmetic dental procedures.',
    //     'similarity_score': 0.12409782885013905
    // },
    // {
    //     'sentence': 'As individuals age, dental health\nTSCI140TSCI14000027750178 5 of 11 www.uscis.gov\nUSCIS encourages you to sign up for a USCIS online account.',
    //     'similarity_score': 0.7734787000354808
    // },
    // {
    //     'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.generally declines and requires more maintenance.',
    //     'similarity_score': 0.7283097548237913
    // },
    // {
    //     'sentence': 'USCIS can consider information about the\nself-petitioner’s current and prospective positions to illustrate the capacity in which he intends to work\nin order to determine whether his proposed endeavor meets the requirements of the first prong of the\nDhanasar analytical framework.',
    //     'similarity_score': 0.8387985484211573
    // },
    // {
    //     'sentence': 'Occupational shortages do not, by itself, establish that the petitioner\'s\nendeavor stands to have a potential prospective impact on the broader field or otherwise have\nimplications rising to the level of national importance,\nTurning to national importance, we note that the relevant question is not the importance of the industry\nor profession in which the individual will work; instead, we focus on the "the specific endeavor that\nthe foreign national proposes to undertake."',
    //     'similarity_score': 0.7417450477570633
    // },
    // { 'sentence': 'See Dhanasar, 26 I&N Dec. at 889.', 'similarity_score': 1.0 },
    // {
    //     'sentence': 'Here, the petitioner\nmust demonstrate the national importance of his work in the Dental industry, rather than the national\nimportance of the industry overall.',
    //     'similarity_score': 0.4203137596257445
    // },
    // {
    //     'sentence': 'In Dhanasar, we noted that "we look for broader implications" of\nthe proposed endeavor and that "[a]n undertaking may have national importance for example, because\nit has national or even global implications within a particular field."',
    //     'similarity_score': 1.0
    // },
    // { 'sentence': 'Id.', 'similarity_score': 1.0 },
    // {
    //     'sentence': 'We also stated that "[a]n\nendeavor that has significant potential to employ U.S. workers or has other substantial positive\neconomic effects, particularly in an economically depressed area, for instance, may well be\nunderstood to have national importance."',
    //     'similarity_score': 0.993592702714353
    // },
    // { 'sentence': 'Id.', 'similarity_score': 1.0 },
    // { 'sentence': 'at 890.', 'similarity_score': 1.0 },
    // {
    //     'sentence': 'However, he did not indicate how he will allocate his time and accomplish all the numerous proposed\nactivities encompassing the specific proposed endeavor.',
    //     'similarity_score': 0.3196340379736332
    // },
    // {
    //     'sentence': 'The record indicated several positions that the\ncompany will would create but it does not explain how any claimed ripple effects of the specific\nproposed endeavor would operate on such a large scale to create jobs and revenues, increase the flow\nof money in the nation, and foment foreign direct investments.',
    //     'similarity_score': 0.2222588739377781
    // },
    // {
    //     'sentence': "It is also not apparent that any\nprojected taxes to be paid will substantially affect either any region where he will pursue his endeavor\nor the United States' tax revenue, or their economies more broadly at a level commensurate with\nnational importance.",
    //     'similarity_score': 0.3480126545277216
    // },
    // {
    //     'sentence': 'Apart from one letter, the petitioner did not submit any evidence of\ncommunications between the beneficiary and any potential employers or clients.',
    //     'similarity_score': 0.4893064472872786
    // },
    // {
    //     'sentence': 'The business plan indicated VL Dental Clinic will be located in Park City, Utah.',
    //     'similarity_score': 0.23367956925430416
    // },
    // {
    //     'sentence': 'The clinic will be a\nfamily-owned business managed by the self-petitioner.',
    //     'similarity_score': 0.19696966205908054
    // },
    // {
    //     'sentence': "The company's vision is to become the number\none choice when it comes to dental care service delivery in the whole of Park City, Utah.",
    //     'similarity_score': 0.11475618913678792
    // },
    // {
    //     'sentence': 'VL Dental\nClinic intends to become a first-class dental clinic that will take care of all kinds of clients that can\nafford their services.',
    //     'similarity_score': 0.1628558635321819
    // },
    // {
    //     'sentence': 'It is not apparent how the above-listed "potential impact" has national or even\nglobal implications.',
    //     'similarity_score': 0.34478826898301734
    // },
    // {
    //     'sentence': "Overall, the petitioner has not offered sufficient evidence to demonstrate that the\npetitioner's endeavor stands to impact the regional or national population at a level consistent with\nhaving national importance.",
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'Nor has the petitioner shown that his work would have broader\nimplications for the field.',
    //     'similarity_score': 0.7626054847721457
    // },
    // {
    //     'sentence': 'Accordingly, the petitioner has not demonstrated that his endeavor is of\nnational importance and has not met the first prong of the Dhanasar framework.',
    //     'similarity_score': 0.9517126947538068
    // },
    // {
    //     'sentence': 'The petitioner indicated he wants to pursue his endeavor in Park City, Utah.',
    //     'similarity_score': 0.23052792467113398
    // },
    // { 'sentence': 'area.', 'similarity_score': 0.25730931801173046 },
    // {
    //     'sentence': 'USCIS cannot\nconclude any area where he will pursue the specific proposed endeavor is economically depressed, that\nthe specific proposed endeavor would somehow lead to the employment of a significant population of\nworkers in the area, or that it would somehow offer the region or its population a substantial positive\neconomic benefit through employment levels, business activity, investment, or related tax revenue as\ncontemplated by Dhanasar.',
    //     'similarity_score': 0.8327107837705515
    // },
    // {
    //     'sentence': "Without sufficient information or evidence regarding any projected U.S.\neconomic impact or job creation attributable to the beneficiary's specific proposed endeavor, the\nrecord does not reflect the benefits to the U.S. regional or national economy resulting from his work as\nan Entrepreneur would reach the level of substantial positive economic effects contemplated by\nDhanasar.",
    //     'similarity_score': 0.8304427473882258
    // },
    // { 'sentence': 'Id.', 'similarity_score': 1.0 },
    // { 'sentence': 'at 890.', 'similarity_score': 1.0 },
    // {
    //     'sentence': 'Throughout the petitioner’s proposed business plan, the business plan provided financial forecasts,\nfinancial highlights, projected profit and loss and SWOT analysis.',
    //     'similarity_score': 0.35948944456113635
    // },
    // {
    //     'sentence': 'The numbers that the business plan\ngive in this example do not demonstrate that the company would be able to employ a large number of\nTSCI140TSCI14000027750178 6 of 11 www.uscis.gov\nUSCIS encourages you to sign up for a USCIS online account.',
    //     'similarity_score': 0.5707148635137569
    // },
    // {
    //     'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.employees that would make an economic difference on a national level and does not demonstrate the\nnational importance portion.',
    //     'similarity_score': 0.5937597522154052
    // },
    // {
    //     'sentence': "Though the beneficiary's intent may be to impact the broader field, the past record, and the potential\nprospective impact of the proposed endeavor point to a single impact within an organization.",
    //     'similarity_score': 0.3376128613250278
    // },
    // {
    //     'sentence': 'Specifically, it is difficult to see how the petitioner will benefit the U.S. beyond just what they can\noffer to their immediate company.',
    //     'similarity_score': 0.2228209173777808
    // },
    // {
    //     'sentence': "Overall, the petitioner has not offered sufficient evidence to demonstrate that the petitioner's endeavor\nstands to impact the regional or national population at a level consistent with having national\nimportance.",
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'Nor has the petitioner shown that his particular work would have broader implications for\nthe field.',
    //     'similarity_score': 0.827473575494926
    // },
    // {
    //     'sentence': 'Accordingly, the petitioner has not demonstrated that his endeavor is of national importance\nand has not met the first prong of the Dhanasar framework.',
    //     'similarity_score': 0.9517126947538068
    // },
    // {
    //     'sentence': 'The following can be submitted:\nA detailed description of the proposed endeavor and why it is of national importance,\nDocumentary evidence that supports the petitioner’s statements and establishes the endeavor’s\nnational importance.',
    //     'similarity_score': 0.8434282804453526
    // },
    // {
    //     'sentence': 'Such evidence must demonstrate the endeavor’s potential prospective\nimpact, and may consist of, but is not limited to, evidence showing that the proposed endeavor:\nHas national or even global implications within a particular field;\nHas significant potential to employ U.S. workers or has other substantial positive\neconomic effects, particularly in an economically depressed area;\nWill broadly enhance societal welfare or cultural or artistic enrichment; and\nImpacts a matter that a government entity has described as having national importance\nor is the subject of national initiatives.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': '2nd Prong - Well Positioned to Advance the Proposed Endeavor\nThe second prong shifts focus from the proposed endeavor to the foreign national.',
    //     'similarity_score': 0.819625559046962
    // },
    // {
    //     'sentence': 'See Dhanasar, 26,\nI&N.',
    //     'similarity_score': 0.7694469604166833
    // },
    // { 'sentence': 'Dec. at 880.', 'similarity_score': 0.8049471323090448 },
    // {
    //     'sentence': "As it relates to the self-petitioner's education and years of experience and knowledge.",
    //     'similarity_score': 0.3165762664759778
    // },
    // {
    //     'sentence': 'The petitioner\nhas not shown that his academic accomplishments by themselves are sufficient to demonstrate that he\nis well positioned to advance his proposed endeavor.',
    //     'similarity_score': 0.44494149168285957
    // },
    // {
    //     'sentence': 'In Dhanasar, the record established that the\npetitioner held multiple graduate degrees including "two master of science degrees, in mechanical\nengineering and applied physics, as well as a Ph.D. in engineering."',
    //     'similarity_score': 0.9684895789592137
    // },
    // { 'sentence': 'Id.', 'similarity_score': 1.0 },
    // { 'sentence': 'at 891.', 'similarity_score': 1.0 },
    // {
    //     'sentence': 'We look to a variety of\nfactors in determining whether a petitioner is well positioned to advance his proposed endeavor and\neducation is merely one factor among many that may contribute to such a finding.',
    //     'similarity_score': 0.6336998232445925
    // },
    // {
    //     'sentence': 'Not every individual who possesses the necessary educational and professional credentials and who\nworked in the field will be found to be well positioned to advance the proposed endeavor.',
    //     'similarity_score': 0.9059550403370429
    // },
    // {
    //     'sentence': 'USCIS\nlooks to a variety of factors in determining whether an individual is well positioned to advance the\nproposed endeavor.',
    //     'similarity_score': 0.6054991636106118
    // },
    // {
    //     'sentence': 'USCIS acknowledges the evidence submitted with respect to the claim that he is\nwell-positioned to advance his proposed endeavor, such as, an academic evaluation, resume, expert\nletters, and a letter of support.',
    //     'similarity_score': 0.35481510180811615
    // },
    // {
    //     'sentence': 'However, the evidence submitted to establish that the petitioner’s\nproposed endeavor qualifies under the well-positioned prong, while relevant to factors that can be\nconsidered as supportive to the petitioner’s claims, has not established that the petitioner is\nwell-positioned to advance his proposed endeavor, or the interests of the United States.',
    //     'similarity_score': 0.5205801912471327
    // },
    // {
    //     'sentence': "It is noted that while the petitioner's reference gives commendable statement of the work he had done,\nhis extensive experience in the Dental area.",
    //     'similarity_score': 0.22609720929601965
    // },
    // {
    //     'sentence': 'However, it failed to provide any information or objective\ndocumentary evidence to show that the petitioner is well-positioned to advance the proposed endeavor\nTSCI140TSCI14000027750178 7 of 11 www.uscis.gov\nUSCIS encourages you to sign up for a USCIS online account.',
    //     'similarity_score': 0.637107111855543
    // },
    // {
    //     'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.or Entrepreneurialism.',
    //     'similarity_score': 0.8920458512451382
    // },
    // {
    //     'sentence': "The self-petitioner's education from Gama Filho University as a title of Dental\nSurgeon is not an accredited U.S. university which makes him not well-positioned to advance his\nproposed endeavor.",
    //     'similarity_score': 0.25806336989282663
    // },
    // {
    //     'sentence': "While the self-petitioner's personal and professional acquaintances express their admiration and\nappreciation for him, the letters are insufficient to show the petitioner meets this prong.",
    //     'similarity_score': 0.738117230096426
    // },
    // {
    //     'sentence': 'The letters\nlack specific, detailed information to show the petitioner is well-positioned to advance the proposed\nendeavor.',
    //     'similarity_score': 0.5983516844449561
    // },
    // {
    //     'sentence': 'The petitioner provided a letter from Roku indicated his experience in the field of endeavor.',
    //     'similarity_score': 0.3664657469573491
    // },
    // {
    //     'sentence': ', Specialist in Implant Dentistry stated:\n"I followed his work in the care of patients between the years of 2014 to 2016, where he\ndemonstrated mastery and knowledge about the most modern surgical techniques,\ncomplemented by dexterity in the prosthetic phase.',
    //     'similarity_score': 0.2038559369583429
    // },
    // {
    //     'sentence': 'He has also worked on making immediate\ntemporaries and has done all kinds of impression-taking techniques.',
    //     'similarity_score': 0.14024051499904813
    // },
    // {
    //     'sentence': 'In other words, he has\nproven to be a very technical and complete professional, easy to learn and with broad\ntheoretical knowledge."',
    //     'similarity_score': 0.16125040297875698
    // },
    // {
    //     'sentence': 'While the letters claims that the self-petitioner is an experienced professional, the writers fail to\nsufficiently articulate the beneficiary’s achievements and progress in his endeavor.',
    //     'similarity_score': 0.23042434497510134
    // },
    // {
    //     'sentence': 'The letters of\nrecommendation, as well as the others submitted in the record, similarly make conclusory statements\nabout the beneficiary’s achievements.',
    //     'similarity_score': 0.2398710638407628
    // },
    // {
    //     'sentence': "The authors claim the self-petitioner’s work has influenced the\nfield, but they failed to provide specific examples of how the self-petitioner's work influenced the field\nor industry beyond his immediate organization.",
    //     'similarity_score': 0.5752643423219899
    // },
    // {
    //     'sentence': 'In addition, the letters fail to show how his experience\ndemonstrates he is well-positioned to advance the proposed endeavor.',
    //     'similarity_score': 0.4056742644947544
    // },
    // {
    //     'sentence': 'Notably, not every individual\nwho possesses the necessary educational and professional credentials, skills, training, education, and\nwho worked in the field will be found to be well positioned to advance the proposed endeavor.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'USCIS notes that the recommendation letters submitted are from individuals who are professionally\nacquainted with the beneficiary.',
    //     'similarity_score': 0.9679845957228104
    // },
    // {
    //     'sentence': 'While it is true that the authors of the letters appear to be experts in\nthe self-petitioner’s field, the record, at present, does not persuasively establish that the self-petitioner\nhas had a substantial global impact in the field of endeavor.',
    //     'similarity_score': 0.8437169581614101
    // },
    // {
    //     'sentence': 'It would be generally expected that an\nalien who has had such a substantial impact on the field that the granting of a national interest waiver\nwould be warranted, would be known outside the circle of his personal acquaintances.',
    //     'similarity_score': 0.2819552969423781
    // },
    // {
    //     'sentence': 'In addition, the\nopinions of experts in the field, while not without weight, cannot form the cornerstone of a successful\nclaim for classification as a professional holding an advanced degree who is also requesting a national\ninterest waiver.',
    //     'similarity_score': 0.4972614435044683
    // },
    // {
    //     'sentence': 'USCIS notes that letters contain general praise and speculation as to the potential\napplications of the alien’s work are less persuasive than letters that provide specific examples of how\nthe alien has already influenced the field.',
    //     'similarity_score': 0.2825889907974169
    // },
    // {
    //     'sentence': 'USCIS may, in its discretion, use advisory opinion statements submitted as expert testimony.',
    //     'similarity_score': 0.8837981006456589
    // },
    // {
    //     'sentence': 'However, where an opinion is not in accord with other information or is in any way questionable,\nUSCIS is not required to accept that evidence, or may give less weight to it.',
    //     'similarity_score': 0.792548143178953
    // },
    // {
    //     'sentence': 'Matter of Caron\nInternational, 19 I &N Dec. 791 (Comm.',
    //     'similarity_score': 0.9266644821953158
    // },
    // {
    //     'sentence': '1988); Matter of Sea, Inc., 19 I&N Dec. 817 (Comm.',
    //     'similarity_score': 0.44589100704172296
    // },
    // { 'sentence': '1988).', 'similarity_score': 0.0 },
    // {
    //     'sentence': "USCIS is ultimately responsible for making the final determination regarding an alien's eligibility for\nthe benefit sought.",
    //     'similarity_score': 0.8896564119506389
    // },
    // {
    //     'sentence': 'Moreover, original contributions in the field must be demonstrated by preexisting,\nindependent, and corroborating evidence.',
    //     'similarity_score': 0.29973572862001024
    // },
    // {
    //     'sentence': "The submission of letters of support from the\nself-petitioner's professional contacts is not presumptive evidence of eligibility.",
    //     'similarity_score': 0.5628901188616229
    // },
    // {
    //     'sentence': "USCIS may evaluate\nthe content of those letters as to whether they support the alien's eligibility.",
    //     'similarity_score': 0.7022358385296282
    // },
    // {
    //     'sentence': "Thus, the content of the\nwriters' statements and how they became aware of the self-petitioner's reputation are important\nconsiderations.",
    //     'similarity_score': 0.1925250488043676
    // },
    // {
    //     'sentence': 'Even when written by independent experts, letters solicited by an alien in support of an\nimmigration petition are of less weight than pre-existing, independent evidence of innovative\ncontributions of major importance.',
    //     'similarity_score': 0.18171028189656485
    // },
    // {
    //     'sentence': 'TSCI140TSCI14000027750178 8 of 11 www.uscis.gov\nUSCIS encourages you to sign up for a USCIS online account.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.As such, the petitioner has failed to establish he is well positioned to advance the proposed endeavor.',
    //     'similarity_score': 0.742179327637316
    // },
    // {
    //     'sentence': 'Evidence which best establishes that the petitioner is well positioned to advance the proposed\nendeavor will document the petitioner’s qualifications (skills, experience and track record), support\n(financial and otherwise) and commitment (plans and progress) to drive the endeavor forward, and\nwill support projections of future work in the proposed endeavor.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'USCIS may consider factors\nincluding, but not limited to, the following:\nThe petitioner’s education, skills, knowledge, and record of success in related or similar efforts:\nTo show a beneficiary’s education, skills, knowledge, and record of success in related or similar\nefforts, the petitioner may submit one or more pieces of evidence from the following non-exhaustive\nlist:\nLicenses in the field.',
    //     'similarity_score': 0.7970166224725328
    // },
    // {
    //     'sentence': 'Patents, trademarks, or copyrights owned by the beneficiary.',
    //     'similarity_score': 0.4678989442198783
    // },
    // {
    //     'sentence': 'Published articles and/or media reports about the beneficiary’s achievements or current work.',
    //     'similarity_score': 0.5051395496150961
    // },
    // {
    //     'sentence': 'Documentation demonstrating a strong citation history.',
    //     'similarity_score': 0.3334231119226504
    // },
    // {
    //     'sentence': 'Evidence that the beneficiary’s work has influenced his or her field of endeavor;\nEvidence demonstrating the beneficiary has a leading, critical or indispensable role in the\nendeavor or similar endeavors; and\nEvidence showing that the beneficiary’s past inventions or innovations have been used or\nlicensed by others in the field.',
    //     'similarity_score': 0.7855181830260964
    // },
    // {
    //     'sentence': 'A model or plan for future activities:\nTo show a model or plan for future activities, the petitioner may submit one or more pieces of\nevidence from the following non-exhaustive list:\nA plan describing how the beneficiary intends to continue his or her work in the United States.',
    //     'similarity_score': 0.8552552679232523
    // },
    // {
    //     'sentence': 'A detailed business model, when appropriate.',
    //     'similarity_score': 0.4014806909090276
    // },
    // {
    //     'sentence': 'Correspondence from prospective/potential employers, clients or customers; and\nDocumentation reflecting feasible plans for financial support.',
    //     'similarity_score': 0.7544763962711735
    // },
    // {
    //     'sentence': ';\nAny progress towards achieving the proposed endeavor:\nTo show progress towards achieving the proposed endeavor, a petitioner may submit one or more\npieces of evidence from the following non-exhaustive list:\nEvidence of grants the beneficiary has received listing the amount and terms of the grants, as\nwell as the grantees.',
    //     'similarity_score': 0.7671057445951992
    // },
    // {
    //     'sentence': 'Copies of contracts, agreements, or licenses resulting from the proposed endeavor or otherwise\ndemonstrating the beneficiary is well positioned to advance the proposed endeavor.',
    //     'similarity_score': 0.5969429815809636
    // },
    // {
    //     'sentence': 'Evidence of achievements that the beneficiary intends to build upon or further develop\n(including the types of documentation listed under “beneficiary’s education, skills, knowledge,\nand record of success in related or similar efforts”); and\nEvidence demonstrating the beneficiary has a leading, critical or indispensable role in the\nendeavor.',
    //     'similarity_score': 0.6958336991635075
    // },
    // {
    //     'sentence': 'TSCI140TSCI14000027750178 9 of 11 www.uscis.gov\nUSCIS encourages you to sign up for a USCIS online account.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.endeavor.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'The interest of potential customers, users, investors, or other relevant entities or individuals:\nTo show interest of potential customers, investors, or other relevant individuals, a petitioner may\nsubmit one or more pieces of evidence from the following non-exhaustive list:\nLetters from a government entity demonstrating its interest in the proposed endeavor.',
    //     'similarity_score': 0.8071123159528675
    // },
    // {
    //     'sentence': 'Evidence that the beneficiary has received investment from U.S. investors, such as venture\ncapital firms, angel investors, or start-up accelerators, in amounts that are appropriate to the\nrelevant endeavor.',
    //     'similarity_score': 0.7021264726104596
    // },
    // {
    //     'sentence': 'Evidence that the beneficiary has received awards, grants, or other indications of relevant\nnon-monetary support (for e.g., using facilities free of charge, etc.)',
    //     'similarity_score': 0.6070803772651866
    // },
    // {
    //     'sentence': 'from Federal, State, or local\ngovernment entities with authority over the field of endeavor.',
    //     'similarity_score': 0.44344315803409834
    // },
    // {
    //     'sentence': 'Evidence demonstrating how the beneficiary’s work is being used by others, such as:\nContracts with companies using products, projects, or services that the beneficiary\ndeveloped or assisted in developing.',
    //     'similarity_score': 0.6706041240794269
    // },
    // {
    //     'sentence': 'Documents showing licensed technology, or other procedural or technological\nadvancements developed in whole or in part by the beneficiary and relied upon by\nothers; and\nPatents or licenses awarded to the beneficiary with documentation showing why the\npatent or license is significant to the field.',
    //     'similarity_score': 0.8223340455867904
    // },
    // {
    //     'sentence': 'Note: The beneficiary may be well positioned to advance the endeavor even if there is no certainty that\nthe proposed endeavor will be a success.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'However, unsubstantiated claims are insufficient and would\nnot meet the petitioner’s burden of proof.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': '3rd Prong – Beneficial to the United States to waive the job offer and labor certification\nrequirements\nThe third prong requires the petitioner to demonstrate that, on balance, it would be beneficial to the\nUnited States to waive the requirements of a job offer and thus of a labor certification.',
    //     'similarity_score': 0.9365890070144534
    // },
    // {
    //     'sentence': 'In performing\nthis analysis, USCIS may evaluate factors such as: whether, in light of the nature of the foreign\nnational’s qualifications or the proposed endeavor, it would be impractical either for the foreign\nnational to secure a job offer or for the petitioner to obtain a labor certification; whether, even\nassuming that other qualified U.S. workers are available, the United States would still benefit from the\nforeign national’s contributions; and whether the national interest in the foreign national’s\ncontributions is sufficiently urgent to warrant forgoing the labor certification process.',
    //     'similarity_score': 0.8954350602801573
    // },
    // {
    //     'sentence': 'The self-petitioner claims his academic and professional background makes him different from those\nin the dental field.',
    //     'similarity_score': 0.1953712619492029
    // },
    // {
    //     'sentence': 'He stated he is valuable to the dental industry from his 14 years of experience in the\nindustry and solid academic background.',
    //     'similarity_score': 0.22911686650247665
    // },
    // {
    //     'sentence': 'The self-petitioner has not established that it would be beneficial to the United States to waive the\nrequirements of a job offer and thus of a labor certification.',
    //     'similarity_score': 0.9180002235678486
    // },
    // {
    //     'sentence': 'The self-petitioner did not establish that\nhis specific proposed endeavor has substantial merit, national importance as required by the first prong\nof the Dhanasar framework.',
    //     'similarity_score': 0.8205015770835007
    // },
    // {
    //     'sentence': 'Further, the self-petitioner did not establish that he is well positioned to\nadvance the specific proposed endeavor, as required by the second prong of the Dhanasar framework.',
    //     'similarity_score': 0.924985329422542
    // },
    // {
    //     'sentence': 'As discussed above, the evidence does not establish that the self-petitioner’s specific proposed\nendeavor has national importance as required by the first prong of the Dhanasar framework.',
    //     'similarity_score': 0.7831222591089094
    // },
    // {
    //     'sentence': 'TSCI140TSCI14000027750178 10 of 11 www.uscis.gov\nUSCIS encourages you to sign up for a USCIS online account.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.endeavor has national importance as required by the first prong of the Dhanasar framework.',
    //     'similarity_score': 0.936787840783524
    // },
    // {
    //     'sentence': 'Moreover, the self-petitioner has not documented his record of successes in advancing his endeavor or\ndemonstrated an urgent national interest in his own contributions.',
    //     'similarity_score': 0.648113402519375
    // },
    // {
    //     'sentence': 'In addition, the self-petitioner has\nnot shown that he offers contributions of such value that, over all, they would benefit the nation even if\nother qualified U.S. workers were available.',
    //     'similarity_score': 0.8567132776866841
    // },
    // {
    //     'sentence': 'Furthermore, an occupational shortage does not eliminate the benefits of a process designed to protect\nU.S. workers and therefore does not tilt the balancing test in the self-petitioner’s favor.',
    //     'similarity_score': 0.26234812619659176
    // },
    // {
    //     'sentence': 'The U.S.\nDepartment of Labor already addresses shortages of qualified workers through the labor certification\nprocess, and through its Schedule A regulations.',
    //     'similarity_score': 0.8256236469642797
    // },
    // {
    //     'sentence': 'In setting forth the balancing test for the discretionary\nnational interest waiver sought in this case, Dhanasar did not indicate that a shortage would affect the\nbalance, but instead focused on factors in the national interest that could outweigh “the benefits\ninherent in the labor certification process.” Id.',
    //     'similarity_score': 0.9898617692995859
    // },
    // { 'sentence': 'at 890-91.', 'similarity_score': 1.0 },
    // {
    //     'sentence': 'In sum, the petitioner has not established that, on balance, it would be beneficial to the United States to\nwaive the requirements of a job offer and thus of a labor certification.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'Please submit evidence to establish that, on balance, it would be beneficial to the United States to\nwaive the requirements of a job offer and thus of a labor certification.',
    //     'similarity_score': 0.9999999999999999
    // },
    // {
    //     'sentence': 'This balance was described in\nDhanasar as on one hand protecting the domestic labor supply through the creation of the labor\ncertification process, while on the other hand recognizing that in certain cases the benefits inherent in\nthe labor certification process can be outweighed by other factors that are also deemed to be in the\nnational interest.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'USCIS may evaluate factors including, but not limited to, the following:\nWhether, in light of the nature of the beneficiary’s qualifications or proposed endeavor, it\nwould be impractical either for the beneficiary to secure a job offer or for the petitioner to\nobtain a labor certification;\nWhether, even assuming that other qualified U.S. workers are available, the United States\nwould still benefit from the beneficiary’s contributions;\nWhether the national interest in the beneficiary’s contributions is sufficiently urgent to warrant\nforgoing the labor certification process;\nWhether the beneficiary’s endeavor may lead to potential creation of jobs; and\nWhether the beneficiary is self-employed in a manner that generally does not adversely affect\nU.S. workers.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': 'PLACE THE ATTACHED COVERSHEET AND THIS ENTIRE LETTER ON TOP OF YOUR\nRESPONSE.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'SUBMISSION OF EVIDENCE WITHOUT THE COVERSHEET AND THIS\nLETTER WILL DELAY PROCESSING OF YOUR CASE AND MAY RESULT IN A DENIAL.',
    //     'similarity_score': 0.9999999999999999
    // },
    // {
    //     'sentence': 'Sincerely,\nMary Elizabeth Brennan Seng\nDirector, Texas Service Center\nOfficer: XM1987\nTSCI140TSCI14000027750178 11 of 11 www.uscis.gov\nUSCIS encourages you to sign up for a USCIS online account.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.Officer: XM1987\nCOVERSHEET\nPLEASE RETURN THE REQUESTED INFORMATION AND\nALL SUPPORTING DOCUMENTS WITH\nTHIS PAGE ON TOP TO:\nUSCIS/TSC\nATTN: RFE/NOID/NOIR/NOIT\n6046 N BELT LINE RD STE 172\nIRVING, TX 75038-0015\nPlease check the appropriate box regarding if there is a new Form G-28, Notice of Entry of Appearance as Attorney or\nAccredited Representative, additional fees, additional forms, etc.',
    //     'similarity_score': 1.0000000000000004
    // },
    // {
    //     'sentence': 'Please place the new Form G-28, additional fees,\nadditional forms directly under this sheet.',
    //     'similarity_score': 1.0
    // },
    // {
    //     'sentence': 'Yes, there is:\nA New G-28 Additional Fees\nAdditional Forms Other:\nIf you have moved, write your current address in the blank area below.',
    //     'similarity_score': 1.0
    // },
    // { 'sentence': 'Please be sure to write clearly.', 'similarity_score': 1.0 },
    // {
    //     'sentence': '(Select appropriate check box)\nApplicant/Beneficiary Petitioner\nNew Address:\nAs required by Section § 265.1 Reporting change of address.',
    //     'similarity_score': 1.0000000000000002
    // },
    // {
    //     'sentence': '“Except for those exempted by section 263(b) of the Act, all\naliens in the United States required to register under section 262 of the Act must report each change of address and new\naddress within 10 days of such change in accordance with instructions provided by USCIS.”\nREQUEST FOR EVIDENCE\n140 Immigrant Petition for Alien Worker\nTSCI140TSCI14000027750178 TSC Routing Sheet Suite 172 1 of 1 ECHO - Enterprise Correspondence Handling Online',
    //     'similarity_score': 0.9012369124747043
    // }]

    const sentences6 = [[{
        'sentence': 'U.S. Department of Homeland Security January 10, 2024 U.S.',
        'similarity_score': 0.8501872823590797
    },
    {
        'sentence': 'Citizenship and Immigration Services Texas Service Center 6046 N Belt Line Rd STE 172 Irving, TX 75038-0015',
        'similarity_score': 1.0000000000000004
    }],
    [{
        'sentence': 'RE: I-140, Immigrant Petition for Alien Worker',
        'similarity_score': 0.8601583184596779
    }],
    [{ 'sentence': 'REQUEST FOR EVIDENCE', 'similarity_score': 1.0 }],
    [{
        'sentence': 'IMPORTANT: THIS NOTICE CONTAINS YOUR UNIQUE NUMBER.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'THE ORIGINAL NOTICE MUST BE SUBMITTED WITH THE REQUESTED EVIDENCE.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'You are receiving this notice because U.S.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Citizenship and Immigration Services (USCIS) requires additional evidence to process your form.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Please provide the evidence requested below.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Include duplicate copies if you are requesting consular notification.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Your response must be received in this office by April 8, 2024.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Please note that you have been allotted the maximum period allowed for responding to a Request for Evidence (RFE).',
        'similarity_score': 1.0
    },
    {
        'sentence': 'The time period for responding cannot be extended.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': '8 Code of Federal Regulations (8 CFR 103.2(b)(8)(iv).',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Because many immigration benefits are time sensitive, you are encouraged to respond to this request as early as possible, but no later than the deadline provided above.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'If you do not respond to this notice within the allotted time, your case may be denied.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'The regulations do not provide for an extension of time to submit the requested evidence.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'You must submit all requested evidence at the same time.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'If you submit only some of the requested evidence, USCIS will consider your response a request for a decision on the record.',
        'similarity_score': 1.0000000000000002
    },
    { 'sentence': '8 CFR 103.2(b)(11).', 'similarity_score': 1.0 }],
    [{
        'sentence': 'If you submit a document in any language other than English, the document must be accompanied by a full and complete English translation.',
        'similarity_score': 1.0000000000000004
    },
    {
        'sentence': 'The translator must certify that the translation is accurate and he or she is competent to translate from that language to English.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'If you submit a foreign language translation in response to this request for evidence, you must also include a copy of the foreign language document.',
        'similarity_score': 0.9999999999999999
    }],
    [{
        'sentence': 'Processing of your I-140 will resume upon receipt of your response.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'If you have not heard from USCIS within 60 days of responding, you may contact the USCIS Contact Center (NCSC) at 1-800-375-5283.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'If you are hearing impaired, please call the NCSC TDD at 1-800-767-1833.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'TSCI140TSCI14000027750178 1 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Reference is made to this Form I-140, Immigrant Petition for Alien Worker, seeking classification as a member of the professions holding an advanced degree and an exemption from the requirement of a job offer, and thus of a labor certification, in the national interest of the United States fo (petitioner and beneficiary), filed on his/her own behalf.',
        'similarity_score': 0.967515976327471
    },
    {
        'sentence': 'The priority date for this petition is August 25, 2023.',
        'similarity_score': 0.7380769759374367
    }],
    [{
        'sentence': 'The beneficiary intends to work as a Dentist.',
        'similarity_score': 0.4646675008374422
    }],
    [{
        'sentence': 'In order to establish eligibility, the petitioner must establish that:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The beneficiary qualifies for the requested classification; and',
        'similarity_score': 0.8855429072702536
    }],
    [{
        'sentence': 'An exemption from the requirement of a job offer, and thus of a labor certification, is in the national interest of the United States.',
        'similarity_score': 1.0
    }],
    [{ 'sentence': 'I.', 'similarity_score': 0.0 },
    {
        'sentence': 'Eligibility for the Requested Classification',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Member of the Professions Holding an Advanced Degree: Eligibility of the Beneficiary',
        'similarity_score': 0.7356727407471019
    }],
    [{
        'sentence': 'The first issue in this case is whether the beneficiary qualifies for the requested classification of a professional with an advanced degree.',
        'similarity_score': 0.6598176153906506
    }],
    [{ 'sentence': 'Advanced Degree', 'similarity_score': 1.0 }],
    [{
        'sentence': 'The regulation at 8 C.F.R.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Section 204.5(k)(2) contains the following relevant definition:',
        'similarity_score': 0.39070815991701646
    }],
    [{
        'sentence': 'Advanced degree means any United States academic or professional degree or a foreign equivalent degree above that of baccalaureate.',
        'similarity_score': 0.8561723163891397
    },
    {
        'sentence': "A United States baccalaureate degree or a foreign equivalent degree followed by at least five years of progressive experience in the specialty shall be considered the equivalent of a master's degree.",
        'similarity_score': 0.5333442072121947
    },
    {
        'sentence': 'If a doctoral degree is customarily required by the specialty, the alien must have a United States doctorate or a foreign equivalent degree (emphasis added).',
        'similarity_score': 0.3373409671102442
    }],
    [{
        'sentence': 'The petitioner must establish that he holds an advanced degree as of the priority date.',
        'similarity_score': 0.9517917206520865
    },
    {
        'sentence': 'The petitioner submitted the following evidence to establish that he meets this requirement:',
        'similarity_score': 0.7802960969469503
    }],
    [{
        'sentence': 'A transcript and diploma from Gama Filho University as a title of Dental Surgeon.',
        'similarity_score': 0.17898381802619068
    }],
    [{
        'sentence': 'The petitioner stated the beneficiary qualifies as an advanced degree holder due to his Bachelor of Dentistry at Gama Filho University in Rio de Janeiro and other certificates.',
        'similarity_score': 0.2737862834101599
    },
    {
        'sentence': 'USCIS consulted the American Association of Collegiate Registrars and Admissions Officers’ (AACRAO’s) Electronic Database for Global Education (EDGE).',
        'similarity_score': 0.6357315570851941
    }],
    [{
        'sentence': 'AACRAO describes itself as “a non-profit, voluntary, professional association of more than 11,000 higher education professionals who represent approximately 2,600 institutions in more than 40 countries.',
        'similarity_score': 0.8316678765970975
    }],
    [{
        'sentence': 'Its mission is to provide professional development, guidelines, and voluntary standards to be used by higher education officials regarding the best practices in records management, admissions, enrollment management, administrative information technology, and student services."',
        'similarity_score': 0.20169390995279396
    },
    {
        'sentence': 'See http://www4.aacrao.org/centennial/about.htm accessed on January 5, 2023.',
        'similarity_score': 0.2771328737956276
    }],
    [{
        'sentence': 'EDGE is a web-based resource for the evaluation of foreign educational credentials.',
        'similarity_score': 0.2484005822513702
    },
    {
        'sentence': 'Each EDGE educational system profile includes: an overview describing the educational history of the country; an educational ladder or ladders to reflect changes in the educational structure; grading system(s); sample credentials; placement recommendations; list of post secondary institutions; resources used to develop',
        'similarity_score': 0.1892823862905882
    }],
    [{
        'sentence': 'TSCI140TSCI14000027750178 2 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'the profile; author biography and notes; and glossary, when applicable.',
        'similarity_score': 0.17597734212759913
    },
    {
        'sentence': 'EDGE is a dynamic resource for evaluating foreign credentials.',
        'similarity_score': 0.22820075668341203
    },
    {
        'sentence': 'See http://edge.aacrao.org/info.php accessed on May 16, 2023.',
        'similarity_score': 0.2898884094323379
    }],
    [{
        'sentence': 'EDGE provides the following information for a Título de Cirurgião Dentista from Brazil:',
        'similarity_score': 0.3989045216605417
    }],
    [{
        'sentence': 'CREDENTIAL DESCRIPTION',
        'similarity_score': 0.41668019768728054
    }],
    [{
        'sentence': '[Title of Dental Surgeon].',
        'similarity_score': 0.18675196951025974
    },
    {
        'sentence': 'Awarded after 4 to 5 years of post secondary study.',
        'similarity_score': 0.3562449298723813
    }],
    [{ 'sentence': 'CREDENTIAL ADVICE', 'similarity_score': 0.35600740332579695 }],
    [{
        'sentence': 'The Título de Cirurgião Dentista represents the attainment of a level of education comparable to a first professional degree in dentistry in the United States.',
        'similarity_score': 0.49082159296746564
    }],
    [{
        'sentence': 'However, it does not appear that the Gama Filho University in Rio de Janeiro, Brazil was accredited by an accrediting agency at the time the beneficiary received the credential.',
        'similarity_score': 0.2504949688536605
    }],
    [{
        'sentence': "An academic evaluation of the beneficiary's foreign education was not submitted to indicate the beneficiary received a U.S. equivalency degree.",
        'similarity_score': 0.36847976328441845
    },
    {
        'sentence': 'USCIS consulted the World Higher Education Database www.whed.net., but the website does not list the Gama Filho University as a higher education institution in Brazil nor does it indicate that the institution was accredited by any accrediting agency.',
        'similarity_score': 0.22344066817645722
    },
    {
        'sentence': 'As such, the beneficiary has not established that he has the foreign equivalent of a degree as a medical doctor from an accredited institution of higher education in the United States.',
        'similarity_score': 0.3553636663880021
    }],
    [{
        'sentence': 'Therefore, the above- listed evidence fails to demonstrate the petitioner possesses an advanced degree.',
        'similarity_score': 0.3910123415255635
    }],
    [{
        'sentence': 'Please submit evidence to establish that the beneficiary is a professional holding an advanced degree.',
        'similarity_score': 0.8762324698421103
    },
    {
        'sentence': 'This evidence may consist of the following documentation:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'A copy of the beneficiary’s official academic record, showing that the beneficiary has a U.S. advanced degree (or a foreign equivalent degree), the dates of attendance, area of concentration of study, and the date the beneficiary received the degree.',
        'similarity_score': 0.8935818385331554
    },
    {
        'sentence': 'Please also submit a complete copy of the beneficiary’s transcripts to show the major area of study; or',
        'similarity_score': 0.5374167453414104
    }],
    [{
        'sentence': 'A copy of the beneficiary’s official academic record showing that the beneficiary has a U.S. baccalaureate degree (or a foreign equivalent degree), and evidence in the form of letters from current or former employer(s) showing that the beneficiary has at least five years of progressive post-baccalaureate experience in the specialty.',
        'similarity_score': 0.9164664945133028
    },
    {
        'sentence': 'The employer must issue the letter of experience on official letterhead and must list the employer’s name and address, the date, the signer’s name and title, and a description of the beneficiary’s experience, including dates of employment and specific duties.',
        'similarity_score': 0.9821226739436698
    }],
    [{
        'sentence': 'If the beneficiary completed his education outside the United States, in addition to the beneficiary’s official academic record, please submit a detailed advisory evaluation of the beneficiary’s credentials.',
        'similarity_score': 0.6120854309512618
    },
    {
        'sentence': 'This evaluation is necessary to determine the level and major field of the beneficiary’s education in terms of equivalent education in the United States.',
        'similarity_score': 0.9553196000041827
    },
    { 'sentence': 'An acceptable evaluation should:', 'similarity_score': 1.0 }],
    [{
        'sentence': 'Consider formal education only, and not practical training or experience;',
        'similarity_score': 0.462043143998078
    }],
    [{
        'sentence': 'State whether the beneficiary completed the United States equivalent of high school before entering college;',
        'similarity_score': 0.5612022943771697
    }],
    [{
        'sentence': 'Provide a detailed explanation of the evaluated material, rather than a simple conclusive statement; and',
        'similarity_score': 0.5512689878210871
    }],
    [{
        'sentence': 'Briefly state the evaluator’s qualifications and experience.',
        'similarity_score': 0.48123105297196334
    }],
    [{
        'sentence': 'TSCI140TSCI14000027750178 3 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'The remaining issue is whether the beneficiary established that a waiver of the job offer requirement, and a labor certification, is in the national interest.',
        'similarity_score': 0.9999999999999998
    }],
    [{ 'sentence': 'II.', 'similarity_score': 1.0 },
    {
        'sentence': 'National Interest Waiver',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'USCIS has designated Matter of Dhanasar, 26 I&N Dec. 884 (AAO 2016) (“Dhanasar”) as a precedent decision.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'That decision rescinded the earlier precedent decision, Matter of New York State Dep’t of Transp.',
        'similarity_score': 1.0000000000000004
    },
    {
        'sentence': '("NYSDOT"), 22 I&N Dec. 215 (Acting Assoc.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Comm’r 1998), regarding national interest waivers under Section 203(b)(2)(B)(i) of the Immigration and Nationality Act, and introduced a new three-prong test for determining eligibility.',
        'similarity_score': 1.0000000000000007
    },
    {
        'sentence': 'Under Dhanasar, USCIS may grant a national interest waiver as a matter of discretion if the petitioner demonstrates by a preponderance of the evidence that:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The beneficiary’s proposed endeavor has both substantial merit and national importance;',
        'similarity_score': 0.8787482571197391
    }],
    [{
        'sentence': 'The beneficiary is well positioned to advance the proposed endeavor; and',
        'similarity_score': 0.9066387291083381
    }],
    [{
        'sentence': 'On balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'If these three elements are satisfied, USCIS may approve the national interest waiver as a matter of discretion.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': '1st Prong - Substantial Merit and National Importance',
        'similarity_score': 0.702108443933854
    }],
    [{ 'sentence': 'Substantial Merit', 'similarity_score': 1.0 }],
    [{
        'sentence': 'The first prong, substantial merit and national importance, focuses on the specific endeavor that the foreign national proposes to undertake.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Matter of Dhanasar, 26 I&N Dec. 884, 889 (AAO 2016).',
        'similarity_score': 1.0
    },
    {
        'sentence': "The endeavor's merit may be demonstrated in a range of areas such as business, entrepreneurialism, science, technology, culture, health, or education.",
        'similarity_score': 1.0
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 }],
    [{
        'sentence': 'In this case, the self-petitioner submitted a statement and stated he wants to present his knowledge in areas of Dentistry, Endodontics and Implantology to the U.S. to generate value in healthcare.',
        'similarity_score': 0.23882137102798695
    }],
    [{
        'sentence': 'Continuing employment in one’s position, field, or industry is not an endeavor sufficient to evaluate under this analytical framework.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'In Dhanasar, North Carolina Agricultural and Technical State University (“North Carolina A&T”) employed the beneficiary as a Postdoctoral Research Associate and the petitioner worked in the air and space propulsion systems field.',
        'similarity_score': 0.9999999999999998
    },
    {
        'sentence': 'Matter of Dhanasar, 26 I&N Dec. 884, 891 (AAO 2016).',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'His proposed endeavor was to “continue research into the design and development of propulsion systems for potential use in military and civilian technologies such as nano-satellites, rocket-propelled ballistic missiles, and single-stage-to-orbit vehicles.” Id.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'The petitioner in Dhanasar, provided specific insight as to what he intended to do as a Postdoctoral Research Associate in the field of air and space propulsion systems.',
        'similarity_score': 1.0
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    {
        'sentence': 'Therefore, the AAO found the petitioner’s endeavor to “advance scientific knowledge and further national security interests and U.S. competitiveness in the civil space sector” as having substantial merit.',
        'similarity_score': 1.0
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    { 'sentence': 'at 892.', 'similarity_score': 1.0 }],
    [{
        'sentence': "From this limited amount of information, USCIS cannot determine the beneficiary's specific proposed endeavor.",
        'similarity_score': 0.35259767953522014
    },
    {
        'sentence': 'Importantly, the term “endeavor” is more specific than the general occupation.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'A petitioner should offer details not only as to what the occupation normally involves, but what types of work the beneficiary proposes to undertake specifically within that occupation.',
        'similarity_score': 0.9471348947731941
    },
    {
        'sentence': 'In Dhanasar, although Dr. Dhanasar was an engineer by occupation, the decision discusses his specific proposed endeavors to engage in research and development relating to air and space propulsion systems, as well as to teach aerospace engineering.',
        'similarity_score': 0.4156175657672637
    }],
    [{
        'sentence': 'Without the petitioner’s proposed endeavor, the petitioner impedes USCIS in determining that the',
        'similarity_score': 0.7227849896113948
    }],
    [{
        'sentence': 'TSCI140TSCI14000027750178 4 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Without the petitioner’s proposed endeavor, the petitioner impedes USCIS in determining that the proposed endeavor has substantial merit and is of national importance and that the petitioner is well positioned to advance his proposed endeavor.',
        'similarity_score': 0.9584741074385992
    },
    {
        'sentence': 'As a result, the petitioner also impedes USCIS in determining that, on balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Evidence to establish that the beneficiary’s proposed endeavor has substantial merit consists of, but is not limited to, the following:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'A detailed description of the proposed endeavor and why it is of substantial merit; and',
        'similarity_score': 0.8290353451273526
    }],
    [{
        'sentence': 'Documentary evidence that supports the petitioner’s statements and establishes the endeavor’s merit.',
        'similarity_score': 0.8952160761998964
    }],
    [{ 'sentence': 'National Importance', 'similarity_score': 1.0 }],
    [{
        'sentence': 'In determining national importance, the relevant question is not the importance of the field, industry, or profession in which the individual will work; instead USCIS must focus on the “the specific endeavor that the foreign national proposes to undertake.” See Dhanasar, 26 I&N Dec. at 889.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'In Dhanasar, the AAO noted that they “look for broader implications” of the proposed endeavor and that “[a]n undertaking may have national importance for example, because it has national or even global implications within a particular field.” Id.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'AAO also stated that "[a]n endeavor that has significant potential to employ U.S. workers or has other substantial positive economic effects, particularly in an economically depressed area, for instance, may well be understood to have national importance."',
        'similarity_score': 1.0
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    { 'sentence': 'at 890.', 'similarity_score': 1.0 }],
    [{
        'sentence': "To evaluate whether the petitioner's proposed endeavor satisfies the national importance requirement USCIS must look to evidence documenting the “potential prospective impact'” of his proposed endeavor work.",
        'similarity_score': 0.9813374026548415
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    { 'sentence': 'at 889.', 'similarity_score': 1.0 }],
    [{
        'sentence': 'The self-petitioned intends to benefit both health and economic impact the health and wellness segments of Americans.',
        'similarity_score': 0.2362486926017493
    },
    {
        'sentence': 'He believes implant dentistry is one of the main specialties today, has a high market value and is extremely important for the population since almost half of the population is missing at least one tooth.',
        'similarity_score': 0.15743101149650007
    },
    {
        'sentence': 'He can immediately impact the dental market in an area with annual solid market growth and community health by working on rehabilitating patients with missing teeth.',
        'similarity_score': 0.22254056004413242
    }],
    [{
        'sentence': 'USCIS can consider information about the beneficiary’s current and prospective positions to illustrate the capacity in which he intends to work in order to determine whether his proposed endeavor meets the requirements of the first prong of the Dhanasar analytical framework.',
        'similarity_score': 0.8388294568108265
    },
    {
        'sentence': 'It is not apparent how the above-listed "potential impact" has national or even global implications.',
        'similarity_score': 0.41390740736401826
    },
    {
        'sentence': 'In determining national importance, the analysis should focus on the impact of what the petitioner intends to do rather than the specific occupational classification.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'In the business plan for VL Dental Clinic was submitted, it stated, the practice will provide dental care services such as dental consultations and diagnostics services, preventive services, restorative dental services, orthodontics, surgical oral and maxillofacial services, non surgical services and more.',
        'similarity_score': 0.2462826300450019
    },
    {
        'sentence': 'To achieve this goal, the business will work on capital needs to scale and grow the brand to become the most recognized and reputable clinic in the region.',
        'similarity_score': 0.2484300655812726
    },
    {
        'sentence': 'However, the self-petitioner failed to demonstrate that his proposed endeavor has national or even global implications within a particular field or industry.',
        'similarity_score': 0.5315301212425815
    },
    {
        'sentence': 'Going on record without supporting documentary evidence is not sufficient for purposes of meeting the burden of proof in these proceedings.',
        'similarity_score': 0.8946783729263077
    }],
    [{
        'sentence': 'The business plan stated demand for industry services will come from older adults who tend to need more serious and more frequent care as they age.',
        'similarity_score': 0.17204979276402355
    },
    {
        'sentence': 'Growth in number of elderly Americans increases demand for dental implants and other cosmetic dental procedures.',
        'similarity_score': 0.11574395482997618
    },
    {
        'sentence': 'As individuals age, dental health',
        'similarity_score': 0.20941624264215916
    }],
    [{
        'sentence': 'TSCI140TSCI14000027750178 5 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'generally declines and requires more maintenance.',
        'similarity_score': 0.16358792202533542
    },
    {
        'sentence': 'USCIS can consider information about the self-petitioner’s current and prospective positions to illustrate the capacity in which he intends to work in order to determine whether his proposed endeavor meets the requirements of the first prong of the Dhanasar analytical framework.',
        'similarity_score': 0.8387468748207119
    },
    {
        'sentence': "Occupational shortages do not, by itself, establish that the petitioner's endeavor stands to have a potential prospective impact on the broader field or otherwise have implications rising to the level of national importance,",
        'similarity_score': 0.6082946566446725
    }],
    [{
        'sentence': 'Turning to national importance, we note that the relevant question is not the importance of the industry or profession in which the individual will work; instead, we focus on the "the specific endeavor that the foreign national proposes to undertake."',
        'similarity_score': 0.9116559519107891
    },
    {
        'sentence': 'See Dhanasar, 26 I&N Dec. at 889.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Here, the petitioner must demonstrate the national importance of his work in the Dental industry, rather than the national importance of the industry overall.',
        'similarity_score': 0.41123880174632865
    },
    {
        'sentence': 'In Dhanasar, we noted that "we look for broader implications" of the proposed endeavor and that "[a]n undertaking may have national importance for example, because it has national or even global implications within a particular field."',
        'similarity_score': 1.0
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    {
        'sentence': 'We also stated that "[a]n endeavor that has significant potential to employ U.S. workers or has other substantial positive economic effects, particularly in an economically depressed area, for instance, may well be understood to have national importance."',
        'similarity_score': 0.9931960001946459
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    { 'sentence': 'at 890.', 'similarity_score': 1.0 }],
    [{
        'sentence': 'However, he did not indicate how he will allocate his time and accomplish all the numerous proposed activities encompassing the specific proposed endeavor.',
        'similarity_score': 0.33416692787092417
    },
    {
        'sentence': 'The record indicated several positions that the company will would create but it does not explain how any claimed ripple effects of the specific proposed endeavor would operate on such a large scale to create jobs and revenues, increase the flow of money in the nation, and foment foreign direct investments.',
        'similarity_score': 0.23432130381844068
    },
    {
        'sentence': "It is also not apparent that any projected taxes to be paid will substantially affect either any region where he will pursue his endeavor or the United States' tax revenue, or their economies more broadly at a level commensurate with national importance.",
        'similarity_score': 0.3481101644077903
    },
    {
        'sentence': 'Apart from one letter, the petitioner did not submit any evidence of communications between the beneficiary and any potential employers or clients.',
        'similarity_score': 0.49188628703453485
    }],
    [{
        'sentence': 'The business plan indicated VL Dental Clinic will be located in Park City, Utah.',
        'similarity_score': 0.22601664654302822
    },
    {
        'sentence': 'The clinic will be a family-owned business managed by the self-petitioner.',
        'similarity_score': 0.20329885581971682
    },
    {
        'sentence': "The company's vision is to become the number one choice when it comes to dental care service delivery in the whole of Park City, Utah.",
        'similarity_score': 0.11794873967678936
    },
    {
        'sentence': 'VL Dental Clinic intends to become a first-class dental clinic that will take care of all kinds of clients that can afford their services.',
        'similarity_score': 0.1618559791577929
    },
    {
        'sentence': 'It is not apparent how the above-listed "potential impact" has national or even global implications.',
        'similarity_score': 0.4131945332632702
    },
    {
        'sentence': "Overall, the petitioner has not offered sufficient evidence to demonstrate that the petitioner's endeavor stands to impact the regional or national population at a level consistent with having national importance.",
        'similarity_score': 1.0
    },
    {
        'sentence': 'Nor has the petitioner shown that his work would have broader implications for the field.',
        'similarity_score': 0.7622949135174845
    },
    {
        'sentence': 'Accordingly, the petitioner has not demonstrated that his endeavor is of national importance and has not met the first prong of the Dhanasar framework.',
        'similarity_score': 0.9525042276684699
    }],
    [{
        'sentence': 'The petitioner indicated he wants to pursue his endeavor in Park City, Utah.',
        'similarity_score': 0.26376660904678934
    },
    { 'sentence': 'area.', 'similarity_score': 0.32565105738972194 },
    {
        'sentence': 'USCIS cannot conclude any area where he will pursue the specific proposed endeavor is economically depressed, that the specific proposed endeavor would somehow lead to the employment of a significant population of workers in the area, or that it would somehow offer the region or its population a substantial positive economic benefit through employment levels, business activity, investment, or related tax revenue as contemplated by Dhanasar.',
        'similarity_score': 0.8332725557694552
    },
    {
        'sentence': "Without sufficient information or evidence regarding any projected U.S. economic impact or job creation attributable to the beneficiary's specific proposed endeavor, the record does not reflect the benefits to the U.S. regional or national economy resulting from his work as an Entrepreneur would reach the level of substantial positive economic effects contemplated by Dhanasar.",
        'similarity_score': 0.8323836188701034
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    { 'sentence': 'at 890.', 'similarity_score': 1.0 }],
    [{
        'sentence': 'Throughout the petitioner’s proposed business plan, the business plan provided financial forecasts, financial highlights, projected profit and loss and SWOT analysis.',
        'similarity_score': 0.3596665463967793
    },
    {
        'sentence': 'The numbers that the business plan give in this example do not demonstrate that the company would be able to employ a large number of',
        'similarity_score': 0.24749503312945037
    }],
    [{
        'sentence': 'TSCI140TSCI14000027750178 6 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'employees that would make an economic difference on a national level and does not demonstrate the national importance portion.',
        'similarity_score': 0.3295425871321307
    }],
    [{
        'sentence': "Though the beneficiary's intent may be to impact the broader field, the past record, and the potential prospective impact of the proposed endeavor point to a single impact within an organization.",
        'similarity_score': 0.35204320411981904
    },
    {
        'sentence': 'Specifically, it is difficult to see how the petitioner will benefit the U.S. beyond just what they can offer to their immediate company.',
        'similarity_score': 0.2246152657208536
    }],
    [{
        'sentence': "Overall, the petitioner has not offered sufficient evidence to demonstrate that the petitioner's endeavor stands to impact the regional or national population at a level consistent with having national importance.",
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Nor has the petitioner shown that his particular work would have broader implications for the field.',
        'similarity_score': 0.8264750827160381
    },
    {
        'sentence': 'Accordingly, the petitioner has not demonstrated that his endeavor is of national importance and has not met the first prong of the Dhanasar framework.',
        'similarity_score': 0.9525450593376318
    },
    {
        'sentence': 'The following can be submitted:',
        'similarity_score': 0.5722154031045921
    }],
    [{
        'sentence': 'A detailed description of the proposed endeavor and why it is of national importance,',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Documentary evidence that supports the petitioner’s statements and establishes the endeavor’s national importance.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Such evidence must demonstrate the endeavor’s potential prospective impact, and may consist of, but is not limited to, evidence showing that the proposed endeavor: Has national or even global implications within a particular field;',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Has significant potential to employ U.S. workers or has other substantial positive economic effects, particularly in an economically depressed area;',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Will broadly enhance societal welfare or cultural or artistic enrichment; and',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Impacts a matter that a government entity has described as having national importance or is the subject of national initiatives.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': '2nd Prong - Well Positioned to Advance the Proposed Endeavor',
        'similarity_score': 0.7082554952373055
    }],
    [{
        'sentence': 'The second prong shifts focus from the proposed endeavor to the foreign national.',
        'similarity_score': 0.9957405445015978
    },
    {
        'sentence': 'See Dhanasar, 26, I&N.',
        'similarity_score': 0.7578487002984337
    },
    { 'sentence': 'Dec. at 880.', 'similarity_score': 0.7978312486716244 }],
    [{
        'sentence': "As it relates to the self-petitioner's education and years of experience and knowledge.",
        'similarity_score': 0.326074748262122
    },
    {
        'sentence': 'The petitioner has not shown that his academic accomplishments by themselves are sufficient to demonstrate that he is well positioned to advance his proposed endeavor.',
        'similarity_score': 0.44436182491656934
    },
    {
        'sentence': 'In Dhanasar, the record established that the petitioner held multiple graduate degrees including "two master of science degrees, in mechanical engineering and applied physics, as well as a Ph.D. in engineering."',
        'similarity_score': 0.9670672097074738
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    { 'sentence': 'at 891.', 'similarity_score': 1.0 },
    {
        'sentence': 'We look to a variety of factors in determining whether a petitioner is well positioned to advance his proposed endeavor and education is merely one factor among many that may contribute to such a finding.',
        'similarity_score': 0.6303039573453844
    }],
    [{
        'sentence': 'Not every individual who possesses the necessary educational and professional credentials and who worked in the field will be found to be well positioned to advance the proposed endeavor.',
        'similarity_score': 0.907405144862244
    },
    {
        'sentence': 'USCIS looks to a variety of factors in determining whether an individual is well positioned to advance the proposed endeavor.',
        'similarity_score': 0.6131955357566381
    },
    {
        'sentence': 'USCIS acknowledges the evidence submitted with respect to the claim that he is well-positioned to advance his proposed endeavor, such as, an academic evaluation, resume, expert letters, and a letter of support.',
        'similarity_score': 0.38010198086771474
    },
    {
        'sentence': 'However, the evidence submitted to establish that the petitioner’s proposed endeavor qualifies under the well-positioned prong, while relevant to factors that can be considered as supportive to the petitioner’s claims, has not established that the petitioner is well-positioned to advance his proposed endeavor, or the interests of the United States.',
        'similarity_score': 0.5303334862974488
    }],
    [{
        'sentence': "It is noted that while the petitioner's reference gives commendable statement of the work he had done, his extensive experience in the Dental area.",
        'similarity_score': 0.2260348029238075
    },
    {
        'sentence': 'However, it failed to provide any information or objective documentary evidence to show that the petitioner is well-positioned to advance the proposed endeavor',
        'similarity_score': 0.5029528663294306
    }],
    [{
        'sentence': 'TSCI140TSCI14000027750178 7 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'or Entrepreneurialism.',
        'similarity_score': 0.33204662110361755
    },
    {
        'sentence': "The self-petitioner's education from Gama Filho University as a title of Dental Surgeon is not an accredited U.S. university which makes him not well-positioned to advance his proposed endeavor.",
        'similarity_score': 0.30251682900977334
    }],
    [{
        'sentence': "While the self-petitioner's personal and professional acquaintances express their admiration and appreciation for him, the letters are insufficient to show the petitioner meets this prong.",
        'similarity_score': 0.7378233821273782
    },
    {
        'sentence': 'The letters lack specific, detailed information to show the petitioner is well-positioned to advance the proposed endeavor.',
        'similarity_score': 0.5919646223439744
    }],
    [{
        'sentence': 'The petitioner provided a letter from Roku indicated his experience in the field of endeavor.',
        'similarity_score': 0.37810929239329266
    },
    {
        'sentence': ', Specialist in Implant Dentistry stated:',
        'similarity_score': 0.45488991782806293
    }],
    [{
        'sentence': '"I followed his work in the care of patients between the years of 2014 to 2016, where he demonstrated mastery and knowledge about the most modern surgical techniques, complemented by dexterity in the prosthetic phase.',
        'similarity_score': 0.17539263771172453
    },
    {
        'sentence': 'He has also worked on making immediate temporaries and has done all kinds of impression-taking techniques.',
        'similarity_score': 0.17986181396931716
    },
    {
        'sentence': 'In other words, he has proven to be a very technical and complete professional, easy to learn and with broad theoretical knowledge."',
        'similarity_score': 0.14100751889725235
    }],
    [{
        'sentence': 'While the letters claims that the self-petitioner is an experienced professional, the writers fail to sufficiently articulate the beneficiary’s achievements and progress in his endeavor.',
        'similarity_score': 0.2800915148026544
    },
    {
        'sentence': 'The letters of recommendation, as well as the others submitted in the record, similarly make conclusory statements about the beneficiary’s achievements.',
        'similarity_score': 0.2626367784059869
    },
    {
        'sentence': "The authors claim the self-petitioner’s work has influenced the field, but they failed to provide specific examples of how the self-petitioner's work influenced the field or industry beyond his immediate organization.",
        'similarity_score': 0.581401474715503
    },
    {
        'sentence': 'In addition, the letters fail to show how his experience demonstrates he is well-positioned to advance the proposed endeavor.',
        'similarity_score': 0.5045185524276172
    },
    {
        'sentence': 'Notably, not every individual who possesses the necessary educational and professional credentials, skills, training, education, and who worked in the field will be found to be well positioned to advance the proposed endeavor.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'USCIS notes that the recommendation letters submitted are from individuals who are professionally acquainted with the beneficiary.',
        'similarity_score': 0.965356899023163
    },
    {
        'sentence': 'While it is true that the authors of the letters appear to be experts in the self-petitioner’s field, the record, at present, does not persuasively establish that the self-petitioner has had a substantial global impact in the field of endeavor.',
        'similarity_score': 0.8446130974011408
    },
    {
        'sentence': 'It would be generally expected that an alien who has had such a substantial impact on the field that the granting of a national interest waiver would be warranted, would be known outside the circle of his personal acquaintances.',
        'similarity_score': 0.25335325603398307
    },
    {
        'sentence': 'In addition, the opinions of experts in the field, while not without weight, cannot form the cornerstone of a successful claim for classification as a professional holding an advanced degree who is also requesting a national interest waiver.',
        'similarity_score': 0.49770262030488177
    },
    {
        'sentence': 'USCIS notes that letters contain general praise and speculation as to the potential applications of the alien’s work are less persuasive than letters that provide specific examples of how the alien has already influenced the field.',
        'similarity_score': 0.2856205724813925
    }],
    [{
        'sentence': 'USCIS may, in its discretion, use advisory opinion statements submitted as expert testimony.',
        'similarity_score': 0.8859953695133004
    },
    {
        'sentence': 'However, where an opinion is not in accord with other information or is in any way questionable, USCIS is not required to accept that evidence, or may give less weight to it.',
        'similarity_score': 0.7933857672048432
    },
    {
        'sentence': 'Matter of Caron International, 19 I &N Dec. 791 (Comm.',
        'similarity_score': 0.9205294476067007
    },
    {
        'sentence': '1988); Matter of Sea, Inc., 19 I&N Dec. 817 (Comm.',
        'similarity_score': 0.45446469640784015
    },
    { 'sentence': '1988).', 'similarity_score': 0.0 },
    {
        'sentence': "USCIS is ultimately responsible for making the final determination regarding an alien's eligibility for the benefit sought.",
        'similarity_score': 0.8867986307818818
    },
    {
        'sentence': 'Moreover, original contributions in the field must be demonstrated by preexisting, independent, and corroborating evidence.',
        'similarity_score': 0.30311446674627557
    },
    {
        'sentence': "The submission of letters of support from the self-petitioner's professional contacts is not presumptive evidence of eligibility.",
        'similarity_score': 0.5687245423374102
    },
    {
        'sentence': "USCIS may evaluate the content of those letters as to whether they support the alien's eligibility.",
        'similarity_score': 0.703071262224243
    },
    {
        'sentence': "Thus, the content of the writers' statements and how they became aware of the self-petitioner's reputation are important considerations.",
        'similarity_score': 0.1977452986830342
    },
    {
        'sentence': 'Even when written by independent experts, letters solicited by an alien in support of an immigration petition are of less weight than pre-existing, independent evidence of innovative contributions of major importance.',
        'similarity_score': 0.23807441222087533
    }],
    [{
        'sentence': 'TSCI140TSCI14000027750178 8 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'As such, the petitioner has failed to establish he is well positioned to advance the proposed endeavor.',
        'similarity_score': 0.6742897733017592
    },
    {
        'sentence': 'Evidence which best establishes that the petitioner is well positioned to advance the proposed endeavor will document the petitioner’s qualifications (skills, experience and track record), support (financial and otherwise) and commitment (plans and progress) to drive the endeavor forward, and will support projections of future work in the proposed endeavor.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'USCIS may consider factors including, but not limited to, the following:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'The petitioner’s education, skills, knowledge, and record of success in related or similar efforts:',
        'similarity_score': 0.9563702296669415
    }],
    [{
        'sentence': 'To show a beneficiary’s education, skills, knowledge, and record of success in related or similar efforts, the petitioner may submit one or more pieces of evidence from the following non-exhaustive list:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Licenses in the field.',
        'similarity_score': 0.34290417001467544
    }],
    [{
        'sentence': 'Patents, trademarks, or copyrights owned by the beneficiary.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Published articles and/or media reports about the beneficiary’s achievements or current work.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Documentation demonstrating a strong citation history.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Evidence that the beneficiary’s work has influenced his or her field of endeavor;',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Evidence demonstrating the beneficiary has a leading, critical or indispensable role in the endeavor or similar endeavors; and',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Evidence showing that the beneficiary’s past inventions or innovations have been used or licensed by others in the field.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'A model or plan for future activities:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'To show a model or plan for future activities, the petitioner may submit one or more pieces of evidence from the following non-exhaustive list:',
        'similarity_score': 0.947423162440685
    }],
    [{
        'sentence': 'A plan describing how the beneficiary intends to continue his or her work in the United States.',
        'similarity_score': 0.6076019977316975
    }],
    [{
        'sentence': 'A detailed business model, when appropriate.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Correspondence from prospective/potential employers, clients or customers; and',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Documentation reflecting feasible plans for financial support.',
        'similarity_score': 1.0000000000000002
    },
    { 'sentence': ';', 'similarity_score': 0.0 }],
    [{
        'sentence': 'Any progress towards achieving the proposed endeavor:',
        'similarity_score': 0.7987793724985479
    }],
    [{
        'sentence': 'To show progress towards achieving the proposed endeavor, a petitioner may submit one or more pieces of evidence from the following non-exhaustive list:',
        'similarity_score': 0.9304834750055735
    }],
    [{
        'sentence': 'Evidence of grants the beneficiary has received listing the amount and terms of the grants, as well as the grantees.',
        'similarity_score': 0.6547483262368947
    }],
    [{
        'sentence': 'Copies of contracts, agreements, or licenses resulting from the proposed endeavor or otherwise demonstrating the beneficiary is well positioned to advance the proposed endeavor.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Evidence of achievements that the beneficiary intends to build upon or further develop (including the types of documentation listed under “beneficiary’s education, skills, knowledge, and record of success in related or similar efforts”); and',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Evidence demonstrating the beneficiary has a leading, critical or indispensable role in the endeavor.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'TSCI140TSCI14000027750178 9 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{ 'sentence': 'endeavor.', 'similarity_score': 1.0 }],
    [{
        'sentence': 'The interest of potential customers, users, investors, or other relevant entities or individuals:',
        'similarity_score': 0.8595180049661159
    }],
    [{
        'sentence': 'To show interest of potential customers, investors, or other relevant individuals, a petitioner may submit one or more pieces of evidence from the following non-exhaustive list:',
        'similarity_score': 0.9202445061610574
    }],
    [{
        'sentence': 'Letters from a government entity demonstrating its interest in the proposed endeavor.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Evidence that the beneficiary has received investment from U.S. investors, such as venture capital firms, angel investors, or start-up accelerators, in amounts that are appropriate to the relevant endeavor.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Evidence that the beneficiary has received awards, grants, or other indications of relevant non-monetary support (for e.g., using facilities free of charge, etc.)',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'from Federal, State, or local government entities with authority over the field of endeavor.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Evidence demonstrating how the beneficiary’s work is being used by others, such as: Contracts with companies using products, projects, or services that the beneficiary developed or assisted in developing.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Documents showing licensed technology, or other procedural or technological advancements developed in whole or in part by the beneficiary and relied upon by others; and',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Patents or licenses awarded to the beneficiary with documentation showing why the patent or license is significant to the field.',
        'similarity_score': 0.9638673587896976
    }],
    [{
        'sentence': 'Note: The beneficiary may be well positioned to advance the endeavor even if there is no certainty that the proposed endeavor will be a success.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'However, unsubstantiated claims are insufficient and would not meet the petitioner’s burden of proof.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': '3rd Prong – Beneficial to the United States to waive the job offer and labor certification requirements',
        'similarity_score': 0.7960511428282202
    }],
    [{
        'sentence': 'The third prong requires the petitioner to demonstrate that, on balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'In performing this analysis, USCIS may evaluate factors such as: whether, in light of the nature of the foreign national’s qualifications or the proposed endeavor, it would be impractical either for the foreign national to secure a job offer or for the petitioner to obtain a labor certification; whether, even assuming that other qualified U.S. workers are available, the United States would still benefit from the foreign national’s contributions; and whether the national interest in the foreign national’s contributions is sufficiently urgent to warrant forgoing the labor certification process.',
        'similarity_score': 0.931319569650185
    }],
    [{
        'sentence': 'The self-petitioner claims his academic and professional background makes him different from those in the dental field.',
        'similarity_score': 0.21569676436064994
    },
    {
        'sentence': 'He stated he is valuable to the dental industry from his 14 years of experience in the industry and solid academic background.',
        'similarity_score': 0.27079872345854306
    }],
    [{
        'sentence': 'The self-petitioner has not established that it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 0.9192716982838642
    },
    {
        'sentence': 'The self-petitioner did not establish that his specific proposed endeavor has substantial merit, national importance as required by the first prong of the Dhanasar framework.',
        'similarity_score': 0.8231320464757553
    },
    {
        'sentence': 'Further, the self-petitioner did not establish that he is well positioned to advance the specific proposed endeavor, as required by the second prong of the Dhanasar framework.',
        'similarity_score': 0.9238750079307351
    }],
    [{
        'sentence': 'As discussed above, the evidence does not establish that the self-petitioner’s specific proposed endeavor has national importance as required by the first prong of the Dhanasar framework.',
        'similarity_score': 0.791121556017546
    }],
    [{
        'sentence': 'TSCI140TSCI14000027750178 10 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'endeavor has national importance as required by the first prong of the Dhanasar framework.',
        'similarity_score': 0.8607426010477335
    },
    {
        'sentence': 'Moreover, the self-petitioner has not documented his record of successes in advancing his endeavor or demonstrated an urgent national interest in his own contributions.',
        'similarity_score': 0.6503468776170548
    },
    {
        'sentence': 'In addition, the self-petitioner has not shown that he offers contributions of such value that, over all, they would benefit the nation even if other qualified U.S. workers were available.',
        'similarity_score': 0.8551826244553893
    }],
    [{
        'sentence': 'Furthermore, an occupational shortage does not eliminate the benefits of a process designed to protect U.S. workers and therefore does not tilt the balancing test in the self-petitioner’s favor.',
        'similarity_score': 0.2831515993441026
    },
    {
        'sentence': 'The U.S. Department of Labor already addresses shortages of qualified workers through the labor certification process, and through its Schedule A regulations.',
        'similarity_score': 0.8253865993515425
    },
    {
        'sentence': 'In setting forth the balancing test for the discretionary national interest waiver sought in this case, Dhanasar did not indicate that a shortage would affect the balance, but instead focused on factors in the national interest that could outweigh “the benefits inherent in the labor certification process.” Id.',
        'similarity_score': 0.9889587447920378
    },
    { 'sentence': 'at 890-91.', 'similarity_score': 1.0 }],
    [{
        'sentence': 'In sum, the petitioner has not established that, on balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Please submit evidence to establish that, on balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'This balance was described in Dhanasar as on one hand protecting the domestic labor supply through the creation of the labor certification process, while on the other hand recognizing that in certain cases the benefits inherent in the labor certification process can be outweighed by other factors that are also deemed to be in the national interest.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'USCIS may evaluate factors including, but not limited to, the following:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Whether, in light of the nature of the beneficiary’s qualifications or proposed endeavor, it would be impractical either for the beneficiary to secure a job offer or for the petitioner to obtain a labor certification;',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Whether, even assuming that other qualified U.S. workers are available, the United States would still benefit from the beneficiary’s contributions;',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Whether the national interest in the beneficiary’s contributions is sufficiently urgent to warrant forgoing the labor certification process;',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Whether the beneficiary’s endeavor may lead to potential creation of jobs; and',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Whether the beneficiary is self-employed in a manner that generally does not adversely affect U.S. workers.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'PLACE THE ATTACHED COVERSHEET AND THIS ENTIRE LETTER ON TOP OF YOUR RESPONSE.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'SUBMISSION OF EVIDENCE WITHOUT THE COVERSHEET AND THIS LETTER WILL DELAY PROCESSING OF YOUR CASE AND MAY RESULT IN A DENIAL.',
        'similarity_score': 1.0000000000000002
    }],
    [{ 'sentence': 'Sincerely,', 'similarity_score': 1.0 }],
    [{
        'sentence': 'Mary Elizabeth Brennan Seng Director, Texas Service Center Officer: XM1987',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'TSCI140TSCI14000027750178 11 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{ 'sentence': 'Officer: XM1987', 'similarity_score': 1.0000000000000002 }],
    [{
        'sentence': 'COVERSHEET PLEASE RETURN THE REQUESTED INFORMATION AND ALL SUPPORTING DOCUMENTS WITH THIS PAGE ON TOP TO: USCIS/TSC ATTN: RFE/NOID/NOIR/NOIT 6046 N BELT LINE RD STE 172 IRVING, TX 75038-0015',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Please check the appropriate box regarding if there is a new Form G-28, Notice of Entry of Appearance as Attorney or Accredited Representative, additional fees, additional forms, etc.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Please place the new Form G-28, additional fees, additional forms directly under this sheet.',
        'similarity_score': 0.9999999999999999
    }],
    [{
        'sentence': 'Yes, there is: A New G-28 Additional Fees',
        'similarity_score': 1.0
    }],
    [{ 'sentence': 'Additional Forms Other:', 'similarity_score': 1.0 }],
    [{
        'sentence': 'If you have moved, write your current address in the blank area below.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Please be sure to write clearly.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': '(Select appropriate check box) Applicant/Beneficiary Petitioner',
        'similarity_score': 1.0
    }],
    [{ 'sentence': 'New Address:', 'similarity_score': 1.0 }],
    [{
        'sentence': 'As required by Section § 265.1 Reporting change of address.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': '“Except for those exempted by section 263(b) of the Act, all aliens in the United States required to register under section 262 of the Act must report each change of address and new address within 10 days of such change in accordance with instructions provided by USCIS.”',
        'similarity_score': 1.0
    }],
    [{ 'sentence': 'REQUEST FOR EVIDENCE', 'similarity_score': 1.0 }],
    [{
        'sentence': '140 Immigrant Petition for Alien Worker',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'TSCI140TSCI14000027750178 TSC Routing Sheet Suite 172 1 of 1 ECHO - Enterprise Correspondence Handling Online',
        'similarity_score': 1.0000000000000002
    }]];

    const sentences3 = [[{ 'sentence': 'May 6,2024', 'similarity_score': 0.3112893286547642 }],
    [{
        'sentence': 'REQUEST FOR EVIDENCE',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'IMPORTANT: THIS NOTICE CONTAINS YOUR UNIQUE NUMBER.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'THE ORIGINAL NOTICE MUST BE SUBMITTED WITH THE REQUESTED EVIDENCE.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'You are receiving this notice because U.S.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Citizenship and Immigration Services (USCIS) requires additional evidence to process your form.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Please provide the evidence requested below.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Include duplicate copies if you are requesting consular notification.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Your response must be received in this office by August 1, 2024.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Please note that you have been allotted the maximum period allowed for responding to a Request for Evidence (RFE).',
        'similarity_score': 1.0
    },
    {
        'sentence': 'The time period for responding cannot be extended.',
        'similarity_score': 1.0
    },
    {
        'sentence': '8 Code of Federal Regulations (8 CFR 103.2(b)(8)(iv).',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Because many immigration benefits are time sensitive, you are encouraged to respond to this request as early as possible, but no later than the deadline provided above.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'If you do not respond to this notice within the allotted time, your case may be denied.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'The regulations do not provide for an extension of time to submit the requested evidence.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'You must submit all requested evidence at the same time.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'If you submit only some of the requested evidence, USCIS will consider your response a request for a decision on the record.',
        'similarity_score': 1.0
    },
    { 'sentence': '8 CFR 103.2(b)(11).', 'similarity_score': 1.0 }],
    [{
        'sentence': 'If you submit a document in any language other than English, the document must be accompanied by a full and complete English translation.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'The translator must certify that the translation is accurate and he or she is competent to translate from that language to English.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'If you submit a foreign language translation in response to this request for evidence, you must also include a copy of the foreign language document.',
        'similarity_score': 0.9999999999999999
    }],
    [{
        'sentence': 'Processing of your I-140 will resume upon receipt of your response.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'If you have not heard from USCIS within 60 days of responding, you may contact the USCIS Contact Center (NCSC) at 1-800-375-5283.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'If you are hearing impaired, please call the NCSC TDD at 1-800-767-1833.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Reference is made to this Form I-140, Immigrant Petition for Alien Worker, seeking classification as a member of the professions holding an advanced degree and an exemption from the requirement of a job offer, and thus of a labor certification, in the national interest of the United States for CAROLINA *** (petitioner), filed on her own behalf.',
        'similarity_score': 0.9321976895751098
    },
    {
        'sentence': 'The priority date for this petition is January 31, 2023.',
        'similarity_score': 0.7400037799548627
    }],
    [{
        'sentence': 'The petitioner intends to work as an Information Security Analyst.',
        'similarity_score': 0.666968208931926
    }],
    [{
        'sentence': 'In order to establish eligibility, the petitioner must establish that:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': '• The petitioner qualifies for the requested classification; and • An exemption from the requirement of a job offer, and thus of a labor certification, is in the national interest of the United States.',
        'similarity_score': 0.9720382349234805
    }],
    [{
        'sentence': 'USCIS has designated Matter of Dhanasar, 26 I&N Dec. 884 (AAO 2016) ("Dhanasar") as a precedent decision.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': "That decision rescinded the earlier precedent decision, Matter of New York State Dep't of Transp.",
        'similarity_score': 1.0
    }],
    [{
        'sentence': '("NY SDOT"), 22 I&N Dec. 215 (Acting Assoc.',
        'similarity_score': 0.5752845458132767
    },
    {
        'sentence': "Comm'r 1998), regarding national interest waivers under Section 203(b)(2)(B)(i) of the Immigration and Nationality Act, and introduced a new three-prong test for determining eligibility.",
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Under Dhanasar, USCIS may grant a national interest waiver as a matter of discretion if the petitioner demonstrates by a preponderance of the evidence that:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': "•The petitioner's proposed endeavor has both substantial merit and national importance; • The petitioner is well positioned to advance the proposed endeavor; and • On balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.",
        'similarity_score': 0.9550846213465088
    }],
    [{
        'sentence': 'If these three elements are satisfied, USCIS may approve the national interest waiver as a matter of discretion.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The evidence does not establish that all the above listed eligibility elements have been met.',
        'similarity_score': 0.46639056328980133
    },
    {
        'sentence': 'Therefore, USCIS requests additional evidence.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Eligibility for the Requested Classification',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The first issue in this case is whether the petitioner established eligibility for the requested classification.',
        'similarity_score': 0.6684906665072037
    },
    {
        'sentence': 'The petitioner must qualify under an advanced degree or exceptional ability criteria in order to be eligible to waive the job offer requirements for a national interest waiver.',
        'similarity_score': 0.3401987501584018
    }],
    [{ 'sentence': 'Advanced Degree', 'similarity_score': 1.0 }],
    [{
        'sentence': 'The petitioner must establish that she holds an advanced degree as of the priority date to qualify as a professional with an advanced degree.',
        'similarity_score': 0.8086673313063906
    },
    {
        'sentence': 'The regulations at 8 CFR § 204.5(k)(2) indicate an advanced degree means a United States academic or professional degree of a foreign equivalent degree above that of baccalaureate.',
        'similarity_score': 0.8541509507215771
    },
    {
        'sentence': "The petitioner can also qualify by having a four-year bachelor's degree plus five years of full- time, progressive, post-baccalaureate in the specialty.",
        'similarity_score': 0.42457554859471186
    }],
    [{
        'sentence': 'The petitioner submitted: Diploma and Academic Transcripts from Faculdade de Technologia IBTA for "Lato Sensu" Postgraduate Specialization Degree in Database Administration -Oracle;',
        'similarity_score': 0.23343503272646796
    }],
    [{
        'sentence': 'Diploma and Academic Transcripts from Faculdade Focus for "Lato Sensu" Postgraduate Specialization Degree in Financial Education;',
        'similarity_score': 0.22614401786795527
    }],
    [{
        'sentence': '• Credential Evaluation report by GEO Credential Services indicating U.S. equivalent of Master of Science in Information Technology.',
        'similarity_score': 0.4036345514530927
    }],
    [{
        'sentence': 'The petitioner submitted evidence and qualifies as a member of the professions holding an advanced degree.',
        'similarity_score': 0.7706198430323065
    },
    {
        'sentence': 'The petitioner meets eligibility for the requested classification.',
        'similarity_score': 0.8058651609682046
    }],
    [{
        'sentence': 'Therefore, at this time, USCIS does not need to evaluate whether the petitioner also qualifies as an alien of exceptional ability.',
        'similarity_score': 0.9675860770187977
    }],
    [{
        'sentence': 'National Importance:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'The next issue in this case is whether the petitioner warrants an exemption from the requirement of a job offer, and thus of a labor certification, in the national interest of the United States.',
        'similarity_score': 0.7611099196140031
    }],
    [{ 'sentence': 'Substantial Merit:', 'similarity_score': 1.0 }],
    [{
        'sentence': 'The first prong, substantial merit and national importance, focuses on the specific endeavor that the foreign national proposes to undertake.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Matter of Dhanasar, 26 I&N Dec. 884, 889 (AAO 2016).',
        'similarity_score': 1.0
    },
    {
        'sentence': "The endeavor's merit may be demonstrated in a range of areas such as business, entrepreneurialism, science, technology, culture, health, or education.",
        'similarity_score': 1.0000000000000002
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 }],
    [{
        'sentence': 'The petitioner provided:',
        'similarity_score': 0.8805312357245125
    }],
    [{
        'sentence': '• Self-composed letter; • Expert opinion letter; .',
        'similarity_score': 0.7569520051894659
    },
    {
        'sentence': 'Curriculum vitae; • Expert opinion letter; • Business plan; • Employment and recommendation letters; and • Information about her background, the field, and occupations.',
        'similarity_score': 0.41033243518535284
    }],
    [{
        'sentence': 'The petitioner states on the Form I-140 that the proposed endeavor is to work as an Information Security Analyst.',
        'similarity_score': 0.4625467917453873
    }],
    [{
        'sentence': 'However, the petitioner\'s proposed endeavor "Through a lot of dedication and skilled work, I will implement my refined set of skills in the most modem strategies and techniques as an Information Security Analyst in order to stimulate exponential growth to organizations located in the United States.',
        'similarity_score': 0.2509754025652071
    },
    {
        'sentence': 'As an Information Security Analyst, I will install software and hardware to protect information and especially sensitive, personal data, ensuring information availability, data integrity and confidentiality, using protective measures based on ISO/IEC 27001" is unclear.',
        'similarity_score': 0.22807811149440993
    },
    {
        'sentence': "Since the petitioner's proposed endeavor is unclear, it cannot be determined it has substantial merit.",
        'similarity_score': 0.4038978070084095
    }],
    [{
        'sentence': 'The petitioner in Dhanasar, provided specific insight as to what she intended to do as a Postdoctoral Research Associate in the field of air and space propulsion systems.',
        'similarity_score': 0.9631351092583578
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    {
        'sentence': 'Therefore, the AAO found the',
        'similarity_score': 0.4183832699822012
    }],
    [{
        'sentence': 'petitioner\'s endeavor to "advance scientific knowledge and further national security interests and U.S. competitiveness in the civil space sector" as having substantial merit.',
        'similarity_score': 0.911216004887487
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    { 'sentence': 'at 892.', 'similarity_score': 1.0 }],
    [{
        'sentence': "Please submit evidence to establish that the petitioner's proposed endeavor has substantial merit.",
        'similarity_score': 0.8863290843871313
    }],
    [{
        'sentence': "Evidence to establish that the petitioner's proposed endeavor has substantial merit consists of, but is not limited to, the following:",
        'similarity_score': 0.9339363823292606
    }],
    [{
        'sentence': "• A detailed description of the proposed endeavor and why it is of substantial merit; and • Documentary evidence that supports the petitioner's statements and establishes the endeavor's merit.",
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'National Importance:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'To evaluate whether the petitioner\'s proposed endeavor satisfies the national importance requirement USCIS must look to evidence documenting the "potential prospective impact" of the proposed endeavor work.',
        'similarity_score': 0.9840109730053301
    },
    {
        'sentence': 'The petitioner intends to work as an Information Security Analyst.',
        'similarity_score': 0.6665403403387995
    },
    {
        'sentence': 'In support of this requirement the petitioner provided a personal statement, letters of support, as well as industry information.',
        'similarity_score': 0.47256662284196316
    }],
    [{
        'sentence': "National Importance evidence must demonstrate the endeavor's potential prospective impact.",
        'similarity_score': 0.8871624289129553
    },
    {
        'sentence': 'The relevant question is not the importance of the industry or profession in which the individual will work; instead we focus on the "the specific endeavor that the foreign national proposes to undertake."',
        'similarity_score': 0.9413350583170452
    },
    {
        'sentence': 'See Dhanasar, 26 I&N Dec. at 889.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'In determining national importance, the relevant question is not the importance of the field, industry, or profession in which the individual will work; instead USCIS must focus on the "the specific endeavor that the foreign national proposes to undertake."',
        'similarity_score': 0.9473867808127505
    },
    {
        'sentence': 'See Dhanasar, 26 I&N Dec. at 889.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'In Dhanasar, the AAO noted that they "look for broader implications" of the proposed endeavor and that "[a]n undertaking may have national importance for example, because it has national or even global implications within a particular field."',
        'similarity_score': 0.9835831702595759
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    {
        'sentence': 'AAO also stated that "[a]n endeavor that has significant potential to employ U.S. workers or has other substantial positive economic effects, particularly in an economically depressed area, for instance, may well be understood to have national importance."',
        'similarity_score': 1.0
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    { 'sentence': 'at 890.', 'similarity_score': 1.0 }],
    [{
        'sentence': 'An endeavor that has significant potential to employ U.S. workers, or has other substantial positive economic effects (particularly in an economically depressed area) may well be understood to have national importance.',
        'similarity_score': 0.9024340059768795
    }],
    [{
        'sentence': "Although the record contains documents about the petitioner's background and training, this prong focuses on the specific endeavor that the foreign national proposes to undertake (as opposed to the second prong, which shifts the focus from the proposed endeavor to the foreign national).",
        'similarity_score': 0.672935259392946
    },
    {
        'sentence': "The issue here is whether the specific endeavor that the petitioner proposes to undertake has national importance under Dhanasar's first prong.",
        'similarity_score': 0.9533770779742113
    },
    {
        'sentence': 'USCIS focuses on what the petitioner will be specifically doing, rather than the broad, general occupational classification.',
        'similarity_score': 0.42457608328441354
    }],
    [{
        'sentence': 'The petitioner is applying for a waiver of the job offer requirement.',
        'similarity_score': 1.0
    },
    {
        'sentence': "While it is not necessary for the petitioner to have a job offer from a specific employer, USCIS can consider information about the petitioner's current and prospective positions to illustrate the capacity in which the petitioner intends to",
        'similarity_score': 0.809866741591484
    }],
    [{
        'sentence': "work in order to determine whether the petitioner's proposed endeavor meets the requirements of the first prong of the Dhanasar analytical framework.",
        'similarity_score': 0.6757618143947421
    }],
    [{
        'sentence': 'Relevant evidence may include detailed information about the capacity in which the individual intends to work, or documentation of formal or informal communications from potential U.S. employers to illustrate the type of position the individual seeks.',
        'similarity_score': 0.3400330741402245
    }],
    [{
        'sentence': "The record does not show the prospective impact of the petitioner's specific proposed endeavor has implications beyond her own self, clients, students, or employer(s) for which she would provide her services, rising to the level of national importance.",
        'similarity_score': 0.43490514226377913
    }],
    [{
        'sentence': 'In Dhanasar, we noted that "we look for broader implications" of the proposed endeavor and that "[a]n',
        'similarity_score': 0.7236297544282483
    }],
    [{
        'sentence': 'undertaking may have national importance for example, because it has national or even global implications within a particular field."',
        'similarity_score': 0.761689060368782
    }],
    [{
        'sentence': 'USCIS cannot conclude that the specific proposed endeavor stands to sufficiently affect or advance the field or industry more broadly (or has wider implications in the field) at a level commensurate with national importance; that the specific proposed endeavor has significant potential to employ U.S. workers or otherwise offers substantial positive economic effects for the nation as contemplated by Dhanasar, and any area where she will pursue her specific proposed endeavor is economically depressed, that it would somehow lead to the employment of a significant population of workers in the area, or that it would somehow offer the region or its population a substantial economic benefit through employment levels, business activity, trade, or related tax revenue.',
        'similarity_score': 0.8320071421219879
    }],
    [{
        'sentence': "While the petitioner's colleagues and expert opinion letters express their support for her, the letters are insufficient to show she meets this prong.",
        'similarity_score': 0.5286622760059204
    },
    {
        'sentence': 'USCIS may, in its discretion, use advisory opinions statements submitted as expert testimony.',
        'similarity_score': 0.987706175718919
    },
    {
        'sentence': "However, USCIS is ultimately responsible for making the final determination regarding an individual's eligibility for the benefit sought.",
        'similarity_score': 1.0000000000000004
    },
    {
        'sentence': "The submission of letters from experts supporting Form I-140 is not presumptive evidence of eligibility; USCIS may evaluate the content of those letters as to whether they support the individual's eligibility.",
        'similarity_score': 1.0
    },
    {
        'sentence': 'USCIS may even give less weight to an opinion that is not corroborated, in accord with other information or is in any way questionable.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Although the petitioner also highlights the challenges that the field faces and its importance, it does not, by itself, establish that the specific proposed endeavor stands to impact the broader field or otherwise has implications rising to the level of national importance.',
        'similarity_score': 0.633768734301724
    },
    {
        'sentence': 'Broad assertions are insufficient to show she meets this prong.',
        'similarity_score': 0.3499935661108122
    }],
    [{
        'sentence': "The record does contain the petitioner's curriculum vitae, but USCIS cannot consider the petitioner's own statements.",
        'similarity_score': 0.35452749221315605
    },
    {
        'sentence': 'Going on record without supporting evidence is insufficient for purposes of meeting the burden of proof in these proceedings.',
        'similarity_score': 0.8950292654252113
    }],
    [{
        'sentence': "Overall, the petitioner has not offered sufficient evidence to demonstrate that the petitioner's endeavor stands to impact the regional or national population at a level consistent with having national importance.",
        'similarity_score': 1.0
    },
    {
        'sentence': 'The petitioner has not shown that her particular work would have broader implications for the field.',
        'similarity_score': 0.8275148012066085
    },
    {
        'sentence': 'Accordingly, the petitioner has not demonstrated that her endeavor is of national importance and has not met the first prong of the Dhanasar framework.',
        'similarity_score': 0.9484090496485544
    }],
    [{
        'sentence': "Please submit evidence to establish that the petitioner's proposed endeavor has national importance.",
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': "This evidence must demonstrate the endeavor's potential prospective impact.",
        'similarity_score': 1.0
    }],
    [{
        'sentence': "Evidence to establish that the petitioner's proposed endeavor has national importance consists of, but is not limited to, the following:",
        'similarity_score': 1.0
    }],
    [{
        'sentence': "• A detailed description of the proposed endeavor and why it is of national importance, • Documentary evidence that supports the petitioner's statements and establishes the endeavor's national importance.",
        'similarity_score': 1.0
    },
    {
        'sentence': "Such evidence must demonstrate the endeavor's potential prospective impact, and may consist of, but is not limited to, evidence showing that the proposed endeavor: • Has national or even global implications within a particular field; • Has significant potential to employ U.S. workers or has other substantial positive economic effects, particularly in an economically depressed area; • Will broadly enhance societal welfare or cultural or artistic enrichment, and",
        'similarity_score': 0.902859639780444
    }],
    [{
        'sentence': '● Impacts a matter that a government entity has described as having national importance or is the subject of national initiatives.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Well-Positioned to Advance the Proposed Endeavor',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'To evaluate whether the petitioner is well positioned to advance the proposed endeavor USCIS will evaluate submitted evidence.',
        'similarity_score': 0.5954383453604888
    },
    {
        'sentence': 'In support of this requirement the petitioner provided evidence of experience, training, certifications, and letters from colleagues and employers.',
        'similarity_score': 0.3761179647235831
    }],
    [{
        'sentence': 'The second prong shifts the focus from the proposed endeavor to the foreign national.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'See Dhanasar, 26 I&N Dec. at 880.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'In general, research must add information to the pool of knowledge in some way in order to be accepted for publication, presentation, funding, or academic credit; not every individual who has performed original research will be found to be well positioned to advance her proposed research.',
        'similarity_score': 0.9929337463721504
    },
    {
        'sentence': "Rather, USCIS must examine the factors set forth in Dhanasar to determine whether, for instance, the individual's progress towards achieving the goals of the proposed research, record of success in similar efforts, or generation of interest among relevant parties supports such a finding.",
        'similarity_score': 1.0
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    { 'sentence': 'at 890.', 'similarity_score': 1.0 }],
    [{
        'sentence': 'The Dhanasar decision spelled out several factors which can be considered in determining whether a petitioner is well-positioned to advance their proposed endeavor, including several which are pertinent to entrepreneurial endeavors.',
        'similarity_score': 0.3120050094117891
    },
    {
        'sentence': 'These include a record of success in similar efforts, any progress towards achieving the proposed endeavor, and the interest of potential customers, users, investors, or other relevant entities or individuals.',
        'similarity_score': 0.7725668209227654
    }],
    [{
        'sentence': 'The petitioner focuses on her years of experience and job-related training.',
        'similarity_score': 0.2744042646099323
    },
    {
        'sentence': 'The petitioner received certificates for completing multiple courses.',
        'similarity_score': 0.26379913681713607
    },
    {
        'sentence': 'The petitioner submitted letters from her colleagues discussing her attributes and contributions to her employers.',
        'similarity_score': 0.36089627908564187
    }],
    [{
        'sentence': "The petitioner's professional accomplishments, training, and certifications are insufficient to demonstrate that she is well positioned to advance the specific proposed endeavor.",
        'similarity_score': 0.531683007352254
    },
    {
        'sentence': "USCIS looks to a variety of factors in determining whether an individual is well positioned to advance the proposed endeavor, such as the individual's progress towards achieving the goals of the proposed endeavor, record of success in similar efforts, or generation of interest among relevant parties supports such a finding.",
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': "The record does not indicate that the petitioner's work has served as an impetus for progress in the field or industry, that it has affected the industry or the occupation in some way, or that it has generated positive discourse in the community.",
        'similarity_score': 0.7597860508357315
    },
    {
        'sentence': 'The record does not demonstrate that her work constitutes a track record of success or progress, reflects a level of interest in her work from relevant parties sufficient to meet this prong, or is otherwise an indication that she is well positioned to advance the endeavor.',
        'similarity_score': 0.7384696777454168
    }],
    [{
        'sentence': 'Notably, not every individual who possesses the necessary educational and professional credentials, skills, training, education, and who worked in the field will be found to be well positioned to advance the proposed endeavor.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': "While the petitioner's colleagues express their admiration and appreciation for her, the letters are insufficient to show the petitioner meets this prong.",
        'similarity_score': 0.731036160227761
    },
    {
        'sentence': 'The letters lack specific, detailed information to show the petitioner is well positioned outside their own interests.',
        'similarity_score': 0.6001536922191862
    }],
    [{
        'sentence': 'Although the expert opinion letter expresses support for her, the letter is insufficient to show she meets this prong.',
        'similarity_score': 0.5167722413225347
    },
    {
        'sentence': 'USCIS may, in its discretion, use advisory opinions statements submitted as expert testimony.',
        'similarity_score': 0.9876878056431614
    }],
    [{
        'sentence': "However, USCIS is ultimately responsible for making the final determination regarding an individual's eligibility for the benefit sought.",
        'similarity_score': 1.0
    },
    {
        'sentence': "The submission of letters from experts supporting Form I-140 is not presumptive evidence of eligibility; USCIS may evaluate the content of those letters as to whether they support the individual's eligibility.",
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'USCIS may even give less weight to an opinion that is not corroborated, in accord with other information or is in any way questionable.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Although the petitioner also highlights the challenges that the field faces and its importance, it does not, by itself, establish that she is well positioned to advance the specific proposed endeavor.',
        'similarity_score': 0.4549394828557775
    },
    {
        'sentence': 'Broad assertions are insufficient to show she meets this prong.',
        'similarity_score': 0.3503103712151568
    }],
    [{
        'sentence': "The record does contain the petitioner's curriculum vitae, but USCIS cannot consider the petitioner's own statements.",
        'similarity_score': 0.35452749221315605
    },
    {
        'sentence': 'Going on record without supporting evidence is insufficient for purposes of meeting the burden of proof in these proceedings.',
        'similarity_score': 0.8950292654252113
    }],
    [{
        'sentence': 'USCIS cannot conclude that the petitioner is well positioned to advance the specific proposed endeavor.',
        'similarity_score': 0.7771049666798193
    },
    {
        'sentence': 'As noted above, the evidence did establish that the proposed endeavor has substantial merit.',
        'similarity_score': 0.5958773247808498
    },
    {
        'sentence': "The petitioner did not establish that the petitioner's work has national importance as required by the first prong of the Dhanasar framework.",
        'similarity_score': 0.7939446711314422
    }],
    [{
        'sentence': 'Please submit evidence to establish that the petitioner is well positioned to advance the proposed endeavor.',
        'similarity_score': 0.9820228052938094
    }],
    [{
        'sentence': "Evidence which best establishes that the petitioner is well positioned to advance the proposed endeavor will document the petitioner's qualifications (skills, experience and track record), support (financial and otherwise) and commitment (plans and progress) to drive the endeavor forward, and will support projections of future work in the proposed endeavor.",
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'USCIS may consider factors including, but not limited to, the following:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': "•The petitioner's education, skills, knowledge, and record of success in related or similar efforts: • To show a petitioner's education, skills, knowledge, and record of success in related or",
        'similarity_score': 0.9552384738907607
    }],
    [{
        'sentence': "similar efforts, the petitioner may submit one or more pieces of evidence from the following non- exhaustive list • Degrees, certificates, or licenses in the field; • Patents, trademarks, or copyrights owned by the petitioner; Letters from experts in the petitioner's field, describing the petitioner's past ⚫ achievements and providing specific examples of how the petitioner is well positioned to advance his or her endeavor.",
        'similarity_score': 0.6561697940439098
    },
    {
        'sentence': "Testimonial letters should include information about the expert's own credentials, such as a curriculum vitae; • Published articles and/or media reports about the petitioner's achievements or current work; • Documentation demonstrating a strong citation history; • Evidence that the petitioner's work has influenced his or her field of endeavor; • Evidence demonstrating the petitioner has a leading, critical or indispensable role in the endeavor or similar endeavors; and • Evidence showing that the petitioner's past inventions or innovations have been used or licensed by others in the field.",
        'similarity_score': 0.929201214307399
    },
    {
        'sentence': '• A model or plan for future activities:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': '• To show a model or plan for future activities, the petitioner may submit one or more pieces of evidence from the following non-exhaustive list • A plan describing how the petitioner intends to continue his or her work in the United States; • A detailed business model, when appropriate; • Correspondence from prospective/potential employers, clients or customers; and • Documentation reflecting feasible plans for financial support.',
        'similarity_score': 0.8426709738977627
    },
    {
        'sentence': '• Any progress towards achieving the proposed endeavor: • To show progress towards achieving the proposed endeavor, a petitioner may submit one or more pieces of evidence from the following non-exhaustive list • Evidence of grants the petitioner has received listing the amount and terms of the grants, as well as the grantees; • Copies of contracts, agreements, or licenses resulting from the proposed endeavor or otherwise demonstrating the petitioner is well positioned to advance the proposed endeavor; • Evidence of achievements that the petitioner intends to build upon or further develop (including the types of documentation listed under "petitioner\'s education, skills, knowledge, and record of success in related or similar efforts"); and • Evidence demonstrating the petitioner has a leading, critical or indispensable role in the endeavor.',
        'similarity_score': 0.823189834052333
    },
    {
        'sentence': '• The interest of potential customers, users, investors, or other relevant entities or individuals: • To show interest of potential customers, investors, or other relevant individuals, a petitioner may submit one or more pieces of evidence from the following non-exhaustive list Letters from a government entity demonstrating its interest in the proposed endeavor, ⚫ • Evidence that the petitioner has received investment from U.S. investors, such as venture capital firms, angel investors, or start-up accelerators, in amounts that are appropriate to the relevant endeavor; • Evidence that the petitioner has received awards, grants, or other indications of relevant non-monetary support (for e.g., using facilities free of charge, etc.)',
        'similarity_score': 0.8249760871520635
    },
    {
        'sentence': 'from Federal, State, or local government entities with authority over the field of endeavor,',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': "• Evidence demonstrating how the petitioner's work is being used by others, such as: • Contracts with companies using products, projects, or services that the petitioner developed or assisted in developing; • Documents showing licensed technology or other procedural or technological advancements developed in whole or in part by the petitioner and relied upon by others; and • Patents or licenses awarded to the petitioner with documentation showing why the particular patent or license is significant to the field.",
        'similarity_score': 0.8976708505978175
    },
    {
        'sentence': '• Other evidence that the petitioner is well-positioned to advance the endeavor.',
        'similarity_score': 0.899379605315904
    }],
    [{
        'sentence': 'Note: The petitioner may be well positioned to advance the endeavor even if there is no certainty that the proposed endeavor will be a success.',
        'similarity_score': 0.9710271196853533
    },
    {
        'sentence': "However, unsubstantiated claims are insufficient and would not meet the petitioner's burden of proof.",
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'On Balance, It Would Be Beneficial to the United States to Waive the Requirements of a Job Offer, and Thus of a Labor Certification',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'To evaluate whether it would be beneficial to the United States to waive the requirements of a job offer USCIS will evaluate submitted evidence.',
        'similarity_score': 0.6367143195243482
    }],
    [{
        'sentence': 'The third prong requires the petitioner to demonstrate that, on balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'USCIS cannot conclude that the petitioner offers contributions of such value that, on balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'As discussed above, the evidence did not establishes that the proposed endeavor has substantial merit, the petitioner did not establish that her specific proposed endeavor has national importance as required by the first prong of the Dhanasar framework.',
        'similarity_score': 0.8899741948003009
    },
    {
        'sentence': 'Further, the petitioner did not establish that she is well positioned to advance the specific proposed endeavor, as required by the second prong of the Dhanasar framework.',
        'similarity_score': 0.9441680817563467
    }],
    [{
        'sentence': 'The record does not demonstrate the widespread benefits associated with the petitioner\'s statement, "As an Information Security Analyst, I will install software and hardware to protect information and especially sensitive, personal data, ensuring information availability, data integrity and confidentiality, using protective measures based on ISO/IEC 27001."',
        'similarity_score': 0.26339558875653923
    },
    {
        'sentence': "The record does not contain sufficient evidence to demonstrate that the petitioner's proposed endeavor benefits would be beneficial to the United States.",
        'similarity_score': 0.45352533759235925
    }],
    [{
        'sentence': 'The petitioner failed to submit evidence that her knowledge or skills are not easily articulated in a labor certification.',
        'similarity_score': 0.6254312101011641
    }],
    [{
        'sentence': 'The petitioner has not demonstrated that she presents benefits to the United States through her proposed endeavor that outweigh those inherent in the labor certification process.',
        'similarity_score': 0.33578937990474805
    },
    {
        'sentence': 'In addition, the petitioner has not shown that she offers contributions of such value that, overall, they would benefit the nation even if other qualified U.S. workers were available.',
        'similarity_score': 0.8551662375095552
    }],
    [{
        'sentence': 'The petitioner has not shown an urgent national interest in her own contributions.',
        'similarity_score': 0.9081023377076477
    },
    {
        'sentence': 'The petitioner did not submit sufficient evidence to indicate the labor certification process restricts her ability to work in the field.',
        'similarity_score': 0.6977135390557464
    }],
    [{
        'sentence': 'Although the petitioner highlights that there is a need for individuals like her, refers to various challenges, and discusses the field in which she works, it does not, by itself, establish that it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 0.6473174446409256
    }],
    [{
        'sentence': 'Moreover, the U.S. Department of Labor already addresses shortages of qualified workers through the labor certification process.',
        'similarity_score': 0.877669426099054
    },
    {
        'sentence': 'In setting forth the balancing test for the discretionary national interest waiver sought in this case, Dhanasar did not indicate that a shortage would affect the balance, but instead focused on factors in the national interest that could outweigh the benefits inherent in the labor certification process.',
        'similarity_score': 0.9999999999999998
    }],
    [{
        'sentence': 'In sum, the petitioner has not established that, on balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Please submit evidence to establish that, on balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 0.9999999999999996
    },
    {
        'sentence': 'This balance was described in Dhanasar as on one hand protecting the domestic labor supply through the creation of the labor certification process, while on the other hand recognizing that in certain cases the benefits inherent in the labor certification process can be outweighed by other factors that are also deemed to be in the national interest.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'USCIS may evaluate factors including, but not limited to, the following:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': "Whether, in light of the nature of the petitioner's qualifications or proposed endeavor, it would be impractical either for the petitioner to secure a job offer or for the petitioner to obtain a labor certification; • Whether, even assuming that other qualified U.S. workers are available, the United States would still benefit from the petitioner's contributions; • Whether the national interest in the petitioner's contributions is sufficiently urgent to warrant forgoing the labor certification process; • Whether the petitioner's endeavor may lead to potential creation of jobs; and • Whether the petitioner is self-employed in a manner that generally does not adversely affect U.S. workers.",
        'similarity_score': 0.8827455728456645
    }],
    [{
        'sentence': 'In summary, the petitioner is eligible for the requested classification under an Advanced Degree.',
        'similarity_score': 0.43418895031902827
    },
    {
        'sentence': 'The petitioner failed to meet part 1 of the first prong of substantial merit.',
        'similarity_score': 0.42816027393573186
    },
    {
        'sentence': 'The petitioner failed to meet part 2 of the first prong of national importance.',
        'similarity_score': 0.4251113542560928
    },
    {
        'sentence': 'The petitioner failed to meet the second prong to advance the proposed endeavor.',
        'similarity_score': 0.531531527902317
    },
    {
        'sentence': 'Therefore, the petitioner did not prove on balance, it would be beneficial to the United States to waive the requirements of a job offer, and thus of a labor certification.',
        'similarity_score': 0.8342252095706065
    },
    {
        'sentence': 'Therefore, USCIS requests additional evidence.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'PLACE THE ATTACHED COVERSHEET AND THIS ENTIRE LETTER ON TOP OF YOUR RESPONSE.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'SUBMISSION OF EVIDENCE WITHOUT THE COVERSHEET AND THIS LETTER WILL DELAY PROCESSING OF YOUR CASE AND MAY RESULT IN A DENIAL.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Sincerely, Mary Elizabeth Brennan Seng Director, Texas Service Center Officer: XM2486',
        'similarity_score': 0.9500524645812416
    }]];

    const sentences4 = [[{
        'sentence': 'U.S. Department of Homeland Security U.S.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Citizenship and Immigration Services May 17, 2024 Texas Service Center 6046 N Belt Line Rd STE 172 Irving, TX 75038-0015',
        'similarity_score': 0.9862493342821264
    }],
    [{
        'sentence': 'THIAGO SANTOS c/o THIAGO NUNES SANTOS RUA 9 QD C LT 3 APT 102 CALDAS NOVAS, GOIAS 75680 220 BRAZIL IOE0923519404',
        'similarity_score': 0.287186271810436
    }],
    [{
        'sentence': 'RE: THIAGO SANTOS I-140, Immigrant Petition for Alien Worker',
        'similarity_score': 0.7878648368823296
    }],
    [{ 'sentence': 'REQUEST FOR EVIDENCE', 'similarity_score': 1.0 }],
    [{
        'sentence': 'IMPORTANT: THIS NOTICE CONTAINS YOUR UNIQUE NUMBER.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'THE ORIGINAL NOTICE MUST BE SUBMITTED WITH THE REQUESTED EVIDENCE.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'You are receiving this notice because U.S.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Citizenship and Immigration Services (USCIS) requires additional evidence to process your form.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Please provide the evidence requested below.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Include duplicate copies if you are requesting consular notification.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Your response must be received in this office by August 12, 2024.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Please note that you have been allotted the maximum period allowed for responding to a Request for Evidence (RFE).',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'The time period for responding cannot be extended.',
        'similarity_score': 1.0
    },
    {
        'sentence': '8 Code of Federal Regulations (8 CFR 103.2(b)(8)(iv).',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Because many immigration benefits are time sensitive, you are encouraged to respond to this request as early as possible, but no later than the deadline provided above.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'If you do not respond to this notice within the allotted time, your case may be denied.',
        'similarity_score': 0.9999999999999999
    },
    {
        'sentence': 'The regulations do not provide for an extension of time to submit the requested evidence.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'You must submit all requested evidence at the same time.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'If you submit only some of the requested evidence, USCIS will consider your response a request for a decision on the record.',
        'similarity_score': 1.0
    },
    { 'sentence': '8 CFR 103.2(b)(11).', 'similarity_score': 1.0 }],
    [{
        'sentence': 'If you submit a document in any language other than English, the document must be accompanied by a full and complete English translation.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'The translator must certify that the translation is accurate and he or she is competent to translate from that language to English.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'If you submit a foreign language translation in response to this request for evidence, you must also include a copy of the foreign language document.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Processing of your I-140 will resume upon receipt of your response.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'If you have not heard from USCIS within 60 days of responding, you may contact the USCIS Contact Center (NCSC) at 1-800-375-5283.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'If you are hearing impaired, please call the NCSC TDD at 1-800-767-1833.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'TSCI140TSCI14000029125772 1 of 8 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The beneficiary intends to work as a physical trainer, according to Form I-140.',
        'similarity_score': 0.38735380704482986
    }],
    [{
        'sentence': 'In order to establish eligibility, the petitioner must establish that:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The beneficiary qualifies for the requested classification; and An exemption from the requirement of a job offer, and thus of a labor certification, is in the national interest of the United States.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Eligibility for the Requested Classification',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': "The petitioner has submitted evidence she was awarded a bachelor's degree Physical Education, which has been evaluated and found to be equivalent to a similar degree in the United States.",
        'similarity_score': 0.301995683258472
    },
    {
        'sentence': 'The petitioner has also provided evidence of at least 5 years progressive experience in the field of endeavor.',
        'similarity_score': 0.46704171347167667
    },
    {
        'sentence': 'Therefore, any claim to exceptional ability is moot, since the beneficiary already meets the qualifications of the classification based on his education and progressive experience.',
        'similarity_score': 0.30785535030162015
    }],
    [{
        'sentence': 'USCIS has designated Matter of Dhanasar, 26 I&N Dec. 884 (AAO 2016) (“Dhanasar”) as a precedent decision.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'That decision rescinded the earlier precedent decision, Matter of New York State Dep’t of Transp.',
        'similarity_score': 1.0
    },
    {
        'sentence': '("NYSDOT"), 22 I&N Dec. 215 (Acting Assoc.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Comm’r 1998), regarding national interest waivers under Section 203(b)(2)(B)(i) of the Immigration and Nationality Act, and introduced a new three-prong test for determining eligibility.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Under Dhanasar, USCIS may grant a national interest waiver as a matter of discretion if the petitioner demonstrates by a preponderance of the evidence that:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The beneficiary’s proposed endeavor has both substantial merit and national importance; The beneficiary is well positioned to advance the proposed endeavor; and On balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'If these three elements are satisfied, USCIS may approve the national interest waiver as a matter of discretion.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': "In this case, the evidence does not establish that the beneficiary's proposed endeavor has national importance; the petitioner is well positioned to advance the proposed endeavor; and on balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.",
        'similarity_score': 0.846285428629296
    },
    {
        'sentence': 'Therefore, USCIS requests additional evidence.',
        'similarity_score': 1.0000000000000002
    }],
    [{ 'sentence': 'I.', 'similarity_score': 0.0 },
    {
        'sentence': 'Whether the Proposed Endeavor has Substantial Merit and National Importance',
        'similarity_score': 0.7690053538241154
    }],
    [{
        'sentence': 'The first prong, substantial merit and national importance, focuses on the specific endeavor that the foreign national proposes to undertake.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Matter of Dhanasar, 26 I&N Dec. 884, 889 (AAO 2016).',
        'similarity_score': 1.0
    },
    {
        'sentence': "The endeavor's merit may be demonstrated in a range of areas such as business, entrepreneurialism, science, technology, culture, health, or education.",
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'According to the petitioner’s letter of intent, he plans to open and operate a physical training business.',
        'similarity_score': 0.22813571304637403
    },
    {
        'sentence': "The petitioner did not submit any evidence of the beneficiary's communications with any employers, companies, or interested parties for him to provide services.",
        'similarity_score': 0.7802810555374236
    },
    {
        'sentence': 'The petitioner did not provide any details',
        'similarity_score': 0.5514596634318998
    }],
    [{
        'sentence': 'TSCI140TSCI14000029125772 2 of 8 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'about where he will pursue his specific proposed endeavor or how his endeavor as a physical trainer, would rise to the level of national importance.',
        'similarity_score': 0.3147543027506416
    }],
    [{
        'sentence': 'In determining national importance, the relevant question is not the importance of the field, industry, or profession in which the individual will work; instead USCIS must focus on the “the specific endeavor that the foreign national proposes to undertake.” See Dhanasar, 26 I&N Dec. at 889.',
        'similarity_score': 1.0000000000000004
    },
    {
        'sentence': 'In Dhanasar, the AAO noted that they “look for broader implications” of the proposed endeavor and that “[a]n undertaking may have national importance for example, because it has national or even global implications within a particular field.” Id.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'AAO also stated that "[a]n endeavor that has significant potential to employ U.S. workers or has other substantial positive economic effects, particularly in an economically depressed area, for instance, may well be understood to have national importance."',
        'similarity_score': 0.9999999999999998
    },
    {
        'sentence': "To evaluate whether the petitioner's proposed endeavor satisfies the national importance requirement USCIS must look to evidence documenting the “potential prospective impact'” of the petitioner's proposed endeavor work.",
        'similarity_score': 0.9850967645495066
    }],
    [{
        'sentence': "Here, we conclude the record does not show that the petitioner's proposed endeavor stands to sufficiently extend beyond the beneficiary’s future employer or the petitioner’s company and its clientele to impact beneficiary’s field, or the U.S. economy more broadly at a level commensurate with national importance.",
        'similarity_score': 0.7537320301214456
    },
    {
        'sentence': "With regard to the petitioner's assertion that petitioner’s plans to be a physical trainer, the record does not show that petitioner’s proposed endeavor as working as a physical trainer, would offer broader implications for beneficiary’s field, commensurate with national importance.",
        'similarity_score': 0.2791591420721914
    }],
    [{
        'sentence': "While the petitioner's plans to provide physical trainer, services have merit, the record does not demonstrate that the petitioner’s instructional activities offer benefits that extend beyond the petitioner’s trainees to impact the physical trainer, field or her industry more broadly.",
        'similarity_score': 0.3417967587014775
    },
    {
        'sentence': "Likewise, in Dhanasar, we determined that the petitioner's teaching activities did not rise to the level of having national importance because they would not impact his field more broadly.",
        'similarity_score': 0.9410776078509162
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    { 'sentence': 'at 893.', 'similarity_score': 1.0 }],
    [{
        'sentence': 'Furthermore, the petitioner has not demonstrated that the specific endeavor the petitioner proposes to undertake has significant potential to employ U.S. workers or otherwise offers substantial positive economic effects for our nation.',
        'similarity_score': 0.7327601760894275
    },
    {
        'sentence': 'Without sufficient information or evidence regarding any projected U.S. economic impact or job creation attributable to the petitioner’s future work, the record does not show that benefits to the U.S. regional or national economy resulting from her physical trainer projects would reach the level of "substantial positive economic effects" contemplated by Dhanasar.',
        'similarity_score': 0.8175773144759685
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    { 'sentence': 'at 890.', 'similarity_score': 1.0 },
    {
        'sentence': "Accordingly, the Petitioner's proposed work does not meet the first prong of the Dhanasar framework.",
        'similarity_score': 0.5734854655941402
    }],
    [{
        'sentence': 'Therefore, please submit evidence to establish that the petitioner’s proposed endeavor has national importance.',
        'similarity_score': 0.9085563940962148
    },
    {
        'sentence': 'This evidence must demonstrate the endeavor’s potential prospective impact.',
        'similarity_score': 1.0
    }],
    [{ 'sentence': 'II.', 'similarity_score': 1.0 },
    {
        'sentence': 'Whether the Beneficiary is Well Positioned to Advance the Proposed Endeavor',
        'similarity_score': 0.9058588824070652
    }],
    [{
        'sentence': "The petitioner included evidence that he was awarded a bachelor's degree in Physical Education multiple certifications, awards, and a statement outlining his experience and his future plans as a physical trainer,in the Untied States and his desire to open a physical trainer business, The petitioner also submitted multiple recommendation letters written on his behalf from either current, former employers, or colleagues.",
        'similarity_score': 0.2720446295372905
    },
    {
        'sentence': 'While these individuals express their admiration and appreciation for the beneficiary’s services, none of these letters explain how the beneficiary’s accomplishments constitute recognition of achievements and significant contributions to the field of physical training,.',
        'similarity_score': 0.36152945215281534
    },
    {
        'sentence': 'The letters lack specific information that the beneficiary was recognized for achievements and significant contributions to the industry or field by peers, governmental entities, or professional or business TSCI140TSCI14000029125772 3 of 8 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 0.6194382670069822
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'contributions to the industry or field by peers, governmental entities, or professional or business organizations.',
        'similarity_score': 0.8569733369108318
    },
    {
        'sentence': 'No further evidence was submitted regarding the petitioner’s plans to open a physical trainer business, in the U.S.',
        'similarity_score': 0.30565155355789564
    }],
    [{
        'sentence': 'Please submit evidence to establish the petitioner is well positioned to advance the proposed endeavor.',
        'similarity_score': 0.9819922530203312
    }],
    [{
        'sentence': 'The beneficiary’s education, skills, knowledge, and record of success in related or similar efforts:',
        'similarity_score': 0.9243423898240649
    }],
    [{
        'sentence': 'To show a beneficiary’s education, skills, knowledge, and record of success in related or similar efforts, the petitioner may submit one or more pieces of evidence from the following non-exhaustive list:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Patents, trademarks, or copyrights owned by the beneficiary;',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Letters from experts in the beneficiary’s field, describing the beneficiary’s past achievements and providing specific examples of how the beneficiary is well positioned to advance his or her endeavor.',
        'similarity_score': 0.7855814248407649
    },
    {
        'sentence': 'Testimonial letters should include information about the expert’s own credentials, such as a curriculum vitae;',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Published articles and/or media reports about the beneficiary’s achievements or current work;',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Evidence that the beneficiary’s work has influenced his or her field of endeavor;',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Evidence demonstrating the beneficiary has a leading, critical or indispensable role in the endeavor or similar endeavors; and',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Evidence showing that the beneficiary’s past inventions or innovations have been used or licensed by others in the field;',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'A model or plan for future activities: To show a model or plan for future activities, the petitioner may submit one or more pieces of evidence from the following non-exhaustive list: A plan describing how the beneficiary intends to continue his or her work in the United States;',
        'similarity_score': 0.9664940607577119
    }],
    [{
        'sentence': 'A detailed business model, when appropriate;',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Correspondence from prospective/potential employers, clients or customers; and',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Documentation reflecting feasible plans for financial support;',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Any progress towards achieving the proposed endeavor: To show progress towards achieving the proposed endeavor, a petitioner may submit one or more pieces of evidence from the following non-exhaustive list: Evidence of grants the beneficiary has received listing the amount and terms of the grants, as well as the grantees;',
        'similarity_score': 0.9678360508758004
    }],
    [{
        'sentence': 'Copies of contracts, agreements, or licenses resulting from the proposed endeavor or otherwise demonstrating the beneficiary is well positioned to advance the proposed endeavor;',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Evidence of achievements that the beneficiary intends to build upon or further develop (including the types of documentation listed under “beneficiary’s education, skills, knowledge, and record of success in related or similar efforts”); and',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'TSCI140TSCI14000029125772 4 of 8 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Evidence demonstrating the beneficiary has a leading, critical or indispensable role in the endeavor;',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'The interest of potential customers, users, investors, or other relevant entities or individuals: To show interest of potential customers, investors, or other relevant individuals, a petitioner may submit one or more pieces of evidence from the following non-exhaustive list: Letters from a government entity demonstrating its interest in the proposed endeavor;',
        'similarity_score': 0.9317659528696463
    }],
    [{
        'sentence': 'Evidence that the beneficiary has received investment from U.S. investors, such as venture capital firms, angel investors, or start-up accelerators, in amounts that are appropriate to the relevant endeavor;',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Evidence that the beneficiary has received awards, grants, or other indications of relevant non-monetary support (for e.g., using facilities free of charge, etc.)',
        'similarity_score': 1.0
    },
    {
        'sentence': 'from Federal, State, or local government entities with authority over the field of endeavor;',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Evidence demonstrating how the beneficiary’s work is being used by others, such as: Contracts with companies using products, projects, or services that the beneficiary developed or assisted in developing;',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Documents showing licensed technology or other procedural or technological advancements developed in whole or in part by the beneficiary and relied upon by others; and',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Patents or licenses awarded to the beneficiary with documentation showing why the particular patent or license is significant to the field; and',
        'similarity_score': 0.9648260966228565
    }],
    [{
        'sentence': 'Other evidence that the beneficiary is well-positioned to advance the endeavor.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Note: The beneficiary may be well positioned to advance the endeavor even if there is no certainty that the proposed endeavor will be a success.',
        'similarity_score': 0.9999999999999999
    },
    {
        'sentence': 'However, unsubstantiated claims are insufficient and would not meet the petitioner’s burden of proof.',
        'similarity_score': 1.0
    }],
    [{ 'sentence': 'III.', 'similarity_score': 0.3709900818802524 },
    {
        'sentence': 'Whether, on Balance, it Would be Beneficial to the United States to Waive the Requirements of a Job Offer, and Thus of a Labor Certification',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'USCIS may, in its discretion, use as advisory opinions statements submitted as expert testimony.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'However, USCIS is ultimately responsible for making the final determination regarding an individual’s eligibility for the benefit sought.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'The submission of letters from experts supporting Form I-140 is not presumptive evidence of eligibility; USCIS may evaluate the content of those letters as to whether they support the individual’s eligibility.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'USCIS may even give less weight to an opinion that is not corroborated, in accord with other information or is in any way questionable.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'In this case, USCIS cannot conclude that the beneficiary offers contributions of such value that, on balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 0.9735318980844836
    },
    {
        'sentence': 'As discussed above, the evidence establishes that the proposed endeavor has substantial merit, but the petitioner did not establish that the endeavor has national importance as TSCI140TSCI14000029125772 5 of 8 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 0.6465811474947143
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'required by the first prong of the Dhanasar framework.',
        'similarity_score': 0.8565167360357807
    },
    {
        'sentence': 'Further, the petitioner did not establish that the beneficiary is well positioned to advance the specific proposed endeavor, as required by the second prong of the Dhanasar framework.',
        'similarity_score': 0.9459431523643135
    }],
    [{
        'sentence': 'Please submit evidence to establish that, on balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'This balance was described in Dhanasar as on one hand protecting the domestic labor supply through the creation of the labor certification process, while on the other hand recognizing that in certain cases the benefits inherent in the labor certification process can be outweighed by other factors that are also deemed to be in the national interest.',
        'similarity_score': 0.9999999999999997
    }],
    [{
        'sentence': 'USCIS may evaluate factors including, but not limited to, the following:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Whether, in light of the nature of the beneficiary’s qualifications or proposed endeavor, it would be impractical either for the beneficiary to secure a job offer or for the petitioner to obtain a labor certification;',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Whether, even assuming that other qualified U.S. workers are available, the United States would still benefit from the beneficiary’s contributions;',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Whether the national interest in the beneficiary’s contributions is sufficiently urgent to warrant forgoing the labor certification process;',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Whether the beneficiary’s endeavor may lead to potential creation of jobs; and',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Whether the beneficiary is self-employed in a manner that generally does not adversely affect U.S. workers.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Please submit evidence to establish that the beneficiary’s proposed endeavor has national importance.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'This evidence must demonstrate the endeavor’s potential prospective impact.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Evidence to establish that the beneficiary’s proposed endeavor has national importance consists of, but is not limited to, the following:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'A detailed description of the proposed endeavor and why it is of national importance, Documentary evidence that supports the petitioner’s statements and establishes the endeavor’s national importance.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Such evidence must demonstrate the endeavor’s potential prospective impact, and may consist of, but is not limited to, evidence showing that the proposed endeavor: Has national or even global implications within a particular field; Has significant potential to employ U.S. workers or has other substantial positive economic effects, particularly in an economically depressed area; Will broadly enhance societal welfare or cultural or artistic enrichment; and Impacts a matter that a government entity has described as having national importance or is the subject of national initiatives.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'PLACE THE ATTACHED COVERSHEET AND THIS ENTIRE LETTER ON TOP OF YOUR RESPONSE.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'SUBMISSION OF EVIDENCE WITHOUT THE COVERSHEET AND THIS LETTER WILL DELAY PROCESSING OF YOUR CASE AND MAY RESULT IN A DENIAL.',
        'similarity_score': 1.0000000000000002
    }],
    [{ 'sentence': 'Sincerely,', 'similarity_score': 1.0 }],
    [{
        'sentence': 'TSCI140TSCI14000029125772 6 of 8 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Mary Elizabeth Brennan Seng Director, Texas Service Center Officer: XM 2567',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'TSCI140TSCI14000029125772 7 of 8 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0
    }],
    [{ 'sentence': 'Officer: XM 2567', 'similarity_score': 1.0000000000000002 }],
    [{
        'sentence': 'COVERSHEET PLEASE RETURN THE REQUESTED INFORMATION AND ALL SUPPORTING DOCUMENTS WITH THIS PAGE ON TOP TO: USCIS/TSC ATTN: RFE/NOID/NOIR/NOIT 6046 N BELT LINE RD STE 172 IRVING, TX 75038-0015',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Please check the appropriate box regarding if there is a new Form G-28, Notice of Entry of Appearance as Attorney or Accredited Representative, additional fees, additional forms, etc.',
        'similarity_score': 1.0000000000000004
    },
    {
        'sentence': 'Please place the new Form G-28, additional fees, additional forms directly under this sheet.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Yes, there is: A New G-28 Additional Fees',
        'similarity_score': 1.0
    }],
    [{ 'sentence': 'Additional Forms Other:', 'similarity_score': 1.0 }],
    [{
        'sentence': 'If you have moved, write your current address in the blank area below.',
        'similarity_score': 1.0000000000000002
    },
    { 'sentence': 'Please be sure to write clearly.', 'similarity_score': 1.0 }],
    [{
        'sentence': '(Select appropriate check box) Applicant/Beneficiary Petitioner',
        'similarity_score': 1.0
    }],
    [{ 'sentence': 'New Address:', 'similarity_score': 1.0 }],
    [{
        'sentence': 'As required by Section § 265.1 Reporting change of address.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': '“Except for those exempted by section 263(b) of the Act, all aliens in the United States required to register under section 262 of the Act must report each change of address and new address within 10 days of such change in accordance with instructions provided by USCIS.”',
        'similarity_score': 1.0000000000000002
    }],
    [{ 'sentence': 'REQUEST FOR EVIDENCE', 'similarity_score': 1.0 }],
    [{
        'sentence': '140 Immigrant Petition for Alien Worker',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'SANTOS, THIAGO IOE0923519404 SANTOS, THIAGO',
        'similarity_score': 0.4183380784141605
    }],
    [{ 'sentence': '8 of 8', 'similarity_score': 0.35042120182438585 }],
    [{
        'sentence': 'TSCI140TSCI14000029125772 TSC Routing Sheet Suite 172 ECHO - Enterprise Correspondence Handling Online',
        'similarity_score': 0.9956803007061121
    }]];

    const sentences5 = [[{
        'sentence': 'U.S. Department of Homeland Security U.S.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Citizenship and Immigration Services May 15, 2024 Texas Service Center 6046 N Belt Line Rd STE 172 Irving, TX 75038-0015',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'ANA CECILIA DIAS PINHEIRO DA CAMARA c/o ANA CECILIA DIAS PINHEIRO DA CAMAR LEONOR SOARES PESSOA 74 APT 74 RECIFE, PERNAMBUCO 51170600 BRAZIL IOE0923735872',
        'similarity_score': 0.13240853601573388
    }],
    [{
        'sentence': 'RE: ANA CECILIA DIAS PINHEIRO DA CAMARA I-140, Immigrant Petition for Alien Worker',
        'similarity_score': 0.552506206565428
    }],
    [{ 'sentence': 'REQUEST FOR EVIDENCE', 'similarity_score': 1.0 }],
    [{
        'sentence': 'IMPORTANT: THIS NOTICE CONTAINS YOUR UNIQUE NUMBER.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'THE ORIGINAL NOTICE MUST BE SUBMITTED WITH THE REQUESTED EVIDENCE.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'You are receiving this notice because U.S.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Citizenship and Immigration Services (USCIS) requires additional evidence to process your form.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Please provide the evidence requested below.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Include duplicate copies if you are requesting consular notification.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Your response must be received in this office by August 12, 2024.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Please note that you have been allotted the maximum period allowed for responding to a Request for Evidence (RFE).',
        'similarity_score': 1.0
    },
    {
        'sentence': 'The time period for responding cannot be extended.',
        'similarity_score': 1.0
    },
    {
        'sentence': '8 Code of Federal Regulations (8 CFR 103.2(b)(8)(iv).',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Because many immigration benefits are time sensitive, you are encouraged to respond to this request as early as possible, but no later than the deadline provided above.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'If you do not respond to this notice within the allotted time, your case may be denied.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'The regulations do not provide for an extension of time to submit the requested evidence.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'You must submit all requested evidence at the same time.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'If you submit only some of the requested evidence, USCIS will consider your response a request for a decision on the record.',
        'similarity_score': 1.0000000000000002
    },
    { 'sentence': '8 CFR 103.2(b)(11).', 'similarity_score': 1.0 }],
    [{
        'sentence': 'If you submit a document in any language other than English, the document must be accompanied by a full and complete English translation.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'The translator must certify that the translation is accurate and he or she is competent to translate from that language to English.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'If you submit a foreign language translation in response to this request for evidence, you must also include a copy of the foreign language document.',
        'similarity_score': 1.0000000000000004
    }],
    [{
        'sentence': 'Processing of your I-140 will resume upon receipt of your response.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'If you have not heard from USCIS within 60 days of responding, you may contact the USCIS Contact Center (NCSC) at 1-800-375-5283.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'If you are hearing impaired, please call the NCSC TDD at 1-800-767-1833.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'TSCI140TSCI14000029405800 1 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0000000000000004
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Reference is made to this Form I-140, Immigrant Petition for Alien Worker, seeking classification as a member of the professions holding an advanced degree and an exemption from the requirement of a job offer, and thus of a labor certification, in the national interest of the United States for ANA CECILIA DIAS PINHEIRO DA CAMARA (petitioner and beneficiary), filed on her own behalf.',
        'similarity_score': 0.8288210905992388
    },
    {
        'sentence': 'The priority date for this petition is December 28, 2023.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The self-petitioner intends to work as an independent chronic disease prevention and management consultant in the field of physical therapy.',
        'similarity_score': 0.29509023746682966
    }],
    [{
        'sentence': 'In order to establish eligibility, the petitioner must establish that:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The self-petitioner qualifies for the requested classification; and An exemption from the requirement of a job offer, and thus of a labor certification, is in the national interest of the United States.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'USCIS has designated Matter of Dhanasar, 26 I&N Dec. 884 (AAO 2016) (“Dhanasar”) as a precedent decision.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'That decision rescinded the earlier precedent decision, Matter of New York State Dep’t of Transp.',
        'similarity_score': 1.0
    },
    {
        'sentence': '("NYSDOT"), 22 I&N Dec. 215 (Acting Assoc.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Comm’r 1998), regarding national interest waivers under Section 203(b)(2)(B)(i) of the Immigration and Nationality Act, and introduced a new three-prong test for determining eligibility.',
        'similarity_score': 0.9999999999999997
    },
    {
        'sentence': 'Under Dhanasar, USCIS may grant a national interest waiver as a matter of discretion if the petitioner demonstrates by a preponderance of the evidence that:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'The self-petitioner’s proposed endeavor has both substantial merit and national importance; The self-petitioner is well positioned to advance the proposed endeavor; and On balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'If these three elements are satisfied, USCIS may approve the national interest waiver as a matter of discretion.',
        'similarity_score': 1.0000000000000002
    }],
    [{ 'sentence': 'Advanced Degree', 'similarity_score': 1.0 }],
    [{
        'sentence': 'You submitted evidence with Form I-140 which establishes the self-petitioner holds a Titulo de Fisioterapeuta, issued in November 2010, followed by five years of progressive experience in her field, and thus qualifies as a member of the professions holding an advanced degree.',
        'similarity_score': 0.6292138102286223
    },
    {
        'sentence': 'Therefore, at this time, USCIS does not need to evaluate whether the self-petitioner also qualifies as an alien of exceptional ability.',
        'similarity_score': 0.9663206802004417
    }],
    [{
        'sentence': 'The remaining issue is whether the self-petitioner established that a waiver of the job offer requirement, and a labor certification, is in the national interest.',
        'similarity_score': 0.9658858730488911
    }],
    [{
        'sentence': 'The evidence does not establish that the self-petitioner’s proposed endeavor has both substantial merit and national importance; that the self-petitioner is well positioned to advance the proposed endeavor; and on balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 0.9482863514828861
    },
    {
        'sentence': 'Therefore, USCIS requests additional evidence.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Substantial Merit and National Importance',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The first prong, substantial merit and national importance, focuses on the specific endeavor that the foreign national proposes to undertake.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Matter of Dhanasar, 26 I&N Dec. 884, 889 (AAO 2016).',
        'similarity_score': 1.0
    },
    {
        'sentence': "The endeavor's merit may be demonstrated in a range of areas such as business, entrepreneurialism, TSCI140TSCI14000029405800 2 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.",
        'similarity_score': 0.7006357231760675
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': "endeavor's merit may be demonstrated in a range of areas such as business, entrepreneurialism, science, technology, culture, health, or education.",
        'similarity_score': 0.9977175243262502
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 }],
    [{
        'sentence': 'The self-petitioner is applying for a waiver of the job offer requirement.',
        'similarity_score': 0.9290819101576528
    },
    {
        'sentence': "While it is not necessary for her to have a job offer from a specific employer, USCIS can consider information about her current and prospective positions to illustrate the capacity in which she intends to work in order to determine whether the self-petitioner's proposed endeavor meets the requirements of the first prong of the Dhanasar analytical framework.",
        'similarity_score': 0.9344965495466594
    }],
    [{
        'sentence': 'In determining national importance, the relevant question is not the importance of the field, industry, or profession in which the individual will work; instead USCIS must focus on the “the specific endeavor that the foreign national proposes to undertake.” See Dhanasar, 26 I&N Dec. at 889.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'In Dhanasar, the AAO noted that they “look for broader implications” of the proposed endeavor and that “[a]n undertaking may have national importance for example, because it has national or even global implications within a particular field.” Id.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'AAO also stated that "[a]n endeavor that has significant potential to employ U.S. workers or has other substantial positive economic effects, particularly in an economically depressed area, for instance, may well be understood to have national importance."',
        'similarity_score': 1.0000000000000002
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    { 'sentence': 'at 890.', 'similarity_score': 1.0 }],
    [{
        'sentence': 'Importantly, the term “endeavor” is more specific than the general occupation.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'A self-petitioner should offer details not only as to what the occupation normally involves, but what types of work the self-petitioner proposes to undertake specifically within that occupation.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': "To evaluate whether the self-petitioner's proposed endeavor satisfies the substantial merit and national importance requirements USCIS must look to evidence documenting the “potential prospective impact'” of her proposed endeavor work.",
        'similarity_score': 0.8457930027860349
    },
    { 'sentence': 'Dhanasar.', 'similarity_score': 0.5638915492335922 },
    { 'sentence': 'at 889.', 'similarity_score': 1.0 },
    {
        'sentence': 'The self-petitioner intends to work as an independent chronic disease prevention and management consultant in the field of physical therapy.',
        'similarity_score': 0.29506256461390645
    }],
    [{
        'sentence': 'The self-petitioner submitted a proposal statement that she intends to work as an independent chronic disease prevention and management consultant in the field of physical therapy.',
        'similarity_score': 0.2814149258204992
    },
    {
        'sentence': 'She will utilize an innovative physical therapy method that she has developed, the Physical Therapy Nutrition (PTN) Method, which integrates nutritional management and physical therapy to enhance the functionality, health, well-being, and life quality for patients, including those with chronic diseases.',
        'similarity_score': 0.19409630794651345
    },
    {
        'sentence': 'There are three components to her proposed endeavor: apply the PTN Method in her work as an independent chronic disease prevention and management consultant; provide advisory, consultant, and training services to physical therapy practitioners and clinics to successfully integrate the PTN Method into their practice; and develop an online platform in English and Portuguese to education physical therapists about the PTN Method, and ways to integrate it into their practice.',
        'similarity_score': 0.17555123890223462
    },
    {
        'sentence': 'The self-petitioner will also offer training and services related to chronic disease management and prevention; personalized rehabilitation; weight management; health and wellness education; geriatric health and wellness; health and wellness for post-Covid 19 patients; patient advocacy; and corporate wellness.',
        'similarity_score': 0.4365829122503928
    }],
    [{
        'sentence': 'However, the self-petitioner makes only broad assertions and does not provide details about her specific proposed endeavor.',
        'similarity_score': 0.3999230339562748
    },
    {
        'sentence': 'She gives only a very rudimentary overview of her PTN Method, providing no evidence of the clinical research she conducted to develop this method, or about its efficacy in treating patients.',
        'similarity_score': 0.1829606997120612
    },
    {
        'sentence': 'The self-petitioner also does not adequately explain how she will balance the three components of her endeavor with the additional training and services she plans on offering.',
        'similarity_score': 0.24747859778082051
    },
    {
        'sentence': 'She does not indicate where the endeavor will be located in the United States; how she will create jobs and revenue through this specific endeavor; what prospective entities she will work with; or how her specific proposed endeavor has both substantial merit and national importance.',
        'similarity_score': 0.4049749181742269
    },
    {
        'sentence': 'The record does not support that the self-petitioner intends to do engage in an endeavor that is separate from or',
        'similarity_score': 0.3323226105039565
    }],
    [{
        'sentence': 'TSCI140TSCI14000029405800 3 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0000000000000004
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'outside of continuing her current position within his field.',
        'similarity_score': 0.3289234722216128
    }],
    [{
        'sentence': 'Continuing employment in one’s position, field, or industry is not an endeavor sufficient to evaluate under this analytical framework.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'In Dhanasar, North Carolina Agricultural and Technical State University (“North Carolina A&T”) employed the beneficiary as a Postdoctoral Research Associate and the petitioner worked in the air and space propulsion systems field.',
        'similarity_score': 1.0000000000000004
    },
    {
        'sentence': 'Matter of Dhanasar, 26 I&N Dec. 884, 891 (AAO 2016).',
        'similarity_score': 1.0
    },
    {
        'sentence': 'His proposed endeavor was to “continue research into the design and development of propulsion systems for potential use in military and civilian technologies such as nano-satellites, rocket-propelled ballistic missiles, and single-stage-to-orbit vehicles.” Id.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': "At issue, however, is not whether any potential employer and client would benefit from the self-petitioner's proposed endeavor, but whether the endeavor has broad implications with substantial positive economic effects.",
        'similarity_score': 0.40345714911721237
    },
    {
        'sentence': "Effects limited to the self-petitioner's potential employer and clients may be of great value to them, but still lack national importance.",
        'similarity_score': 0.32269222383883445
    },
    {
        'sentence': "In the same way that Dhanasar finds that a classroom teacher's proposed endeavor is not nationally important because it will not impact the field more broadly, USCIS finds that the self-petitioner has not shown her proposed endeavor in this case stands to sufficiently extend beyond any potential employer and clients to impact the industry more broadly.",
        'similarity_score': 0.9292768346217268
    },
    {
        'sentence': 'In Dhanasar, the AAO noted that they “look for broader implications” of the proposed endeavor and that “[a]n undertaking may have national importance for example, because it has national or even global implications within a particular field.” See Dhanasar, 26 I&N Dec. at 889.',
        'similarity_score': 0.9477620975697747
    }],
    [{
        'sentence': 'Without details about her specific proposed endeavor, the self-petitioner impedes USCIS in determining that the proposed endeavor has substantial merit and national importance, and that she is well positioned to advance her proposed endeavor.',
        'similarity_score': 0.8178310794011351
    },
    {
        'sentence': 'As a result, the self-petitioner also impedes USCIS in determining that, on balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 0.973080642201166
    }],
    [{
        'sentence': "The self-petitioner submitted multiple letters of support; however, these letters focus on the self-petitioner's qualifications, experience, and accomplishments.",
        'similarity_score': 0.3741772431863527
    },
    {
        'sentence': 'None of the letters address the importance of or details about her proposed endeavor; how she will create jobs and revenue; what prospective entities she will work with; or how the specific proposed endeavor has both substantial merit and national importance.',
        'similarity_score': 0.4441870165885962
    },
    {
        'sentence': 'USCIS must focus on the “the specific endeavor that the foreign national proposes to undertake.” See Dhanasar, 26 I&N Dec. at 889.',
        'similarity_score': 0.6911771256590631
    }],
    [{
        'sentence': 'USCIS may, in its discretion, use as advisory opinions statements submitted as expert testimony.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'See Matter of Caron International, 19 I&N Dec. at 795.',
        'similarity_score': 0.8726260297891024
    },
    {
        'sentence': "However, USCIS is ultimately responsible for making the final determination regarding an individual's eligibility for the benefit sought.",
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': "The submission of letters from experts supporting Form I-140 is not presumptive evidence of eligibility; USCIS may evaluate the content of those letters as to whether they support the individual's eligibility.",
        'similarity_score': 0.9999999999999998
    },
    {
        'sentence': "USCIS may even give less weight to an opinion that is not whether they support the individual's eligibility.",
        'similarity_score': 0.556340984069905
    },
    {
        'sentence': 'USCIS may even give less weight to an opinion that is not corroborated, in accord with other information or is in any way questionable.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'See also Matter of Soffici, 22 I&N Dec. at 165.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Broad assertions are insufficient to show the self-petitioner meets this prong.',
        'similarity_score': 0.9092107803927721
    },
    {
        'sentence': 'See Matter of Chawathe, 25 I&N Dec. 369 (AAO 2010).',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Going on record without supporting evidence is insufficient for purposes of meeting the burden of proof in these proceedings.',
        'similarity_score': 1.0000000000000004
    },
    {
        'sentence': 'Matter of Soffici, 22 I&N Dec. at 165.',
        'similarity_score': 0.8390603092651119
    }],
    [{
        'sentence': 'The self-petitioner submitted printouts, articles, and reports about different topics in general (e.g., chronic diseases, and their prevention and cost; adult obesity; nutrition and physical therapy; physical therapist occupational outlook).',
        'similarity_score': 0.20422197836775408
    },
    {
        'sentence': 'While these documents highlight the importance of her field and occupation, the self-petitioner must demonstrate both the substantial merit and national importance of',
        'similarity_score': 0.482705661630147
    }],
    [{
        'sentence': 'TSCI140TSCI14000029405800 4 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0000000000000004
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'her own specific endeavor, rather than both the substantial merit and national importance of the field and occupation overall.',
        'similarity_score': 0.5154208226054412
    },
    {
        'sentence': 'None of the submitted printouts, articles, and reports mention the self-petitioner or her proposed endeavor, the impact her work has had on the field of her proposed endeavor, or how and why the proposed endeavor would have national importance.',
        'similarity_score': 0.4018155182193803
    },
    {
        'sentence': 'They are not probative.',
        'similarity_score': 0.4388092067126634
    }],
    [{
        'sentence': "Overall, the record does not demonstrate that the self-petitioner's proposed endeavor as a chronic disease prevention and management consultant will impact the industry or the U.S. economy more broadly at a level commensurate with national importance.",
        'similarity_score': 0.4150322260465841
    },
    {
        'sentence': 'The self-petitioner did not demonstrate that the particular work she proposes to undertake offers original innovations that contribute to advancements in her industry or otherwise has broader implications for her field.',
        'similarity_score': 0.4866733140789631
    },
    {
        'sentence': 'In addition, she did not sufficiently demonstrate that her specific proposed endeavor has significant potential to employ U.S. workers or otherwise offer substantial positive economic effects for the nation.',
        'similarity_score': 0.6131599174995341
    }],
    [{
        'sentence': 'Thus, the self-petitioner failed to demonstrate that her proposed endeavor has national or even global implications.',
        'similarity_score': 0.7214899680172511
    },
    {
        'sentence': "Accordingly, the self-petitioner's proposed work does not meet the first prong of the Dhanasar framework.",
        'similarity_score': 0.9270925075807637
    }],
    [{
        'sentence': 'Please submit evidence to establish that the self-petitioner’s proposed endeavor has substantial merit.',
        'similarity_score': 0.8801500369237998
    }],
    [{
        'sentence': 'Evidence to establish that the self-petitioner’s proposed endeavor has substantial merit consists of, but is not limited to, the following:',
        'similarity_score': 0.930178060619155
    }],
    [{
        'sentence': 'A detailed description of the proposed endeavor and why it is of substantial merit; and Documentary evidence that supports the petitioner’s statements and establishes the endeavor’s merit.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Please submit evidence to establish that the self-petitioner’s proposed endeavor has national importance.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'This evidence must demonstrate the endeavor’s potential prospective impact.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Evidence to establish that the self-petitioner’s proposed endeavor has national importance consists of, but is not limited to, the following:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'A detailed description of the proposed endeavor and why it is of national importance, Documentary evidence that supports the petitioner’s statements and establishes the endeavor’s national importance.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Such evidence must demonstrate the endeavor’s potential prospective impact, and may consist of, but is not limited to, evidence showing that the proposed endeavor: Has national or even global implications within a particular field; Has significant potential to employ U.S. workers or has other substantial positive economic effects, particularly in an economically depressed area; Will broadly enhance societal welfare or cultural or artistic enrichment; and Impacts a matter that a government entity has described as having national importance or is the subject of national initiatives.',
        'similarity_score': 0.9999999999999999
    }],
    [{
        'sentence': 'Well-Positioned to Advance the Proposed Endeavor',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The second prong shifts the focus from the proposed endeavor to the foreign national.',
        'similarity_score': 1.0
    },
    { 'sentence': 'See Dhanasar, 26 I&N Dec. at 880.', 'similarity_score': 1.0 },
    {
        'sentence': 'In general, research must add information to the pool of knowledge in some way in order to be accepted for publication, presentation, funding, or academic credit; not every individual who has performed original research will be found to be well positioned to advance his or her proposed research.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': "Rather, USCIS must examine the factors set forth in Dhanasar to determine whether, for instance, the individual's progress towards achieving the goals of the proposed research, TSCI140TSCI14000029405800 5 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.",
        'similarity_score': 0.6720446365842041
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'record of success in similar efforts, or generation of interest among relevant parties supports such a finding.',
        'similarity_score': 0.6553767052565918
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    { 'sentence': 'at 890.', 'similarity_score': 1.0 }],
    [{
        'sentence': "USCIS looks to a variety of factors in determining whether an individual is well positioned to advance the proposed endeavor, such as the individual's progress towards achieving the goals of the proposed endeavor, record of success in similar efforts, or generation of interest among relevant parties supports such a finding.",
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'The self-petitioner is not well positioned to advance his proposed endeavor.',
        'similarity_score': 0.8268998138461541
    },
    {
        'sentence': 'She does not establish that the proposed endeavor has substantial merit and national importance as required by the first prong of the Dhanasar framework.',
        'similarity_score': 0.821657863076972
    }],
    [{
        'sentence': 'The self-petitioner submitted copies of her educational credentials and professional development certificates.',
        'similarity_score': 0.28328108306583377
    },
    {
        'sentence': 'These credentials support she meets the requirement as a member of the professions holding an advanced degree; however, she does not establish that her educational credentials set her apart from others to show a degree of expertise significantly above that ordinarily encountered in her field.',
        'similarity_score': 0.43774142378943237
    },
    {
        'sentence': 'Qualifications possessed by most members of a given field cannot demonstrate a degree of expertise significantly above that ordinarily encountered.',
        'similarity_score': 0.48653797362779044
    },
    {
        'sentence': 'In Dhanasar, the record established that Dr. Dhanasar held multiple graduate degrees including two master of science degrees, in mechanical engineering and applied physics, as well as a Ph.D. in engineering.',
        'similarity_score': 1.0000000000000004
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    { 'sentence': 'at 891.', 'similarity_score': 1.0 },
    {
        'sentence': 'Regardless, education and training are two factors among many that may contribute to a self-petitioner being well positioned to advance their endeavor.',
        'similarity_score': 0.5150052178953017
    }],
    [{
        'sentence': 'The self-petitioner provided evidence of employment in the pharmaceutical field; however, the evidence does not demonstrate that she has a degree of expertise significantly above that ordinarily encountered in her field, only that she has work experience in the field related to her proposed endeavor.',
        'similarity_score': 0.4607810024797449
    }],
    [{
        'sentence': "The self-petitioner submitted printouts of job recruiters messaging her on an unidentified website; however, these appear to be standard employment solicitations and do not demonstrate that the self-petitioner's experience is significantly above what is ordinarily encountered in her field; how her achievements and experience have influenced her field at any level; or that she has garnered recognition in her field at any level.",
        'similarity_score': 0.40331769872582174
    },
    {
        'sentence': "The solicitations do not appear to relate to the self-petitioner's specific proposed endeavor, and do not establish that she is well positioned to advance her endeavor.",
        'similarity_score': 0.460410381758515
    }],
    [{
        'sentence': 'The self-petitioner indicated that she has started the process to transfer her credentials; however, she does not provide information about the credentialing agency or its process.',
        'similarity_score': 0.2891562004273105
    },
    {
        'sentence': 'She submitted a printout of a personal profile which does not have any identifying information related to the credentialing agency.',
        'similarity_score': 0.1927341844228299
    },
    {
        'sentence': 'It does not establish that she has taken steps to advance her proposed endeavor.',
        'similarity_score': 0.3817519056118497
    }],
    [{
        'sentence': "The self-petitioner cites her employment history as proof that she is well-positioned; however, the record does not contain evidence showing the self-petitioner has made any progress in attempting to achieve the proposed endeavor; that her experience is significantly above what is ordinarily encountered in her field; that she has received any interest from any individuals or entities in her services; or received any interest from other relevant parties sufficient to meet Dhanasar's second prong.",
        'similarity_score': 0.48372828627428216
    }],
    [{
        'sentence': 'The self-petitioner submitted a proposal statement that she intends to work as an independent chronic disease prevention and management consultant in the field of physical therapy.',
        'similarity_score': 0.2732228438934096
    },
    {
        'sentence': 'She will utilize an innovative physical therapy method that she has developed, the Physical Therapy Nutrition (PTN) Method, which integrates nutritional management and physical therapy to enhance the functionality, health, well-being, and life quality for patients, including those with chronic diseases.',
        'similarity_score': 0.1931737338563551
    },
    {
        'sentence': 'She gives only a very rudimentary overview of her PTN Method, providing no evidence of the clinical research she',
        'similarity_score': 0.16802518219837154
    }],
    [{
        'sentence': 'TSCI140TSCI14000029405800 6 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0000000000000004
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'conducted to develop this method; publication of her research in peer-reviewed, scholarly journals; or about its efficacy in treating patients.',
        'similarity_score': 0.21681140673660543
    },
    {
        'sentence': 'Although the self-petitioner submitted a copy of a case study, the document is incomplete, with only the first page is viewable.',
        'similarity_score': 0.2519756312101247
    },
    {
        'sentence': "No evidence has been provided to show the self-petitioner's work has served as an impetus for progress in her field, that it has affected the industry or occupation in some way outside of any benefit to her patients, or that it has generated positive discourse in the community.",
        'similarity_score': 0.7663794602185986
    }],
    [{
        'sentence': 'The self-petitioner submitted multiple letters of support; however, none of the letters address her specific proposed endeavor.',
        'similarity_score': 0.41634380126852033
    },
    {
        'sentence': "None of the letters directly address the self-petitioner's development of the PTN Method, or that it has served as an impetus for progress in her field, that it has affected the industry or occupation in some way outside of any benefit to her patients, or that it has generated positive discourse in the community.",
        'similarity_score': 0.7266579990538344
    },
    {
        'sentence': "The letters do not demonstrate that the self-petitioner's experience is significantly above what is ordinarily encountered in her field; how her achievements and experience have influenced her field at any level; or that she has garnered recognition in his field at any level.",
        'similarity_score': 0.4661134132914282
    },
    {
        'sentence': 'No information is provided about what steps the self-petitioner has taken to advance her proposed endeavor, or that her proposed endeavor has garnered interest from investors, U.S. governmental and non-governmental entities, or any other entity.',
        'similarity_score': 0.3533365361445731
    },
    {
        'sentence': 'These letters do not establish that the self-petitioner is well positioned to advance her proposed endeavor.',
        'similarity_score': 0.611509779540389
    }],
    [{
        'sentence': 'The self-petitioner submitted printouts, articles, and reports about different topics in general (e.g., chronic diseases, and their prevention and cost; adult obesity; nutrition and physical therapy; physical therapist occupational outlook).',
        'similarity_score': 0.20319853052982628
    },
    {
        'sentence': 'None of these articles mention the self-petitioner or her proposed endeavor.',
        'similarity_score': 0.26665411965819225
    },
    {
        'sentence': "They do not demonstrate that the self-petitioner's experience is significantly above what is ordinarily encountered in her field; how her achievements and experience have influenced her field at any level; or that she has garnered recognition in her field at any level.",
        'similarity_score': 0.4554679108768771
    },
    {
        'sentence': 'No information is provided about what steps the self-petitioner has taken to advance her proposed endeavor, or that her proposed endeavor has garnered interest from investors, U.S. governmental and non-governmental entities, or any other entity.',
        'similarity_score': 0.35329346935875516
    },
    {
        'sentence': 'As stated above; they are not probative.',
        'similarity_score': 0.33588331930469806
    }],
    [{
        'sentence': 'The self-petitioner provided no evidence of investors, such as venture capital firms or start-up accelerators; no letters from government agencies or non-governmental entities in the United States demonstrating interest or that she is well positioned to advance her proposed endeavor; no indications of relevant monetary and/or non-monetary support from United States government agencies or non-governmental entities; and no evidence showing interest from prospective employers or clients.',
        'similarity_score': 0.43058756151294475
    },
    {
        'sentence': 'Although the self-petitioner indicates she will collaborate with entities such as the National Institute of Health and the Biomedical Advanced Research and Development Authority, she has provided no evidence of interest from these or any other entity.',
        'similarity_score': 0.2834186959805267
    }],
    [{
        'sentence': 'The record does not reflect that the self-petitioner made any progress in attempting to achieve the proposed endeavor.',
        'similarity_score': 0.7657552327828473
    },
    {
        'sentence': 'While the self-petitioner did submit a basic proposal statement, she does not provide details about how she will pursue his specific proposed endeavor.',
        'similarity_score': 0.36277161856254875
    },
    {
        'sentence': 'The record lacks evidence of any sorts of communications with potential employers, businesses, or individuals for her to provide his services.',
        'similarity_score': 0.4500818998185827
    }],
    [{
        'sentence': "Although the self-petitioner's work constitutes a track record of success and/or progressive experience, a track record of success is one factor among many that may contribute to finding her well positioned to advance her proposed endeavor.",
        'similarity_score': 0.4904826059402129
    },
    {
        'sentence': 'The record does not reflect that the self-petitioner has made any progress to advance her proposed endeavor; that she has received any investments or interest from any individuals or entities in her services; or that she is otherwise well positioned to advance the proposed endeavor.',
        'similarity_score': 0.47364639400660935
    },
    {
        'sentence': "The evidence does not establish that the self-petitioner's proposed endeavor has both substantial merit and national importance.",
        'similarity_score': 0.7504770887560466
    }],
    [{
        'sentence': 'TSCI140TSCI14000029405800 7 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0000000000000004
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Notably, not every individual who possesses the necessary educational and professional credentials, skills, training, and experience will be found to be well positioned to advance the proposed endeavor.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'The self-petitioner must go beyond showing expertise in a particular field.',
        'similarity_score': 0.9644710961541206
    },
    {
        'sentence': 'Regardless of whether an individual seeks classification as an individual of exceptional ability, or as a member of the professions holding an advanced degree, he or she cannot qualify for a waiver just by demonstrating a degree of expertise significantly above that ordinarily encountered in the field of expertise.',
        'similarity_score': 0.9937991290253354
    },
    {
        'sentence': 'See Dhanasar, 26 I&N Dec. at 886 n.3.',
        'similarity_score': 0.9999999999999998
    }],
    [{
        'sentence': 'Please submit evidence to establish that the self-petitioner is well positioned to advance the proposed endeavor.',
        'similarity_score': 0.9275733649588566
    }],
    [{
        'sentence': 'Evidence which best establishes that the self-petitioner is well positioned to advance the proposed endeavor will document the self-petitioner’s qualifications (skills, experience and track record), support (financial and otherwise) and commitment (plans and progress) to drive the endeavor forward, and will support projections of future work in the proposed endeavor.',
        'similarity_score': 0.9732975782992481
    },
    {
        'sentence': 'USCIS may consider factors including, but not limited to, the following:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': "The self-petitioner's education, skills, knowledge, and record of success in related or similar efforts: To show a self-petitioner’s education, skills, knowledge, and record of success in related or similar efforts, the petitioner may submit one or more pieces of evidence from the following non-exhaustive list:",
        'similarity_score': 0.9413700269781066
    }],
    [{
        'sentence': "Degrees, certificates, or licenses in the field; Patents, trademarks, or copyrights owned by the self-petitioner; Letters from experts in the self-petitioner’s field, describing the self-petitioner's past achievements and providing specific examples of how the self-petitioner is well positioned to advance his or her endeavor.",
        'similarity_score': 0.7575997826536909
    },
    {
        'sentence': "Testimonial letters should include information about the expert’s own credentials, such as a curriculum vitae; Published articles and/or media reports about the self-petitioner’s achievements or current work; Documentation demonstrating a strong citation history; Evidence that the self-petitioner’s work has influenced his or her field of endeavor; Evidence demonstrating the self-petitioner has a leading, critical or indispensable role in the endeavor or similar endeavors; and Evidence showing that the self-petitioner's past inventions or innovations have been used or licensed by others in the field.",
        'similarity_score': 0.9261306147948107
    },
    {
        'sentence': 'A model or plan for future activities: To show a model or plan for future activities, the petitioner may submit one or more pieces of evidence from the following non-exhaustive list:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'A plan describing how the self-petitioner intends to continue his or her work in the United States; A detailed business model, when appropriate; Correspondence from prospective/potential employers, clients or customers; and Documentation reflecting feasible plans for financial support.',
        'similarity_score': 0.9814830699397228
    },
    {
        'sentence': 'Any progress towards achieving the proposed endeavor: To show progress towards achieving the proposed endeavor, a petitioner may submit one or more pieces of evidence from the following non-exhaustive list:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'TSCI140TSCI14000029405800 8 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0000000000000004
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Evidence of grants the self-petitioner has received listing the amount and terms of the grants, as well as the grantees; Copies of contracts, agreements, or licenses resulting from the proposed endeavor or otherwise demonstrating the self-petitioner is well positioned to advance the proposed endeavor; Evidence of achievements that the self-petitioner intends to build upon or further develop (including the types of documentation listed under “self-petitioner’s education, skills, knowledge, and record of success in related or similar efforts”); and Evidence demonstrating the self-petitioner has a leading, critical or indispensable role in the endeavor.',
        'similarity_score': 0.8997147052256702
    },
    {
        'sentence': 'The interest of potential customers, users, investors, or other relevant entities or individuals: To show interest of potential customers, investors, or other relevant individuals, a petitioner may submit one or more pieces of evidence from the following non-exhaustive list:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Letters from a government entity demonstrating its interest in the proposed endeavor; Evidence that the self-petitioner has received investment from U.S. investors, such as venture capital firms, angel investors, or start-up accelerators, in amounts that are appropriate to the relevant endeavor; Evidence that the self-petitioner has received awards, grants, or other indications of relevant non-monetary support (for e.g., using facilities free of charge, etc.)',
        'similarity_score': 0.9634315413075464
    },
    {
        'sentence': 'from Federal, State, or local government entities with authority over the field of endeavor; Evidence demonstrating how the self-petitioner’s work is being used by others, such as: Contracts with companies using products, projects, or services that the self-petitioner developed or assisted in developing; Documents showing licensed technology or other procedural or technological advancements developed in whole or in part by the self-petitioner and relied upon by others; and Patents or licenses awarded to the self-petitioner with documentation showing why the particular patent or license is significant to the field.',
        'similarity_score': 0.9470794547157757
    },
    {
        'sentence': 'Other evidence that the self-petitioner is well-positioned to advance the endeavor.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Note: The self-petitioner may be well positioned to advance the endeavor even if there is no certainty that the proposed endeavor will be a success.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'However, unsubstantiated claims are insufficient and would not meet the petitioner’s burden of proof.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'On Balance, it Would be Beneficial to the United States to Waive the Requirements of a Job Offer and Thus of a Labor Certification',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'The self-petitioner has not established that it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'She did not establish that the proposed endeavor has national importance or that she is well positioned to advance her proposed endeavor, as required by the first and second prongs of the Dhanasar framework.',
        'similarity_score': 0.7332401961381707
    }],
    [{
        'sentence': 'The self-petitioner failed to submit evidence that her knowledge or skills are not easily articulated in a labor certification.',
        'similarity_score': 0.9618468286783645
    },
    {
        'sentence': 'She has not demonstrated, as claimed, that she presents benefits to the United States through her proposed endeavor that outweigh those inherent in the labor certification process.',
        'similarity_score': 0.9236612498742534
    },
    {
        'sentence': 'In TSCI140TSCI14000029405800 9 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 0.9906064890140812
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'through her proposed endeavor that outweigh those inherent in the labor certification process.',
        'similarity_score': 0.750945383201714
    },
    {
        'sentence': 'In addition, the self-petitioner has not shown that her proposed endeavor offers contributions of such value that, overall, they would benefit the nation even if other qualified U.S. workers were available.',
        'similarity_score': 0.9261568272051768
    }],
    [{
        'sentence': 'Please submit evidence to establish that, on balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'This balance was described in Dhanasar as on one hand protecting the domestic labor supply through the creation of the labor certification process, while on the other hand recognizing that in certain cases the benefits inherent in the labor certification process can be outweighed by other factors that are also deemed to be in the national interest.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'USCIS may evaluate factors including, but not limited to, the following:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Whether, in light of the nature of the self-petitioner’s qualifications or proposed endeavor, it would be impractical either for the self-petitioner to secure a job offer or for the petitioner to obtain a labor certification; Whether, even assuming that other qualified U.S. workers are available, the United States would still benefit from the self-petitioner’s contributions; Whether the national interest in the self-petitioner’s contributions is sufficiently urgent to warrant forgoing the labor certification process; Whether the self-petitioner’s endeavor may lead to potential creation of jobs; and Whether the self-petitioner is self-employed in a manner that generally does not adversely affect U.S. workers.',
        'similarity_score': 0.8780041573917283
    }],
    [{
        'sentence': 'PLACE THE ATTACHED COVERSHEET AND THIS ENTIRE LETTER ON TOP OF YOUR RESPONSE.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'SUBMISSION OF EVIDENCE WITHOUT THE COVERSHEET AND THIS LETTER WILL DELAY PROCESSING OF YOUR CASE AND MAY RESULT IN A DENIAL.',
        'similarity_score': 1.0000000000000002
    }],
    [{ 'sentence': 'Sincerely,', 'similarity_score': 1.0 }],
    [{
        'sentence': 'Mary Elizabeth Brennan Seng Director, Texas Service Center Officer: XM2534',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'TSCI140TSCI14000029405800 10 of 11 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0000000000000004
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0
    }],
    [{ 'sentence': 'Officer: XM2534', 'similarity_score': 1.0000000000000002 }],
    [{
        'sentence': 'COVERSHEET PLEASE RETURN THE REQUESTED INFORMATION AND ALL SUPPORTING DOCUMENTS WITH THIS PAGE ON TOP TO: USCIS/TSC ATTN: RFE/NOID/NOIR/NOIT 6046 N BELT LINE RD STE 172 IRVING, TX 75038-0015',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Please check the appropriate box regarding if there is a new Form G-28, Notice of Entry of Appearance as Attorney or Accredited Representative, additional fees, additional forms, etc.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Please place the new Form G-28, additional fees, additional forms directly under this sheet.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Yes, there is: A New G-28 Additional Fees',
        'similarity_score': 1.0
    }],
    [{ 'sentence': 'Additional Forms Other:', 'similarity_score': 1.0 }],
    [{
        'sentence': 'If you have moved, write your current address in the blank area below.',
        'similarity_score': 1.0000000000000002
    },
    { 'sentence': 'Please be sure to write clearly.', 'similarity_score': 1.0 }],
    [{
        'sentence': '(Select appropriate check box) Applicant/Beneficiary Petitioner',
        'similarity_score': 1.0
    }],
    [{ 'sentence': 'New Address:', 'similarity_score': 1.0000000000000002 }],
    [{
        'sentence': 'As required by Section § 265.1 Reporting change of address.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': '“Except for those exempted by section 263(b) of the Act, all aliens in the United States required to register under section 262 of the Act must report each change of address and new address within 10 days of such change in accordance with instructions provided by USCIS.”',
        'similarity_score': 1.0000000000000002
    }],
    [{ 'sentence': 'REQUEST FOR EVIDENCE', 'similarity_score': 1.0 }],
    [{
        'sentence': '140 Immigrant Petition for Alien Worker',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'DIAS PINHEIRO DA CAMARA, ANA CECILIA IOE0923735872 DIAS PINHEIRO DA CAMARA, ANA CECILIA',
        'similarity_score': 0.1364135063496021
    }],
    [{ 'sentence': '11 of 11', 'similarity_score': 1.0 }],
    [{
        'sentence': 'TSCI140TSCI14000029405800 TSC Routing Sheet Suite 172 ECHO - Enterprise Correspondence Handling Online',
        'similarity_score': 1.0
    }]];

    const sentences1 = [[{
        'sentence': 'U.S. Department of Homeland Security U.S.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Citizenship and Immigration Services January 3, 2024 P.O.',
        'similarity_score': 0.4998768863595182
    },
    {
        'sentence': 'Box 82521 Lincoln, NE 68501-2521',
        'similarity_score': 0.19769377676504202
    }],
    [{
        'sentence': 'DANIEL MARTINS DE OLIVEIRA c/o DANIEL MARTINS DE OLIVEIRA',
        'similarity_score': 0.17702702541141638
    }],
    [{
        'sentence': 'RE: DANIEL MARTINS DE OLIVEIRA I- 0, Immigrant Petition for Alien Worker',
        'similarity_score': 0.649659067122118
    }],
    [{ 'sentence': 'REQUEST FOR EVIDENCE', 'similarity_score': 1.0 }],
    [{
        'sentence': '**PREMIUM PROCESSING**',
        'similarity_score': 0.20716391829332337
    }],
    [{
        'sentence': 'IMPORTANT: THIS NOTICE CONTAINS YOUR UNIQUE NUMBER.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'THE ORIGINAL NOTICE MUST BE SUBMITTED WITH THE REQUESTED EVIDENCE.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'You are receiving this notice because U.S.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Citizenship and Immigration Services (USCIS) requires additional evidence to process your form.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Please provide the evidence listed on the attached page(s).',
        'similarity_score': 0.3512795882382196
    },
    {
        'sentence': 'Include duplicate copies if you are requesting consular notification.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Your response must be received in this office by March 30, 2024.',
        'similarity_score': 0.8155679140974499
    }],
    [{
        'sentence': 'Please note that you have been allotted the maximum period allowed for responding to an RFE.',
        'similarity_score': 0.93846062141967
    },
    {
        'sentence': 'The time period for responding cannot be extended.',
        'similarity_score': 1.0
    },
    {
        'sentence': '8 Code of Federal Regulations (8 CFR) 103.2(b)(8)(iv).',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Because many immigration benefits are time sensitive, you are encouraged to respond to this request as early as possible, but no later than the deadline provided above.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'If you do not respond to this notice within the allotted time, your case may be denied.',
        'similarity_score': 0.9999999999999999
    },
    {
        'sentence': 'The regulations do not provide for an extension of time to submit the requested evidence.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'You must submit all requested evidence at the same time.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'If you submit only some of the requested evidence, USCIS will consider your response a request for a decision on the record.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'See 8 CFR 103.2(b)(11).',
        'similarity_score': 0.7387631700628021
    }],
    [{
        'sentence': 'If you submit a document in any language other than English, the document must be accompanied by a full and complete English translation.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'The translator must certify that the translation is accurate and he or she is competent to translate from that language to English.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'If you submit a foreign language translation in response to this request for evidence, you must also include a copy of the foreign language document.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Processing of your I-140 will resume upon receipt of your response.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'If you have not heard from USCIS within 20 days of responding, you may contact the USCIS Contact Center at 1-866-315-5718.',
        'similarity_score': 0.9667103230668118
    },
    {
        'sentence': 'If you are hearing impaired, please call the USCIS Contact Center TDD at 1-800-767-1833.',
        'similarity_score': 0.8134888923032262
    }],
    [{
        'sentence': 'NSCI140NSCI14000027692213 (COURTESY COPY) 1 of 12 www.uscis.gov',
        'similarity_score': 0.2944245659383932
    }],
    [{
        'sentence': 'USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 0.8508412599226838
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Reference is made to this Form I-140, Immigrant Petition for Alien Worker, seeking classification as a member of the professions holding an advanced degree and an exemption from the requirement of a job offer, and thus of a labor certification, in the national interest of the United States for DANIEL MARTINS DE OLIVEIRA (petitioner and beneficiary), filed on his/her own behalf.',
        'similarity_score': 0.9073648640197629
    },
    {
        'sentence': 'The priority date for this petition is December 14, 2023.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The beneficiary intends to work as an Information Technology Specialist.',
        'similarity_score': 0.554149156149463
    }],
    [{
        'sentence': 'In order to establish eligibility, the petitioner must establish that:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The beneficiary qualifies for the requested classification; and An exemption from the requirement of a job offer, and thus of a labor certification, is in the national interest of the United States.',
        'similarity_score': 1.0000000000000004
    }],
    [{
        'sentence': 'USCIS has designated Matter of Dhanasar, 26 I&N Dec. 884 (AAO 2016) (“Dhanasar”) as a precedent decision.',
        'similarity_score': 1.0000000000000004
    },
    {
        'sentence': 'That decision rescinded the earlier precedent decision, Matter of New York State Dep’t of Transp.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': '("NYSDOT"), 22 I&N Dec. 215 (Acting Assoc.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Comm’r 1998), regarding national interest waivers under Section 203(b)(2)(B)(i) of the Immigration and Nationality Act, and introduced a new three-prong test for determining eligibility.',
        'similarity_score': 0.9999999999999999
    },
    {
        'sentence': 'Under Dhanasar, USCIS may grant a national interest waiver as a matter of discretion if the petitioner demonstrates by a preponderance of the evidence that:',
        'similarity_score': 1.0000000000000004
    }],
    [{
        'sentence': 'The beneficiary’s proposed endeavor has both substantial merit and national importance; The beneficiary is well positioned to advance the proposed endeavor; and On balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'If these three elements are satisfied, USCIS may approve the national interest waiver as a matter of discretion.',
        'similarity_score': 1.0
    }],
    [{ 'sentence': 'E21 Exceptional Ability', 'similarity_score': 1.0 }],
    [{
        'sentence': 'The E21 immigrant classification applies to individuals who are members of the professions holding advanced degrees or individuals of exceptional ability in the sciences, arts or business.',
        'similarity_score': 0.38739079130711007
    },
    {
        'sentence': 'When seeking consideration as an individual of exceptional ability, it must be demonstrated that the individual possesses a degree of expertise significantly above that ordinarily encountered in the sciences, arts or business.',
        'similarity_score': 0.6931752118536703
    }],
    [{
        'sentence': 'A two-part analysis is used to determine whether the beneficiary has exceptional ability in the sciences, arts, or business.',
        'similarity_score': 0.9645230024170831
    }],
    [{
        'sentence': 'First, we determine whether the petitioner has submitted evidence to qualify under at least three of the criteria required for this classification.',
        'similarity_score': 0.9162216590821299
    },
    {
        'sentence': 'Second, we determine whether the petitioner has submitted evidence demonstrating that the beneficiary possesses a degree of expertise significantly above that ordinarily encountered in the sciences, arts, or business.',
        'similarity_score': 0.9491426958810063
    }],
    [{
        'sentence': 'All evidence submitted should address both parts of the analysis.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'A discussion follows addressing the evidence you have provided with the petition.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': '(i) An official academic record showing that the beneficiary has a degree, diploma, certificate, or similar award from a college, university, school, or other institution of learning relating to the area of',
        'similarity_score': 0.9596284810017296
    }],
    [{
        'sentence': 'NSCI140NSCI14000027692213 (COURTESY COPY) 2 of 12 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 0.7394600786941635
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{ 'sentence': 'exceptional ability;', 'similarity_score': 1.0 }],
    [{
        'sentence': 'The petitioner has provided a copy of his Bachelor of Computer Science degree from Universidade Paulista conferred on August 6, 2009.',
        'similarity_score': 0.25979739656838946
    }],
    [{
        'sentence': "In order to assess the equivalency of the beneficiary's foreign degree, USCIS has also reviewed the Electronic Database for Global Education (EDGE) database (www.aacrao.org/edge) on January 2, 2024.",
        'similarity_score': 0.35173388565484964
    },
    {
        'sentence': 'EDGE was created by the American Association of Collegiate Registrars and Admissions Officers (AACRAO).',
        'similarity_score': 0.6425751348952286
    },
    {
        'sentence': 'AACRAO’s mission is to advance and support a richly diverse, globally interconnected community of higher education professionals as well as the institutions, organizations, and learners they serve through its resources, leadership, and advocacy.',
        'similarity_score': 0.19400404444557995
    },
    {
        'sentence': 'AACRAO EDGE utilizes the expertise of theInternational Education Standards Council (IESC) to review and update their recommendations.',
        'similarity_score': 0.17097802053035047
    },
    {
        'sentence': "The Council is composed of professionals who have worked in higher education as well as private credential evaluation firms, to instill confidence that the recommendations presented are appropriate, thorough, and representative of the varied viewpoints held within higher education.https://www.aacrao.org/resources/AACRAO-International/about-edge In the section related to the Brazil educational system, EDGE provides that the 4- or 5-year Título de Bacharel/Grau de Bacharel represents attainment of a level of education comparable to a bachelor's degree in the United States.",
        'similarity_score': 0.39877688205108436
    },
    {
        'sentence': 'Therefore, this criterion has been met.',
        'similarity_score': 0.841226623030759
    }],
    [{
        'sentence': '(ii) Evidence in the form of letter(s) from current or former employer(s) showing that the beneficiary has at least ten years of full-time experience in the occupation for which he or she is being sought;',
        'similarity_score': 0.9362356722394498
    }],
    [{
        'sentence': 'The petitioner has also provided the following:',
        'similarity_score': 0.6539037903132953
    }],
    [{
        'sentence': 'Work letter from Via Appia Consultoria e Treinamento Empresarial with employment dates of May 16, 2022 to present; Work letter from Lear do Brasil Industria e Comercio de Interiores Automotivos with employment dates of September 2, 2019 to May 13, 2022; Work certificate letter from RK2 Tecnologia Da Informacao with employment dates November 9, 2015 to August 31, 2019; Work letter from Imagem Sistemas de Informacao LTDA with employment dates of July 1, 2011 to August 4, 2015; and Work certificate letter from Sergio Fujimoto stating the petitioner had a work contract from July 31, 2010 to June 30, 2011;',
        'similarity_score': 0.23806919456427716
    }],
    [{
        'sentence': 'The petitioner submitted a copy of various work certificates and accompanying translation to satisfy this requirement.',
        'similarity_score': 0.2983188246272461
    },
    {
        'sentence': 'The plain language of the regulation, however, requires letter(s) which 1) are from current or former employers and 2) establish ten years of full-time experience in the occupation (emphasis added).',
        'similarity_score': 0.5468427262330317
    },
    {
        'sentence': 'Not only has the petitioner not demonstrated that the various work certificates is the equivalent of a letter from a current or former employer, but it also does not establish at least ten years of full-time experience.',
        'similarity_score': 0.4834603627274902
    }],
    [{
        'sentence': '(iii) A license to practice the profession or certification for a particular profession or occupation;',
        'similarity_score': 0.92614926641469
    }],
    [{
        'sentence': 'No license was provided.',
        'similarity_score': 0.3577461424617124
    },
    {
        'sentence': 'Therefore, this criterion has not been met.',
        'similarity_score': 0.8184756419913772
    }],
    [{
        'sentence': '(iv) Evidence that the beneficiary has commanded a salary, or other remuneration for services, which demonstrates exceptional ability;',
        'similarity_score': 0.9186498186441688
    }],
    [{
        'sentence': 'The petitioner has provided:',
        'similarity_score': 0.8861120100834537
    }],
    [{
        'sentence': 'NSCI140NSCI14000027692213 (COURTESY COPY) 3 of 12 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 0.7394600786941635
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'A letter from Via Appia Consultoria e Treinamento Empresarial showing the petitioner receives a monthly compensation of BRL 17,000 for his work; The petitioner provided a letter from Sergio Fujimoto, Accountant, showing a yearly income from 2019 to 2023.',
        'similarity_score': 0.27236122128060214
    }],
    [{ 'sentence': 'The regulation at 8 C.F.R.', 'similarity_score': 1.0 },
    {
        'sentence': '§ 204.5(k)(3)(ii)(D) requires "[e]vidence that the alien has commanded a salary, or other remuneration for services, which demonstrates exceptional ability."',
        'similarity_score': 0.8292038653789812
    },
    {
        'sentence': 'While the evidence indicates that he earned a salary he did not establish that he commanded a salary "which demonstrates exceptional ability" consistent with this regulatory criterion.',
        'similarity_score': 0.49434284832964703
    },
    {
        'sentence': 'While the petitioner, for instance, offered comparative wage information to show the significance of his earnings, such that he commands a salary commensurate with exceptional ability.',
        'similarity_score': 0.28683019844803187
    },
    {
        'sentence': 'Without further evidence, simply submitting evidence of remuneration from an employer is insufficient to meet this criterion.',
        'similarity_score': 0.3348374524229457
    }],
    [{
        'sentence': '(v) Evidence of membership in professional associations;',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'The petitioner has provided a copy of membership to the Association of Information Technology Professionals and Companies along with a printout of the organization.',
        'similarity_score': 0.2912971988018353
    },
    {
        'sentence': 'The petitioner did not provide further evidence to establish that this certificate represents membership in a professional association, nor what the requirements are to gain membership.',
        'similarity_score': 0.41786769088374254
    },
    {
        'sentence': 'Therefore, this criterion has not been met.',
        'similarity_score': 0.8185901377107555
    }],
    [{
        'sentence': '(vi) Evidence of recognition for achievements and significant contributions to the industry or field by peers, governmental entities, or professional or business organizations.',
        'similarity_score': 0.9357528589527188
    }],
    [{
        'sentence': 'The petitioner has provided documents about media recognition, various certificates and letters of support.',
        'similarity_score': 0.40225456265689563
    }],
    [{
        'sentence': 'The Petitioner submitted copies of awards, multiple letters of support, an "expert opinion letter," and articles in support of this criterion.',
        'similarity_score': 0.486556955739325
    }],
    [{
        'sentence': 'USCIS notes the letters of support, while speaking highly of the petitioner as an employee and professional, do not describe recognition for achievements or significant contributions to his industry.',
        'similarity_score': 0.3871269002406406
    },
    {
        'sentence': 'The same is true of the "expert opinion letter."',
        'similarity_score': 0.6358986357399345
    },
    {
        'sentence': 'The articles demonstrate some news coverage of the petitioner where he was employed, but the articles do not describe the petitioner being recognized for achievements or significant contributions to the industry.',
        'similarity_score': 0.4393845112061343
    },
    {
        'sentence': 'The petitioner has not established this criterion.',
        'similarity_score': 0.48570875301519145
    }],
    [{
        'sentence': 'If you are submitting comparable evidence:',
        'similarity_score': 0.5688731354970893
    }],
    [{
        'sentence': 'Submit evidence to establish that the six criteria do not apply to the beneficiary’s field of endeavor; or, The reasons the required evidence is not available.',
        'similarity_score': 0.9605176898757015
    }],
    [{
        'sentence': 'As discussed above, the petitioner has not met at least three of the criteria.',
        'similarity_score': 0.9589605096550211
    },
    {
        'sentence': 'As such, USCIS is affording the petitioner the opportunity to submit additional evidence to establish that the petitioner meets the regulatory criteria.',
        'similarity_score': 0.409585074293898
    },
    {
        'sentence': 'The response to this request should address the insufficiencies articulated by USCIS in this request.',
        'similarity_score': 0.37331490435892045
    }],
    [{
        'sentence': 'Additionally, meeting the minimum regulatory criteria outlined above, alone will not establish eligibility for the E21 immigrant classification.',
        'similarity_score': 0.294984797907632
    },
    {
        'sentence': 'Any evidence submitted in response to this request, should also articulate how the evidence establishes that the beneficiary possesses the required level of expertise for the E21 immigrant classification.',
        'similarity_score': 0.30675699253568156
    }],
    [{
        'sentence': 'NSCI140NSCI14000027692213 (COURTESY COPY) 4 of 12 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 0.7394600786941635
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Establishing eligibility for the level of expertise required for the E21 immigrant classification is based on the beneficiary possessing a degree of expertise significantly above that ordinarily encountered in the sciences, arts, or business.',
        'similarity_score': 0.6758462138272489
    }],
    [{
        'sentence': 'When ultimately making a final decision regarding eligibility, we will:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': '- First evaluate the evidence on an individual basis to determine if it meets the criteria, as discussed in part one; then,',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': '- Consider all of the evidence in totality in making the final merits determination.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'The final merits determination will determine if the beneficiary possesses a degree of expertise significantly above that ordinarily encountered in the sciences, arts, or business.',
        'similarity_score': 0.9749653296243717
    }],
    [{
        'sentence': 'You may submit any available additional documentary evidence and/or further documentary evidence to demonstrate how the record shows the beneficiary meets the regulatory criteria as discussed above.',
        'similarity_score': 0.9784723827167672
    }],
    [{ 'sentence': 'Please note, the evidence must:', 'similarity_score': 1.0 }],
    [{
        'sentence': 'Have been in existence as of the date the petition was filed; and Demonstrate that the beneficiary possesses a degree of expertise significantly above that ordinarily encountered in the sciences, arts, or business.',
        'similarity_score': 0.7766160594396627
    }],
    [{
        'sentence': 'This is the petitioner’s opportunity to meet their legal burden of proof to establish eligibility in all respects.',
        'similarity_score': 0.3278519100969889
    },
    {
        'sentence': 'Whenever any person makes an application for an immigration benefit, they shall bear the burden of proof to establish eligibility for the benefit sought.',
        'similarity_score': 0.3003001079470671
    },
    {
        'sentence': 'Therefore, the petitioner must prove, by a preponderance of the evidence, in other words, that it is more likely than not, that the beneficiary is fully qualified for the benefit sought.',
        'similarity_score': 0.29572193290869153
    },
    {
        'sentence': 'Accordingly, the decision will be based on the initial evidence submitted upon filing and all additional evidence submitted in response to this request.',
        'similarity_score': 0.31780815511646865
    }],
    [{ 'sentence': 'E21 Advanced Degree', 'similarity_score': 1.0 }],
    [{
        'sentence': 'To establish that the beneficiary is a professional holding an advanced degree, you must submit:',
        'similarity_score': 0.8720144696948146
    }],
    [{
        'sentence': 'An official academic record showing that the beneficiary has a U.S. advanced degree (or a foreign equivalent degree); or An official academic record showing that the beneficiary has a U.S. baccalaureate degree (or a foreign equivalent degree), and evidence in the form of letters from current or former employer(s) showing that the beneficiary has at least five years of progressive post-baccalaureate experience in the specialty.',
        'similarity_score': 0.9143657916820951
    }],
    [{
        'sentence': 'The petitioner has provided a copy of his Bachelor of Computer Science degree from Universidade Paulista conferred on August 6, 2009.',
        'similarity_score': 0.25979739656838946
    }],
    [{
        'sentence': "In order to assess the equivalency of the beneficiary's foreign degree, USCIS has also reviewed the Electronic Database for Global Education (EDGE) database (www.aacrao.org/edge) on January 2, 2024.",
        'similarity_score': 0.3519968739482024
    },
    {
        'sentence': 'EDGE was created by the American Association of Collegiate Registrars and Admissions Officers (AACRAO).',
        'similarity_score': 0.6420281825536643
    },
    {
        'sentence': 'AACRAO’s mission is to advance and support a richly diverse, globally interconnected community of higher education professionals as well as the institutions, organizations, and learners they serve through its resources, leadership, and advocacy.',
        'similarity_score': 0.1938629246756603
    },
    {
        'sentence': 'AACRAO EDGE utilizes the expertise of theInternational Education Standards Council (IESC) to review and update their recommendations.',
        'similarity_score': 0.17015825249952624
    },
    {
        'sentence': 'The Council is composed of professionals who have worked in higher education as well as private credential evaluation firms, to instill confidence that the recommendations presented',
        'similarity_score': 0.19979360926512996
    }],
    [{
        'sentence': 'NSCI140NSCI14000027692213 (COURTESY COPY) 5 of 12 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 0.7394600786941635
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': "are appropriate, thorough, and representative of the varied viewpoints held within higher education.https://www.aacrao.org/resources/AACRAO-International/about-edge In the section related to the Brazil educational system, EDGE provides that the 4- or 5-year Título de Bacharel/Grau de Bacharel represents attainment of a level of education comparable to a bachelor's degree in the United States.",
        'similarity_score': 0.4619215111000472
    }],
    [{
        'sentence': 'The petitioner has also provided the following:',
        'similarity_score': 0.6539037903132953
    }],
    [{
        'sentence': 'Work letter from Via Appia Consultoria e Treinamento Empresarial with employment dates of May 16, 2022 to present; Work letter from Lear do Brasil Industria e Comercio de Interiores Automotivos with employment dates of September 2, 2019 to May 13, 2022; Work certificate letter from RK2 Tecnologia Da Informacao with employment dates November 9, 2015 to August 31, 2019; Work letter from Imagem Sistemas de Informacao LTDA with employment dates of July 1, 2011 to August 4, 2015; and Work certificate letter from Sergio Fujimoto stating the petitioner had a work contract from July 31, 2010 to June 30, 2011;',
        'similarity_score': 0.23806919456427716
    }],
    [{
        'sentence': 'The petitioner submitted a copy of various work letters and accompanying translation to satisfy this requirement.',
        'similarity_score': 0.3067783344527244
    },
    {
        'sentence': 'The petitioner at least five years of full-time, post-baccalaureate experience in the specialty.',
        'similarity_score': 0.4817270207885991
    },
    {
        'sentence': 'Therefore, the petitioner qualifies for the E21 visa classification.',
        'similarity_score': 0.6031349639748812
    }],
    [{ 'sentence': 'National Interest Waiver', 'similarity_score': 1.0 }],
    [{
        'sentence': 'USCIS may grant a national interest waiver as a matter of discretion if the petitioner demonstrates by a preponderance of the evidence that:',
        'similarity_score': 0.9463226491209823
    }],
    [{
        'sentence': 'The beneficiary’s proposed endeavor has both substantial merit and national importance; The beneficiary is well positioned to advance the proposed endeavor; and On balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'If these three elements are satisfied, USCIS may approve the national interest waiver as a matter of discretion.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Dhanasar Prong 1 - Substantial Merit and National Importance',
        'similarity_score': 0.803559250657693
    }],
    [{
        'sentence': 'The petitioner has provided the following:',
        'similarity_score': 0.7417164723142661
    }],
    [{
        'sentence': "Petitioner's resume; Petitioner's professional plan; and Expert opinion letter;",
        'similarity_score': 0.5796146423033607
    }],
    [{
        'sentence': 'The first prong, substantial merit and national importance, focuses on the specific endeavor that the noncitizen proposes to undertake.',
        'similarity_score': 0.8629653350657812
    },
    {
        'sentence': "The endeavor's merit may be demonstrated in a range of areas such as business, entrepreneurialism, science, technology, culture, health, or education.",
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'In determining whether the proposed endeavor has national importance, we consider its potential prospective impact.',
        'similarity_score': 0.7248710250082293
    }],
    [{
        'sentence': 'To satisfy the first prong under the Dhanasar analytical framework, the petitioner must demonstrate that their proposed endeavor has both substantial merit and national importance.',
        'similarity_score': 0.559903823249908
    },
    {
        'sentence': 'The first prong focuses on the specific endeavor that the individual proposes to undertake.',
        'similarity_score': 0.7900498521641316
    },
    {
        'sentence': "As stated above, the endeavor's merit may be demonstrated in a range of areas such as business, entrepreneurialism,",
        'similarity_score': 0.705310018095657
    }],
    [{
        'sentence': 'NSCI140NSCI14000027692213 (COURTESY COPY) 6 of 12 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 0.7394600786941635
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'science, technology, culture, health, or education.',
        'similarity_score': 0.6143673892633744
    },
    {
        'sentence': "The record, which contains the petitioner's initial statement, business plan, various reports, and articles, supports the determination that the petitioner's proposed endeavor is substantially meritorious.",
        'similarity_score': 0.2773854127916484
    }],
    [{
        'sentence': 'In determining that the petitioner did not establish the national importance of the proposed endeavor, USCIS has concluded that the petitioner did not offer sufficient evidence to demonstrate that the proposed endeavor stands to have implications beyond an company and or its clientele to impact the IT field more broadly or to have substantial positive economic effects.',
        'similarity_score': 0.45492204273460857
    },
    {
        'sentence': "Although the petitioner emphasizes his expertise and experience as evidence of the endeavor's national importance, the focus of the first Dhanasar prong is on the proposed endeavor itself and not the petitioner's background.",
        'similarity_score': 0.4401347100060039
    },
    {
        'sentence': 'Similarly, although the petitioner discusses the importance of STEM (science, technology, engineering, and mathematics) professionals, in determining national importance, the focus is on the impact of what a petitioner intends to do rather than the occupational classification.',
        'similarity_score': 0.6257022497590354
    }],
    [{
        'sentence': 'In Dhanasar we concluded that STEM teaching has substantial merit in relation to U.S. educational interests, but that the petitioner had not demonstrated that the activities of one individual STEM teacher would impact the education field more broadly.',
        'similarity_score': 0.35128290087032366
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    { 'sentence': 'at 893.', 'similarity_score': 1.0 },
    {
        'sentence': 'The same is true here.',
        'similarity_score': 0.2886589197967469
    },
    {
        'sentence': 'The petitioner has not established that the benefits of his proposed endeavor will extend beyond his own clients or employees to impact the network infrastructure, servers, telecommunication, software development, quality control, database administration and software testing.',
        'similarity_score': 0.224105501299238
    }],
    [{
        'sentence': 'To evaluate whether the proposed endeavor satisfies the national importance requirement USCIS looks to evidence documenting the "potential prospective impact" of the petitioner\'s particular undertaking.',
        'similarity_score': 0.7930766668189339
    },
    {
        'sentence': 'While the petitioner asserts that the national importance of his endeavor is evident from the scale of the overall operations, he has not demonstrated that the economic implications of these operations would be attributable to his IT projects to an extent that his proposed work holds national importance.',
        'similarity_score': 0.37397280868282373
    },
    {
        'sentence': 'For example, the petitioner has not demonstrated that his proposed endeavor has significant potential to employ U.S. workers or otherwise offers substantial positive economic effects for the United States.',
        'similarity_score': 0.7317107774340009
    }],
    [{
        'sentence': 'Here, the petitioner has not shown that the wider economic effects he is claiming are implications of his specific proposed endeavor to provide network infrastructure, servers, telecommunication, software development, quality control, database administration and software testing.',
        'similarity_score': 0.2771908215310508
    },
    {
        'sentence': 'Because the petitioner has not provided sufficient information or evidence regarding any projected U.S. economic impact or job creation attributable to his future work, he has not shown that benefits to the regional or national economy resulting from his projects would reach the level of “substantial positive economic effects" contemplated by Dhanasar.',
        'similarity_score': 0.8271945737946769
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    { 'sentence': 'at 890.', 'similarity_score': 1.0 },
    {
        'sentence': "Furthermore, the issue here is not the broader implications of the petitioner's innovations in network infrastructure, servers, telecommunication, software development, quality control, database administration and software testing or the widespread utilization of his services by consumers, but rather the potential prospective impact of his specific proposed work as a engineer.",
        'similarity_score': 0.2201339974069081
    },
    {
        'sentence': "The record does not show that the petitioner's proposed endeavor stands to sufficiently extend beyond an company, future clientele, or business partnership to impact his field or the IT industry more broadly at a level commensurate with national importance.",
        'similarity_score': 0.7513971276504376
    }],
    [{
        'sentence': 'The petitioner also submits his professional plan and statement to support the national importance of his proposed endeavor.',
        'similarity_score': 0.365005309707621
    },
    {
        'sentence': "As noted, to establish national importance, the petitioner must demonstrate the proposed endeavor's impact.",
        'similarity_score': 0.6153367459692463
    },
    {
        'sentence': 'In Dhanasar, we noted that "we look for broader implications" of the proposed endeavor and that "[a] n undertaking may have national importance for example, because it has national or even global implications within a particular field."',
        'similarity_score': 0.9933782716526265
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    { 'sentence': 'at 889.', 'similarity_score': 1.0 }],
    [{
        'sentence': 'Please submit evidence to establish that the beneficiary’s proposed endeavor has national importance.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'This evidence must demonstrate the endeavor’s potential prospective impact.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'NSCI140NSCI14000027692213 (COURTESY COPY) 7 of 12 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 0.7394600786941635
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Evidence to establish that the beneficiary’s proposed endeavor has national importance consists of, but is not limited to, the following:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'A detailed description of the proposed endeavor and why it is of national importance, Documentary evidence that supports the petitioner’s statements and establishes the endeavor’s national importance.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Such evidence must demonstrate the endeavor’s potential prospective impact, and may consist of, but is not limited to, evidence showing that the proposed endeavor: Has national or even global implications within a particular field; Has significant potential to employ U.S. workers or has other substantial positive economic effects, particularly in an economically depressed area; Will broadly enhance societal welfare or cultural or artistic enrichment; and Impacts a matter that a government entity has described as having national importance or is the subject of national initiatives.',
        'similarity_score': 0.9999999999999998
    }],
    [{
        'sentence': 'Dhanasar Prong 2 - Well Positioned to Advance Proposed Endeavor',
        'similarity_score': 0.6962594967348807
    }],
    [{
        'sentence': 'The petitioner has provided the following:',
        'similarity_score': 0.7417164723142661
    }],
    [{
        'sentence': "Petitioner's resume; Petitioner's professional plan; Expert opinion letter; Educational documents; and Letters of recommendation;",
        'similarity_score': 0.4638215688283739
    }],
    [{
        'sentence': 'The second prong shifts the focus from the proposed endeavor to the foreign national.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': "To determine whether the petitioner is well positioned to advance the proposed endeavor, we consider factors including, but not limited to: the individual's education, skills, knowledge and record of success in related or similar efforts; a model or plan for future activities; any progress towards achieving the proposed endeavor; and the interest of potential customers, users, investors, or other relevant entities or individuals.",
        'similarity_score': 0.857251776632784
    },
    {
        'sentence': 'Matter of Dhanasar, 26 I&N Dec. at 890.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Thus, USCIS shifts its focus from a proposed endeavor to a petitioner.',
        'similarity_score': 0.5560787385879029
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 }],
    [{
        'sentence': 'A petitioner need not demonstrate that their endeavor would ultimately succeed.',
        'similarity_score': 0.21346699016700224
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    {
        'sentence': 'But they must establish that they are "well positioned to advance the proposed endeavor."',
        'similarity_score': 0.5793240760316365
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    {
        'sentence': 'A petitioner must also demonstrate their qualifications "at the time of filing the requested benefit and through adjudication."',
        'similarity_score': 0.29074572692975836
    },
    { 'sentence': '8 C.F.R.', 'similarity_score': 0.0 },
    { 'sentence': '§ 103.2(b)(l).', 'similarity_score': 0.0 }],
    [{
        'sentence': 'The petitioner did not establish that he has the means or record of successes to self-supported work.',
        'similarity_score': 0.3564716629834614
    },
    {
        'sentence': 'The evidence lacks relevant, probative, and credible information of his track record of success, his support (financial and otherwise) and his plans and progress to drive his endeavor forward.',
        'similarity_score': 0.4626134133784769
    },
    {
        'sentence': 'The petitioner did not provide interest from potential customers, users, investors, or other relevant entities.',
        'similarity_score': 0.7967518483419522
    },
    {
        'sentence': 'The NIW waiver is meant for those who stand out among their peers and are already being sought out for their particular expertise.',
        'similarity_score': 0.22278398527700016
    },
    {
        'sentence': 'The record of evidence does not demonstrate that the petitioner has parties interested in hiring or investing in his particular model or plan.',
        'similarity_score': 0.34600915829668566
    },
    {
        'sentence': 'Therefore, the evidence does not support projections of future work as an entrepreneur, in the United States, that would serve the national interest of the United States.',
        'similarity_score': 0.3722096116645946
    }],
    [{
        'sentence': 'It appears the petitioner has the necessary education, skills and knowledge in his endeavor.',
        'similarity_score': 0.38399735455422374
    },
    {
        'sentence': 'However, the petitioner has also not submitted any letters of interest or documentation reflecting feasible plans for financial support involving venture capital firms, angel investors, or start up accelerators in amounts appropriate to the relevant endeavor.',
        'similarity_score': 0.5416400327050404
    },
    {
        'sentence': 'The NIW waiver is not intended to grant individuals',
        'similarity_score': 0.2450740578094749
    }],
    [{
        'sentence': 'NSCI140NSCI14000027692213 (COURTESY COPY) 8 of 12 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 0.7394600786941635
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'time to conduct a search for investors, users and/or clients.',
        'similarity_score': 0.32996600776019147
    },
    {
        'sentence': 'It is meant for those who stand out among their peers and are already being sought out for their particular expertise.',
        'similarity_score': 0.23344646756219867
    },
    {
        'sentence': 'The record of evidence does not demonstrate that the petitioner has parties interested in hiring or investing in his particular model or plan.',
        'similarity_score': 0.3458154115216707
    },
    {
        'sentence': 'The level of interest in the petitioner’s endeavor remains an important part of evaluating whether or not he is well positioned to advance his endeavor.',
        'similarity_score': 0.4533367239167085
    }],
    [{
        'sentence': 'USCIS notes that the letters of recommendation submitted are from individuals who are professionally acquainted with the petitioner.',
        'similarity_score': 0.9641456933103912
    },
    {
        'sentence': 'While it is true that the authors of these letters appear to work in the same field, the record at present, does not persuasively establish that the petitioner has had a substantial impact in the field of endeavor within the United States.',
        'similarity_score': 0.8492225339436473
    }],
    [{
        'sentence': 'Many of the letters of support spoke highly of the petitioner’s character and work ethic, the letters did not provide sufficient details regarding the petitioner’s unique abilities to advance his proposed endeavor in the United States.',
        'similarity_score': 0.46048574707636136
    }],
    [{
        'sentence': 'Letters, though not without weight, cannot form the cornerstone of a successful extraordinary ability claim.',
        'similarity_score': 0.49190306088548164
    },
    {
        'sentence': 'USCIS may, in its discretion, use such letters as advisory opinions submitted by expert witnesses.',
        'similarity_score': 0.7344382252270818
    },
    {
        'sentence': 'However, USCIS is ultimately responsible for making the final determination of the alien’s eligibility.',
        'similarity_score': 0.820088711858265
    },
    {
        'sentence': 'See Matter of Caron International, 19 I&N Dec. 791, 795 (Comm.',
        'similarity_score': 0.9267616903736574
    },
    { 'sentence': '1988).', 'similarity_score': 0.0 },
    {
        'sentence': 'Without documentation showing that the petitioner’s work has made original contributions of major significance to the field, USCIS cannot conclude this criterion has been met',
        'similarity_score': 0.4837704193493813
    }],
    [{
        'sentence': 'Please submit evidence to establish that the beneficiary is well positioned to advance the proposed endeavor.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Evidence which best establishes that the beneficiary is well positioned to advance the proposed endeavor will document the beneficiary’s qualifications (skills, experience and track record), support (financial and otherwise) and commitment (plans and progress) to drive the endeavor forward, and will support projections of future work in the proposed endeavor.',
        'similarity_score': 1.0000000000000004
    },
    {
        'sentence': 'USCIS may consider factors including, but not limited to, the following:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'A model or plan for future activities: To show a model or plan for future activities, the petitioner may submit one or more pieces of evidence from the following non-exhaustive list:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'A plan describing how the beneficiary intends to continue his or her work in the United States; A detailed business model, when appropriate; Correspondence from prospective/potential employers, clients or customers; and Documentation reflecting feasible plans for financial support.',
        'similarity_score': 0.9825643372604554
    },
    {
        'sentence': 'Any progress towards achieving the proposed endeavor: To show progress towards achieving the proposed endeavor, a petitioner may submit one or more pieces of evidence from the following non-exhaustive list:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Evidence of grants the beneficiary has received listing the amount and terms of the grants, as well as the grantees; Copies of contracts, agreements, or licenses resulting from the proposed endeavor or otherwise demonstrating the beneficiary is well positioned to advance the proposed endeavor; Evidence of achievements that the beneficiary intends to build upon or further develop (including the types of documentation listed under “beneficiary’s education, skills,',
        'similarity_score': 0.9186742626091794
    }],
    [{
        'sentence': 'NSCI140NSCI14000027692213 (COURTESY COPY) 9 of 12 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 0.7394600786941635
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'knowledge, and record of success in related or similar efforts”); and Evidence demonstrating the beneficiary has a leading, critical or indispensable role in the endeavor.',
        'similarity_score': 0.7930761676380527
    },
    {
        'sentence': 'The interest of potential customers, users, investors, or other relevant entities or individuals: To show interest of potential customers, investors, or other relevant individuals, a petitioner may submit one or more pieces of evidence from the following non-exhaustive list:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Letters from a government entity demonstrating its interest in the proposed endeavor; Evidence that the beneficiary has received investment from U.S. investors, such as venture capital firms, angel investors, or start-up accelerators, in amounts that are appropriate to the relevant endeavor; Evidence that the beneficiary has received awards, grants, or other indications of relevant non-monetary support (for e.g., using facilities free of charge, etc.)',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'from Federal, State, or local government entities with authority over the field of endeavor; Evidence demonstrating how the beneficiary’s work is being used by others, such as: Contracts with companies using products, projects, or services that the beneficiary developed or assisted in developing; Documents showing licensed technology or other procedural or technological advancements developed in whole or in part by the beneficiary and relied upon by others; and Patents or licenses awarded to the beneficiary with documentation showing why the particular patent or license is significant to the field.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Other evidence that the beneficiary is well-positioned to advance the endeavor.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Note: The beneficiary may be well positioned to advance the endeavor even if there is no certainty that the proposed endeavor will be a success.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'However, unsubstantiated claims are insufficient and would not meet the petitioner’s burden of proof.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Dhanasar Prong 3 - On balance, is it beneficial to the United States to waive the requirements of a job offer and thus a labor certification.',
        'similarity_score': 0.8731673529826965
    }],
    [{
        'sentence': 'The petitioner has provided the following:',
        'similarity_score': 0.7417164723142661
    }],
    [{
        'sentence': "Petitioner's resume; Petitioner's professional plan; Expert opinion letter; Educational documents; and Letters of recommendation;",
        'similarity_score': 0.4638215688283739
    }],
    [{
        'sentence': 'The third prong requires the petitioner to demonstrate that, on balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 1.0
    },
    {
        'sentence': "In performing this analysis, USCIS may evaluate factors such as: whether, in light of the nature of the noncitizen's qualifications or the proposed endeavor, it would be impractical either for the noncitizen to secure a job offer or for the petitioner to obtain a labor certification; whether, even assuming that other qualified U.S. workers are available, the United States would still benefit from the noncitizen's contributions; and whether the national interest in the noncitizen's contributions is sufficiently urgent to warrant forgoing the labor certification process.",
        'similarity_score': 0.7036565570646155
    }],
    [{
        'sentence': "The petitioner's specific proposed endeavor lacked a detailed description or documentary evidence that demonstrated that his proposed endeavor, rather than a possible employer's business enterprise, will have potential impact at the national or global level.",
        'similarity_score': 0.33626967534889035
    },
    {
        'sentence': 'The petitioner must support its assertions with',
        'similarity_score': 0.3758103363354489
    }],
    [{
        'sentence': 'NSCI140NSCI14000027692213 (COURTESY COPY) 10 of 12 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 0.7394600786941635
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'relevant, probative, and credible evidence.',
        'similarity_score': 0.4304311265719071
    },
    {
        'sentence': 'See Matter of Chawathe, 25 I&N Dec. 369,376 (AAO 2010).',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The petitioner failed to show that, in light of the nature of his qualifications or proposed endeavor, it would be impractical either for him to secure a job offer or for him to obtain a labor certification; moreover, the petitioner has not demonstrated that the national interest in his contributions is sufficiently urgent to warrant forgoing the labor certification process.',
        'similarity_score': 0.6858971018330876
    },
    {
        'sentence': 'The record suggests that the petitioner is a capable Information Technology Specialist, but it does not establish that he has unique knowledge or skills, that he is distinguished among others in his same field, or that he is principal leader of projects in the United States that can significantly influence his field as an Information Technology Specialist..',
        'similarity_score': 0.3482473556423213
    }],
    [{
        'sentence': "USCIS acknowledges that the petitioner's work has added to the overall body of knowledge in the field of Information Technology Specialist, but this is the goal of all such research or work; general assertions that the petitioner's research has influenced the field does not persuasively show that it would be beneficial to the United States to waive requirements of a job offer and thus waive the labor certification.",
        'similarity_score': 0.5277395829264581
    },
    {
        'sentence': 'Simply going on record without substantive evidence to support assertions is not sufficient in these proceedings.',
        'similarity_score': 0.5214655813139438
    },
    {
        'sentence': 'See Matter of Treasure Craft of California, 14 I&N Dec. 190 (BIA 1972).',
        'similarity_score': 0.33827505792479534
    }],
    [{
        'sentence': 'The third prong requires the petitioner to demonstrate that, on balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Here, the petitioner claims that he is eligible for a waiver due to the importance of his field and the impracticality of labor certification.',
        'similarity_score': 0.31874451449021923
    },
    {
        'sentence': 'However, as the petitioner has not established that the proposed endeavor satisfies the national importance or that he is well positioned to advance the proposed endeavor as required by the first and second prong of the Dhanasar framework, he is not eligible for a national interest waiver.',
        'similarity_score': 0.6731127234472343
    }],
    [{
        'sentence': 'Please submit evidence to establish that, on balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 0.9999999999999996
    },
    {
        'sentence': 'This balance was described in Dhanasar as on one hand protecting the domestic labor supply through the creation of the labor certification process, while on the other hand recognizing that in certain cases the benefits inherent in the labor certification process can be outweighed by other factors that are also deemed to be in the national interest.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'USCIS may evaluate factors including, but not limited to, the following:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Whether, in light of the nature of the beneficiary’s qualifications or proposed endeavor, it would be impractical either for the beneficiary to secure a job offer or for the petitioner to obtain a labor certification; Whether, even assuming that other qualified U.S. workers are available, the United States would still benefit from the beneficiary’s contributions; Whether the national interest in the beneficiary’s contributions is sufficiently urgent to warrant forgoing the labor certification process; Whether the beneficiary’s endeavor may lead to potential creation of jobs; and Whether the beneficiary is self-employed in a manner that generally does not adversely affect U.S. workers.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'PLACE THE ENCLOSED COVERSHEET ON TOP OF YOUR RESPONSE.',
        'similarity_score': 0.6333145861826177
    },
    {
        'sentence': 'SUBMISSION OF EVIDENCE WITHOUT THIS COVERSHEET WILL DELAY PROCESSING OF YOUR CASE AND MAY RESULT IN A DENIAL.',
        'similarity_score': 0.9588328783505045
    }],
    [{
        'sentence': 'NSCI140NSCI14000027692213 (COURTESY COPY) 11 of 12 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 0.7394600786941635
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': "If you choose to mail your response by courier to the Center's physical address, please write Premium Processing, P.O.",
        'similarity_score': 0.2609283199108402
    },
    {
        'sentence': 'Box 87103 on both sides of the outer envelope.',
        'similarity_score': 0.17049910354775946
    }],
    [{ 'sentence': 'Sincerely,', 'similarity_score': 1.0 }],
    [{
        'sentence': 'L. Miller Director Officer: 0884',
        'similarity_score': 0.5095028468991769
    }],
    [{
        'sentence': 'NSCI140NSCI14000027692213 (COURTESY COPY) 12 of 12 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 0.7394600786941635
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0000000000000002
    }],
    [{ 'sentence': 'Officer: 0884', 'similarity_score': 1.0 }],
    [{
        'sentence': 'COVERSHEET PLEASE RETURN THE REQUESTED INFORMATION AND ALL SUPPORTING DOCUMENTS WITH THIS PAGE ON TOP TO: USCIS/NSC ATTN: RFE/NOID/NOIR/NOIT PO BOX 87103 LINCOLN, NE 68501-7103',
        'similarity_score': 0.6075225773518094
    }],
    [{
        'sentence': 'Please check the appropriate box regarding if there is a new Form G-28, Notice of Entry of Appearance as Attorney or Accredited Representative, additional fees, additional forms, etc.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Please place the new Form G-28, additional fees, additional forms directly under this sheet.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Yes, there is: A New G-28 Additional Fees',
        'similarity_score': 1.0
    }],
    [{ 'sentence': 'Additional Forms Other:', 'similarity_score': 1.0 }],
    [{
        'sentence': 'If you have moved, write your current address in the blank area below.',
        'similarity_score': 1.0
    },
    { 'sentence': 'Please be sure to write clearly.', 'similarity_score': 1.0 }],
    [{
        'sentence': '(Select appropriate check box) Applicant/Beneficiary Petitioner',
        'similarity_score': 1.0
    }],
    [{ 'sentence': 'New Address:', 'similarity_score': 1.0000000000000002 }],
    [{
        'sentence': 'As required by Section § 265.1 Reporting change of address.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': '“Except for those exempted by section 263(b) of the Act, all aliens in the United States required to register under section 262 of the Act must report each change of address and new address within 10 days of such change in accordance with instructions provided by USCIS.”',
        'similarity_score': 1.0000000000000002
    }],
    [{ 'sentence': 'REQUEST FOR EVIDENCE', 'similarity_score': 1.0 }],
    [{
        'sentence': '140 Immigrant Petition for Alien Worker',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'MARTINS DE OLIVEIRA, DANIEL MARTINS DE OLIVEIRA, DANIEL',
        'similarity_score': 0.16090675796191115
    }],
    [{
        'sentence': 'NSCI140NSCI14000027692213 NSC I-140 Premium Coversheet 1 of 1 ECHO - Enterprise Correspondence Handling Online',
        'similarity_score': 0.4775116294525275
    }]];

    const sentences = [[{
        'sentence': 'U.S. Department of Homeland Security U.S.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Citizenship and Immigration Services May 30, 2024 Texas Service Center 6046 N Belt Line Rd STE 172 Irving, TX 75038-0015',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'ANA CAROLINA GONCALVES HOSKEN FERNANDES c/o ANA CAROLINA HOSKEN FERNANDES 2339 VILA TEIXEIRA ITAPIPOCA, CEARA 62502 562 IOE0923952513 BRAZIL RE: ANA CAROLINA GONCALVES HOSKEN FERNANDES I-140, Immigrant Petition for Alien Worker',
        'similarity_score': 0.26781353943627006
    }],
    [{ 'sentence': 'REQUEST FOR EVIDENCE', 'similarity_score': 1.0 }],
    [{
        'sentence': 'IMPORTANT: THIS NOTICE CONTAINS YOUR UNIQUE NUMBER.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'THE ORIGINAL NOTICE MUST BE SUBMITTED WITH THE REQUESTED EVIDENCE.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'You are receiving this notice because U.S.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Citizenship and Immigration Services (USCIS) requires additional evidence to process your form.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Please provide the evidence requested below.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Include duplicate copies if you are requesting consular notification.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Your response must be received in this office by August 26, 2024.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Please note that you have been allotted the maximum period allowed for responding to a Request for Evidence (RFE).',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'The time period for responding cannot be extended.',
        'similarity_score': 1.0
    },
    {
        'sentence': '8 Code of Federal Regulations (8 CFR 103.2(b)(8)(iv).',
        'similarity_score': 0.9999999999999999
    },
    {
        'sentence': 'Because many immigration benefits are time sensitive, you are encouraged to respond to this request as early as possible, but no later than the deadline provided above.',
        'similarity_score': 1.0000000000000004
    },
    {
        'sentence': 'If you do not respond to this notice within the allotted time, your case may be denied.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'The regulations do not provide for an extension of time to submit the requested evidence.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'You must submit all requested evidence at the same time.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'If you submit only some of the requested evidence, USCIS will consider your response a request for a decision on the record.',
        'similarity_score': 1.0000000000000002
    },
    { 'sentence': '8 CFR 103.2(b)(11).', 'similarity_score': 1.0 }],
    [{
        'sentence': 'If you submit a document in any language other than English, the document must be accompanied by a full and complete English translation.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'The translator must certify that the translation is accurate and he or she is competent to translate from that language to English.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'If you submit a foreign language translation in response to this request for evidence, you must also include a copy of the foreign language document.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Processing of your I-140 will resume upon receipt of your response.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'If you have not heard from USCIS within 60 days of responding, you may contact the USCIS Contact Center (NCSC) at 1-800-375-5283.',
        'similarity_score': 1.0000000000000004
    },
    {
        'sentence': 'If you are hearing impaired, please call the NCSC TDD at 1-800-767-1833.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'TSCI140TSCI14000029584660 1 of 8 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Reference is made to this Form I-140, Immigrant Petition for Alien Worker, seeking classification as a member of the professions holding an advanced degree and an exemption from the requirement of a job offer, and thus of a labor certification, in the national interest of the United States for ANA CAROLINA GONCALVES HOSKEN FERNANDES (self-petitioner), filed on her own behalf.',
        'similarity_score': 0.8866173867909122
    },
    {
        'sentence': 'The priority date for this petition is January 17, 2024.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The petitioner intends to work as a General Dentist.',
        'similarity_score': 0.7899736111206621
    }],
    [{
        'sentence': 'In order to establish eligibility, the petitioner must establish that:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The petitioner qualifies for the requested classification; and An exemption from the requirement of a job offer, and thus of a labor certification, is in the national interest of the United States.',
        'similarity_score': 1.0000000000000002
    }],
    [{ 'sentence': 'I.', 'similarity_score': 0.0 },
    {
        'sentence': 'Eligibility for the Requested Classification',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'The first issue in this case is whether the petitioner qualifies for the requested classification of a professional with an advanced degree.',
        'similarity_score': 0.9623125916947989
    }],
    [{
        'sentence': 'The petitioner must establish they hold an advanced degree as of the priority date.',
        'similarity_score': 0.7033925593833218
    }],
    [{
        'sentence': 'The petitioner provided her diploma for her degree as a Dental Surgeon.',
        'similarity_score': 0.4734397418873595
    },
    {
        'sentence': 'The degree was issued by the Estacio de Sa Juiz Fora Educational Center on April 6, 2018.',
        'similarity_score': 0.1826968635996467
    }],
    [{
        'sentence': 'As such, the petitioner established eligibility for the requested E21 classification.',
        'similarity_score': 0.7379928667838259
    }],
    [{ 'sentence': 'II.', 'similarity_score': 1.0 },
    {
        'sentence': 'National Interest Waiver',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'The second issue in this case is whether the petitioner is eligible for, and merits, a national interest waiver as a matter of discretion.',
        'similarity_score': 0.4544451866507607
    }],
    [{
        'sentence': 'USCIS has designated Matter of Dhanasar, 26 I&N Dec. 884 (AAO 2016) (“Dhanasar”) as a precedent decision.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'That decision rescinded the earlier precedent decision, Matter of New York State Dep’t of Transp.',
        'similarity_score': 1.0
    },
    {
        'sentence': '("NYSDOT"), 22 I&N Dec. 215 (Acting Assoc.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Comm’r 1998), regarding national interest waivers under Section 203(b)(2)(B)(i) of the Immigration and Nationality Act, and introduced a new three-prong test for determining eligibility.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Under Dhanasar, USCIS may grant a national interest waiver as a matter of discretion if the petitioner demonstrates by a preponderance of the evidence that:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'The petitioner’s proposed endeavor has both substantial merit and national importance;',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The petitioner is well positioned to advance the proposed endeavor; and On balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 0.9702417361411513
    }],
    [{
        'sentence': 'If these three elements are satisfied, USCIS may approve the national interest waiver as a matter of discretion.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'The evidence does not establish that all three (3) of the above-listed elements have been met.',
        'similarity_score': 0.6929760098754104
    },
    {
        'sentence': 'Therefore, USCIS requests additional evidence.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': '1st Prong - Substantial Merit and National Importance',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The first prong, substantial merit and national importance, focuses on the specific endeavor that the foreign national proposes to undertake.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Matter of Dhanasar, 26 I&N Dec. 884, 889 (AAO 2016).',
        'similarity_score': 1.0
    },
    {
        'sentence': "The endeavor's merit may be demonstrated in a range of areas such as business, entrepreneurialism, science, technology, culture, health, or education.",
        'similarity_score': 1.0000000000000002
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 }],
    [{
        'sentence': 'TSCI140TSCI14000029584660 2 of 8 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The petitioner claims her proposed endeavor is open a dental business, Prime Dental Clinic.',
        'similarity_score': 0.36971248368536935
    }],
    [{ 'sentence': 'Substantial Merit', 'similarity_score': 1.0000000000000002 }],
    [{
        'sentence': 'The petitioner claims she will provide exceptional dental services to all age groups and social classes, offering specialized treatments and procedures to improve overall oral health and well-being.',
        'similarity_score': 0.31546489133871786
    }],
    [{
        'sentence': 'In support of this claim, the petitioner submitted several articles.',
        'similarity_score': 0.5395506250209254
    }],
    [{
        'sentence': 'Based on this evidence, the petitioner established that her proposed endeavor has substantial merit.',
        'similarity_score': 0.4835422004913786
    }],
    [{ 'sentence': 'National Importance', 'similarity_score': 1.0 }],
    [{
        'sentence': 'In determining national importance, the relevant question is not the importance of the field, industry, or profession in which the individual will work; instead USCIS must focus on the “the specific endeavor that the foreign national proposes to undertake.” See Dhanasar, 26 I&N Dec. at 889.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'In Dhanasar, the Administrative Appeals Office (AAO) noted that they “look for broader implications” of the proposed endeavor and that “[a]n undertaking may have national importance for example, because it has national or even global implications within a particular field.” Id.',
        'similarity_score': 0.9025917955912078
    },
    {
        'sentence': 'AAO also stated that "[a]n endeavor that has significant potential to employ U.S. workers or has other substantial positive economic effects, particularly in an economically depressed area, for instance, may well be understood to have national importance."',
        'similarity_score': 1.0
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    { 'sentence': 'at 890.', 'similarity_score': 1.0 }],
    [{
        'sentence': "To evaluate whether the petitioner's proposed endeavor satisfies the national importance requirement USCIS must look to evidence documenting the “potential prospective impact” of the petitioner's proposed endeavor work.",
        'similarity_score': 1.0000000000000004
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    { 'sentence': 'at 889.', 'similarity_score': 1.0 }],
    [{
        'sentence': 'The petitioner stated she has extensive knowledge of dental treatments and procedures, along with her understanding of the specific needs and challenges of underserved populations, will be instrumental in providing specialized dental services.',
        'similarity_score': 0.3122173837653968
    },
    {
        'sentence': 'The documentary did not show the petitioner has extensive knowledge of dental treatments and procedures, along with her understanding of the specific needs and challenges of underserved populations.',
        'similarity_score': 0.22807617731507357
    },
    {
        'sentence': "T he evidence did not demonstrate how the petitioner's accomplishments have global implications.",
        'similarity_score': 0.3234193958164724
    },
    {
        'sentence': 'The evidence did not show how the petitioner has the potential to employ US workers or has other substantial positive economic effects.',
        'similarity_score': 0.594202412632098
    },
    {
        'sentence': 'The petitioner did not discuss how her work will broadly enhance societal welfare, cultural aspects, artistic enrichment, or impacts a matter that a government entity has described as having national importance or is the subject of national initiatives.',
        'similarity_score': 0.6600797089993209
    }],
    [{
        'sentence': 'As such, the petitioner did not establish that her proposed endeavor is of national importance.',
        'similarity_score': 0.6174113984379669
    }],
    [{
        'sentence': 'Please submit evidence to establish that the petitioner’s proposed endeavor has national importance.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'This evidence must demonstrate the endeavor’s potential prospective impact.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Evidence to establish that the petitioner’s proposed endeavor has national importance consists of, but is not limited to, the following:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'A detailed description of the proposed endeavor and why it is of national importance,',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Documentary evidence that supports the petitioner’s statements and establishes the endeavor’s national importance.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Such evidence must demonstrate the endeavor’s potential prospective impact, and may consist of, but is not limited to, evidence showing that the proposed endeavor:',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Has national or even global implications within a particular field; Has significant potential to employ U.S. workers or has other substantial positive economic effects, particularly in an economically depressed area; TSCI140TSCI14000029584660 3 of 8 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 0.7272270485829042
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'economic effects, particularly in an economically depressed area; Will broadly enhance societal welfare or cultural or artistic enrichment; and Impacts a matter that a government entity has described as having national importance or is the subject of national initiatives.',
        'similarity_score': 0.853583223189796
    }],
    [{
        'sentence': 'This criterion has not been met.',
        'similarity_score': 0.906476116413624
    }],
    [{
        'sentence': '2nd Prong - Well Positioned to Advance the Proposed Endeavor',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The second prong shifts the focus from the proposed endeavor to the foreign national.',
        'similarity_score': 1.0
    },
    { 'sentence': 'See Dhanasar, 26 I&N Dec. at 880.', 'similarity_score': 1.0 },
    {
        'sentence': 'In general, research must add information to the pool of knowledge in some way in order to be accepted for publication, presentation, funding, or academic credit; not every individual who has performed original research will be found to be well positioned to advance their proposed research.',
        'similarity_score': 0.9843248259988298
    },
    {
        'sentence': "Rather, USCIS must examine the factors set forth in Dhanasar to determine whether, for instance, the individual's progress towards achieving the goals of the proposed research, record of success in similar efforts, or generation of interest among relevant parties supports such a finding.",
        'similarity_score': 1.0000000000000002
    },
    { 'sentence': 'Id.', 'similarity_score': 1.0 },
    { 'sentence': 'at 890.', 'similarity_score': 1.0 }],
    [{
        'sentence': 'Not every individual who possesses the necessary educational and professional credentials, skills, and who worked in the field will be found to be well positioned to advance the proposed endeavor.',
        'similarity_score': 0.9806525224626332
    },
    {
        'sentence': 'USCIS looks to a variety of factors in determining whether an individual is well positioned to advance the proposed endeavor.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'The petitioner stated she is an experienced General Dentist with over six years of practice focusing on pediatric dentistry, endodontist, and dental radiology.',
        'similarity_score': 0.20991562811940243
    },
    {
        'sentence': 'She claims she is motivated and a driven leader.',
        'similarity_score': 0.2203938334290386
    },
    {
        'sentence': 'The petitioner stated she manages her own Dental Clinic offering dental care that has proven to deliver successful procedures and improve patient outcomes.',
        'similarity_score': 0.3186741692059592
    },
    {
        'sentence': 'In support of this claim, the petitioner submitted a resume, business plan, memberships, certificates for training, an award; however, the evidence did not demonstrate her results as a leader, successful procedures, and how she improved patient outcomes.',
        'similarity_score': 0.24171879239387195
    }],
    [{
        'sentence': 'The petitioner claims she will open her own company to provide quality oral healthcare services.',
        'similarity_score': 0.24645296263397953
    },
    {
        'sentence': 'She stated she has extensive knowledge of dental treatments and procedures.',
        'similarity_score': 0.27165237994522506
    },
    {
        'sentence': 'The evidence did not show how she is well-positioned to start her own company in the US and provide dental treatments.',
        'similarity_score': 0.2633542805587691
    }],
    [{
        'sentence': "The petitioner provided several letters of recommendation which praises the petitioner's work in the field of dentistry.",
        'similarity_score': 0.5237622228428944
    },
    {
        'sentence': 'However, there is no evidence that shows a history of achievements, any substantial developments attributed directly to the petitioner, or evidence that shows the petitioner’s expertise renders her well-positioned to advance the field.',
        'similarity_score': 0.3519109211092485
    }],
    [{
        'sentence': 'As such, the petitioner did not establish that she is well positioned to advance the proposed endeavor.',
        'similarity_score': 0.6845667333933436
    }],
    [{
        'sentence': 'Please submit evidence to establish that the petitioner is well positioned to advance the proposed endeavor.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Evidence which best establishes that the petitioner is well positioned to advance the proposed endeavor will document the petitioner qualifications (skills, experience and track record), support (financial and otherwise) and commitment (plans and progress) to drive the endeavor forward, and will support projections of future work in the proposed endeavor.',
        'similarity_score': 0.9886215523376782
    },
    {
        'sentence': 'USCIS may consider factors including, but not limited to, the following:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Your education, skills, knowledge, and record of success in related or similar efforts: To show a petitioner’s education, skills, knowledge, and record of success in related or similar efforts, the petitioner may submit one or more pieces of evidence from the following non-exhaustive list:',
        'similarity_score': 0.9547053545232022
    }],
    [{
        'sentence': 'TSCI140TSCI14000029584660 4 of 8 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Degrees, certificates, or licenses in the field;',
        'similarity_score': 0.6179239605772481
    }],
    [{
        'sentence': 'Patents, trademarks, or copyrights owned by the petitioner;',
        'similarity_score': 0.9449930247584564
    }],
    [{
        'sentence': 'Letters from experts in the petitioner’s field, describing the petitioner’s past achievements and providing specific examples of how the petitioner is well positioned to advance her endeavor.',
        'similarity_score': 0.8114976110184058
    },
    {
        'sentence': 'Testimonial letters should include information about the expert’s own credentials, such as a curriculum vitae;',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Published articles and/or media reports about the petitioner’s achievements or current work;',
        'similarity_score': 0.9440933028362443
    }],
    [{
        'sentence': 'Documentation demonstrating a strong citation history;',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Evidence that the petitioner’s work has influenced her field of endeavor;',
        'similarity_score': 0.8052894110290176
    }],
    [{
        'sentence': 'Evidence demonstrating the petitioner has a leading, critical or indispensable role in the endeavor or similar endeavors; and',
        'similarity_score': 0.9617500344317215
    }],
    [{
        'sentence': 'Evidence showing that the petitioner’s past inventions or innovations have been used or licensed by others in the field.',
        'similarity_score': 0.9557378714497953
    }],
    [{
        'sentence': 'Any progress towards achieving the proposed endeavor: To show progress towards achieving the proposed endeavor, a petitioner may submit one or more pieces of evidence from the following non-exhaustive list: Evidence of grants the petitioner has received listing the amount and terms of the grants, as well as the grantees;',
        'similarity_score': 0.9901532830543757
    }],
    [{
        'sentence': 'Copies of contracts, agreements, or licenses resulting from the proposed endeavor or otherwise demonstrating the petitioner is well positioned to advance the proposed endeavor;',
        'similarity_score': 0.9740098973264077
    }],
    [{
        'sentence': 'Evidence of achievements that the petitioner intends to build upon or further develop (including the types of documentation listed under “petitioner’s education, skills, knowledge, and record of success in related or similar efforts”); and',
        'similarity_score': 0.9579170138971776
    }],
    [{
        'sentence': 'Evidence demonstrating the petitioner has a leading, critical or indispensable role in the endeavor.',
        'similarity_score': 0.9486214625238377
    }],
    [{
        'sentence': 'A model or plan for future activities: To show a model or plan for future activities, the petitioner may submit one or more pieces of evidence from the following non-exhaustive list: A plan describing how the petitioner intends to continue her work in the United States;',
        'similarity_score': 0.9807801195788217
    }],
    [{
        'sentence': 'A detailed business model, when appropriate;',
        'similarity_score': 0.9999999999999999
    }],
    [{
        'sentence': 'Correspondence from prospective/potential employers, clients or customers; and',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Documentation reflecting feasible plans for financial support.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'The interest of potential customers, users, investors, or other relevant individuals To show interest of potential customers, investors, or other relevant individuals, a petitioner may submit one or more pieces of evidence from the following TSCI140TSCI14000029584660 5 of 8 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 0.8504122817678661
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'petitioner may submit one or more pieces of evidence from the following non-exhaustive list: Letters from a government entity demonstrating its interest in the proposed endeavor;',
        'similarity_score': 0.6992559023325565
    }],
    [{
        'sentence': 'Evidence that the petitioner has received investment from U.S. investors, such as venture capital firms, angel investors, or start-up accelerators, in amounts that are appropriate to the relevant endeavor;',
        'similarity_score': 0.98278974255452
    }],
    [{
        'sentence': 'Evidence that the petitioner has received awards, grants, or other indications of relevant non-monetary support (for e.g., using facilities free of charge, etc.)',
        'similarity_score': 0.9777962143089938
    },
    {
        'sentence': 'from Federal, State, or local government entities with authority over the field of endeavor;',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Evidence demonstrating how the petitioner’s work is being used by others, such as: Contracts with companies using products, projects, or services that the petitioner developed or assisted in developing;',
        'similarity_score': 0.9540533178265977
    }],
    [{
        'sentence': 'Documents showing licensed technology or other procedural or technological advancements developed in whole or in part by the petitioner and relied upon by others; and',
        'similarity_score': 0.9824595393132816
    }],
    [{
        'sentence': 'Patents or licenses awarded to the petitioner with documentation showing why the particular patent or license is significant to the field.',
        'similarity_score': 0.9738791694610115
    }],
    [{
        'sentence': 'Other evidence that you are well-positioned to advance the endeavor.',
        'similarity_score': 0.8003381328403827
    }],
    [{
        'sentence': 'This criterion has not been met.',
        'similarity_score': 0.906476116413624
    }],
    [{
        'sentence': '3rd Prong – Beneficial to the United States to waive the job offer and labor certification requirements',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Based on the above-discussed documentary evidence, the petitioner has not established that it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 0.7988942410918622
    }],
    [{
        'sentence': 'The provided evidence is deficient in demonstrating that their knowledge or skills are not easily articulated in a labor certification, they have not demonstrated that they present benefits to the United States through her proposed endeavor that outweigh those inherent in the labor certification process.',
        'similarity_score': 0.6090934624205144
    },
    {
        'sentence': 'In addition, the petitioner has not shown that they offer contributions of such value that, overall, they would benefit the nation even if other qualified U.S. workers were available.',
        'similarity_score': 0.9192357879899997
    },
    {
        'sentence': 'In sum, the petitioner has not established that, on balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 0.9999999999999999
    }],
    [{
        'sentence': 'Therefore, please submit evidence to establish that, on balance, it would be beneficial to the United States to waive the requirements of a job offer and thus of a labor certification.',
        'similarity_score': 0.9626565817376989
    },
    {
        'sentence': 'This balance was described in Dhanasar as on one hand protecting the domestic labor supply through the creation of the labor certification process, while on the other hand recognizing that in certain cases the benefits inherent in the labor certification process can be outweighed by other factors that are also deemed to be in the national interest.',
        'similarity_score': 1.0000000000000004
    }],
    [{
        'sentence': 'USCIS may evaluate factors including, but not limited to, the following:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'TSCI140TSCI14000029584660 6 of 8 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Whether, in light of the nature of the petitioner’s qualifications or proposed endeavor, it would be impractical either for the petitioner to secure a job offer or for the petitioner to obtain a labor certification; Whether, even assuming that other qualified U.S. workers are available, the United States would still benefit from the petitioner’s contributions; Whether the national interest in the petitioner’s contributions is sufficiently urgent to warrant forgoing the labor certification process; Whether the petitioner’s endeavor may lead to potential creation of jobs; and Whether the petitioner is self-employed in a manner that generally does not adversely affect U.S. workers.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'This criterion has not been met.',
        'similarity_score': 0.906476116413624
    }],
    [{
        'sentence': 'PLACE THE ATTACHED COVERSHEET AND THIS ENTIRE LETTER ON TOP OF YOUR RESPONSE.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'SUBMISSION OF EVIDENCE WITHOUT THE COVERSHEET AND THIS LETTER WILL DELAY PROCESSING OF YOUR CASE AND MAY RESULT IN A DENIAL.',
        'similarity_score': 1.0
    }],
    [{ 'sentence': 'Sincerely,', 'similarity_score': 1.0 }],
    [{
        'sentence': 'Mary Elizabeth Brennan Seng Director, Texas Service Center Officer: XM2170',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'TSCI140TSCI14000029584660 7 of 8 www.uscis.gov USCIS encourages you to sign up for a USCIS online account.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'To learn more about creating an account and the benefits, go to https://www.uscis.gov/file-online.',
        'similarity_score': 1.0
    }],
    [{ 'sentence': 'Officer: XM2170', 'similarity_score': 1.0000000000000002 }],
    [{
        'sentence': 'COVERSHEET PLEASE RETURN THE REQUESTED INFORMATION AND ALL SUPPORTING DOCUMENTS WITH THIS PAGE ON TOP TO: USCIS/TSC ATTN: RFE/NOID/NOIR/NOIT 6046 N BELT LINE RD STE 172 IRVING, TX 75038-0015',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Please check the appropriate box regarding if there is a new Form G-28, Notice of Entry of Appearance as Attorney or Accredited Representative, additional fees, additional forms, etc.',
        'similarity_score': 1.0
    },
    {
        'sentence': 'Please place the new Form G-28, additional fees, additional forms directly under this sheet.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'Yes, there is: A New G-28 Additional Fees',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'Additional Forms Other:',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': 'If you have moved, write your current address in the blank area below.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': 'Please be sure to write clearly.',
        'similarity_score': 1.0000000000000002
    }],
    [{
        'sentence': '(Select appropriate check box) Applicant/Beneficiary Petitioner',
        'similarity_score': 1.0000000000000002
    }],
    [{ 'sentence': 'New Address:', 'similarity_score': 1.0000000000000002 }],
    [{
        'sentence': 'As required by Section § 265.1 Reporting change of address.',
        'similarity_score': 1.0000000000000002
    },
    {
        'sentence': '“Except for those exempted by section 263(b) of the Act, all aliens in the United States required to register under section 262 of the Act must report each change of address and new address within 10 days of such change in accordance with instructions provided by USCIS.”',
        'similarity_score': 1.0000000000000007
    }],
    [{ 'sentence': 'REQUEST FOR EVIDENCE', 'similarity_score': 1.0 }],
    [{
        'sentence': '140 Immigrant Petition for Alien Worker',
        'similarity_score': 1.0
    }],
    [{
        'sentence': 'GONCALVES HOSKEN FERNANDES, ANA CAROLINA IOE0923952513 GONCALVES HOSKEN FERNANDES, ANA CAROLINA',
        'similarity_score': 0.1936419983115176
    }],
    [{ 'sentence': '8 of 8', 'similarity_score': 1.0 }],
    [{
        'sentence': 'TSCI140TSCI14000029584660 TSC Routing Sheet Suite 172 ECHO - Enterprise Correspondence Handling Online',
        'similarity_score': 1.0
    }]]

    const commonStyle = {
        fontFamily: 'TimesNewRoman',
        fontSize: 17,
        color: '#04141d',
        lineHeight: '1.6',
    };

    const notSoCommonStyle = {
        backgroundColor: '#ffe571',
        paddingLeft: '.25rem',
        paddingRight: '.25rem',
        borderRadius: '.25rem',
        fontFamily: 'TimesNewRoman',
        fontSize: 17,
        lineHeight: '1.6',
        border: '1px solid #ffd700',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    };

    const uncommonStyle = {
        backgroundColor: 'red',
        color: 'white',
        paddingLeft: '.25rem',
        paddingRight: '.25rem',
        borderRadius: '.25rem',
        fontFamily: 'TimesNewRoman',
        fontSize: 17,
        lineHeight: '1.6',
        border: '1px solid darkred',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    };

    const getTextMarkStyle = (similarityScore) => {
        if (similarityScore < 0.3) {
            return uncommonStyle;
        } else if (similarityScore < 0.7) {
            return notSoCommonStyle;
        } else {
            return commonStyle;
        }
    };

    return (
        <div className='row'>
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
                <div className='card'>
                    <div className='card-body'>
                        <div style={{ padding: '20px' }}>
                            {sentences.map((paragraph, paragraphIndex) => (
                                <p key={paragraphIndex} style={{ marginBottom: '15px' }}>
                                    {paragraph.map((sentence, sentenceIndex) => (
                                        <span
                                            key={sentenceIndex}
                                            style={getTextMarkStyle(sentence.similarity_score)}
                                        >
                                            {sentence.sentence.split('\n').map((text, i) => (
                                                <React.Fragment key={i}>
                                                    {text}
                                                    {i < sentence.sentence.split('\n').length - 1 && <br />}
                                                </React.Fragment>
                                            ))}
                                            {' '}
                                        </span>
                                    ))}
                                </p>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-3"></div>

        </div >
    );
}

export default EBGLab;
