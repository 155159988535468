import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { useNavigate } from 'react-router-dom';
import { useAuth } from './Contexts/AuthContext';
import Header from './Header';
import Footer from './Footer';
import { fetchCasesHistogram, fetchLastDaysData } from './ApiService.js';

function Analytics() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [histogram, setHistogram] = useState([]);
    const [categories, setCategories] = useState([]);
    const [statusTypes, setStatusTypes] = useState([]);
    const [bigNumbers, setBigNumbers] = useState({
        totalCases: 0,
        avgGrowthVelocity: 0,
        newCases: 0,
        highestGrowthVelocity: { key: '', value: 0 },
        mostActiveStatus: { status: '', count: 0 },
        pendingCases: 0,
        approvalRate: 0
    });
    const [days, setDays] = useState([]); // To store the last 30 days
    const [combinedSeries, setCombinedSeries] = useState([]);
    const [yAxisMin, setYAxisMin] = useState(0);
    const [yAxisMax, setYAxisMax] = useState(100);

    useEffect(() => {
        async function fetchData() {
            const histogramData = await fetchCasesHistogram();
            const uniqueCategories = [...new Set(histogramData.cases.map(item => item.range_key))];
            const uniqueStatusTypes = [...new Set(histogramData.cases.map(item => item.status))];

            setHistogram(histogramData.cases);
            setCategories(uniqueCategories);
            setStatusTypes(uniqueStatusTypes);

            calculateBigNumbers(histogramData.cases, uniqueCategories);

            const data = await fetchLastDaysData(); // Call the new API
            setDays(data.map(day => day.date)); // Assuming the API returns a 'date' field

            const approvalRateData = data.map(day => day.approval_rate); // Extract approval rate
            const movementData = data.map(day => day.movements); // Extract movements per day

            // Calculate dynamic min and max for approval rate
            const minApprovalRate = Math.min(...approvalRateData);
            const maxApprovalRate = Math.max(...approvalRateData);

            const buffer = 5; // Adjust buffer as needed
            const calculatedMin = Math.floor(minApprovalRate - buffer);
            const calculatedMax = Math.ceil(maxApprovalRate + buffer);

            setYAxisMin(calculatedMin);
            setYAxisMax(calculatedMax);

            // Set combined series
            setCombinedSeries([
                { name: 'Approval Rate', data: approvalRateData },
                { name: 'Movements per Day', data: movementData }
            ]);
        }

        fetchData();
    }, []);

    const calculateBigNumbers = (data, categories) => {
        const totalCases = data.reduce((acc, item) => acc + item.count, 0);

        const avgGrowthVelocityByBlock = categories.map(category => {
            const growthVelocities = data
                .filter(item => item.range_key === category)
                .map(item => Math.abs(item.new_cases || 0));

            if (growthVelocities.length === 0) {
                return 0; // Handle the case where there are no growth velocities
            }

            return growthVelocities.reduce((acc, val) => acc + val, 0) / growthVelocities.length;
        });

        // Calculate the average growth velocity for all blocks
        const validVelocities = avgGrowthVelocityByBlock.filter(val => !isNaN(val) && val !== 0);
        const avgGrowthVelocity = validVelocities.length > 0
            ? validVelocities.reduce((acc, val) => acc + val, 0) / validVelocities.length
            : 0;

        const newCases = data.reduce((acc, item) => acc + (item.new_cases || 0), 0);
        const highestGrowthVelocity = data.reduce((max, item) => item.new_cases > max.value ? { key: item.range_key, value: item.new_cases } : max, { key: '', value: 0 });
        const mostActiveStatus = data.reduce((acc, item) => {
            acc[item.status] = (acc[item.status] || 0) + item.count;
            return acc;
        }, {});
        const mostActiveStatusEntry = Object.entries(mostActiveStatus).reduce((max, [status, count]) => count > max.count ? { status, count } : max, { status: '', count: 0 });
        const pendingCases = data.filter(item => item.status === 'Processing').reduce((acc, item) => acc + item.count, 0);
        const approvedCases = data.filter(item => item.status === 'Approved').reduce((acc, item) => acc + item.count, 0);
        const approvalRate = totalCases > 0 ? (approvedCases / totalCases) * 100 : 0;

        setBigNumbers({
            totalCases,
            avgGrowthVelocity,
            newCases,
            highestGrowthVelocity,
            mostActiveStatus: mostActiveStatusEntry,
            pendingCases,
            approvalRate
        });
    };

    const histogramSeries = statusTypes.map(status => ({
        name: status,
        type: 'bar',
        data: categories.map(category => {
            const item = histogram.filter(d => d.range_key === category && d.status === status)
                .reduce((sum, item) => sum + item.count, 0);
            return item;
        })
    }));

    const newCasesSeries = {
        name: 'Updated Cases',
        type: 'bar',
        data: categories.map(category => {
            const item = histogram.filter(d => d.range_key === category)
                .reduce((sum, item) => sum + (item.new_cases || 0), 0);
            return item;
        })
    };

    const avgVelocitySeries = {
        name: 'Average Growth Velocity',
        type: 'bar',
        data: categories.map(category => {
            const growthVelocities = histogram.filter(d => d.range_key === category)
                .map(item => Math.abs(item.new_cases_7days || 0));
            const avgVelocity = growthVelocities.reduce((acc, val) => acc + val, 0) / growthVelocities.length;
            return avgVelocity || 0;
        })
    };

    const getFormattedDate = () => {
        const date = new Date();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };

    const histogramChartOptions = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                dataLabels: {
                    enabled: false, // Hide the numbers on the bars
                }
            },
        },
        dataLabels: {
            enabled: false
        },
        title: {
            text: `EB Genius - IOE Cases Histogram (Updated on ${getFormattedDate()})`
        },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            shared: true, // Show the tooltip for all series in a bar
            intersect: false,
            y: {
                formatter: function (val) {
                    return val;
                }
            }
        },
        fill: {
            opacity: 0.8 // Make the bars slightly transparent for a softer look
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
        },
        colors: [
            '#FF6F61', // Soft Red
            '#6B8E23', // Olive Green
            '#4682B4', // Steel Blue
            '#FFD700', // Gold
            '#9370DB', // Medium Purple
            '#FF8C00', // Dark Orange
            '#20B2AA', // Light Sea Green
            '#CD5C5C', // Indian Red
            '#3CB371', // Medium Sea Green
            '#775DD0', // Purple for New Cases
        ]
    };

    const newCasesChartOptions = {
        chart: {
            type: 'bar',
            height: 350,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                dataLabels: {
                    enabled: false, // Hide the numbers on the bars
                }
            },
        },
        dataLabels: {
            enabled: false
        },
        title: {
            text: `EB Genius - IOE Updated Cases (Updated on ${getFormattedDate()})`
        },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            shared: true, // Show the tooltip for all series in a bar
            intersect: false,
            y: {
                formatter: function (val) {
                    return val;
                }
            }
        },
        fill: {
            opacity: 0.8 // Make the bars slightly transparent for a softer look
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
        },
        colors: [
            '#775DD0', // Purple for New Cases
        ]
    };

    const avgVelocityChartOptions = {
        chart: {
            type: 'bar',
            height: 350,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                dataLabels: {
                    enabled: false, // Hide the numbers on the bars
                }
            },
        },
        dataLabels: {
            enabled: false
        },
        title: {
            text: `EB Genius - Average Growth Velocity by Block (Updated on ${getFormattedDate()})`
        },
        xaxis: {
            categories: categories,
        },
        yaxis: {
            title: {
                text: undefined
            },
            labels: {
                formatter: function (val) {
                    return val.toFixed(2); // Show only 2 decimal places
                }
            }
        },
        tooltip: {
            shared: true, // Show the tooltip for all series in a bar
            intersect: false,
            y: {
                formatter: function (val) {
                    return val.toFixed(2); // Show only 2 decimal places
                }
            }
        },
        fill: {
            opacity: 0.8 // Make the bars slightly transparent for a softer look
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
        },
        colors: [
            '#008FFB', // Blue for Average Growth Velocity
        ]
    };

    const combinedChartOptions = {
        chart: {
            type: 'line', // Set default type
            height: 250,
        },
        title: {
            text: 'EB Genius - Approval Rate and Movements (Last 30 Days)'
        },
        xaxis: {
            categories: days, // Last 30 days
        },
        yaxis: [
            {
                title: {
                    text: 'Approval Rate (%)'
                },
                min: yAxisMin,
                max: yAxisMax,
                labels: {
                    formatter: function (val) {
                        return val.toFixed(2) + '%'; // Display percentage for approval rate
                    }
                }
            },
            {
                opposite: true, // Display on the opposite axis
                title: {
                    text: 'Movements per Day'
                },
                labels: {
                    formatter: function (val) {
                        return Math.round(val); // Display whole numbers for movements
                    }
                }
            }
        ],
        tooltip: {
            shared: true, // Display tooltips for both lines and bars
            intersect: false,
            y: [
                {
                    formatter: function (val) {
                        return val.toFixed(2) + '%'; // Show approval rate as percentage
                    }
                },
                {
                    formatter: function (val) {
                        return Math.round(val); // Show movements as whole numbers
                    }
                }
            ]
        },
        stroke: {
            curve: 'smooth', // Smooth the line
            width: 2,
        },
        plotOptions: {
            bar: {
                horizontal: false, // Ensure the bars are vertical
            }
        },
        series: combinedSeries.map(series => ({
            ...series,
            type: series.name === 'Approval Rate' ? 'line' : 'bar' // Set 'line' for Approval Rate, 'bar' for Movements
        })),
        colors: ['#20B2AA', '#FF8C00'], // Light Sea Green for Approval Rate, Dark Orange for Movements
        legend: {
            position: 'top',
            horizontalAlign: 'left',
        }
    };

    if (loading) return <div>Loading data...</div>;
    if (error) return <div>Error loading data: {error}</div>;

    return (
        <div id="kt_app_body" data-kt-app-header-fixed-mobile="true" data-kt-app-toolbar-enabled="true" className="app-default">
            <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
                <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
                    <Header />
                    <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                        <div id="kt_app_toolbar" className="app-toolbar pt-4 pt-lg-7 mb-n2 mb-lg-n3">
                            <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack flex-row-fluid">
                                <div className="d-flex flex-stack flex-row-fluid">
                                    <div className="d-flex flex-column flex-row-fluid">
                                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold mb-1 mb-lg-3 me-2 fs-7">
                                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                                                <span href="#" className="text-white text-hover-primary">
                                                    <i className="ki-outline ki-graph-3 text-gray-700 fs-6"></i>
                                                </span>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1"></i>
                                            </li>
                                            <li className="breadcrumb-item text-gray-900 fs-4 fw-bold lh-1">Analytics</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="app-container container-xxl d-flex">
                            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                                <div className="d-flex flex-column flex-column-fluid">
                                    <div id="kt_app_content" className="app-content">
                                        <div className="card card-reset mb-lg-5 mb-sm-5">
                                            <div className="card-body p-0">
                                                <div className="row">
                                                    <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-5">
                                                        <div className="card h-lg-100">
                                                            <div className="card-body d-flex justify-content-between align-items-start flex-column">
                                                                <div className="m-0">
                                                                    <i className="ki-outline ki-abstract-26 fs-2hx text-gray-600"></i>
                                                                </div>
                                                                <div className="d-flex flex-column my-7">
                                                                    <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{bigNumbers.totalCases}</span>
                                                                    <div className="m-0">
                                                                        <span className="fw-semibold fs-6 text-gray-500">Total Cases Analyzed</span>
                                                                    </div>
                                                                </div>
                                                                &#8287;
                                                                {/* <span className="badge badge-light-danger fs-base">
                                                                                    <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1"></i>0.47%
                                                                                </span> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-5">
                                                        <div className="card h-lg-100">
                                                            <div className="card-body d-flex justify-content-between align-items-start flex-column">
                                                                <div className="m-0">
                                                                    <i className="ki-outline ki-chart-simple fs-2hx text-gray-600"></i>
                                                                </div>
                                                                <div className="d-flex flex-column my-7">
                                                                    <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{bigNumbers.avgGrowthVelocity.toFixed(2)}</span>
                                                                    <div className="m-0">
                                                                        <span className="fw-semibold fs-6 text-gray-500">Average Growth Velocity</span>
                                                                    </div>
                                                                </div>
                                                                &#8287;
                                                                {/* <span className="badge badge-light-danger fs-base">
                                                                                    <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1"></i>0.47%
                                                                                </span> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-5">
                                                        <div className="card h-lg-100">
                                                            <div className="card-body d-flex justify-content-between align-items-start flex-column">
                                                                <div className="m-0">
                                                                    <i className="ki-outline ki-support-24 fs-2hx text-gray-600"></i>
                                                                </div>
                                                                <div className="d-flex flex-column my-7">
                                                                    <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{bigNumbers.newCases}</span>
                                                                    <div className="m-0">
                                                                        <span className="fw-semibold fs-6 text-gray-500">Updated Cases in Last 24 Hours</span>
                                                                    </div>
                                                                </div>
                                                                &#8287;
                                                                {/* <span className="badge badge-light-danger fs-base">
                                                                                    <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1"></i>0.47%
                                                                                </span> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-5">
                                                        <div className="card h-lg-100">
                                                            <div className="card-body d-flex justify-content-between align-items-start flex-column">
                                                                <div className="m-0">
                                                                    <i className="ki-outline ki-chart-line-up fs-2hx text-gray-600"></i>
                                                                </div>
                                                                <div className="d-flex flex-column my-7">
                                                                    <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{bigNumbers.highestGrowthVelocity.key}</span>
                                                                    {/* <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{bigNumbers.highestGrowthVelocity.key}: {bigNumbers.highestGrowthVelocity.value.toFixed(2)}</span> */}
                                                                    <div className="m-0">
                                                                        <span className="fw-semibold fs-6 text-gray-500">Highest Growth Velocity Block</span>
                                                                    </div>
                                                                </div>
                                                                &#8287;
                                                                {/* <span className="badge badge-light-danger fs-base">
                                                                                    <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1"></i>0.47%
                                                                                </span> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-5">
                                                        <div className="card h-lg-100">
                                                            <div className="card-body d-flex justify-content-between align-items-start flex-column">
                                                                <div className="m-0">
                                                                    <i className="ki-outline ki-abstract-45 fs-2hx text-gray-600"></i>
                                                                </div>
                                                                <div className="d-flex flex-column my-7">
                                                                    <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{bigNumbers.mostActiveStatus.status}</span>
                                                                    {/* <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{bigNumbers.mostActiveStatus.status}: {bigNumbers.mostActiveStatus.count}</span> */}
                                                                    <div className="m-0">
                                                                        <span className="fw-semibold fs-6 text-gray-500">Most Active Status</span>
                                                                    </div>
                                                                </div>
                                                                &#8287;
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-5">
                                                        <div className="card h-lg-100">
                                                            <div className="card-body d-flex justify-content-between align-items-start flex-column">
                                                                <div className="m-0">
                                                                    <i className="ki-outline ki-medal-star fs-2hx text-gray-600"></i>
                                                                </div>
                                                                <div className="d-flex flex-column my-7">
                                                                    <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{bigNumbers.approvalRate.toFixed(2)}%</span>
                                                                    <div className="m-0">
                                                                        <span className="fw-semibold fs-6 text-gray-500">Approval Rate</span>
                                                                    </div>
                                                                </div>
                                                                &#8287;
                                                                {/* <span className="badge badge-light-danger fs-base">
                                                                                    <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1"></i>0.47%
                                                                                </span> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-5">
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <div className="card mb-5">
                                                            <div className="card-header pt-7">
                                                                <h3 className="card-title align-items-start flex-column">
                                                                    <span className="card-label fs-3 fw-bold text-primary">USCIS I-140 Processing Snapshot</span>
                                                                    <span className="text-gray-500 mt-1 fw-semibold fs-6">Visualizing the progress and backlog of USCIS I-140 case evaluations by Block - Updated Daily</span>
                                                                </h3>
                                                            </div>
                                                            <div className="card-body">
                                                                <Chart options={histogramChartOptions} series={histogramSeries} type="bar" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* </div> */}
                                                    {/* <div className="row mb-5"> */}
                                                    <div className="col-12 col-md-12 col-lg-6">
                                                        <div className="card mb-5">
                                                            <div className="card-header pt-7">
                                                                <h3 className="card-title align-items-start flex-column">
                                                                    <span className="card-label fs-3 fw-bold text-primary">USCIS I-140 Last 24 Hours</span>
                                                                    <span className="text-gray-500 mt-1 fw-semibold fs-6">Visualizing how many updated cases in the last 24 hours by Block - Updated Daily</span>
                                                                </h3>
                                                            </div>
                                                            <div className="card-body">
                                                                <Chart options={newCasesChartOptions} series={[newCasesSeries]} type="bar" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-6">
                                                        <div className="card mb-5">
                                                            <div className="card-header pt-7">
                                                                <h3 className="card-title align-items-start flex-column">
                                                                    <span className="card-label fs-3 fw-bold text-primary">Average Growth Velocity</span>
                                                                    <span className="text-gray-500 mt-1 fw-semibold fs-6">Visualizing the average growth velocity by Block - Updated Daily</span>
                                                                </h3>
                                                            </div>
                                                            <div className="card-body">
                                                                <Chart options={avgVelocityChartOptions} series={[avgVelocitySeries]} type="bar" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-lg-12">
                                                        <div className="card mb-5">
                                                            <div className="card-header pt-7">
                                                                <h3 className="card-title align-items-start flex-column">
                                                                    <span className="card-label fs-3 fw-bold text-primary">Approval Rate & Daily Movements</span>
                                                                    <span className="text-gray-500 mt-1 fw-semibold fs-6">Tracking the approval rate and daily movements for the last 30 days</span>
                                                                </h3>
                                                            </div>
                                                            <div className="card-body">
                                                                {(combinedChartOptions && combinedSeries.length > 0) ? (
                                                                    <Chart options={combinedChartOptions} series={combinedChartOptions.series} />
                                                                ) : (
                                                                    <div className="spinner-border text-primary" role="status">
                                                                        <span className="sr-only">Loading data...</span>
                                                                    </div>
                                                                )}                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Footer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Analytics;