// PostPage.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from './Contexts/AuthContext';
import Header from './Header';
import Footer from './Footer';
import Post from './CommunityPost';
import { fetchPost } from './ApiService'; // A new API service function to fetch post by ID

function CommunityPostPage() {
    const { id } = useParams(); // Get the post ID from the route
    const { currentUser } = useAuth();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function loadPost() {
            try {
                const fetchedPost = await fetchPost(id);
                setPost(fetchedPost);
            } catch (error) {
                console.error("Failed to load post:", error);
            } finally {
                setLoading(false);
            }
        }

        if (id) {
            loadPost();
        }
    }, [id]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center mt-4">
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    if (!post) {
        return <div>Post not found</div>;
    }

    return (
        // <div className="container">
        //     <Post post={post} currentUser={currentUser} />
        // </div>

        <div id="kt_app_body" data-kt-app-header-fixed-mobile="true" data-kt-app-toolbar-enabled="true" className="app-default">
            <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
                <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
                    <Header />
                    <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                        <div id="kt_app_toolbar" className="app-toolbar pt-4 pt-lg-7 mb-n2 mb-lg-n3">
                            <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack flex-row-fluid">
                                <div className="d-flex flex-stack flex-row-fluid">
                                    <div className="d-flex flex-column flex-row-fluid">
                                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold mb-1 mb-lg-3 me-2 fs-7">
                                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                                                <span href="#" className="text-white text-hover-primary">
                                                    <i className="ki-outline ki-people text-gray-700 fs-6"></i>
                                                </span>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1"></i>
                                            </li>
                                            <li className="breadcrumb-item fs-4 fw-bold lh-1">
                                                <Link to={`/community`}>
                                                    <span className="text-gray-900">Community</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="app-container container-xxl d-flex">
                            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                                <div className="d-flex flex-column flex-column-fluid">
                                    <div id="kt_app_content" className="app-content">
                                        <div className='row'>
                                            <div className='col-12 col-md-12 col-lg-9'>
                                                {/* Begin::Post */}
                                                <Post post={post} currentUser={currentUser} />
                                                {/* End::Post */}
                                            </div>
                                        </div>
                                        <Footer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default CommunityPostPage;
