import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import Modal from "react-bootstrap/Modal";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAuth } from './Contexts/AuthContext';
import { isPetitionOwner } from './Utils.js'
import { fetchPetitions, fetchPetitionsSharedWithMe, deletePetition, addPetition } from './ApiService';
import Header from './Header';
// import PetitionList from './PetitionList';
import Footer from './Footer';


function Home() {
    const navigate = useNavigate();
    const { oauthAccessToken, currentUser, logout } = useAuth();

    const [petitions, setPetitions] = useState([]);
    const [petitionsSharedWithMe, setPetitionsSharedWithMe] = useState([]);
    const [loadingPetitions, setLoadingPetitions] = useState(false);
    const [loadingPetitionsSharedWithMe, setLoadingPetitionsSharedWithMe] = useState(false);
    const [error, setError] = useState(null);
    const [selectedOption, setSelectedOption] = useState('AD');
    const [activeStep, setActiveStep] = useState(1);
    const totalSteps = 3;


    const [formState, setFormState] = useState({
        petitionType: 'OriginalSubmission', // default selected petition type
        petitionerName: '',
        subCategory: selectedOption,
        userId: currentUser.uid
    });

    useEffect(() => {
        if (!currentUser) {
            navigate('/login');
            return;
        }

        const fetchAndSetPetitions = async () => {
            setLoadingPetitions(true);
            try {
                // console.log(currentUser);
                const petitionsData = await fetchPetitions();
                setPetitions(petitionsData);

            } catch (error) {
                setError(error.message);
                console.error("Failed to fetch petitions:", error);
            } finally {
                setLoadingPetitions(false);
            }
        };

        const fetchAndSetPetitionsSharedWithMe = async () => {
            setLoadingPetitionsSharedWithMe(true);
            try {
                // console.log(currentUser);
                const petitionsData = await fetchPetitionsSharedWithMe();
                setPetitionsSharedWithMe(petitionsData);

            } catch (error) {
                setError(error.message);
                console.error("Failed to fetch petitions:", error);
            } finally {
                setLoadingPetitionsSharedWithMe(false);
            }
        };

        fetchAndSetPetitions();
        fetchAndSetPetitionsSharedWithMe();

    }, [currentUser, navigate]);

    const handleNextStep = () => {
        // Get the current form inputs based on the active step
        const currentFormInputs = document.querySelectorAll(`.step-${activeStep} .form-control, .step-${activeStep} .form-select`);

        let isValid = true;
        currentFormInputs.forEach(input => {
            if (!input.checkValidity()) {
                isValid = false;
                input.classList.add('is-invalid'); // Optional: add styling or indication of error
            } else {
                input.classList.remove('is-invalid'); // Optional: clear any previous error indication
            }
        });

        if (!isValid) {
            // If current inputs are not valid, prevent moving to next step
            Swal.fire("Warning", 'Please upload at least one file.', "warning");
            return;
        }

        // If all inputs are valid, increment the step
        if (activeStep < totalSteps) {
            setActiveStep(activeStep + 1);
        }
    };

    const handlePrevStep = () => {
        if (activeStep > 1) {
            setActiveStep((prev) => prev - 1);
        }
    };

    const isStepActive = (stepNumber) => {

        if (activeStep === stepNumber) return "current";
        if (activeStep <= stepNumber) return "pending";
        if (activeStep >= stepNumber) return "completed";

    };

    // Hook que demonstra se a modal está aberta ou não
    const [modalIsOpen, setIsOpen] = React.useState(false);

    // Função que abre a modal
    function openModal() {
        setIsOpen(true);
    }

    // Função que fecha a modal
    function closeModal() {
        setIsOpen(false);
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleOptionChange = (event) => {
        const value = event.target.value;
        // console.log(value);
        setSelectedOption(value);
        // Update formState as well when the option changes
        setFormState(prevState => ({
            ...prevState,
            subCategory: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoadingPetitions(true);

        try {
            var docId = '';

            await addPetition(formState, currentUser.uid, currentUser.BaseFolderId, oauthAccessToken)
                .then((result) => {
                    docId = result.docId;

                    navigate(`/petition/${docId}?showTour=1`);
                })
                .catch((error) => {
                    console.error(error);
                    if (error.message === 'Unauthorized') {
                        logout();
                    } else {
                        Swal.fire("Error", 'An error occurred while creating petition. Please try again.', "error");
                    }
                });

        } catch (error) {
            setLoadingPetitions(false);
            console.error(error);
            if (error.message === 'Unauthorized') {
                logout();
            } else {
                Swal.fire("Error", 'An error occurred while creating petition. Please try again.', "error");
            }
        }
    };

    const deletePetitionConfirmation = async (petitionId) => {
        Swal.fire({
            customClass: {
                confirmButton: "btn btn-danger",
                cancelButton: "btn btn-light-info"
            },
            buttonsStyling: false,
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                deletePetitionCall(petitionId);
            }
        });
    }

    const deletePetitionCall = async (petitionId) => {
        setLoadingPetitions(true);

        try {
            await deletePetition(petitionId);
            // await deleteDocument({ docPath: `/petition/${petitionId}` });

            // await deletePetitionFunction({ docId: petitionId });
            setPetitions(petitions.filter(petition => petition.id !== petitionId));
        } catch (error) {
            console.error("Error deleting petition:", error);
            Swal.fire("Error", 'Failed to delete petition. Please try again.', "error");
        } finally {
            setLoadingPetitions(false);
        }
    };

    // if (loading) return (
    //     <div className="d-flex justify-content-center mt-4">
    //         <div className="spinner-border text-primary" role="status">
    //             <span className="sr-only">Loading...</span>
    //         </div>

    //     </div>
    // )

    if (error) return <div>Error loading petitions: {error}</div>;

    return (
        <>
            {/* <Loading isLoading={loading} /> */}
            <Modal show={modalIsOpen} onHide={closeModal} className="modal-lg">
                <form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create Petition</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/*  <!--begin::Modal body--> */}
                        <div className="scroll-y">
                            {/* <!--begin::Stepper--> */}
                            <div className="stepper stepper-links d-flex flex-column" id="kt_create_account_stepper">
                                {/* <!--begin::Nav--> */}
                                <div className="stepper-nav py-5">
                                    {/* <!--begin::Step 1--> */}
                                    <div className={`stepper-item ${isStepActive(1)}`} data-kt-stepper-element="nav">
                                        <h3 className="stepper-title">Petition Type</h3>
                                    </div>
                                    {/* <!--end::Step 1--> */}
                                    {/* <!--begin::Step 2--> */}
                                    <div className={`stepper-item ${isStepActive(2)}`} data-kt-stepper-element="nav">
                                        <h3 className="stepper-title">Petitioner's Details</h3>
                                    </div>
                                    {/* <!--end::Step 2--> */}
                                    {/* <!--begin::Step 2--> */}
                                    <div className={`stepper-item ${isStepActive(3)}`} data-kt-stepper-element="nav">
                                        <h3 className="stepper-title">Proposed Endeavor</h3>
                                    </div>
                                    {/* <!--end::Step 2--> */}
                                </div>
                                {/* <!--end::Nav--> */}
                                {/* <!--begin::Form--> */}
                                {/* <form className="mx-auto mw-600px w-100 py-10" noValidate="novalidate" id="kt_create_account_form"> */}
                                {/* <!--begin::Step 1--> */}
                                <div className={`${isStepActive(1)} step-1`} data-kt-stepper-element="content">
                                    {/* <!--begin::Wrapper--> */}
                                    <div className="w-100">
                                        {/* <!--begin::Input group--> */}
                                        <div className="mb-0 fv-row">
                                            {/* <!--begin::Label--> */}
                                            <label className="d-flex align-items-center form-label mb-5">Select sub-category
                                                <span className="ms-1" data-bs-toggle="tooltip" title="Monthly billing will be based on your account plan">
                                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                        <span className="path3"></span>
                                                    </i>
                                                </span></label>
                                            {/* <!--end::Label--> */}
                                            {/* <!--begin::Options--> */}
                                            <div className="mb-0">
                                                {/* <!--begin:Option--> */}
                                                <label className="d-flex flex-stack mb-5 cursor-pointer">
                                                    {/* <!--begin:Label--> */}
                                                    <span className="d-flex align-items-center me-2">
                                                        {/* <!--begin::Icon--> */}
                                                        <span className="symbol symbol-50px me-6">
                                                            <span className="symbol-label">
                                                                <i className="ki-duotone ki-teacher fs-1 text-gray-600">
                                                                    <span className="path1"></span>
                                                                    <span className="path2"></span>
                                                                </i>
                                                            </span>
                                                        </span>
                                                        {/* <!--end::Icon--> */}
                                                        {/* <!--begin::Description--> */}
                                                        <span className="d-flex flex-column">
                                                            <span className="fw-bold text-gray-800 text-hover-primary fs-5">Advanced Degree</span>
                                                            <span className="fs-6 fw-semibold text-muted">You must have a U.S. baccalaureate degree and 5 years of experience or a U.S. advanced degree.</span>
                                                        </span>
                                                        {/* <!--end:Description--> */}
                                                    </span>
                                                    {/* <!--end:Label--> */}
                                                    {/* <!--begin:Input--> */}
                                                    <span className="form-check form-check-custom form-check-solid">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="subCategory"
                                                            value="AD"
                                                            checked={selectedOption === 'AD'}
                                                            onChange={handleOptionChange}
                                                        />
                                                        {/* <input className="form-check-input" type="radio" name="account_plan" value="1" /> */}
                                                        {/* <select name="subCategory" value={formState.subCategory} onChange={handleInputChange}> */}
                                                    </span>
                                                    {/* <!--end:Input--> */}
                                                </label>
                                                {/* <!--end::Option--> */}
                                                {/* <!--begin:Option--> */}
                                                <label className="d-flex flex-stack mb-5 cursor-pointer">
                                                    {/* <!--begin:Label--> */}
                                                    <span className="d-flex align-items-center me-2">
                                                        {/* <!--begin::Icon--> */}
                                                        <span className="symbol symbol-50px me-6">
                                                            <span className="symbol-label">
                                                                <i className="ki-duotone ki-chart-pie-3 fs-1 text-gray-600">
                                                                    <span className="path1"></span>
                                                                    <span className="path2"></span>
                                                                </i>
                                                            </span>
                                                        </span>
                                                        {/* <!--end::Icon--> */}
                                                        {/* <!--begin::Description--> */}
                                                        <span className="d-flex flex-column">
                                                            <span className="fw-bold text-gray-800 text-hover-primary fs-5">Exceptional Ability</span>
                                                            <span className="fs-6 fw-semibold text-muted">You must be able to show exceptional ability in the sciences, arts, or business.</span>
                                                        </span>
                                                        {/* <!--end:Description--> */}
                                                    </span>
                                                    {/* <!--end:Label--> */}
                                                    {/* <!--begin:Input--> */}
                                                    <span className="form-check form-check-custom form-check-solid">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="subCategory"
                                                            value="EA"
                                                            checked={selectedOption === 'EA'}
                                                            onChange={handleOptionChange}
                                                        />

                                                        {/* <input className="form-check-input" type="radio" name="account_plan" value="2" /> */}
                                                    </span>
                                                    {/* <!--end:Input--> */}
                                                </label>
                                                {/* <!--end::Option--> */}
                                            </div>
                                            {/* <!--end::Options--> */}
                                        </div>
                                        {/* <!--end::Input group--> */}
                                    </div>
                                    {/* <!--end::Wrapper--> */}
                                </div>
                                {/* <!--end::Step 1--> */}
                                {/* <!--begin::Step 2--> */}
                                <div className={`${isStepActive(2)} step-2`} data-kt-stepper-element="content">
                                    {/* <!--begin::Wrapper--> */}
                                    <div className="w-100">
                                        {/* <!--begin::Input group--> */}
                                        <div className="mb-10 fv-row">
                                            {/* <!--begin::Label--> */}
                                            <label className="form-label mb-3 required">Petitioner's name</label>
                                            {/* <!--end::Label--> */}
                                            {/* <!--begin::Input--> */}
                                            <input type="text" className="form-control form-control-lg form-control-solid" name="petitionerName" value={formState.petitionerName} onChange={handleInputChange} required />

                                            {/* <input type="text" className="form-control form-control-lg form-control-solid" name="account_name" placeholder="" value="" /> */}
                                            {/* <!--end::Input--> */}
                                        </div>
                                        {/* <!--end::Input group--> */}
                                        {/* <!--begin::Input group--> */}
                                        <div className="mb-10 fv-row">
                                            <label className="d-flex align-items-center form-label mb-5 required">Place of Birth
                                            </label>
                                            <select
                                                name="placeOfBirth"
                                                className="form-select form-select-lg form-select-solid"
                                                value={formState.placeOfBirth}
                                                onChange={handleInputChange}
                                                data-control="select2"
                                                data-placeholder="Select..."
                                                data-allow-clear="true"
                                                data-hide-search="true"
                                                required
                                            >
                                                <option value="">Select country...</option>
                                                <option value="Afghanistan">Afghanistan</option>
                                                <option value="Albania">Albania</option>
                                                <option value="Algeria">Algeria</option>
                                                <option value="Argentina">Argentina</option>
                                                <option value="Australia">Australia</option>
                                                <option value="Austria">Austria</option>
                                                <option value="Bangladesh">Bangladesh</option>
                                                <option value="Belarus">Belarus</option>
                                                <option value="Belgium">Belgium</option>
                                                <option value="Brazil">Brazil</option>
                                                <option value="Bulgaria">Bulgaria</option>
                                                <option value="Canada">Canada</option>
                                                <option value="Chile">Chile</option>
                                                <option value="China">China</option>
                                                <option value="Colombia">Colombia</option>
                                                <option value="Croatia">Croatia</option>
                                                <option value="Cuba">Cuba</option>
                                                <option value="CzechRepublic">Czech Republic</option>
                                                <option value="Denmark">Denmark</option>
                                                <option value="Egypt">Egypt</option>
                                                <option value="Estonia">Estonia</option>
                                                <option value="Ethiopia">Ethiopia</option>
                                                <option value="Finland">Finland</option>
                                                <option value="France">France</option>
                                                <option value="Germany">Germany</option>
                                                <option value="Ghana">Ghana</option>
                                                <option value="Greece">Greece</option>
                                                <option value="Hungary">Hungary</option>
                                                <option value="Iceland">Iceland</option>
                                                <option value="India">India</option>
                                                <option value="Indonesia">Indonesia</option>
                                                <option value="Iran">Iran</option>
                                                <option value="Iraq">Iraq</option>
                                                <option value="Ireland">Ireland</option>
                                                <option value="Israel">Israel</option>
                                                <option value="Italy">Italy</option>
                                                <option value="Jamaica">Jamaica</option>
                                                <option value="Japan">Japan</option>
                                                <option value="Jordan">Jordan</option>
                                                <option value="Kenya">Kenya</option>
                                                <option value="Latvia">Latvia</option>
                                                <option value="Lebanon">Lebanon</option>
                                                <option value="Lithuania">Lithuania</option>
                                                <option value="Malaysia">Malaysia</option>
                                                <option value="Mexico">Mexico</option>
                                                <option value="Morocco">Morocco</option>
                                                <option value="Nepal">Nepal</option>
                                                <option value="Netherlands">Netherlands</option>
                                                <option value="NewZealand">New Zealand</option>
                                                <option value="Nigeria">Nigeria</option>
                                                <option value="Norway">Norway</option>
                                                <option value="Pakistan">Pakistan</option>
                                                <option value="Peru">Peru</option>
                                                <option value="Philippines">Philippines</option>
                                                <option value="Poland">Poland</option>
                                                <option value="Portugal">Portugal</option>
                                                <option value="Romania">Romania</option>
                                                <option value="Russia">Russia</option>
                                                <option value="SaudiArabia">Saudi Arabia</option>
                                                <option value="Serbia">Serbia</option>
                                                <option value="Singapore">Singapore</option>
                                                <option value="Slovakia">Slovakia</option>
                                                <option value="Slovenia">Slovenia</option>
                                                <option value="SouthAfrica">South Africa</option>
                                                <option value="SouthKorea">South Korea</option>
                                                <option value="Spain">Spain</option>
                                                <option value="SriLanka">Sri Lanka</option>
                                                <option value="Sweden">Sweden</option>
                                                <option value="Switzerland">Switzerland</option>
                                                <option value="Syria">Syria</option>
                                                <option value="Taiwan">Taiwan</option>
                                                <option value="Thailand">Thailand</option>
                                                <option value="Tunisia">Tunisia</option>
                                                <option value="Turkey">Turkey</option>
                                                <option value="Ukraine">Ukraine</option>
                                                <option value="UnitedArabEmirates">United Arab Emirates</option>
                                                <option value="UnitedKingdom">United Kingdom</option>
                                                <option value="UnitedStates">United States</option>
                                                <option value="Venezuela">Venezuela</option>
                                                <option value="Vietnam">Vietnam</option>
                                                <option value="Yemen">Yemen</option>
                                                <option value="Zimbabwe">Zimbabwe</option>
                                            </select>
                                        </div>
                                        {/* <!--end::Input group--> */}
                                        {/* <!--begin::Input group--> */}
                                        <div className="mb-0 fv-row">
                                            <label className="d-flex align-items-center form-label mb-5 required">Industry
                                            </label>
                                            <select
                                                name="industry"
                                                className="form-select form-select-lg form-select-solid"
                                                value={formState.industry}
                                                onChange={handleInputChange}
                                                data-control="select2"
                                                data-placeholder="Select..."
                                                data-allow-clear="true"
                                                data-hide-search="true"
                                                required
                                            >
                                                <option value="">Select industry...</option>
                                                <option value="Agriculture">Agriculture</option>
                                                <option value="Automotive">Automotive</option>
                                                <option value="Aviation">Aviation</option>
                                                <option value="Biotechnology">Biotechnology</option>
                                                <option value="Chemical">Chemical</option>
                                                <option value="Communications">Communications</option>
                                                <option value="Construction">Construction</option>
                                                <option value="Consulting">Consulting</option>
                                                <option value="ConsumerGoods">Consumer Goods</option>
                                                <option value="Education">Education</option>
                                                <option value="Electronics">Electronics</option>
                                                <option value="Energy">Energy</option>
                                                <option value="Engineering">Engineering</option>
                                                <option value="Entertainment">Entertainment</option>
                                                <option value="Environmental">Environmental</option>
                                                <option value="Finance">Finance</option>
                                                <option value="FoodAndBeverage">Food & Beverage</option>
                                                <option value="Government">Government</option>
                                                <option value="Healthcare">Healthcare</option>
                                                <option value="Hospitality">Hospitality</option>
                                                <option value="Insurance">Insurance</option>
                                                <option value="Legal">Legal</option>
                                                <option value="Manufacturing">Manufacturing</option>
                                                <option value="Marketing">Marketing</option>
                                                <option value="Media">Media</option>
                                                <option value="Mining">Mining</option>
                                                <option value="NonProfit">Non-Profit</option>
                                                <option value="Pharmaceutical">Pharmaceutical</option>
                                                <option value="ProfessionalServices">Professional Services</option>
                                                <option value="RealEstate">Real Estate</option>
                                                <option value="Retail">Retail</option>
                                                <option value="Technology">Technology</option>
                                                <option value="Telecommunications">Telecommunications</option>
                                                <option value="Textile">Textile</option>
                                                <option value="Transportation">Transportation</option>
                                                <option value="Utilities">Utilities</option>
                                                <option value="Veterinary">Veterinary</option>
                                                <option value="Wholesale">Wholesale</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                        {/* <!--end::Input group--> */}
                                    </div>
                                    {/* <!--end::Wrapper--> */}
                                </div>
                                {/* <!--end::Step 2--> */}
                                {/* <!--begin::Step 2--> */}
                                <div className={`${isStepActive(3)} step-3`} data-kt-stepper-element="content">
                                    {/* <!--begin::Wrapper--> */}
                                    <div className="w-100">
                                        {/* <!--begin::Input group--> */}
                                        <div className="mb-10 fv-row">
                                            {/* <!--begin::Label--> */}
                                            <label className="form-label mb-3 required">State of Proposed Endeavor
                                                <span className="ms-1" data-bs-toggle="tooltip" title="Please select the U.S. state where you intend to execute your proposed endeavor. You may update this information at any time should your plans change.">
                                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                        <span className="path3"></span>
                                                    </i>
                                                </span></label>
                                            {/* <!--end::Label--> */}
                                            <select
                                                name="stateOfEndeavor"
                                                className="form-select form-select-lg form-select-solid"
                                                value={formState.stateOfEndeavor}
                                                onChange={handleInputChange}
                                                data-control="select2"
                                                data-placeholder="Select..."
                                                data-allow-clear="true"
                                                data-hide-search="true"
                                                required
                                            >
                                                <option value="">Select state...</option>
                                                <option value="Alabama">Alabama</option>
                                                <option value="Alaska">Alaska</option>
                                                <option value="Arizona">Arizona</option>
                                                <option value="Arkansas">Arkansas</option>
                                                <option value="California">California</option>
                                                <option value="Colorado">Colorado</option>
                                                <option value="Connecticut">Connecticut</option>
                                                <option value="Delaware">Delaware</option>
                                                <option value="DistrictOfColumbia">District of Columbia</option>
                                                <option value="Florida">Florida</option>
                                                <option value="Georgia">Georgia</option>
                                                <option value="Hawaii">Hawaii</option>
                                                <option value="Idaho">Idaho</option>
                                                <option value="Illinois">Illinois</option>
                                                <option value="Indiana">Indiana</option>
                                                <option value="Iowa">Iowa</option>
                                                <option value="Kansas">Kansas</option>
                                                <option value="Kentucky">Kentucky</option>
                                                <option value="Louisiana">Louisiana</option>
                                                <option value="Maine">Maine</option>
                                                <option value="Maryland">Maryland</option>
                                                <option value="Massachusetts">Massachusetts</option>
                                                <option value="Michigan">Michigan</option>
                                                <option value="Minnesota">Minnesota</option>
                                                <option value="Mississippi">Mississippi</option>
                                                <option value="Missouri">Missouri</option>
                                                <option value="Montana">Montana</option>
                                                <option value="Nebraska">Nebraska</option>
                                                <option value="Nevada">Nevada</option>
                                                <option value="NewHampshire">New Hampshire</option>
                                                <option value="NewJersey">New Jersey</option>
                                                <option value="NewMexico">New Mexico</option>
                                                <option value="NewYork">New York</option>
                                                <option value="NorthCarolina">North Carolina</option>
                                                <option value="NorthDakota">North Dakota</option>
                                                <option value="Ohio">Ohio</option>
                                                <option value="Oklahoma">Oklahoma</option>
                                                <option value="Oregon">Oregon</option>
                                                <option value="Pennsylvania">Pennsylvania</option>
                                                <option value="PuertoRico">Puerto Rico</option>
                                                <option value="RhodeIsland">Rhode Island</option>
                                                <option value="SouthCarolina">South Carolina</option>
                                                <option value="SouthDakota">South Dakota</option>
                                                <option value="Tennessee">Tennessee</option>
                                                <option value="Texas">Texas</option>
                                                <option value="Utah">Utah</option>
                                                <option value="Vermont">Vermont</option>
                                                <option value="Virginia">Virginia</option>
                                                <option value="Washington">Washington</option>
                                                <option value="WestVirginia">West Virginia</option>
                                                <option value="Wisconsin">Wisconsin</option>
                                                <option value="Wyoming">Wyoming</option>
                                            </select>
                                        </div>
                                        {/* <!--end::Input group--> */}
                                        {/* <!--begin::Input group--> */}
                                        <div className="mb-0 fv-row">
                                            {/* <!--begin::Label--> */}
                                            <label className="form-label mb-3 required">Brief overview of your Proposed Endeavor
                                                <span className="ms-1" data-bs-toggle="tooltip" title="Provide a concise summary of your proposed endeavor. This should capture the essence of your project or activity.">
                                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                        <span className="path3"></span>
                                                    </i>
                                                </span></label>
                                            {/* <!--end::Label--> */}
                                            <textarea className="form-control form-control form-control-solid" data-kt-autosize="true" name="endeavorSummary" value={formState.endeavorSummary} onChange={handleInputChange} required></textarea>
                                        </div>
                                        {/* <!--end::Input group--> */}
                                    </div>
                                    {/* <!--end::Wrapper--> */}
                                </div>
                                {/* <!--end::Step 2--> */}
                                {/* </form> */}
                                {/* <!--end::Form--> */}
                            </div>
                            {/* <!--end::Stepper--> */}
                        </div>
                        {/*  <!--end::Modal body--> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn btn-light-secondary" onClick={closeModal}>
                            Close
                        </span>

                        {activeStep > 1 && (
                            <span className="btn btn-light-primary me-3" onClick={handlePrevStep}>
                                <i className="ki-duotone ki-arrow-left fs-4 me-1">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>Back
                            </span>
                        )}

                        {activeStep < totalSteps ? (
                            <span className="btn btn-primary" onClick={handleNextStep}>
                                Continue
                                <i className="ki-duotone ki-arrow-right fs-4 ms-1 me-0">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>
                            </span>
                        ) : (
                            <button type="submit" className="btn btn-primary">
                                Submit
                            </button>
                        )}

                    </Modal.Footer>
                </form>
            </Modal>

            <div id="kt_app_body" data-kt-app-header-fixed-mobile="true" data-kt-app-toolbar-enabled="true" className="app-default">
                {/* <!--begin::Theme mode setup on page load--> */}
                {/* <!--end::Theme mode setup on page load--> */}
                {/* <!--begin::App--> */}
                <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
                    {/* <!--begin::Page--> */}
                    <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
                        {/* <!--begin::Header--> */}
                        <Header />
                        {/* <!--end::Header--> */}
                        {/* <!--begin::Wrapper--> */}
                        <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                            {/* <!--begin::Toolbar--> */}
                            <div id="kt_app_toolbar" className="app-toolbar pt-4 pt-lg-7 mb-n2 mb-lg-n3">
                                {/* <!--begin::Toolbar container--> */}
                                <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack flex-row-fluid">
                                    {/* <!--begin::Toolbar container--> */}
                                    <div className="d-flex flex-stack flex-row-fluid">
                                        {/* <!--begin::Toolbar container--> */}
                                        <div className="d-flex flex-column flex-row-fluid">
                                            {/* <!--begin::Toolbar wrapper--> */}
                                            {/* <!--begin::Breadcrumb--> */}
                                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold mb-1 mb-lg-3 me-2 fs-7">
                                                {/* <!--begin::Item--> */}
                                                <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                                                    <span href="#" className="text-white text-hover-primary">
                                                        <i className="ki-outline ki-home text-gray-700 fs-6"></i>
                                                    </span>
                                                </li>
                                                {/* <!--end::Item--> */}
                                                <li className="breadcrumb-item">
                                                    <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1"></i>
                                                </li>
                                                <li className="breadcrumb-item text-gray-900 fs-4 fw-bold lh-1">Petitions</li>

                                            </ul>
                                            {/* <!--end::Breadcrumb--> */}
                                        </div>
                                        {/* <!--end::Toolbar container--> */}
                                        {/* <!--begin::Actions--> */}
                                        {/* <!--end::Actions--> */}
                                    </div>
                                    {/* <!--end::Toolbar container--> */}
                                </div>
                                {/* <!--end::Toolbar container--> */}
                            </div>
                            {/* <!--end::Toolbar--> */}

                            {/* <!--begin::Wrapper container--> */}
                            <div className="app-container container-xxl d-flex">
                                {/* <!--begin::Main--> */}
                                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                                    {/* <!--begin::Content wrapper--> */}
                                    <div className="d-flex flex-column flex-column-fluid">
                                        {/* <!--begin::Content--> */}
                                        <div id="kt_app_content" className="app-content">
                                            {/* <!--begin::Row--> */}
                                            <div className="row g-5 g-xxl-10">
                                                {/* <!--begin::Col--> */}
                                                <div className="col-xxl-12 mb-xxl-10">
                                                    {/* <!--begin::Card Widget 22--> */}
                                                    <div className="card card-reset mb-5 mb-xl-10">
                                                        {/* <!--begin::Body--> */}
                                                        <div className="card-body p-0">
                                                            {/* <!--begin::Row--> */}
                                                            <div className="row g-5 g-lg-9">

                                                                <div className="row">

                                                                    {/* <!--begin::Toolbar--> */}
                                                                    <div class="d-flex flex-wrap flex-stack my-5">
                                                                        {/* <!--begin::Heading--> */}
                                                                        <h2 class="fs-2 fw-semibold my-2">My Petitions
                                                                            <span class="fs-6 text-gray-500 ms-1">Petitions created by me</span></h2>
                                                                        {/* <!--end::Heading--> */}
                                                                    </div>
                                                                    {/* <!--end::Toolbar--> */}

                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                        <div className="row">

                                                                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                                                                <div className="card border border-primary border-dashed rounded bg-light-primary" onClick={openModal} style={{ cursor: 'pointer' }}>
                                                                                    <div className="card-body py-12">
                                                                                        <div className="d-flex">
                                                                                            <span className="me-5">
                                                                                                <i className="ki-outline ki-plus fs-3x text-primary"></i>
                                                                                            </span>
                                                                                            <div className="me-2">
                                                                                                <span href="#" className="text-gray-800 text-hover-primary fs-4 fw-bolder">New Petition!</span>
                                                                                                <span className="text-gray-700 fw-semibold d-block fs-5">An Original Submission or RFE.</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {petitions.map(petition => (
                                                                                <div key={petition.id} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-5">
                                                                                    {/* <!--begin::Petition item--> */}
                                                                                    <div className="card h-100">
                                                                                        {/* <!--begin::Body--> */}
                                                                                        <Link to={`/petition/${petition.id}`}>
                                                                                            {/* <div className="card-body pt-10 px-0 border border-primary rounded bg-hover-light-dark"> */}
                                                                                            <div className="card-body p-9">
                                                                                                {/* <!--begin::Member--> */}
                                                                                                <div className="d-flex flex-column text-center mb-9 px-9">
                                                                                                    {/* <!--begin::Stat--> */}
                                                                                                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3 me-6 ms-6">
                                                                                                        {/* <!--begin::Number--> */}
                                                                                                        <div className="align-items-center">
                                                                                                            <div className="fs-1 fw-bold text-primary">{petition.engagementPercentile}%</div>
                                                                                                        </div>
                                                                                                        {/* <!--end::Number--> */}
                                                                                                        {/* <!--begin::Label--> */}
                                                                                                        <div className="fw-semibold fs-6 text-gray-500">EB Genius Ranking
                                                                                                            <span className="ms-1" data-bs-toggle="tooltip" title="A higher percentile shows your petition has more frequent and varied activities compared to most others in EB Genius' database. For example, a percentile of 75% means your petition's engagement is higher than 75% of all petitions, emphasizing your strong participation and areas for further enhancement.">
                                                                                                                <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                                                                                    <span className="path1"></span>
                                                                                                                    <span className="path2"></span>
                                                                                                                    <span className="path3"></span>
                                                                                                                </i>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        {/* <!--end::Label--> */}
                                                                                                    </div>
                                                                                                    {/* <!--end::Stat--> */}
                                                                                                    {/* <!--begin::Info--> */}
                                                                                                    <div className="text-center">
                                                                                                        {/* <!--begin::Name--> */}
                                                                                                        <span className="fw-bold fs-4 text-gray-800">{petition.petitionerName}</span>
                                                                                                        {/* <!--end::Name--> */}
                                                                                                        {/* <!--begin::Petition--> */}
                                                                                                        <span className="text-muted d-block fw-semibold">{petition.placeOfBirth}</span>
                                                                                                        {petition.receiptNumber && (
                                                                                                            <span className="text-muted d-block fw-semibold text-gray-800">{petition.receiptNumber}</span>
                                                                                                        )}
                                                                                                        {/* <!--end::Position--> */}
                                                                                                    </div>
                                                                                                    {/* <!--end::Info--> */}
                                                                                                </div>
                                                                                                {/* <!--end::Member--> */}
                                                                                                {/* <!--begin::Navbar--> */}
                                                                                                <div className="m-0">
                                                                                                    <div id="kt_job_1_1" className="collapse show fs-6 ms-1">
                                                                                                        {/* <!--begin::Item--> */}
                                                                                                        <div className="mb-4 fw-bold">
                                                                                                            <div className="d-flex align-items-center ps-10 mb-n1 text-gray-700">
                                                                                                                {petition.subCategory === "AD" && (
                                                                                                                    <>
                                                                                                                        <i className="ki-outline ki-teacher fs-3 text-muted me-3"></i>Advanced Degree
                                                                                                                    </>
                                                                                                                )}
                                                                                                                {petition.subCategory === "EA" && (
                                                                                                                    <>
                                                                                                                        <i className="ki-outline ki-medal-star fs-3 text-muted me-3"></i>Exceptional Ability
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {/* <!--end::Item--> */}
                                                                                                        <div className="mb-4 fw-bold">
                                                                                                            <div className="d-flex align-items-center ps-10 mb-n1 text-gray-700">
                                                                                                                <i className="ki-outline ki-wrench fs-3 text-muted me-3"></i>{petition.industry}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="mb-4 fw-bold">
                                                                                                            <div className="d-flex align-items-center ps-10 mb-n1 text-gray-700">
                                                                                                                <i className="ki-outline ki-pin fs-3 text-muted me-3"></i>{petition.stateOfEndeavor}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {/* <!--end::Navbar--> */}
                                                                                            </div>
                                                                                        </Link>
                                                                                        {/* <!--end::Body--> */}

                                                                                        {/* <!-- Display shared icon if petition is shared --> */}
                                                                                        {petition.SharedWithArray && petition.SharedWithArray.length > 0 && (
                                                                                            <div className="position-absolute top-0 end-0 m-3">
                                                                                                <i className="ki-outline ki-share fs-2 text-primary" title="Petition Shared"></i>
                                                                                            </div>
                                                                                        )}

                                                                                        {/* <!-- Delete link positioned at the bottom-right corner of the card --> */}
                                                                                        {isPetitionOwner(petition, currentUser) ? (
                                                                                            <span span className="btn btn-light-danger" style={{
                                                                                                position: 'absolute',
                                                                                                bottom: '10px',
                                                                                                right: '10px',
                                                                                                padding: '5px 10px',
                                                                                            }} onClick={() => deletePetitionConfirmation(petition.id)}>Delete</span>
                                                                                        ) : (<></>)}
                                                                                    </div>
                                                                                    {/* <!--end::Petition item--> */}
                                                                                </div>
                                                                            ))}

                                                                            {(loadingPetitions) ? (
                                                                                <div className="spinner-border text-primary" role="status">
                                                                                    <span className="sr-only">Loading data...</span>
                                                                                </div>
                                                                            ) : (<></>)}

                                                                        </div>
                                                                    </div>

                                                                    {/* <!--begin::Toolbar--> */}
                                                                    <div class="d-flex flex-wrap flex-stack my-5">
                                                                        {/* <!--begin::Heading--> */}
                                                                        <h2 class="fs-2 fw-semibold my-2">Shared With Me
                                                                            <span class="fs-6 text-gray-500 ms-1">Petitions shared with me</span></h2>
                                                                        {/* <!--end::Heading--> */}
                                                                    </div>
                                                                    {/* <!--end::Toolbar--> */}

                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                        <div className="row">

                                                                            {petitionsSharedWithMe.length > 0 ? (
                                                                                petitionsSharedWithMe.map(petition => (
                                                                                    <div key={petition.id} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-5">
                                                                                        {/* <!--begin::Petition item--> */}
                                                                                        <div className="card h-100">
                                                                                            {/* <!--begin::Body--> */}
                                                                                            <Link to={`/petition/${petition.id}`}>
                                                                                                {/* <div className="card-body pt-10 px-0 border border-primary rounded bg-hover-light-dark"> */}
                                                                                                <div className="card-body p-9">
                                                                                                    {/* <!--begin::Member--> */}
                                                                                                    <div className="d-flex flex-column text-center mb-9 px-9">
                                                                                                        {/* <!--begin::Stat--> */}
                                                                                                        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3 me-6 ms-6">
                                                                                                            {/* <!--begin::Number--> */}
                                                                                                            <div className="align-items-center">
                                                                                                                <div className="fs-1 fw-bold text-primary">{petition.engagementPercentile}%</div>
                                                                                                            </div>
                                                                                                            {/* <!--end::Number--> */}
                                                                                                            {/* <!--begin::Label--> */}
                                                                                                            <div className="fw-semibold fs-6 text-gray-500">EB Genius Ranking
                                                                                                                <span className="ms-1" data-bs-toggle="tooltip" title="A higher percentile shows your petition has more frequent and varied activities compared to most others in EB Genius' database. For example, a percentile of 75% means your petition's engagement is higher than 75% of all petitions, emphasizing your strong participation and areas for further enhancement.">
                                                                                                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                                                                                        <span className="path1"></span>
                                                                                                                        <span className="path2"></span>
                                                                                                                        <span className="path3"></span>
                                                                                                                    </i>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            {/* <!--end::Label--> */}
                                                                                                        </div>
                                                                                                        {/* <!--end::Stat--> */}
                                                                                                        {/* <!--begin::Info--> */}
                                                                                                        <div className="text-center">
                                                                                                            {/* <!--begin::Name--> */}
                                                                                                            <span className="fw-bold fs-4 text-gray-800">{petition.petitionerName}</span>
                                                                                                            {/* <!--end::Name--> */}
                                                                                                            {/* <!--begin::Petition--> */}
                                                                                                            <span className="text-muted d-block fw-semibold">{petition.placeOfBirth}</span>
                                                                                                            {petition.receiptNumber && (
                                                                                                                <span className="text-muted d-block fw-semibold text-gray-800">{petition.receiptNumber}</span>
                                                                                                            )}
                                                                                                            {/* <!--end::Position--> */}
                                                                                                        </div>
                                                                                                        {/* <!--end::Info--> */}
                                                                                                    </div>
                                                                                                    {/* <!--end::Member--> */}
                                                                                                    {/* <!--begin::Navbar--> */}
                                                                                                    <div className="m-0">
                                                                                                        <div id="kt_job_1_1" className="collapse show fs-6 ms-1">
                                                                                                            {/* <!--begin::Item--> */}
                                                                                                            <div className="mb-4 fw-bold">
                                                                                                                <div className="d-flex align-items-center ps-10 mb-n1 text-gray-700">
                                                                                                                    {petition.subCategory === "AD" && (
                                                                                                                        <>
                                                                                                                            <i className="ki-outline ki-teacher fs-3 text-muted me-3"></i>Advanced Degree
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                    {petition.subCategory === "EA" && (
                                                                                                                        <>
                                                                                                                            <i className="ki-outline ki-medal-star fs-3 text-muted me-3"></i>Exceptional Ability
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            {/* <!--end::Item--> */}
                                                                                                            <div className="mb-4 fw-bold">
                                                                                                                <div className="d-flex align-items-center ps-10 mb-n1 text-gray-700">
                                                                                                                    <i className="ki-outline ki-wrench fs-3 text-muted me-3"></i>{petition.industry}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="mb-4 fw-bold">
                                                                                                                <div className="d-flex align-items-center ps-10 mb-n1 text-gray-700">
                                                                                                                    <i className="ki-outline ki-pin fs-3 text-muted me-3"></i>{petition.stateOfEndeavor}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* <!--end::Navbar--> */}
                                                                                                </div>
                                                                                            </Link>
                                                                                            {/* <!--end::Body--> */}

                                                                                            {/* <!-- Display shared icon if petition is shared --> */}
                                                                                            {petition.SharedWithArray && petition.SharedWithArray.length > 0 && (
                                                                                                <div className="position-absolute top-0 end-0 m-3">
                                                                                                    <i className="ki-outline ki-share fs-2 text-primary" title="Petition Shared"></i>
                                                                                                </div>
                                                                                            )}

                                                                                            {/* <!-- Delete link positioned at the bottom-right corner of the card --> */}
                                                                                            {isPetitionOwner(petition, currentUser) ? (
                                                                                                <span span className="btn btn-light-danger" style={{
                                                                                                    position: 'absolute',
                                                                                                    bottom: '10px',
                                                                                                    right: '10px',
                                                                                                    padding: '5px 10px',
                                                                                                }} onClick={() => deletePetitionConfirmation(petition.id)}>Delete</span>
                                                                                            ) : (<></>)}
                                                                                        </div>
                                                                                        {/* <!--end::Petition item--> */}
                                                                                    </div>
                                                                                ))
                                                                            ) : (<span>No shared petitions</span>)}

                                                                            {(loadingPetitionsSharedWithMe) ? (
                                                                                <div className="spinner-border text-primary" role="status">
                                                                                    <span className="sr-only">Loading data...</span>
                                                                                </div>
                                                                            ) : (<></>)}

                                                                        </div>
                                                                    </div>

                                                                </div >

                                                            </div>
                                                        </div>
                                                        {/* <!--end::Body--> */}
                                                    </div>
                                                    {/* <!--end::Card Widget 22--> */}
                                                </div>
                                                {/* <!--end::Col--> */}
                                            </div>
                                            {/* <!--end::Row--> */}
                                        </div>
                                        {/* <!--end::Content--> */}
                                    </div>
                                    {/* <!--end::Content wrapper--> */}
                                    {/* <!--begin::Footer--> */}
                                    <Footer />
                                    {/* <!--end::Footer--> */}
                                </div>
                                {/* <!--end:::Main--> */}
                            </div>
                            {/* <!--end::Wrapper container--> */}

                        </div>
                        {/* <!--end::Wrapper--> */}
                    </div>
                    {/* <!--end::Page--> */}
                </div>
            </div >
        </>
    );
}

export default Home;
