import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './Login';
import Home from './Home';
import PetitionDetail from './PetitionDetail';
import { useAuth } from './Contexts/AuthContext';
import ExhibitDetail from './ExhibitDetail';
import EBGLab from './EBGLab';
import Analytics from './Analytics';
import Community from './Community';
import CommunityPostPage from './CommunityPostPage';
import Settings from './Settings';
import AdminPage from './AdminPage';

const AppRoutes = () => {
    const { currentUser, loading, loginInProgress } = useAuth();

    if (loading || loginInProgress) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    const isAdmin = currentUser && currentUser.uid === "QFBK9I6akDOmOSzsPqQwuR49UsI2"; // Check if the current user is the admin

    return (
        <Routes>
            <Route path="/" element={!currentUser ? <Login /> : <Navigate to="/home" replace />} />
            <Route path="/home" element={currentUser ? <Home /> : <Navigate to="/" replace />} />
            <Route path="/login" element={<Login />} />
            <Route path="/lab" element={<EBGLab />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/community" element={<Community />} />
            <Route path="/community/post/:id" element={<CommunityPostPage />} />
            <Route path="/admin" element={isAdmin ? <AdminPage /> : <Navigate to="/home" replace />} />

            <Route path="/petition/:petitionId/" element={currentUser ? <PetitionDetail type="OriginalSubmission" /> : <Navigate to="/" replace />} />
            <Route path="/petition/:petitionId/exhibit/:exhibitId" element={currentUser ? <ExhibitDetail type="OriginalSubmission" /> : <Navigate to="/" replace />} />
            <Route path="/petition/:petitionId/rfe" element={currentUser ? <PetitionDetail type="RFEResponse" /> : <Navigate to="/" replace />} />
            <Route path="/petition/:petitionId/rfe/exhibit/:exhibitId" element={currentUser ? <ExhibitDetail type="RFEResponse" /> : <Navigate to="/" replace />} />
            <Route path="/petition/:petitionId/settings" element={currentUser ? <Settings /> : <Navigate to="/" replace />} />
        </Routes>
    );
};

export default AppRoutes;
